import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { ErrorPlaceholder, withErrorBoundary } from '../general/util/ErrorBoundaries'
import track from 'react-tracking'
import { Link } from '../general/util/Links'
import Image from '../general/util/Image'
import HTML from '../general/util/HTML'

import './History.scss'

@track({ gtmContext: ['History'] })
class History extends Component {
  static propTypes = {
    isHorizontal: PropTypes.bool,
    card: PropTypes.object,
  }

  pos = { top: 0, left: 0, x: 0, y: 0 }
  preventClick = false

  constructor (props) {
    super(props)
    this.slider = React.createRef()
    if (props.card) {
      this.state = { historyData: props.card }
    } else {
      this.state = {}
    }
  }

  componentDidMount () {
    this._mounted = true
    this.timeOut = setInterval(() => {
      if (this._mounted) {
        this.positionUpdate()
      }
    }, 500)
    this.slider.addEventListener && this.slider.addEventListener('mousedown', this.mouseDownHandler)
  }

  componentWillUnmount () {
    this._mounted = false
    this.timeOut && clearInterval(this.timeOut)
  }

  positionUpdate = () => {
    if (this.slider.scrollLeft <= 0 && !this.state.startReached) {
      this.setState({ startReached: true })
    } else if (this.slider.scrollLeft > 0 && this.state.startReached) {
      this.setState({ startReached: false })
    }
    if (this.slider.parentElement && this.slider.scrollLeft >= this.slider.scrollWidth - this.slider.parentElement.offsetWidth && !this.state.endReached) {
      this.setState({ endReached: true })
    } else if (this.slider.parentElement && this.slider.scrollLeft < this.slider.scrollWidth - this.slider.parentElement.offsetWidth && this.state.endReached) {
      this.setState({ endReached: false })
    }
  }

  mouseDownHandler = (e) => {
    this.pos = {
      // The current scroll
      left: this.slider.scrollLeft,
      // Get the current mouse position
      x: e.clientX,
    }
    document.addEventListener('mousemove', this.mouseMoveHandler)
    document.addEventListener('mouseup', this.mouseUpHandler)
  }

  mouseMoveHandler = (e) => {
    const dx = e.clientX - this.pos.x
    this.slider.scrollLeft = this.pos.left - dx
    this.preventClick = true
  }

  mouseUpHandler = () => {
    document.removeEventListener('mousemove', this.mouseMoveHandler)
    document.removeEventListener('mouseup', this.mouseUpHandler)
    setTimeout(() => {
      this.preventClick = false
    }, 100)
  }

  render () {
    const { isHorizontal } = this.props

    if (!this.state.historyData) return null
    const { historyTitle, historyEvents, historyArticle, smallTitle } = this.state.historyData

    if (isHorizontal) {
      const matches = historyArticle.match(/^https?:\/\/[^/]+(\/[^?#]*)/)
      const pathname = matches[1]

      return <div styleName="history horizontal">

        <div styleName="header">
          <Link to={{ link: pathname }}>
            <h3>{historyTitle}</h3>
          </Link>
        </div>
        <div styleName="item-shader">
          <div styleName="items" ref={(ref) => {
            this.slider = ref
          }}>
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
            {historyEvents.map((item, idx) => <div styleName={item.event_large_image ? 'item large' : 'item'} key={idx}
              onClick={() => !this.preventClick && (window.location.href = pathname)}>
              <div styleName={item.event_large_image ? 'image-ball large' : 'image-ball'}>
                <Image data={item.event_image}/>
                {!item.event_large_image && <div styleName="divider"></div>}
              </div>
              <div styleName="title">{item.event_year}</div>
              <div styleName="short-text">{item.event_short}</div>
            </div>)}
          </div>
        </div>
        <div styleName="footer">
          <Link to={{ link: pathname }}>
            <button>Siirry aikajanalle</button>
          </Link>
        </div>
      </div>
    }
    return <div styleName="history vertical">
      <div styleName="items">
        {historyEvents.map((item, idx) => <div styleName="item" key={idx}>
          <div styleName="image-ball">
            <Image data={item.event_image}/>
          </div>
          <div styleName="texts">
            <div styleName={smallTitle ? 'title small-title' : 'title'}>{item.event_year}</div>
            <div styleName="long-text"><HTML>{item.event_long}</HTML></div>
          </div>
        </div>)}
      </div>
    </div>
  }
}

export default withErrorBoundary(
  History,
  ErrorPlaceholder()
)
