import debugRender from 'react-render-debugger'
import { isDevelopment } from '@otavamedia/om-component-library/lib/util/env'

function renderDebugger (component) {
  if (isDevelopment()) {
    return debugRender(component)
  }

  return component
}

export default renderDebugger
