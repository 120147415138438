import React, { Component } from 'react'
import PropTypes from 'prop-types'

import './ProductMemory.scss'
import { withRouter } from 'react-router-dom'
import img from '../../../assets/pictures/muisto-img.png'
import ProductMemories from './ProductMemories'
import { connect } from 'kea'
import auth from '../../../kea/auth'
import CustomForm from '../comments/CustomForm'

@connect({
  actions: [
    auth, [
      'updateModal',
      'setCommenting'
    ]
  ],
  props: [
    auth, [
      'loggedIn',
    ]
  ]
})
  /**
   * Displays a product memory card (muistokortti) and a form for readers to submit their own memories.
   * Shortcode parameter means card in placed in the article text using a short code.
   */
class ProductMemory extends Component {
  static propTypes = {
    card: PropTypes.object.isRequired,
    loggedIn: PropTypes.bool,
    actions: PropTypes.object,
    shortCode: PropTypes.bool
  }

  state = {
    showForm: false
  }

  render () {
    const { card, shortCode } = this.props

    if (!card || (card.shortCode && !shortCode)) {
      return null
    }
    return <div styleName="main">
      <ProductMemories id={card.id}/>
      <CustomForm
        id={card.id}
        thankYouTitle="Kiitos muistostasi."
        thankYouText="Muisto julkaistaan, kun toimitus on tarkastanut sen."
        header="Lisää muisto"
        instructions="Kerro meille muistostasi alla. Voit halutessasi lisätä muistoosi myös kuvan! Muistosi julkaistaan
            toimituksemme tarkastuksen jälkeen."
        showImage
        showTitle
        titleTitle="Muistosi otsikko"
        commentTitle="Kirjoita muistosi"
        commentPlaceholder="Kirjoita oma arviosi tuotteen ominaisuuksista, vahvuuksista ja heikkouksista. Älä käytä yli 5000 merkkiä."
        cancel={() => this.setState({ showForm: false })}
        anonTitle="Onko sinulla tähän juttuun liittyviä muistoja tai tarinoita?"
        anonText="Kerro meille niistä ja julkaisemme ne osana tätä artikkelia!"
        buttonText="Jaa muistosi"
        image={img}/>
    </div>
  }
}

export default withRouter(ProductMemory)
