import React, { Fragment, Component } from 'react'
import PropTypes from 'prop-types'
import iconURL from '../../../assets/ui/bars.svg'

import './Loading.scss'

export const Spinner = (props) => (
  <div styleName="spinner">
    <img
      src={iconURL}
      alt="loading"
    />
  </div>
)

export default class Loading extends Component {
  static propTypes = {
    ready: PropTypes.bool,
    children: PropTypes.node,
    loadingText: PropTypes.node,
    error: PropTypes.object,
    errorRenderer: PropTypes.func,
    className: PropTypes.string,
  }

  static defaultProps = {
    className: '',
  }

  render () {
    const {
      ready,
      children,
      error,
      errorRenderer: RenderedError,
      className,
      loadingText = null,
    } = this.props

    let node
    if (!ready && !error) {
      const cn = `loading ${className}`
      node = (
        <div styleName={cn}>
          {loadingText}
          <Spinner />
        </div>
      )
    } else if (error) {
      node = <RenderedError error={error} />
    } else if (ready) {
      node = <Fragment>{children}</Fragment>
    }

    return node
  }
}
