export const STATUS = {
  NOT_REQUESTED: 0,
  REQUESTED: 1,
  LOADING: 1, // alias for REQUESTED
  DONE: 2,
  ERROR: 3,
  EXPIRED: 4, // still usable
}

export const getTextStatus = statusNumber => Object.entries(STATUS).find(
  ([text, number]) => number === statusNumber
)[0]
