import React, { Component } from 'react'
import PropTypes from 'prop-types'

import ImageBase from '../util/Image'
import { IMAGE_SIZE } from '../../../entities/ImageModel'
import HTML from '../util/HTML'

import './ImageCollage.scss'

const Image = ({ className, ...props }) => ( // eslint-disable-line react/prop-types
  <div className={className}>
    <ImageBase {...props} addToGallery className="wp-image-collage"/>
  </div>
)

export default class ImageCollage extends Component {
  static propTypes = {
    count: PropTypes.number.isRequired,
    images: PropTypes.array.isRequired,
  }

  render () {
    const { images } = this.props

    const imageSizes = { LARGE: 'large', LEFT: 'left', RIGHT: 'right', LEFT2: 'left2', THUMB: 'thumbnail' }
    let imageSize = imageSizes.LARGE

    if (!images || !images.length) return null

    let idx = 0
    const output = []
    while (idx < images.length) {
      const image = images[idx]

      if (!images[idx].caption) {
        if (!idx) {
          output.push(<div styleName="row" key={idx}>
            <Image data={image} size={IMAGE_SIZE.LARGE} styleName="wrapper100" noHeight/>
          </div>)
          imageSize = imageSizes.LEFT
          idx++
        } else {
          const thumbs = []
          do {
            thumbs.push(<Image data={images[idx]} key={idx} size={IMAGE_SIZE.SMALL} styleName="thumb" noHeight/>)
            idx++
          } while (images[idx] && !images[idx].caption)
          output.push(<div key={idx} styleName={'row thumbs ' + (imageSize === imageSizes.LEFT2 ? 'right' : '')}>{thumbs}</div>)
        }
      } else {
        switch (imageSize) {
        case imageSizes.LARGE:
          output.push(<div styleName="row" key={idx}>
            <Image data={image} size={IMAGE_SIZE.LARGE} styleName="wrapper100" noHeight/>
            <div styleName="caption">
              <span styleName="arrow-up"></span> <HTML>{image.caption}</HTML>
            </div>
          </div>)
          imageSize = imageSizes.LEFT
          break
        case imageSizes.LEFT:
          output.push(<div styleName="row row-left" key={idx}>
            <Image data={image} size={IMAGE_SIZE.MEDIUM} styleName="wrapper50" noHeight/>
            <div styleName="side-caption wrapper50">
              <div>
                <span styleName="arrow-left"></span> <HTML>{image.caption}</HTML>
              </div>
            </div>
          </div>)
          imageSize = imageSizes.RIGHT
          break
        case imageSizes.RIGHT:
          output.push(<div styleName="row row-right" key={idx}>
            <div styleName="wrapper50 side-caption">
              <div>
                <span styleName="arrow-right"></span> <HTML>{image.caption}</HTML>
              </div>
            </div>
            <Image data={image} size={IMAGE_SIZE.MEDIUM} styleName="wrapper50" noHeight/>
          </div>)
          imageSize = imageSizes.LEFT2
          break
        case imageSizes.LEFT2:
          output.push(<div styleName="row row-left" key={idx}>
            <Image data={image} size={IMAGE_SIZE.MEDIUM} styleName="wrapper50" noHeight/>
            <div styleName="side-caption wrapper50">
              <div>
                <span styleName="arrow-left"></span> <HTML>{image.caption}</HTML>
              </div>
            </div>
          </div>)
          imageSize = imageSizes.LARGE
          break
        }
        idx++
      }
    }
    return <div styleName={'collage'}>{output} </div>
  }
}
