import React, { Component } from 'react'
import ForumPost from './ForumPost'
import { ErrorPlaceholderBox, withErrorBoundary } from '../util/ErrorBoundaries'
import './ForumBox.scss'

class ForumBox extends Component {
  render () {
    return <div styleName="main">
      <div styleName="header">Keskusteluissa</div>
      <div styleName="list">
        <ul>
          {dummyPosts.map((post) =>
            <li styleName="item" key={post.id}>
              <ForumPost forumPost={post} />
            </li>
          )}
        </ul>
      </div>
      <a href='/' styleName="more-link">Siirry keskustelut osioon<span styleName="arrow" /></a>
    </div>
  }
}

const dummyPosts = [
  {
    id: 1,
    title: 'Polkupyörällä pääsee',
    text: 'Sähköavusteisella fillarilla ei tulisi niin kovin hikikään... Täällä meilläpäin (Tre) tuntuu liikkuvan myös ihan itsestään (avustamatta) liikkuvia kaksipyöräisiä potkupyöriä ja fillareita kevyen liikenteen väylillä ympärivuotisesti. Viimeksi maanantaina näin jonkun ajavan fillarilla täysin polkematta. Jossain sivistysmaissa nuo saattaa olla laillisiakin, mutta meillä tuskin. Keskieuroopassa sähköavusteinen fillari saa kai kulkea 35 km/h ennen sähkömoottorin poiskytkeytymistä. Tuollaisella voisi ajella jo vähän pitempiäkin matkoja.',
    date: '2018-03-13T10:58:52',
  },
  {
    id: 2,
    title: 'Pelkällä polttomottorilla varustetut autot ovat tulleet tiensä päähän',
    text: 'Jos olet aikaasi seuraava kansalainen, olet varmaan huomannut, että liikenteen sähköistäminen on ollut vahvasti pinnalla niin mediassa kuin politiikassakin. Parin viimeisen vuoden sisällä ovat alkaneet kuolinkellot soida pelkällä polttomoottorilla varustetuille autoille niin lehdistössä kuin Eu:n komissiossakin. Samoin myös Yhdysvalloissa.',
    date: '2018-03-13T10:58:52',
  },
  {
    id: 3,
    title: 'Autovalmistajien tyhmyydellä ei ole rajoja',
    text: 'Jo vuosia sitten kuorma-autotehtaita jäi kiinni päästömittausten kiertämisestä. Moottorinohjaus tunnisti auton olevan testipenkissä ja sääti päästöjä (arvatenkin myös tehoja) sen mukaan.',
    date: '2018-03-13T10:58:52',
  },
]

export default withErrorBoundary(
  ForumBox,
  ErrorPlaceholderBox('Keskusteluja ei saatu ladattua')
)
