import get from 'lodash/get'
import isArray from 'lodash/isArray'
import AuthorModel from './AuthorModel'
import CategoryModel from './CategoryModel'
import TagModel from './TagModel'
import ImageModel from './ImageModel'
import HistoryModel from './HistoryModel'
import { addMetadata } from '../lib/transformers'
import WP from '../lib/WP'
import articleTypes from '../components/general/article/ArticleTypes'
import ComparisonModel from './ComparisonModel'
import { fixArticleUrl } from '../lib/url'
import { stripHTML } from '../lib/utils'
import some from 'lodash/some'

const resolveArticleType = ({ format, acf }, postType) => {
  if (~window.location.search.indexOf('featurev2') || (acf && acf.article_type === 'featurev2')) {
    return articleTypes.FEATUREV2
  } else if (~window.location.search.indexOf('feature') || (acf && acf.article_type === 'feature')) {
    return articleTypes.FEATURE
  } else if ((postType === 'om_digimag_post' || postType === 'om_theme_article') && format === 'aside') {
    return articleTypes.MAGAZINE_COMMENT
  } else if (postType === 'om_digimag_post' || postType === 'om_theme_article') {
    return articleTypes.MAGAZINE_ARTICLE
  } else if (postType === 'om_ad_article') {
    return articleTypes.MAGAZINE_ARTICLE
  } else if (format === 'standard' && acf && acf.article_type === 'erikoistaitto') {
    return articleTypes.SPECIAL
  } else if (format === 'aside') {
    return articleTypes.COMMENT
  } else {
    return articleTypes.DEFAULT
  }
}

/**
 * This creates a standardised model of the post data received from WP.
 * Different post types have different ACF fields in WP, and different
 * Rest API end points can return the data in different formats.
 *
 * An ArticleModel object can be displayed as an article page, or as a
 * 'card' in a listing.
 */
export default class ArticleModel {
  id
  slug
  link
  originalLink
  title
  titleOverride
  content
  ingress
  excerpt
  type
  createdDate
  modifiedDate
  author // AuthorModel
  photographer // AuthorModel
  photoCaption
  photoCopyright
  status
  sticky
  categories // Array<CategoryModel>
  tags // Array<TagModel>
  featuredMedia // ImageModel
  imageCollage //  ImageModel[]
  relatedArticles // Array<ArticleModel>
  shareCount
  commentCount
  _meta
  articleType
  assistants
  shareToken
  comparisons // deprecated
  printmag
  omAdMagazine
  omThemeMagazine
  headerVideoMp4
  headerVideoWebm
  headerVideoMobile
  sidestories
  hasHeroImage
  commentator // AuthorModel
  weightTable
  details
  requiredSubscriptionLevel
  linkedProductCards // Array<ArticleModel>
  linkedProductMemories
  linkedQuestions
  linkedHistoryCards
  cardOwnerPost // int
  postsCardIsLinkedTo // Array<int>
  otherProductCards // Array<int>
  productData // Array
  createToc
  forSubscribers
  nettixSearchId
  userLoggedIn
  productAds
  hideAds
  mobileHero
  disableAdCoin
  ogImage
  ogTitle
  ogDescription
  isPreview
  timelineTag
  timelineDigimag
  leikiData
  neuwoData
  stars
  reviewCount
  adCampaign
  allowCommenting
  parser
  comments
  categoriesAvailable
  originalArticle
  crossLinkingUrl

  // Question fields:
  commentPlaceholder
  commentTitle
  header
  instructions
  showImage
  showStars
  showTitle
  thankYouText
  thankYouTitle
  titleTitle
  authorTitle
  extraTitle
  extraTitleOnly
  extraTitleColour
  extraTitleSize
  extraVideo
  runningHead2
  whiteBackground
  hintaOpas
  tuumaAnswer
  tuumaQuestion
  tuumaOff
  paywallTexts
  linkedThemeBoxesData

  constructor (WPData, parser = 'default', validateUserState = false) {
    this.parser = parser
    switch (parser) {
    case 'default': // this parses article data
      this.defaultModelParser(WPData, validateUserState)
      break
    case 'raw': // parses data produced by a WP_Query in the backend
      this.rawModelParser(WPData)
      break
    case 'productcard': // post type om_product
      this.productCardModelParser(WPData)
      break
    case 'memorycard': // post type om_product_memory
      this.defaultModelParser(WPData, validateUserState)
      // There is no direct URL to a memory card, instead we want to link to point to a post that contains this card
      this.link = fixArticleUrl(WPData.acf.cardOwnerPostLink) || fixArticleUrl(get(WPData, 'om:postsCardIsLinkedToUrls', [])[0]) || this.link
      break
    case 'questioncard': // post type om_question
      this.defaultModelParser(WPData, validateUserState)
      // There is no direct URL to a question card, instead we want to link to point to a post that contains this card
      this.link = fixArticleUrl(get(WPData, 'om:postsCardIsLinkedToUrls', [])[0]) || this.link
      this.reviewCount = this.comments && this.comments.length
      break
    default:
      throw new Error('Invalid parser:', parser)
    }
  }

  defaultModelParser (WPData, validateUserState = false) {
    const WPModel = ArticleModel.transformPost(WPData)

    try {
      this.id = WPModel.id
      this.slug = WPModel.slug
      this.link = get(WPModel, 'acf.new_url', undefined) || WPModel.link
      this.crossLinkingUrl = get(WPModel, 'acf.crossLinking__url', undefined)
      this.originalLink = WPModel.link
      this.isPreview = (WPModel.link || '').includes('preview=true')
      this.title = WPModel['om:shortcut:title'] || get(WPModel, 'title.rendered') || get(WPModel, 'title') || ''
      this.titleOverride = get(WPModel, 'acf.title_override', undefined)
      this.mobileHero = get(WPModel, 'acf.mobile_hero') || null
      this.tuumaOff = get(WPModel, 'acf.tuuma_off') || false
      this.tuumaQuestion = get(WPModel, 'acf.tuuma_kysymys') || null
      this.tuumaAnswer = get(WPModel, 'acf.tuuma_vastaus') || null
      if (this.mobileHero) {
        this.mobileHero = new ImageModel(this.mobileHero)
      }
      this.content = get(WPModel, 'content.rendered', '') // Missing for related posts?
      // workaround to screen9 video + paywall
      this.content = this.content.replace('<div style="position: relative; width: 100%; padding-bottom: 59.25%;"/>', '')
      this.excerpt = get(WPModel, 'om:content_excerpt', get(WPModel, 'excerpt.rendered', null))
      this.forSubscribers = get(WPModel, 'asauth.is_paid') || WPData.is_paid || false
      this.requiredSubscriptionLevel = get(WPModel, 'asauth.required_access_level', 0)
      this.shareToken = get(WPModel, 'asauth.share_token')
      this.type = WPModel.type
      this.status = WPModel.status
      this.sticky = get(WPModel, 'acf.stick_to_frontpage') || get(WPModel, 'acf.stick_to_categories')
      this.ingress = (get(WPModel, 'acf.jutun_ingressi') || '').trim()
      this.disableAdCoin = true // get(WPModel, 'acf.disable_adcoin') || false
      this.adCampaign = get(WPModel, 'acf.ad_campaign') || ''
      this.authorTitle = get(WPModel, 'acf.author_title') || null
      this.vignette = (get(WPModel, 'acf.running_head') || '').trim()
      this.topic = (get(WPModel, 'acf.topic') || '').trim()
      this.photographer = new AuthorModel({ id: null, name: get(WPModel, 'acf.photographer', '') })
      this.commentator = new AuthorModel({ id: null, name: get(WPModel, 'acf.kolumnisti') || get(WPModel, 'acf.author.name') || get(WPModel, '_embedded.author[0].name') || 'Tekniikan Maailma' })
      this.assistants = get(WPModel, 'acf.assistants', []) || []
      this.scrollVideos = get(WPModel, 'acf.scroll_videos', []) || []
      if (this.assistants.length) {
        this.assistants = this.assistants.map(assistant => ({
          title: assistant.assistant_title,
          name: assistant.assistant_name
        }))
      }
      this.createdDate = WPModel.date
      this.modifiedDate = WPModel.modified
      this.allowCommenting = WPModel.comment_status === 'open'
      this.author = AuthorModel.createFromWPPost(WPModel).pop()
      this.categories = CategoryModel.createFromWPPost(WPModel) || []
      if (WPModel.category) {
        this.categories = [
          { name: WPModel.category }
        ]
      }
      this.isAd = this.type === 'om_ad_article' || (this.categories && this.categories.length && some(this.categories, { slug: 'mainos' }))
      if (this.isAd && (!this.author.name || !this.author.name.includes(':'))) {
        this.author.name = 'Mainos: ' + this.author.name
      }
      this.tags = TagModel.createFromWPPost(WPModel)
      const timelineTag = get(WPModel, 'acf.timeline')
      this.timelineTag = timelineTag ? new TagModel(timelineTag) : null
      this.timelineDigimag = timelineTag && get(WPModel, 'acf.timeline_digimag', false)
      this.imageCollage = ImageModel.createFromWPPost(WPModel)
      this.featuredMedia = this.imageCollage.shift()
      this.photoCaption = stripHTML(get(WPModel, 'acf.kuvat.caption', this.featuredMedia ? this.featuredMedia.caption : null))
      this.photoCopyright = get(WPModel, 'acf.kuvat.copyright', this.featuredMedia ? this.featuredMedia.copyright : null)
      try {
        this.relatedArticles = WPModel.relatedArticles && WPModel.relatedArticles.map(x => new ArticleModel(x, 'default', validateUserState))
      } catch (e) {
        this.relatedArticles = null
      }
      const readAlso = (get(WPModel, 'acf.read_also') || []).map(x => {
        return { link: x.url, title: x.title }
      })
      this.relatedArticles = (this.relatedArticles || []).concat(readAlso)

      const shared = get(WPModel, 'om:socialShares', 0)
      this.shareCount = typeof shared === 'number' ? shared : shared.share_count
      this.commentCount = get(WPModel, 'post_comments.comment_count', 0) || get(WPModel['om:post_comments'], 'comments_count', 0)
      this._meta = WPModel._meta
      this.articleType = resolveArticleType(WPData, WPModel.type)
      this.headerVideoMp4 = get(WPModel, 'acf.header_video_mp4', 0)
      this.headerVideoWebm = get(WPModel, 'acf.header_video_webm', 0)
      // this.headerVideoMobile = get(WPModel, 'acf.header_video_mp4', 0) || get(WPModel, 'acf.header_video_webm', 0)
      if (get(WPModel, 'acf.header_video_mobile_mp4', 0)) {
        this.headerVideoMobile = new ImageModel(get(WPModel, 'acf.header_video_mobile_mp4', 0))
      } else if (get(WPModel, 'acf.header_video_mobile_webm', 0)) {
        this.headerVideoMobile = new ImageModel(get(WPModel, 'acf.header_video_mobile_webm', 0))
      }
      if (!this.headerVideoMobile) {
        if (get(WPModel, 'acf.header_video_mp4', 0)) {
          this.headerVideoMobile = new ImageModel(get(WPModel, 'acf.header_video_mp4', 0))
        } else if (get(WPModel, 'acf.header_video_webm', 0)) {
          this.headerVideoMobile = new ImageModel(get(WPModel, 'acf.header_video_webm', 0))
        }
      }
      this.comparisons = (get(WPModel, 'acf.comparison') || []).map((item) => new ComparisonModel(item)) // deprecated
      this.printmag = get(WPModel, 'printmag', [])[0] || undefined
      this.omAdMagazine = get(WPModel, 'om_ad_magazine', [])[0] || undefined
      this.omThemeMagazine = get(WPModel, 'om_theme_magazine', [])[0] || undefined
      this.sidestories = get(WPModel, 'acf.sidestories') || []
      this.hasHeroImage = get(WPModel, 'acf.digimag.hasHeroImage', false)
      this.hideAds = get(WPModel, 'acf.no-ad', false) || this.isAd
      this.groupHeader = get(WPModel, 'acf.digimag.groupHeader', false)
      this.capitalizeTitle = get(WPModel, 'acf.digimag.capitalizeTitle', false)
      this.weightTable = get(WPModel, 'acf.painoarvotaulukko', null) || null
      this.details = get(WPModel, 'acf.details', []) || []
      this.linkedProductCards = ArticleModel.createFromLinkedProductCards(WPModel)
      this.linkedProductMemories = ArticleModel.createFromLinkedProductMemories(WPModel)
      this.linkedQuestions = ArticleModel.createFromLinkedQuestions(WPModel)
      this.linkedHistoryCards = (get(WPModel, 'om:linkedHistoryCards', []) || []).map(history => new HistoryModel(history))
      this.createToc = get(WPModel, 'acf.createToc', false)
      this.nettixSearchId = false // get(WPModel, 'acf.nettix.searchID', false)
      this.leikiData = get(WPModel, 'leiki_data', {})
      this.neuwoData = get(WPModel, 'neuwo_data', {})
      this.userLoggedIn = validateUserState ? get(WPModel, 'om:userLoggedIn') : undefined
      this.productAds = false // get(WPModel, 'acf.productAds', false)
      this.relatedAds = false /// get(WPModel, 'acf.relatedAds', false)
      this.ogImage = get(WPModel, 'og:image')
      this.ogTitle = get(WPModel, 'og:title')
      this.ogDescription = get(WPModel, 'og:description')
      this.comments = WPModel.comments && WPModel.comments[0]
      this.categoriesAvailable = get(WPModel, 'acf.categoriesAvailable')
      this.originalArticle = get(WPModel, 'om:originalArticle')
      this.runningHead2 = get(WPModel, 'acf.running_head_2')
      this.extraTitle = get(WPModel, 'acf.extra_title')
      this.extraTitleOnly = get(WPModel, 'acf.extra_title_only')
      this.extraTitleColour = get(WPModel, 'acf.extra_title_colour')
      this.extraTitleSize = get(WPModel, 'acf.extra_title_size')
      this.whiteBackground = get(WPModel, 'acf.white_background')
      this.extraVideo = get(WPModel, 'acf.extra_video') ? new ImageModel(get(WPModel, 'acf.extra_video')) : null
      this.priceGuide = this.createPriceGuide(WPModel)
      this.linkedThemeBoxesData = get(WPData, 'om:LinkedThemeBoxData', [])
      this.paywallTexts = get(WPData, 'acf.paywall_texts', [])
    } catch (e) {
      console.error('Failed to parse "default" model:', WPModel)
      throw e
    }
  }

  rawModelParser (WPData) {
    const WPModel = ArticleModel.transformRawPost(WPData)
    try {
      this.id = WPModel.ID
      this.slug = WPModel.slug
      this.link = WPModel.link
      this.title = WPModel.post_title
      this.content = WPModel.post_content
      this.excerpt = WPModel.post_excerpt
      this.forSubscribers = get(WPModel, 'asauth.is_paid') || WPData.is_paid || false
      this.requiredSubscriptionLevel = get(WPModel, 'asauth.required_access_level', 0)
      this.type = WPModel.post_type
      this.status = WPModel.post_status
      this.sticky = false
      this.ingress = null
      this.photographer = null
      this.createdDate = WPModel.post_date
      this.modifiedDate = WPModel.post_modified
      this.author = null
      this.categories = []
      this.tags = []
      this.featuredMedia = null
      this.photoCaption = null
      this.photoCopyright = null
      this.relatedArticles = []
      this._meta = WPModel._meta
      this.shareToken = get(WPModel, 'asauth.share_token')
      this.assistants = []
      this.shareCount = 0
      this.commentCount = 0
      this.articleType = resolveArticleType(WPData, WPModel.post_type)
      this.comparisons = []
      this.details = []
    } catch (e) {
      console.error('Failed to parse "related" model:', WPModel)
      throw e
    }
  }

  productCardModelParser (WPData, validateUserState = false) {
    const { cardOwnerPost, postsCardIsLinkedTo, productData, otherProductCards } = WPData.acf

    this.defaultModelParser(WPData, validateUserState)
    this.cardOwnerPost = cardOwnerPost ? cardOwnerPost[0] : null // array, because WP
    this.postsCardIsLinkedTo = postsCardIsLinkedTo
    this.otherProductCards = otherProductCards
    this.productData = new ComparisonModel(productData)
    this.productData.id = WPData.id
    this.productAds = [] // tuotemainokset || []
    this.relatedAds = [] // relatedAds || []

    this.commentPlaceholder = WPData.acf.commentPlaceholder
    this.commentTitle = WPData.acf.commentTitle
    this.header = WPData.acf.header
    this.instructions = WPData.acf.instructions
    this.showImage = WPData.acf.showImage
    this.showStars = WPData.acf.showStars
    this.showTitle = WPData.acf.showTitle
    this.thankYouText = WPData.acf.thankYouText
    this.thankYouTitle = WPData.acf.thankYouTitle
    this.titleTitle = WPData.acf.titleTitle
    this.anonTitle = WPData.acf.anonTitle
    this.anonText = WPData.acf.anonText
    this.buttonText = WPData.acf.buttonText

    this.productData = new ComparisonModel(WPData.acf.productData)
    this.imageCollage = WPData.acf.productData ? [new ImageModel(WPData.acf.productData.image)] : []
    this.featuredMedia = this.imageCollage.shift()
    this.link = fixArticleUrl(WPData.acf.cardOwnerPostLink) || this.link
    const stars = WPData.comments && WPData.comments[0]
      ? WPData.comments[0].reduce((result, x) => {
        return x.parent ? result : [result[0] + 1, result[1] + ((x.acf && parseInt(x.acf.tahdet)) || 0)]
      }, [0, 0])
      : [0, 0]
    this.stars = stars[0] ? Math.round(stars[1] / stars[0]) : 0
    this.reviewCount = stars[0]
    this.priceGuide = this.createPriceGuide(WPData)
  }

  createPriceGuide (WPData) {
    if (!WPData['om:hintaopas']) {
      return []
    }
    const hpc = get(WPData, 'om:hintaopas.hpc') || []
    return hpc.map(item => ({
      title: get(item, 'id.1'),
      id: get(item, 'id.0'),
      price: get(item, 'items.0.price.excl_shipping'),
    }))
  }

  static create (WPData, parser, validateUserState = false) {
    return new ArticleModel(WPData, parser, validateUserState)
  }

  static createFromRelatedArticle (WPData) {
    return new ArticleModel(WPData, 'raw')
  }

  static createFromRelatedArticles (WPModel) {
    const data = get(WPModel, 'acf.related_articles', [])
    return isArray(data) ? data.map((relatedArticle) => ArticleModel.createFromRelatedArticle(relatedArticle)) : []
  }

  static createFromLinkedProductCards (WPModel) {
    const data = get(WPModel, 'om:linkedProductCards', [])
    return data.map(x => x.acf ? new ArticleModel(x, 'productcard') : null).filter(x => x)
  }

  static createFromLinkedProductMemories (WPModel) {
    const data = get(WPModel, 'om:linkedProductMemories', [])
    return data.map(x => x.acf ? new ArticleModel(x, 'productcard') : null).filter(x => x)
  }

  static createFromLinkedQuestions (WPModel) {
    const data = get(WPModel, 'om:linkedQuestions', [])
    return data.map(x => x.acf ? new ArticleModel(x, 'productcard') : null).filter(x => x)
  }

  static transformPost (post) {
    if (post._embedded) {
      if (post._embedded['wp:term']) {
        const postTerms = post._embedded['wp:term'].filter(arr => arr.length !== 0)
        const taxonomies = postTerms.reduce((acc, terms) => {
          if (terms.length && terms[0]) {
            acc[terms[0].taxonomy] = terms
          }

          return acc
        }, {})

        Object.entries(taxonomies).forEach(([taxonomy, terms]) => {
          const key = WP.getRESTBase(taxonomy)
          post[key] = terms
        })

        post.terms = taxonomies
        delete post._embedded['wp:term']
      }

      if (post._embedded.related) {
        post.relatedArticles = post._embedded.related

        delete post._embedded.related
      }

      post.comments = post._embedded.replies || []
      delete post._embedded.replies
    }

    post.link = fixArticleUrl(post.link, post.status)

    return addMetadata('post', post)
  }

  static transformRawPost (post) {
    post.link = fixArticleUrl(post.post_link, post.status)
    return addMetadata('post', post)
  }
}
