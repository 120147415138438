import get from 'lodash/get'
import ImageModel from './ImageModel'

export default class HistoryModel {
  historyTitle
  historyArticle
  historyEvents
  smallTitle

  constructor (WPModel) {
    this.historyTitle = get(WPModel, 'acf.history_title') || ''
    this.historyArticle = get(WPModel, 'acf.history_article') || null
    this.smallTitle = get(WPModel, 'acf.history_small_title', false) || false
    this.historyEvents = get(WPModel, 'acf.history_events') || []
    this.historyEvents.forEach(event => {
      event.event_image = new ImageModel(event.event_image)
    })
  }
}
