import React, { Component } from 'react'
import PropTypes from 'prop-types'
import querystring from 'querystring'
import WP from '../lib/WP'
import { STATUS } from '../lib/request-state'
import { Link } from '../components/general/util/Links'
import HTML from '../components/general/util/HTML'

import './OrderConfirmation.scss'

// params may be empty, they don't exist if order failed
const orderWentThrough = params => (params.orderName !== undefined && params.orderRevenue !== undefined)

export default class OrderConfirmation extends Component {
  state = {
    page: STATUS.REQUESTED,
    params: null,
  }

  static propTypes = {
    setRendered: PropTypes.func.isRequired,
  }

  async componentDidMount () {
    const params = querystring.parse(window.location.search.replace('?', ''))
    let page

    try {
      if (orderWentThrough(params)) {
        page = WP.url + '/kiitos-tilauksesta'
      } else {
        page = WP.url + '/tilaus-epäonnistui'
      }

      const { data } = await WP.getForURL(page)
      this.setState({
        page: data,
        params,
      })
    } catch (e) {
      this.setState({
        page: STATUS.ERROR,
        params,
      })
    }

    this.props.setRendered(true)
  }

  componentWillUnmount () {
    this.props.setRendered(false)
  }

  render () {
    const { page, params } = this.state
    const Continue = () => <Link to={{ link: '/' }}>Jatka palveluun</Link>
    let node

    if (page === STATUS.REQUESTED) {
      node = (
        <p>Tilauksen tietoja ladataan...</p>
      )
    } else if (page === STATUS.ERROR) { // Failed to load page from WP
      if (orderWentThrough(params)) {
        node = (
          <p>
            Tilaus onnistui. <br />
            <Continue />
          </p>
        )
      } else {
        node = (
          <p>
            Ostotapahtuma keskeytyi, tilausta ei suoritettu loppuun. <br />
            <Continue />
          </p>
        )
      }
    } else {
      node = (
        <HTML>{page.content}</HTML>
      )
    }

    return (
      <div styleName="order-confirmation">
        {node}
      </div>
    )
  }
}
