import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'kea'

import application from '../../kea/application'
import HTML from '../general/util/HTML'
import { ErrorPlaceholder, withErrorBoundary } from '../../components/general/util/ErrorBoundaries'
import WP from '../../lib/WP'
import { STATUS } from '../../lib/request-state'

import './TopPicks.scss'
import some from 'lodash/some'
import { Link } from '../general/util/Links'
import track from 'react-tracking'
import auth from '../../kea/auth'

@track({ gtmContext: ['TopPicks'] })
@connect({
  props: [
    application, [
      'view',
    ],
    auth, [
      'premiumUser'
    ]
  ],
})
  /**
   * Displays link banners at the top of a page.  Data comes from Frontpage Settings is WP.
   */
class TopPicks extends Component {
  _mounted = true

  static propTypes = {
    view: PropTypes.object,
    canAccessArticle: PropTypes.func,
    isMagazineArticle: PropTypes.bool,
    excluded: PropTypes.array,
    premiumUser: PropTypes.bool,
  }

  static defaultProps = {
    excluded: [],
  }

  constructor (props) {
    super()
    this.loadTopPicks(props)
    this.state = {
      topPicks: [],
    }
  }

  async loadTopPicks () {
    try {
      WP.getTopPicks()
        .then((articles) => {
          if (this._mounted) {
            articles = articles.filter(article => {
              return (!article.article.id || (article.article.id !== this.props.view.id)) &&
                (article.is_premium ? this.props.premiumUser : true) &&
                (article.is_free ? !this.props.premiumUser : true)
            })
            this.setState({ topPicks: articles, status: articles ? STATUS.DONE : STATUS.ERROR })
          }
        })
        .catch((e) => this._mounted && this.setState({ status: STATUS.ERROR, error: e }))
    } catch (e) {
      console.log('Failed loading top picks', e)
    }
  }

  componentWillReceiveProps (nextProps) {
    this.loadTopPicks(nextProps)
  }

  componentDidMount () {
    this._mounted = true
  }

  componentWillUnmount () {
    this._mounted = false
  }

  isResearcher (article) {
    return some(article.categories, (cat) => cat.slug === 'tutkijalta')
  }

  render () {
    const { topPicks } = this.state

    return <Fragment>
      {topPicks && topPicks.length
        ? <div styleName="highlights">
          <Fragment>
            {topPicks.map((pick, idx) => (
              <Link to={pick.article} styleName="full-row" key={idx}>
                <span
                  styleName={'category' + (this.isResearcher(pick.article) ? ' researcher' : '')}>
                  {pick.article.categories[0] ? pick.article.categories[0].name : ''}
                </span>
                <HTML>{pick.article.title}</HTML>
              </Link>
            ))}
          </Fragment>
        </div>
        : null }
    </Fragment>
  }
}

export default withErrorBoundary(
  TopPicks,
  ErrorPlaceholder()
)
