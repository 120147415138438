import axios from 'axios'
import NProgress from 'nprogress'

NProgress.configure({
  easing: 'linear',
  speed: 350
})

window.NProgress = NProgress

const instance = axios.create({
  onDownloadProgress (e) {
    const percentage = Math.floor(e.loaded * 1.0) / e.total

    if (percentage === Infinity) {
      // NProgress.set(50)
      return
    }

    // NProgress.set(percentage)
  },

  onUploadProgress (e) {
    // const percentage = Math.floor(e.loaded * 1.0) / e.total
    // NProgress.set(percentage)
  },

  timeout: 50000,
})

instance.interceptors.response.use(response => {
  // NProgress.done(true)
  return response
})

delete instance.defaults.headers.common
export default instance
