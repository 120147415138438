import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import TabbedListWidget from '@otavamedia/om-component-library/lib/TabbedListWidget'

import { PaywallIcon, SubscriberIcon } from '../../widgets/Icons'
import WP from '../../../lib/WP'
import { errors as err } from '../../../lib/errors'
import { Link } from '../util/Links'
import HTML from '../util/HTML'
import { ErrorPlaceholderBox, withErrorBoundary } from '../util/ErrorBoundaries'
import { ShowAll } from '../../widgets/ShowAll'

import './PopularPosts.scss'
import track from 'react-tracking'

const StyledTabbedListWidget = styled(TabbedListWidget)`
  font-family: "Flama Semicond Semibold";
  background: white !important;
  margin-bottom: 1.5rem;

  .header {
    color: ${props => props.theme.colourTmDark};
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.3px;
    text-transform: uppercase;
    margin-bottom: 1rem;
    margin-top: 0;
  }

  .tabs {

    .tab-header {

      > div {

        button {
          height: 2.25rem;
          background: white;
          border: 1px solid lightgrey;
          border-bottom: none;
        }
        button:hover {
          color: #565e70;
          transition: color 0.1s ease;
        }

        button:focus {
          outline: none;
        }

        &:after {
          content: "";
          display: block;
          width: 100%;
          border-bottom: 4px solid ${props => props.theme.colourTmDark};
        }

        &.selected:after {
          border-bottom: 4px solid ${props => props.theme.colourTmRed};
        }

        &:first-child button {
          border-radius: 3px 0 0 0;
        }

        &:last-child button {
          border-radius: 0 3px 0 0;
        }
      }
    }
    .tab-content-list {

      .tab-content-list-item {
        padding-top: 1rem;
        padding-bottom: 1rem;
        border-bottom: 1px solid ${props => props.theme.colourTmLightgrey};

        .number {
          font-family: "Flama Semicond Semibold";
          font-size: 32px;
          line-height: 36px;
          padding-right: 0.75rem;
          padding-left: 0.25rem;
          color: ${props => props.theme.colourTmDarkgrey};
        }

        .title {
          h5 {
            font-size: 16px;
            line-height: 20px;
            margin: 0;
          }

          a:hover h5 {
            color: ${props => props.theme.colourTitleHover};
            transition: color 0.1s ease;
          }

          > span {
            border: none;
          }
          span:first-child {
            padding-right: 0.5rem;
          }
          span:last-child {
            padding-left: 0;
          }
        }
      }
    }
  }
`

const PopularPaid = styled(StyledTabbedListWidget)`
  h4.header {
    background: ${props => props.theme.colourTmDark};
    color: white;
    padding: 1rem;
    margin-bottom: 0;
    div {
      width: 16px;
      height: 16px;
      margin-right: 0.75rem;
    }
  }
`

// eslint-disable-next-line react/prop-types
const ItemComponent = ({ article }) => {
  return (
    <div className="title">
      <Fragment>
        <Link to={article} clickLocation="title">
          <h5>
            <span><HTML>{article.title}</HTML></span>
            {!!article.forSubscribers && (
              <div styleName="subscriber-icon-container"><SubscriberIcon/></div>
            )}
          </h5>
        </Link>
      </Fragment>
    </div>
  )
}
ItemComponent.propTypes = {
  article: PropTypes.object,
}

class PopularPosts extends Component {
  _mounted = false
  STATUS = {
    NOT_LOADED: 0,
    DONE: 1,
    ERROR: 2,
  }

  tabNameMap = {
    hour: '1 h',
    day: '24 h',
    week: 'Viikko',
  }

  tabNameMapPaid = {
    day: '24 h',
    week: 'Viikko',
    month: 'Kuukausi',
  }

  constructor (props) {
    super(props)
    this.state = {
      selectedColumn: props.paidOnly ? 'day' : 'hour',
      status: this.STATUS.NOT_LOADED
    }
  }

  loadData (props) {
    WP.getMostPopular('', props.paidOnly, props.limit > 5)
      .then((popularArticles) => {
        if (this._mounted) {
          this.setState({ popularArticles, status: popularArticles ? this.STATUS.DONE : this.STATUS.ERROR })
          this.props.doneLoading()
        }
      })
      .catch((e) => this._mounted && this.setState({ status: this.STATUS.ERROR, error: e }) && this.props.doneLoading())
  }

  componentDidMount () {
    this._mounted = true
    this.loadData(this.props)
  }

  componentWillReceiveProps (props) {
    if (props.paidOnly !== this.props.paidOnly || props.limit !== this.props.limit) {
      this.loadData(props)
    }
  }

  shouldComponentUpdate (nextProps, nextState) {
    return nextProps.paidOnly !== this.props.paidOnly || nextProps.limit !== this.props.limit || nextState !== this.state
  }

  componentWillUnmount () {
    this._mounted = false
  }

  render () {
    const { status, popularArticles } = this.state
    const { paidOnly, limit } = this.props
    const Element = paidOnly ? PopularPaid : StyledTabbedListWidget
    const headers = paidOnly ? this.tabNameMapPaid : this.tabNameMap

    if (status === this.STATUS.ERROR) throw err.popularLoadError({ previousError: this.state.error })

    const tabs = popularArticles && Object.keys(popularArticles).map((key) => {
      const column = popularArticles[key]

      if (column.length === 0) {
        return {
          title: headers[key],
          contentComponent: () => <p style={{ textAlign: 'center' }} className="empty">Tälle ajankohdalle ei vielä ole suosituimpia artikkeleita.</p>,
        }
      }

      return {
        title: headers[key],
        ItemComponent,
        itemComponentProps: {},
        itemProps: column.slice(0, limit || 5).map((article) => {
          return { article }
        }),
      }
    })

    return !!tabs && (
      <Fragment>
        <Element
          title={paidOnly ? <Fragment><PaywallIcon/>Luetuimmat tähtijutut</Fragment> : 'Suosituimmat'}
          tabs={tabs}
        />
        {limit || paidOnly ? null : <ShowAll url="/suosituimmat"/>}
      </Fragment>
    )
  }
}

PopularPosts.propTypes = {
  doneLoading: PropTypes.func.isRequired,
  paidOnly: PropTypes.bool,
  limit: PropTypes.number,
}

export default track({ gtmContext: ['PopularPosts'] })(withErrorBoundary(
  PopularPosts,
  ErrorPlaceholderBox('Suosittuja ei saatu ladattua'),
))
