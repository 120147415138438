import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'kea'
import application from '../kea/application'

export default @connect({
  actions: [
    application, [
      'setRendered'
    ],
  ],
})
class Placeholder extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
  }

  componentDidMount () {
    this.actions.setRendered(true)
  }

  render () {
    return <Fragment>{this.props.children}</Fragment>
  }
}
