import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { AdsForDesktop, AdsForMobile, AdSlotDesk1, AdSlotMob1 } from '../ads/Ads'
import application from '../../../kea/application'
import { connect } from 'kea'
import './TopRowAd.scss'

export default @connect({
  props: [
    application, [
      'ready as appReady',
    ],
  ]
})
class TopRowAd extends Component {
  static propTypes = {
    appReady: PropTypes.bool,
    isArticleView: PropTypes.bool,
    display: PropTypes.bool,
  }

  render () {
    if (!this.props.display) return null

    const displayHeaderAd = this.props.appReady
    return displayHeaderAd && <Fragment>
      <div styleName={this.props.isArticleView ? "top-ad" : "top-ad not-article"}>
        <AdsForDesktop>
          <AdSlotDesk1 loadInstantly={true}/>
        </AdsForDesktop>
        {!this.props.isArticleView && ( // mob-1 ad slot resides in article body, so lets not render it here
          <AdsForMobile>
            <AdSlotMob1 loadInstantly={true}/>
          </AdsForMobile>
        )}
      </div>
    </Fragment>
  }
}
