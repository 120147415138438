import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from '../general/util/Links'

import './PaywallBanner.scss'
import { connect } from 'kea'
import application from '../../kea/application'
import auth from '../../kea/auth'

@connect({
  props: [
    application, [
      'view',
    ],
    auth, [
      'shareTokenAccess',
    ],
  ],
})
class PaywallBanner extends Component {
  static propTypes = {
    showText: PropTypes.bool,
    shareTokenAccess: PropTypes.func,
    view: PropTypes.object
  }

  render () {
    const { showText, shareTokenAccess, view } = this.props

    if (!shareTokenAccess(view)) return null
    return <div styleName={'banner' + (showText ? '' : ' top-only')}>
      <div styleName="top-bar">Tämä on ilmaisnäyte TM:n maksullisesta sisällöstä</div>
      {showText
        ? <div styleName="text">
          <p styleName="text-header">Kiitos, että viihdyit Tekniikan Maailman artikkelin parissa!</p>
          <p>Tilaamalla Tekniikan Maailman pääset lukemaan kaikkea tilaajille tarkoitettu sisältöämme,
             kuten viikottain ilmestyvän <Link to={{ link: '/digilehdet/' }}>digilehden</Link>,
             vuoteen 1953 ulottuvan <Link to={{ link: '/arkisto/' }}>lehtiarkistomme</Link> sekä
             laajan <Link to={{ link: '/testit/' }}>testit-osiomme</Link>,
             jonne olemme koonneet tekemiämme <Link to={{ link: '/testit-autot/' }}>koeajoja</Link>,&nbsp;
          <Link to={{ link: '/testit/' }}>testejä</Link> ja <Link to={{ link: '/testivoittajat/' }}>testivoittajia</Link>.</p>
          <p><Link to={{ link: 'https://tilaus.tekniikanmaailma.fi/tilaus?promoID=OM_linkki&promoName=OM_TM-shared&promoCreative=onlinetarjous&promoPosition=shared' }}>Tutustu tarkemmin uusille tilaajille tarkoitettuihin tarjouksiimme täällä!</Link></p>
        </div>
        : null
      }
    </div>
  }
}

export default PaywallBanner
