import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import invert from 'lodash/invert'
import isError from 'lodash/isError'
import omit from 'lodash/omit'

import { connect } from 'kea'
import appLogic from '../kea/application'
import auth from '../kea/auth'
import { isAdministratorLikeUser } from './userManagement'
import HTML from '../components/general/util/HTML'
import { isDevelopment } from '@otavamedia/om-component-library/lib/util/env'
import './errors.scss'

import Page404 from '../components/general/error/404-page'
import Page403 from '../components/general/error/403-page'
import Page401 from '../components/general/error/401-page'
import {
  handleException as sentryHandleException,
  handleErrorBoundaryError as sentryHandleErrorBoundaryError,
} from './sentry'

/**
 * Available error severities:
 * severe: error that is generally unrecoverable from
 * significant: error that may be hard to recover from, but possible
 * minor: error that doesn't really matter
 */
const severity = {
  severe: 10,
  significant: 5,
  minor: 1,
}

const severe = error => ({ error, severity: severity.severe, })
const significant = error => ({ error, severity: severity.significant })
const minor = error => ({ error, severity: severity.minor })

/**
 * Errors from external sources or libraries
 */
const external = {
  AXIOS_404: severe('Request failed with status code 404'),
  RESOLVER_POST_NOT_FOUND: minor('No post found.'),
}

/*
 * Our errors
 */
const internal = {
  UNKNOWN_ERROR: severe('Tuntematon virhe'),
  INIT_ERROR: severe('Virhe käynnistettäessä sivustoa'),
  INVALID_RESPONSE: severe('Palvelimelta tuli hassu vastaus'),
  FORBIDDEN: significant('Pääsy kielletty'),
  NOT_FOUND: severe('Ei löytynyt'),
  NO_RESPONSE: significant('Palvelin ei vastannut'), // Timeout or 500 error, can't tell the difference
  SOME_ERROR: minor('Muu virhe?'),
  UNABLE_TO_LOAD_STICKY: minor('Artikkeleita ei saatu ladattua'),
  UNABLE_TO_LOAD_COMMENTS: minor('Kommentteja ei saatu ladattua'),
  UNABLE_TO_LOAD_POPULAR: minor('Suosituimpia artikkeleita ei saatu ladattua'),
  CONTEXT_MISSING: significant('Konteksti puuttuu'),
  TOO_EARLY_RACE_CONDITION: severe('Funktiota kutsuttiin liian aikaisin'),
  INVALID_CONTEXT: minor('Virheellinen konteksti'),
  UNKNOWN_CONTENT_TYPE: minor('Tuntematon "contentType"'),
  UNKNOWN_SIZE: minor('Tuntematon koko'),
  INVALID_CREDENTIALS: significant('Väärä käyttäjätunnus tai salasana'),
  BAD_REQUEST: significant('Virheellinen kutsu'),
  NO_RESULTS: minor('Ei tuloksia'),
}

const types = {
  ...external,
  ...internal,
}

/**
 * Create an error using a type constant from `types`.
 * Allows embedding data in the error
 */
const createError = (type, data) => {
  const msg = types[type] ? types[type].error : types.UNKNOWN_ERROR.error
  const error = new Error(msg)
  error.severity = getSeverity(type)

  if (data) {
    error.data = data
  }

  return error
}

const copyError = error => {
  const { message, data } = error
  const type = getType(message)
  return createError(type, data)
}

/**
 * Numeric severity is stored in state and so on
 */
const getNumericSeverity = type => types[type] ? types[type].severity : false

/**
 * Get human readable severity
 */
const getSeverity = type => {
  const numeric = getNumericSeverity(type)

  if (!numeric) {
    return false
  }

  return invert(severity)[numeric]
}

/**
 * Get error type (`types`) from the message
 */
const getType = msg => {
  const type = Object.entries(types).find(([type, eObj]) => msg === eObj.error)

  if (type) {
    return type[0]
  }

  return false
}

/*
 * Human readable severity checks
 */
const is = {
  severe: type => getNumericSeverity(type) === severity.severe,
  significant: type => getNumericSeverity(type) === severity.significant,
  minor: type => getNumericSeverity(type) === severity.minor,
  error: x => isError(x),
}

/*
 * Convenience methods for throwing different errors
 * Instead of using createError elsewhere, add your error here, unless
 * you're using it dynamically w/ getType
 */
const errors = {
  noResponseError: (data) => createError('NO_RESPONSE', data),
  invalidResponseError: (data) => createError('INVALID_RESPONSE', data),
  invalidCredentialsError: (data) => createError('INVALID_CREDENTIALS', data),
  forbiddenResponseError: (data) => createError('FORBIDDEN', data),
  notFoundResponseError: (data) => createError('NOT_FOUND', data),
  initError: (data) => createError('INIT_ERROR', data),
  stickyLoadError: (data) => createError('UNABLE_TO_LOAD_STICKY', data),
  popularLoadError: (data) => createError('UNABLE_TO_LOAD_POPULAR', data),
  nettixEmbedLoadError: (data) => createError('UNABLE_TO_LOAD_NETTIX_EMBED', data),
  editorsPicksLoadError: (data) => createError('UNABLE_TO_LOAD_EDITORS_PICKS', data),
  noContextError: (data) => createError('CONTEXT_MISSING', data),
  tooEarlyRaceConditionError: (data) => createError('TOO_EARLY_RACE_CONDITION', data),
  invalidContextError: (data) => createError('INVALID_CONTEXT', data),
  unknownContentTypeError: (data) => createError('UNKNOWN_CONTENT_TYPE', data),
  unknownSizeError: (data) => createError('UNKNOWN_SIZE', data),
  badRequestError: (data) => createError('BAD_REQUEST', data),
  unableToLoadComments: (data) => createError('UNABLE_TO_LOAD_COMMENTS', data),
  noResultsError: (data) => createError('NO_RESULTS', data),
}

@connect({
  actions: [
    appLogic, [
      'setRendered'
    ],
  ],
  props: [
    auth, [
      'userRoles',
    ],
  ],
})
class RenderedError extends Component {
  static propTypes = {
    error: PropTypes.instanceOf(Error),
    alternative: PropTypes.node,
    userRoles: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    setRendered: PropTypes.func,
  }

  state = {
    compact: !isDevelopment(),
  }

  toggleType = () => {
    const { compact } = this.state
    // console.log('called', !compact)

    this.setState({
      compact: !compact,
    })
  }

  renderStackTrace (error) {
    const { name, message, severity, data, stack } = error
    const { previousError } = data || {}

    return (
      <Fragment>
        <strong>Error: </strong>{name}<br />
        <strong>Message: </strong>{message}<br />
        <strong>Severity: </strong>{severity}<br />
        <strong>Data: </strong><br />
        <pre>
          <code>
            {JSON.stringify(omit(data, ['previousError']), null, 2)}
          </code>
        </pre>

        <strong>Stack: </strong>
        <HTML>{stack && stack.replace('\n', '<br />')}</HTML>

        {previousError
          ? (
            <div styleName="previousError">
              <strong>Previous error: </strong><br />
              {this.renderStackTrace(previousError)}
            </div>
          )
          : false}
      </Fragment>
    )
  }

  otherError ({ error }) {
    return (
      <div>
        <div styleName="meta">Hups!</div>
        <h1>Tapahtui virhe</h1>
        <p styleName="large-font">{error.message}</p>
      </div>
    )
  }

  render () {
    const { error, userRoles } = this.props
    const { setRendered } = this.actions
    const { compact } = this.state

    const type = getType(error.message)
    switch (type) {
    case 'FORBIDDEN': {
      return <Page403 setRendered={setRendered} />
    }

    case 'INVALID_CREDENTIALS': {
      return <Page401 setRendered={setRendered} />
    }

    case 'NOT_FOUND': {
      return <Page404 setRendered={setRendered} />
    }

    case 'RESOLVER_POST_NOT_FOUND': {
      return <Page404 setRendered={setRendered} />
    }

    default: {
      // continue
    }
    }

    return (
      <div styleName='error'>
        {compact ? this.otherError(this.props) : this.renderStackTrace(error)}
        <br/>
        {(isAdministratorLikeUser(userRoles) || !compact) && (
          <button key="errorToggle" onClick={() => this.toggleType()}>
            {compact ? 'Lisätietoja' : 'Vähemmän'}
          </button>
        )}
      </div>
    )
  }
}

const renderError = (error, alternative) => {
  return <RenderedError error={error} alternative={alternative} />
}

const logException = (error) => {
  sentryHandleException(error)
}

const logErrorBoundaryError = (error, info) => {
  sentryHandleErrorBoundaryError(error, info)
}

export default createError
export {
  errors,
  types,
  is,
  getType,
  getNumericSeverity,
  getSeverity,
  severity,
  createError,
  copyError,
  RenderedError,
  renderError,
  logException,
  logErrorBoundaryError,
}
