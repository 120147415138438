import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { TextRow, RectShape } from 'react-placeholder/lib/placeholders'

import { ErrorPlaceholder, withErrorBoundary } from '../general/util/ErrorBoundaries'
import Image from '../general/util/Image'
import { Link } from '../general/util/Links'
import HTML from '../general/util/HTML'
import Paywall from '../widgets/Paywall'
import { IMAGE_SIZE } from '../../entities/ImageModel'

import './MagazineContentsPreview.scss'

class MagazineContentsPreview extends Component {
  render () {
    const { magazine } = this.props
    let pagePairs
    const links = []

    if (magazine && magazine.pages) {
      pagePairs = [{
        left: <div styleName="page-count"><span>{magazine.name}</span><span>{`${magazine.pages.length} sivua`}</span>
        </div>,
        right: <Link to={magazine.pages[0]}><Image data={magazine.pages[0].image} size={IMAGE_SIZE.THUMBNAIL} noSrcSet noHeight/></Link>
      }]

      for (let i = 1; i < magazine.pages.length; i += 2) {
        if (i === magazine.pages.length - 1) {
          pagePairs.push({
            left: <Link to={magazine.pages[i]}><Image data={magazine.pages[i].image} size={IMAGE_SIZE.THUMBNAIL}
              sizes="150px" noHeight/></Link>,
            right: null,
          })
        } else {
          pagePairs.push({
            left: <Link to={magazine.pages[i]}><Image data={magazine.pages[i].image} size={IMAGE_SIZE.THUMBNAIL}
              sizes="150px" noHeight/></Link>,
            right: <Link to={magazine.pages[i + 1]}><Image data={magazine.pages[i + 1].image}
              size={IMAGE_SIZE.THUMBNAIL} noSrcSet noHeight/></Link>,
          })
        }
      }
      const nameA = magazine.name.replace('/', 'A/').replace(/^0/, '')
      const nameB = magazine.name.replace('/', 'B/').replace(/^0/, '')
      links.push({
        name: nameA,
        url: nameA.replace('/', '-').toLowerCase()
      })
      links.push({
        name: nameB,
        url: nameB.replace('/', '-').toLowerCase()
      })
    }

    return (
      <div styleName="main">
        {(!magazine || !magazine.pages) && (!magazine || !magazine.behindPaywall)
          ? <MagazineContentsPreviewLoader/>
          : (
            <Fragment>
              <div styleName={`left-col ${magazine.behindPaywall ? 'paywall-container' : ''}`}>
                {
                  !magazine.behindPaywall
                    ? (
                      <div styleName="pages">
                        <ul>
                          {pagePairs.map((page, index) => {
                            return (
                              <li key={`page-thumbnail-${index}`}>
                                <div styleName="page-left">
                                  {page.left}
                                </div>
                                <div styleName="page-right">
                                  {page.right}
                                </div>
                              </li>
                            )
                          })}
                        </ul>
                      </div>
                    )
                    : (
                      <Paywall disableAdCoin={true}/>
                    )
                }
              </div>
            </Fragment>
          )}
        {!magazine || !magazine.pages
          ? <MagazineContentsTocLoader/>
          : (
            <Fragment>
              <div styleName="right-col">
                <div styleName="toc">
                  <div styleName="toc-header">
                    <h2 styleName="toc-title">
                      {magazine.name}
                    </h2>
                    {
                      magazine.digimagUrl
                        ? <a styleName="digimag-link" href={magazine.digimagUrl}>Lue digilehtenä</a>
                        : magazine.year > 2017
                          ? <div>
                            <a styleName="digimag-link" href={'/lehti/' + links[0].url}>Lue digilehti {links[0].name}</a>
                            <br/>
                            <a styleName="digimag-link" href={'/lehti/' + links[1].url}>Lue digilehti {links[1].name}</a>
                          </div>
                          : null
                    }
                  </div>
                  <ul styleName="toc-list">
                    {magazine.pages.map((page, index) => {
                      return page.showInToc
                        ? (
                          <li key={`toc-page-${page.id}`}>
                            <Link to={page}>
                              <div styleName="toc-list-page">{page.number}</div>
                              {
                                page.excerpt
                                  ? <div styleName="toc-list-title">
                                    <HTML>{`<strong>${page.title}</strong>${page.excerpt}`}</HTML></div>
                                  : <div styleName="toc-list-title"><HTML>{page.title}</HTML></div>
                              }
                            </Link>
                          </li>
                        )
                        : null
                    })}
                  </ul>
                </div>
              </div>
            </Fragment>
          )}
      </div>
    )
  }
}

MagazineContentsPreview.propTypes = {
  magazine: PropTypes.object,
  premiumUser: PropTypes.bool,
}

export default withErrorBoundary(
  MagazineContentsPreview,
  ErrorPlaceholder(null)
)

const MagazineContentsTocLoader = () => {
  const linkPlaceholders = Array(30).fill().map((x, i) =>
    <li key={i}>
      <TextRow color="black" style={{ width: '100%' }}/>
    </li>
  )

  return (
    <div styleName="right-col">
      <div styleName="toc">
        <div styleName="toc-header loader">
          <TextRow color="black" style={{ width: '100%' }}/>
        </div>
        <ul styleName="toc-list loader">
          {linkPlaceholders}
        </ul>
      </div>
    </div>
  )
}

const MagazineContentsPreviewLoader = () => {
  const pagePlaceholders = Array(50).fill().map((x, i) =>
    <li key={i} styleName="loader">
      <RectShape color="grey" style={{ width: '100%', height: 109 }}/>
    </li>
  )
  return (
    <div styleName="left-col">
      <div styleName="pages">
        <ul>
          {pagePlaceholders}
        </ul>
      </div>

    </div>
  )
}
