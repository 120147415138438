import PropTypes from 'prop-types'
import { kea } from 'kea'
import { channel, buffers, eventChannel } from 'redux-saga'
import { put, take } from 'redux-saga/effects'
import querystring from 'querystring'
import { USER_ROLE_LOGGED_IN, USER_ROLE_PREMIUM, USER_ROLE_ADMINISTRATOR_LIKE, USER_ROLE_READER } from '../lib/userManagement'
import { STATUS } from '../lib/request-state'
import isMatch from 'lodash/isMatch'
import WP from '../lib/WP'
import req from '../lib/req'
import DiskStorage, { userData } from '../lib/DiskStorage'
import { getURL } from '../lib/WPClient'
import { generateUniqueId } from '../lib/utils'

export const defaultModalData = {
  open: false,
  responseText: null,
}

const defaultUserData = {
  data: {},
  oauth: {},
}

let authInterceptor = null
let tabId = null

export const getAuthHeader = (tokenType, token, withCredentials) => {
  return withCredentials
    ? {
      Authorization: `${tokenType} ${token}`,
    }
    : {}
}

export const setAuthInterceptor = (tokenType, token) => {
  authInterceptor = req.interceptors.request.use(config => {
    // only send auth header to our WP backend
    return config.url.indexOf(getURL()) > -1
      ? {
        ...config,
        headers: {
          ...config.headers,
        },
      }
      : {
        ...config,
        headers: {
          ...config.headers,
        }
      }
  })

  return authInterceptor
}

const store = new DiskStorage(userData)
const responseChannel = channel(buffers.expanding())

window.isMatch = isMatch

export default kea({
  paths: ['auth'], // default
  actions: () => ({
    startAuth: () => true,
    updateUser: (user, save = true) => ({ ...user, save }),
    updateUserAuthenticationStatus: status => status,
    updateUserFromWP: () => ({}),
    receiveMessage: (event) => ({ event }),
    remoteLogin: (username, password) => ({ username, password }),
    setCommenting: (newState = false) => ({ newState }),
    updateModal: (obj) => ({ ...obj }),
  }),

  reducers: ({ actions }) => ({
    user: [defaultUserData, PropTypes.object, { persist: false }, {
      [actions.updateUser]: (state, { save, ...user }) => ({ ...state, ...user }),
    }],

    userAuthenticationStatus: [STATUS.NOT_REQUESTED, PropTypes.number, {
      [actions.updateUserAuthenticationStatus]: (state, status) => status,
    }],

    modal: [defaultModalData, PropTypes.object, { persist: false }, {
      [actions.updateModal]: (state, payload) => ({ ...state, ...payload }),
    }],
    isCommenting: [false, PropTypes.bool, {
      [actions.setCommenting]: (state, { newState }) => {
        return newState
      }
    }],
  }),
  start: function * () {
    // console.log('auth start running')
    while (true) {
      const action = yield take(responseChannel)
      yield put(action)
    }
  },
  takeLatest: ({ actions }) => ({
    [actions.startAuth]: function * () {
      if (!tabId) {
        tabId = generateUniqueId()
      }

      const chan = eventChannel(emitter => {
        window.addEventListener('storage', (message) => emitter(message))
        return () => {}
      })

      sessionStorage.setItem('tabId', tabId)

      try {
        // 4. Initialize user, if there is one
        yield put(this.actions.updateUserFromWP())

      } catch (e) {
        console.log(e)
      }
      while (true) {
        const message = yield take(chan)
        yield put(this.actions.receiveMessage(message))
      }
    },
    [actions.remoteLogin]: function * (action) {
      const { username, password } = action.payload
      window.location = getURL('/asauth/remote-login/?email=' + username + '&token=' + password)
    },
    [actions.receiveMessage]: function * ({ payload }) {
      const { event } = payload
      if (event.key !== 'wp-react-auth') return
      const message = JSON.parse(event.newValue)
      if (!message || message.data === tabId) return // if message sender is this tab, ignore it
      yield WP.updateNonce()
      yield put(this.actions.updateUserFromWP())
    },
    [actions.updateUserFromWP]: function * () {
      try {
        const data = yield WP.getUser()
        const oldUser = yield this.get('user')
        const newUser = { ...oldUser, data }
        yield put(actions.updateUser({ ...newUser, save: true }))
        const roles = yield this.get('userRoles')
        WP.setUserRoles(roles)
        const gtmData = {
          UserLevel: ((newUser && newUser.data && newUser.data.asauth && newUser.data.asauth.access_level) || 1).toString(),
        }
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push(gtmData)

        yield put(actions.updateModal(defaultModalData))
        if (newUser.oauth && newUser.oauth.access_token) {
          const {
            access_token: accessToken,
            token_type: tokenType,
          } = newUser.oauth

          setAuthInterceptor(tokenType, accessToken)
        }
        // 4. Done initializing user
        localStorage.setItem('wp-react-auth', JSON.stringify({ command: 'startAuth', data: tabId }))
        localStorage.removeItem('wp-react-auth')
      } catch (e) {
        console.log('Exception in updateUserFromWP')
        console.log(e)
        const user = yield this.get('user')
        const gtmData = {
          UserLevel: ((user && user.data && user.data.asauth && user.data.asauth.access_level) || 1).toString(),
        }
        WP.setUserRoles([])
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push(gtmData)

        if (user.data && user.data.asauth) {
          yield put(actions.updateUser(defaultUserData))
        }
      }
    },
    [actions.updateUser]: function * ({ payload }) {
      const { save, ...user } = payload

      if (save === true) {
        if (user && user.data && user.data.asauth && user.data.asauth.access_level > 2) {
          document.cookie = 'rememberPremium=1'
        }
        yield store.put('user', { saveTime: Date.now() }, user)
      }
    },
  }),

  selectors: ({ selectors }) => ({
    loggedIn: [
      () => [selectors.user],
      (user) => {
        return Boolean(Object.keys(user.data).length)
      },
      PropTypes.bool,
    ],
    premiumUser: [
      () => [selectors.user],
      (user) => {
        if (user.data && user.data.asauth) {
          const { asauth } = user.data
          const { access_level: level } = asauth

          return level > 2 // Users with level 3 are paying customers
        }

        return false
      },
      PropTypes.bool,
    ],
    accessLevel: [
      () => [selectors.user],
      (user) => {
        if (user.data && user.data.asauth) {
          const { asauth } = user.data
          const { access_level: level } = asauth

          return level
        }

        return 1
      },
      PropTypes.number
    ],
    canAccessArticle: [
      () => [selectors.accessLevel],
      (accessLevel) => (model) => {
        const { requiredSubscriptionLevel, shareToken, userLoggedIn } = model
        const qs = querystring.parse(window.location.search.substring(1))

        if (shareToken && qs.shared && qs.shared.substr(0, 15) === shareToken.substr(0, 15)) {
          return true
        } else {
          const cookieLevelCheck = document.cookie.match(/asauth_temporary_access=([^;]+)/)
          if (cookieLevelCheck && cookieLevelCheck[1]) {
            WP.setUserRoles([USER_ROLE_READER])
            return true
          }
        }
        if (userLoggedIn && userLoggedIn !== true) {
          return true
        }
        return accessLevel >= requiredSubscriptionLevel
      },
      PropTypes.func
    ],
    shareTokenAccess: [
      () => [selectors.accessLevel],
      (accessLevel) => (model) => {
        const { forSubscribers, requiredSubscriptionLevel, shareToken } = model
        const qs = querystring.parse(window.location.search.substring(1))

        if (!forSubscribers) {
          return false
        } else if (accessLevel >= requiredSubscriptionLevel) {
          return false
        } else if (shareToken && qs.shared && qs.shared.substr(0, 15) === shareToken.substr(0, 15)) {
          return true
        }
        return false
      },
      PropTypes.func
    ],
    userRoles: [
      () => [selectors.loggedIn, selectors.premiumUser, selectors.user],
      (loggedIn, premiumUser, user) => {
        const access = []
        if (loggedIn) access.push(USER_ROLE_LOGGED_IN)
        if (premiumUser) access.push(USER_ROLE_PREMIUM)

        if (user.data && user.data['om:roles']) {
          const roles = user.data['om:roles']

          if (roles.includes('administrator')) {
            access.push(USER_ROLE_ADMINISTRATOR_LIKE)
          }
        }

        return access
      },
      PropTypes.array
    ],
    username: [
      () => [selectors.user, selectors.loggedIn],
      (user, loggedIn) => {
        if (loggedIn) {
          return user.data.name
        }

        return null
      },
      PropTypes.string
    ],
    userData: [
      () => [selectors.user, selectors.loggedIn],
      (user, loggedIn) => {
        if (loggedIn) {
          return user.data
        }

        return null
      },
      PropTypes.object
    ],
  })
})
