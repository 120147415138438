import PropTypes from 'prop-types'
import { kea } from 'kea'
import { channel, buffers } from 'redux-saga'
import { put, take } from 'redux-saga/effects'
import { LOCATION_CHANGE } from 'react-router-redux'

import WP from '../lib/WP'
import { STATUS } from '../lib/request-state'
import { errors as err } from '../lib/errors'

const responseChannel = channel(buffers.expanding())

const initialForms = {
  'mvp-palaute': {
    status: STATUS.NOT_REQUESTED,
    data: null,
  },
  bugiraportti: {
    status: STATUS.NOT_REQUESTED,
    data: null,
  }
}

export default kea({
  paths: ['feedback'],
  actions: () => ({
    loadForm: slug => slug,
    updateAssetStatus: status => status,
    updateForm: (form, status) => ({ form, status }),
    updateReferrer: href => href,
  }),

  reducers: ({ actions }) => ({
    assetStatus: [STATUS.NOT_REQUESTED, PropTypes.number, {
      [actions.updateAssetStatus]: (state, status) => status,
    }],
    formStatus: [STATUS.NOT_REQUESTED, PropTypes.object, {
      [actions.updateFormStatus]: (state, status) => status,
    }],
    forms: [initialForms, PropTypes.object, {
      [actions.updateForm]: (state, { form, status }) => ({
        ...state,
        [form.slug]: {
          status, data: form.content
        }
      }),
    }],
    referrer: ['', PropTypes.string, {
      [actions.updateReferrer]: (state, href) => href,
    }]
  }),

  start: function * () {
    yield put(this.actions.updateAssetStatus(STATUS.NOT_REQUESTED))
    yield put(this.actions.updateReferrer(window.location.pathname))
    while (true) {
      const action = yield take(responseChannel)
      yield put(action)
    }
  },

  takeEvery: ({ actions }) => ({
    [actions.loadForm]: function * ({ payload: slug }) {
      try {
        const wplfInitializeStatus = yield this.get('assetStatus')

        if (wplfInitializeStatus === STATUS.NOT_REQUESTED) {
          yield put(actions.updateAssetStatus(STATUS.REQUESTED))
          window.ajax_object = {
            ajax_url: `${WP.url}/wp-admin/admin-ajax.php`,
            ajax_credentials: 'include', // different origin
            wplf_assets_dir: `${WP.url}/wp-content/plugins/wp-libre-form/assets`,
          }

          yield new Promise((resolve, reject) => {
            const script = document.createElement('script')
            const timeout = setTimeout(reject, 30000)
            script.src = `${WP.url}/wp-content/plugins/wp-libre-form/assets/scripts/wplf-form.js`
            script.onload = (e) => {
              clearInterval(timeout)
              resolve()
            }

            document.body.appendChild(script)
          })

          yield put(actions.updateAssetStatus(STATUS.DONE))
        }

        yield put(actions.updateForm(
          { slug, data: null },
          STATUS.NOT_REQUESTED
        ))

        const { data: [form] } = yield WP.getFromPostTypes('wplf-form', {
          proxysettings: {
            prefetch: false,
            expiry: 30,
          },
          slug,
          per_page: 1,
          clientSettings: {
            skipErrorHandling: true,
          }
        })

        if (form && form.id) {
          yield put(actions.updateForm(form, STATUS.DONE))
        } else {
          throw err.invalidResponseError({ form })
        }
      } catch (e) {
        console.log(`Loading ${slug} form failed`, e)
      }
    },
  }),

  takeLatest: ({ actions }) => ({
    [LOCATION_CHANGE]: function * ({ payload }) {
      yield put(actions.updateReferrer(payload.pathname))
    },
  }),

  selectors: ({ selectors }) => ({
    assetsLoaded: [
      () => [selectors.assetStatus],
      (status) => {
        return status === STATUS.DONE
      },
      PropTypes.bool,
    ],
    formLoaded: [
      () => [selectors.formStatus],
      (status) => {
        return status === STATUS.DONE
      },
      PropTypes.bool,
    ],
    isLoaded: [
      () => [selectors.forms],
      (forms) => (slug) => {
        return forms[slug].status === STATUS.DONE
      },
      PropTypes.func,
    ],
    form: [
      () => [selectors.forms],
      (forms) => (slug) => {
        return forms[slug].data
      },
      PropTypes.func,
    ],
  }),
})
