import React, { Component } from 'react'
import PropTypes from 'prop-types'
import AdComponent from '@otavamedia/om-component-library/lib/ProductAd'
import AdManager from '../ads/AdManager'
import styled from 'styled-components'

import { Link } from '../util/Links'
import PowerLogo from '../../../assets/logos/power-logo.svg'
import DNALogo from '../../../assets/logos/dna-logo.svg'
import VerkkokauppaLogo from '../../../assets/logos/Verkkokauppa.com_logo.svg'

import './ProductAd.scss'

const StyledAd = styled(AdComponent)`
  border: 1px solid ${props => props.theme.colourArticleBorder};
  padding: 0.5rem 1rem;
  margin-bottom: 3rem;
  a {
    display: block;
    text-decoration: none !important;
    color: ${props => props.theme.colourTmDark};
  }

  .logo, .content, .price, .link {
    margin: auto 0;
    display: flex;
  }

  .content {
    flex-direction: column;

    .title {
      font-family: "Flama Medium";
      font-size: 0.875rem;
      line-height: 1rem;
      font-weight: initial;
      color: ${props => props.theme.colourTmBlack};
      margin: 0 !important;
    }

    .description {
      font-family: "Guardian TextSans";
      font-size: 0.875rem;
      line-height: 1.25rem;
      margin-top: 4px;
      color: ${props => props.theme.colourTmGrey};
    }
  }

  .price {
    font-family: "Flama Semicond Extrabold";
    font-size: 1.5rem;
    line-height: 1.75rem;
    color: ${props => props.theme.colourPowerOrange};
  }

  .link {
    display: flex;
    flex-flow: column;
    a {
      background-color: ${props => props.theme.colourTmBlue};
      color: ${props => props.theme.colourTmWhite};
      font-family: "Flama Semicond Semibold";
      font-size: 1rem;
      line-height: 1.25rem;
      padding: 0.875rem 1.25rem;
      border-radius: 3px;
      text-align: center;
      &:visited {
        color: ${props => props.theme.colourTmWhite};
      }
    }
    .previous-price span {
      background: #fcee25;
      color: #6f7174;
      width: 100%;
      font-family: "Flama Semicond Semibold";
      padding: 2px 5px;
      font-size: 14px;
    }
  }
`

export default class ProductAd extends Component {
  static propTypes = {
    ad: PropTypes.object,
  }

  selectLogo = adStore => {
    switch (adStore) {
    case 'power':
      return PowerLogo
    case 'dna':
      return DNALogo
    case 'verkkokauppa':
      return VerkkokauppaLogo
    default:
      return null
    }
  }

  linkOnClick = adStore => {
    switch (adStore) {
    case 'power':
      return () => {
        AdManager.adformPowerArticleAdClick()
      }
    case 'dna':
      return () => {
        AdManager.adformDNAArticleAdClick()
      }
    default:
      return null
    }
  }

  render () {
    const { ad } = this.props
    const trackingId = ad.store === 'power' ? '26763857' : (ad.store === 'dna' ? '27466383' : '')

    return (
      <div styleName={'product-ad-container ' + ad.store}>
        <StyledAd
          product={ad}
          storeLogo={this.selectLogo(ad.store)}
          LinkComponent={Link}
          linkOnClick={this.linkOnClick(ad.store)}
        />
        <img src={'https://track.adform.net/adfserve/?bn=' + trackingId + ';1x1inv=1;srctype=3;ord=' + Date.now()}
          alt="" border="0" width="1" height="1"/>
      </div>
    )
  }
}
