import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'

import { ErrorPlaceholder, withErrorBoundary } from '../general/util/ErrorBoundaries'

import './MagazineCarouselRow.scss'
import { LibraryIcon } from '../widgets/Icons'
import track from 'react-tracking'

class MagazineCarouselRow extends Component {
  state = {
    empty: false
  }

  shouldComponentUpdate (nextProps) {
    return this.props.title !== nextProps.title ||
      this.props.initialSlide !== nextProps.initialSlide ||
      this.props.highlightSelected !== nextProps.highlightSelected ||
      this.props.year !== nextProps.year
  }

  onLoad (state) {
    if (state.magazines && !state.magazines.length) {
      this.setState({ empty: true }, () => this.forceUpdate())
    }
    this.props.onLoad && this.props.onLoad(state)
  }

  render () {
    const {
      title,
      initialSlide,
      onMagazineItemClick,
      highlightSelected,
      year,
      LoaderComponent,
      showIcon
    } = this.props

    if (this.state.empty) {
      return null
    }

    const carouselClass = []

    if (highlightSelected) carouselClass.push('highlight-selected')

    return (
      <Fragment>
        <h2 styleName="title">
          {showIcon && <LibraryIcon />}
          {title}
        </h2>
        <LoaderComponent onLoad={(state) => this.onLoad(state)} year={year}
          initialSlide={initialSlide}
          onMagazineItemClick={onMagazineItemClick}
          highlightSelected={highlightSelected}/>
      </Fragment>
    )
  }
}

MagazineCarouselRow.propTypes = {
  initialSlide: PropTypes.number,
  title: PropTypes.string.isRequired,
  highlightSelected: PropTypes.bool,
  showIcon: PropTypes.bool,
  onMagazineItemClick: PropTypes.func,
  year: PropTypes.number,
  onLoad: PropTypes.func,
  LoaderComponent: PropTypes.any
}

export default track({ gtmContext: ['MagazineCarouselRow'] })(withErrorBoundary(
  MagazineCarouselRow,
  ErrorPlaceholder(null)
))
