import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import whatsapp from '../../assets/ui/whatsapp-white.svg'
import messenger from '../../assets/ui/messenger-white.svg'
import twitter from '../../assets/ui/twitter-white.svg'
import twitterGray from '../../assets/social/x.png'
import twitterBlue from '../../assets/social/x-hover.png'
import email from '../../assets/ui/email-white.svg'
import copy from '../../assets/ui/copy-white.svg'
import facebookWhite from '../../assets/ui/facebook-white.svg'
import facebookGray from '../../assets/ui/facebook_gray.svg'
import facebookBlue from '../../assets/ui/facebook_blue.svg'

import caret from '../../assets/ui/caret-icon.svg'
import caretRed from '../../assets/ui/caret-icon-red.svg'
import searchIcon from '../../assets/ui/search-icon.svg'
import searchIconRed from '../../assets/ui/search-icon-red.svg'
import chatIcon from '../../assets/ui/chat-icon.svg'
import bugReportIcon from '../../assets/ui/icon_flag.svg'
import closeIcon from '../../assets/ui/close-icon.svg'
import closeIconRed from '../../assets/ui/close-icon-red.svg'
import arrowIcon from '../../assets/ui/arrow-icon.svg'
import arrowIconRed from '../../assets/ui/arrow-icon-red.svg'
import arrowLeftRed from '../../assets/ui/arrow-left-red.svg'
import arrowRightRed from '../../assets/ui/arrow-right-red.svg'
import libraryIconRed from '../../assets/ui/library-red.svg'
import libraryIcon from '../../assets/ui/library.svg'
import paywallIcon from '../../assets/ui/paywall-icon.svg'
import listIcon from '../../assets/ui/list.svg'
import listIconRed from '../../assets/ui/list-red.svg'
import listBulletIcon from '../../assets/ui/list-bullet-icon.svg'
import listBulletIconBlue from '../../assets/ui/list-bullet-icon-blue.svg'
import userIcon from '../../assets/ui/user.svg'
import userIconRed from '../../assets/ui/user-red.svg'
import userIconWhite from '../../assets/ui/user-white.svg'
import passwordIcon from '../../assets/ui/password.svg'
import tmText from '../../assets/logos/tm-text.svg'
import tmLogo from '../../assets/logos/tm-logo.svg'
import facebookLogo from '../../assets/social/facebook-new.svg'
import facebookLogoHover from '../../assets/social/facebook-new-hover.svg'
import tiktokLogo from '../../assets/social/tiktok-new.png'
import tiktokLogoHover from '../../assets/social/tiktok-new-hover.png'
import facebookNoBgLogo from '../../assets/social/facebook-no-bg.svg'
import instagramLogo from '../../assets/social/instagram-new.svg'
import instagramLogoHover from '../../assets/social/instagram-new-hover.png'
import youtubeLogo from '../../assets/social/youtube-new.svg'
import youtubeLogoHover from '../../assets/social/youtube-new-hover.svg'
import twitterLogo from '../../assets/social/twitter-new.svg'
import twitterLogoHover from '../../assets/social/twitter-new-hover.svg'
import twitterNoBgLogo from '../../assets/social/twitter-no-bg.svg'
import mailLogo from '../../assets/social/mail-new.svg'
import mailLogoHover from '../../assets/social/mail-new-hover.svg'
import mailNoBgLogo from '../../assets/social/mail-no-bg.svg'
import whatsappLogo from '../../assets/social/whatsapp-new.svg'
import whatsappLogoHover from '../../assets/social/whatsapp-new-hover.svg'
import infoIcon from '../../assets/logos/info.svg'
import arrowDown from '../../assets/logos/line-2.svg'
import thumbIcon from '../../assets/logos/thumb.svg'
import styles from './Icons.scss'

class Icon extends Component {
  static propTypes = {
    src: PropTypes.string.isRequired,
    baseStyle: PropTypes.string.isRequired,
    style: PropTypes.string,
    alt: PropTypes.string,
    role: PropTypes.string,
  }

  render () {
    const { src, baseStyle, style = false, alt = '', role, ...rest } = this.props
    return (
      <div className={classnames(styles[baseStyle], style ? styles[style] : false)} styleName='icon' {...rest}>
        <img src={src} alt={alt} role={role}/>
      </div>
    )
  }
}

class IconWithHover extends Component {
  static propTypes = {
    src: PropTypes.string.isRequired,
    hoverSrc: PropTypes.string.isRequired,
    baseStyle: PropTypes.string.isRequired,
    style: PropTypes.string,
    alt: PropTypes.string,
    role: PropTypes.string,
  }

  render () {
    const { src, hoverSrc, baseStyle, style = false, alt = '', role, ...rest } = this.props
    return (
      <div className={classnames(styles[baseStyle], style ? styles[style] : false)} styleName='icon' {...rest}>
        <img src={src} alt={alt} role={role} styleName="no-hover"/>
        <img src={hoverSrc} alt={alt} role={role} styleName="hover"/>
      </div>
    )
  }
}

/* UI */

const Caret = ({ style, color }) => {
  const svg = color === 'red' ? caretRed : caret
  return <Icon src={svg} baseStyle='caret-icon' style={style} alt='' role='presentation' />
}

Caret.propTypes = {
  style: PropTypes.string.isRequired,
  color: PropTypes.string,
}

// eslint-disable-next-line react/prop-types
export const CaretRight = ({ color }) => {
  return <Caret style="caret-right-icon" color={color} />
}

// eslint-disable-next-line react/prop-types
export const CaretDown = ({ color }) => {
  return <Caret style="caret-down-icon" color={color} />
}

// eslint-disable-next-line react/prop-types
export const CaretLeft = ({ color }) => {
  return <Caret style="caret-left-icon" color={color} />
}

export const ThumbIcon = () => {
  return <Icon src={thumbIcon} baseStyle='ui-icon' style='thumb-icon' alt='' />
}

export const SearchIcon = () => {
  return <Icon src={searchIcon} baseStyle='ui-icon' style='search-icon' alt='Hae' />
}

export const InfoIcon = () => {
  return <Icon src={infoIcon} baseStyle='ui-icon' style='info-icon' alt='Lisätietoa' />
}

export const ArrowDown = () => {
  return <Icon src={arrowDown} baseStyle='ui-icon' style='arrow-down' alt='' role='presentation' />
}

export const SearchIconRed = () => {
  return <Icon src={searchIconRed} baseStyle='ui-icon' style='search-icon-red' alt='Hae' />
}

export const ChatIcon = () => {
  return <Icon src={chatIcon} baseStyle='ui-icon' alt='Keskustelu' role='presentation' />
}

export const BugReportIcon = () => {
  return <Icon src={bugReportIcon} baseStyle='ui-icon' alt='Raportoi ongelma' role='presentation' />
}

export const CloseIcon = () => {
  return <Icon src={closeIcon} baseStyle='ui-icon' alt='Sulje' role='presentation' />
}

export const CloseIconRed = () => {
  return <Icon src={closeIconRed} baseStyle='ui-icon' alt='Sulje' role='presentation' />
}

export const ArrowRightIcon = (props = {}) => {
  return <Icon src={arrowIcon} baseStyle='ui-icon' alt='Avaa' {...props} />
}

export const ArrowRightRedIcon = () => {
  return <Icon src={arrowIconRed} baseStyle='ui-icon' alt='Avaa' />
}

export const PreviousArrow = () => {
  return <Icon src={arrowLeftRed} baseStyle='ui-icon' alt='Edellinen' />
}

export const NextArrow = () => {
  return <Icon src={arrowRightRed} baseStyle='ui-icon' alt='Seuraava' />
}

export const LibraryIcon = () => {
  return <Icon src={libraryIcon} baseStyle='ui-icon' alt='Arkisto' />
}

export const LibraryIconRed = () => {
  return <Icon src={libraryIconRed} baseStyle='ui-icon' alt='Arkisto' />
}

export const ListBulletIcon = () => {
  return <Icon src={listBulletIcon} baseStyle='ui-icon' alt='' role='presentation' />
}

export const ListBulletIconBlue = () => {
  return <Icon src={listBulletIconBlue} baseStyle='ui-icon' alt='' role='presentation' />
}

export const PaywallIcon = () => {
  return <Icon src={paywallIcon} baseStyle='ui-icon' alt='' role='presentation' />
}

/* Logos */

export const TmText = () => {
  return <Icon src={tmText} baseStyle='rm-icon' alt='' role='presentation'/>
}

export const TmLogo = () => {
  return <Icon src={tmLogo} baseStyle='rm-icon' alt='' role='presentation' />
}

/* Social */

export const TwitterGrayLogo = () => {
  return <IconWithHover src={twitterGray} hoverSrc={twitterBlue} baseStyle='social-icon' alt='' role='presentation'/>
}
export const FacebookGrayLogo = () => {
  return <IconWithHover src={facebookGray} hoverSrc={facebookBlue} baseStyle='social-icon' alt='' role='presentation'/>
}
export const FacebookLogo = () => {
  return <IconWithHover src={facebookLogo} hoverSrc={facebookLogoHover} baseStyle='social-icon' alt='' role='presentation'/>
}

export const TikTokLogo = () => {
  return <IconWithHover src={tiktokLogo} hoverSrc={tiktokLogoHover} baseStyle='social-icon' alt='' role='presentation'/>
}

export const FacebookNoBgLogo = () => {
  return <Icon src={facebookNoBgLogo} baseStyle='social-icon' alt='' role='presentation'/>
}

export const InstagramLogo = () => {
  return <IconWithHover src={instagramLogo} hoverSrc={instagramLogoHover} baseStyle='social-icon' alt='' role='presentation'/>
}

export const YoutubeLogo = () => {
  return <IconWithHover src={youtubeLogo} hoverSrc={youtubeLogoHover} baseStyle='social-icon' alt='' role='presentation'/>
}

export const TwitterLogo = () => {
  return <IconWithHover src={twitterLogo} hoverSrc={twitterLogoHover} baseStyle='social-icon' alt='' role='presentation'/>
}

export const TwitterNoBgLogo = () => {
  return <Icon src={twitterNoBgLogo} baseStyle='social-icon' alt='' role='presentation'/>
}

export const MailLogo = () => {
  return <IconWithHover src={mailLogo} hoverSrc={mailLogoHover} baseStyle='social-icon' alt='' role='presentation'/>
}
export const MailNoBgLogo = () => {
  return <Icon src={mailNoBgLogo} baseStyle='social-icon' alt='' role='presentation'/>
}

export const WhatsappLogo = () => {
  return <IconWithHover src={whatsappLogo} hoverSrc={whatsappLogoHover} baseStyle='social-icon' alt='' role='presentation'/>
}

export const SubscriberIcon = ({center}) => {
  return (
    <div styleName={center ? "subscriber-icon center" : "subscriber-icon"}>
      <PaywallIcon />
      <div>Tilaajille</div>
    </div>
  )
}

export const ListIcon = () => {
  return <Icon src={listIcon} baseStyle='ui-icon' alt='' role='presentation' />
}

export const ListIconRed = () => {
  return <Icon src={listIconRed} baseStyle='ui-icon' alt='' role='presentation' />
}

export const UserIcon = () => {
  return <Icon src={userIcon} baseStyle='ui-icon' alt='' role='presentation' />
}

export const UserIconRed = () => {
  return <Icon src={userIconRed} baseStyle='ui-icon' alt='' role='presentation' />
}

export const UserIconWhite = () => {
  return <Icon src={userIconWhite} baseStyle='ui-icon' alt='' role='presentation' />
}

export const PasswordIcon = () => {
  return <Icon src={passwordIcon} baseStyle='ui-icon' alt='' role='presentation' />
}

export const WhatsappIcon = () => {
  return <Icon src={whatsapp} baseStyle='ui-icon' alt='' role='presentation' />
}

export const MessengerIcon = () => {
  return <Icon src={messenger} baseStyle='ui-icon' alt='' role='presentation' />
}

export const TwitterWhiteIcon = () => {
  return <Icon src={twitter} baseStyle='ui-icon' alt='' role='presentation' />
}

export const EmailWhiteIcon = () => {
  return <Icon src={email} baseStyle='ui-icon' alt='' role='presentation' />
}

export const CopyIcon = () => {
  return <Icon src={copy} baseStyle='ui-icon' alt='' role='presentation' />
}
export const FacebookWhiteIcon = () => {
  return <Icon src={facebookWhite} baseStyle='ui-icon' alt='' role='presentation' />
}

/*
// eslint-disable-next-line react/prop-types
export const TrashIcon = ({ alt = 'Poista', ...rest }) => (
  <Icon src={trashIcon} baseStyle='ui-icon' alt={alt} {...rest} />
)
*/
