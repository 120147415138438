import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'kea'
import { ErrorPlaceholder, withErrorBoundary } from '../general/util/ErrorBoundaries'
import SocialLinks from '../widgets/SocialLinks'
import { UserIconRed } from '../widgets/Icons'
import auth from '../../kea/auth'
import { Link, NavLink } from '../general/util/Links'
import track from 'react-tracking'
import WP from '../../lib/WP'
import { getCookie } from '../../lib/utils'

import './NavigationBar.scss'

@track({ gtmContext: ['NavigationTop'] })
@connect({
  props: [
    auth, [
      'loggedIn',
      'username',
      'accessLevel',
    ],
  ],
})
class NavigationTop extends Component {
  static propTypes = {
    loggedIn: PropTypes.bool,
    username: PropTypes.string,
    accessLevel: PropTypes.any,
  }

  render () {
    const { loggedIn } = this.props
    const isPreviouslyPremium = !loggedIn && getCookie('rememberPremium')

    return (
      <Fragment>
        <div styleName="logos">

        </div>
        <div styleName="top-nav">
          <NavLink to={{ link: 'https://tekniikanmaailma.fi/toimituksen-yhteystiedot/' }} styleName="top-navigation-item"><span>Toimitus</span></NavLink>
          {this.props.accessLevel > 2
            ? <Link to={{ link: '/tekniikan-maailman-uusi-mobiilisovellus-on-nyt-ladattavissa-sovelluskaupoista-lue-kaikkia-sisaltoja-katevasti-sovelluksessa/' }} styleName="top-navigation-item">
              <span>Lataa sovellus</span>
            </Link>
            : <NavLink to={{ link: '/tilaa/' }}
              onClick={(e) => {
                let promoPosition = '-single'
                if (window.location.pathname === '/') {
                  promoPosition = '-etusivu'
                } else if (window.location.pathname.includes('/kategoria/') || window.location.pathname.includes('/avainsana/')) {
                  promoPosition = '-kategoria'
                }
                window.location.href = 'https://tilaus.tekniikanmaailma.fi/tilaus?promoID=OM_tilaa-painike&promoName=OM_TM&promoCreative=onlinetarjous&promoPosition=header' + promoPosition
                e.preventDefault()
                return false
              }}
              styleName="top-navigation-item"><span>Tilaa lehti</span></NavLink>
          }

          <div styleName="social-links">
            <SocialLinks />
          </div>
          <div styleName="top-navigation-item">
            {loggedIn
              ? <Link id="login-button" to={{ link: '/tili' }} styleName="login-button"><UserIconRed/> <span>Oma tili</span></Link>
              : <a id="login-button" styleName="login-button" href={WP.getLoginUrl()}>
                <span>Kirjaudu</span>
              </a>
            }
            {isPreviouslyPremium
              ? <div styleName="premium-popup">
                <a href={WP.getLoginUrl()}>
                  <div>Oletko jo tilaaja?</div>
                  <p>Kirjaudu sisään, niin pääset lukemaan kaikkia juttuja</p>
                </a>
                <div styleName="caret"></div>
              </div>
              : null}
          </div>
        </div>
      </Fragment>
    )
  }
}

export default withErrorBoundary(
  NavigationTop,
  ErrorPlaceholder(null)
)
