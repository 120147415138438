
export default class CrossLinkModel {
  link
  title
  siteName
  date
  thumbnail

  constructor (feedData) {
    this.link = feedData.link
    this.title = feedData.title.replace(/kommentit$/, '').replace(/^Artikkelin /, '')
    this.siteName = feedData.magazine || ''
    this.date = new Date(feedData.pubDate.substr(0, feedData.pubDate.length - 6)).toISOString()
    this.thumbnail = feedData.thumbnail_url
  }
}
