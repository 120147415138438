import ImageModel from './ImageModel'

const getImages = images => {
  return images && images.length
    ? images.map(extraImage => new ImageModel(extraImage.image))
    : []
}

export default class ComparisonModel {
  goodList
  badList
  stars
  grade
  images
  details
  bulletPoints
  title
  byNumbersList
  comments

  constructor (comparison) {
    if (comparison) {
      // later value is old format & deprecated
      this.goodList = comparison.good || comparison.hyvaa
      this.badList = comparison.bad || comparison.huonoa
      this.stars = comparison.stars || comparison.tahdet
      this.byNumbersList = comparison.byTheNumbers || comparison.numeroiden_mukaan
      this.images = comparison.image
        ? [
          comparison.image ? new ImageModel(comparison.image) : undefined,
          ...getImages(comparison.additionalImages || comparison.lisakuvat),
        ]
        : [
          ...getImages(comparison.additionalImages || comparison.lisakuvat),
        ]
      this.details = comparison.productInfo || comparison.tuotetiedot
      this.bulletPoints = comparison.generalText || comparison.yleistekstit
      this.title = comparison.makeAndModel || comparison.model
      this.grade = comparison.rating || comparison.arvosana
    }
  }
}
