import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { connect } from 'kea'

import application from '../kea/application'
import Sidebar from '../components/views/Sidebar'
import IndexSidebar from '../components/views/IndexSidebar'
import PostList from '../components/general/lists/PostList'
import TopRowAd from '../components/general/util/TopRowAd'
import Highlights from '../components/views/IndexHighlights'
import Title from '../components/general/util/Title'
import NoAdBlocker from '../components/widgets/NoAdBlocker'
import TopPicks from '../components/widgets/TopPicks'
import HTML from '../components/general/util/HTML'
import GAMNativeAdHolder from '../components/widgets/GAMNativeAdHolder'
import Timeline from '../components/widgets/Timeline'

import WP from '../lib/WP'
import {
  AdsForDesktop, AdsForMobile,
  AdSlotDesk2,
  AdSlotMob2,
  AdSlotRectangle1Index,
} from '../components/general/ads/Ads'
import {
  ErrorPlaceholderBox,
  withErrorBoundary
} from '../components/general/util/ErrorBoundaries'

import { STATUS } from '../lib/request-state'
import { RenderedError } from '../lib/errors'

import './FrontPage.scss'
import some from 'lodash/some'
import track from 'react-tracking'
import { archiveLayout as layout } from '../lib/defaults'
import CategoryPriceGuide from '../components/widgets/CategoryPriceGuide'

@track({ gtmContext: ['Archive'] })
@connect({
  actions: [
    application, [
      'loadViewDataFromPathname',
      'addArticleToBlacklist',
      'resetBlacklist',
      'setRendered'
    ],
  ],
  props: [
    application, [
      'view',
      'error'
    ],
  ]
})
  /**
   * This renders a taxonomy page (category or keyword).
   * It can contain a description and some highlight articles. It always contains a list of the
   * latest articles in this category/keyword.
   */
class Taxonomy extends Component {
  loaded = {
    articles: false,
    postlist: false
  }

  _mounted = false
  asyncSetState (...args) {
    // avoid setting state after unmount in case async operations attempts to do so
    if (this._mounted) this.setState(...args)
  }

  constructor (props) {
    super(props)
    this.state = {
      articles: null,
      stickies: null,
      isMainCategory: !props.view || (
        props.view.taxonomy === 'category' && props.view.parent === 0
      ),
      status: STATUS.NOT_REQUESTED,
      nativeAds: []
    }
  }

  static propTypes = {
    match: PropTypes.object,
    history: PropTypes.object,
    location: PropTypes.object,
    view: PropTypes.object,
    error: PropTypes.any,
    setRendered: PropTypes.func,
    actions: PropTypes.object,
  }

  viewLoaded () {
    const { view } = this.props

    const keysLength = Object.keys(view).length
    return keysLength > 0 && view._meta
  }

  async componentDidMount () {
    this._mounted = true

    const { loadViewDataFromPathname } = this.actions
    const { location } = this.props

    // If view is empty, it's a fresh page load. Determine what type of content
    // user is expecting from the URL. Right now works for tags and categories only.
    if (!this.viewLoaded()) {
      loadViewDataFromPathname(location)
    }
  }

  componentWillUnmount () {
    this._mounted = false
    this.props.actions.setRendered(false)
  }

  /**
   * Check if links are identical or differ only by page number at the end
   * @param oldView
   * @param newView
   * @return {boolean}
   */
  isDifferentCategory (oldView, newView) {
    if (!oldView) return true

    const regex = /\/[0-9]+\/?$/
    return !oldView.link || !newView.link ||
      (oldView.link.replace(regex, '/') !== newView.link.replace(regex, '/'))
  }

  /**
   * Yes, this looks messy. It loads the highlights and stickies, but only if we are
   * in a main category and we haven't loaded them already.
   * @param nextProps
   * @return {Promise<void>}
   */
  async componentWillReceiveProps (nextProps) {
    const { view } = nextProps
    const { status } = this.state
    const isMainCategory = (view) => view.taxonomy === 'category' && view.parent === 0

    if ((
      this.isDifferentCategory(this.props.view, nextProps.view) ||
      status === STATUS.NOT_REQUESTED
    ) && (
      isMainCategory(view) && (status !== STATUS.REQUESTED)
    )) {
      try {
        this.resetDoneLoading()
        this.asyncSetState({
          status: STATUS.REQUESTED,
          articles: [],
          stickies: [],
          isMainCategory: isMainCategory(nextProps.view)
        })
        this.actions.resetBlacklist()
        let [{ highlights, editorsPicks }, stickies] = await Promise.all([
          WP.getCategoryHighlights(view.id),
          WP.getStickyPosts(view.id)
        ])
        this.doneLoading('articles')

        const joinedHighlights = [...highlights, ...editorsPicks]
        let exclude = joinedHighlights.map(x => x.item).concat(stickies)
        let offset = 0
        if (stickies.length < layout.highlights.stickyCount) {
          offset = layout.highlights.stickyCount - stickies.length
          const fill = await WP.getForContext(view, {
            per_page: offset,
            exclude: exclude.map(x => x.id)
          })
          stickies = stickies.concat(fill.data)
          exclude = exclude.concat(fill.data)
        }
        this.asyncSetState({
          status: STATUS.DONE,
          articles: joinedHighlights,
          stickies,
          offset
        })
        exclude.forEach(this.actions.addArticleToBlacklist)
      } catch (e) {
        return this.asyncSetState({ status: STATUS.ERROR, error: e })
      }
    } else if (isMainCategory(nextProps.view)) {
      this.asyncSetState({
        isMainCategory: true
      })
      this.doneLoading('articles')
    } else if (view.taxonomy) {
      // if taxonomy is not set, we don't know if this is a subcategory
      if (this.isDifferentCategory(this.props.view, nextProps.view)) {
        this.resetDoneLoading()
      }
      this.asyncSetState({
        status: STATUS.DONE,
        isMainCategory: false,
        stickies: [],
        articles: [],
      })
      this.doneLoading('articles')
    } else {
      if (this.isDifferentCategory(this.props.view, nextProps.view)) {
        this.resetDoneLoading()
      }
    }

    if (location.pathname.includes('tm-ratkaisu')) {
      const ads = [
        {
          advertiser: 'Fortum Charge & Drive',
          image: '',
          title: 'Täyssähköautojen akut ja latauspaikat kehittyvät nopeasti – lue lisää!',
          url: 'https://tekniikanmaailma.fi/mainoslehti/fortum/levollisin-mielin-liikkeelle'
        },
        {
          advertiser: 'Fortum Charge & Drive',
          image: 'https://cdn.tekniikanmaailma.fi/wp-content/uploads/s3/2019/05/21165127/asset-2-100-768x512.jpg',
          title: 'Akut lataukseen asioinnin yhteydessä',
          url: 'https://tekniikanmaailma.fi/mainoslehti/fortum/akut-lataukseen-asioinnin-yhteydessa'
        },
      ]
      this.setState({ nativeAds: ads })
    }
  }

  resetDoneLoading () {
    this.loaded = {
      articles: false,
      postlist: false,
    }
    this.props.actions.setRendered(false)
  }

  doneLoading (element) {
    if (this.loaded[element]) {
      return
    }
    this.loaded[element] = true
    if (!some(Object.values(this.loaded), (x) => !x)) {
      this.props.actions.setRendered(true)
    }
  }

  render () {
    const { view, error, history, location } = this.props
    const { status, isMainCategory, stickies, error: stateError, nativeAds } = this.state
    const { name, blacklisted, slug } = view
    const postListStickies = stickies ? stickies.slice(layout.highlights.stickyCount) : []
    const shownNativeAds = view.nativeAds && view.nativeAds.length ? view.nativeAds : nativeAds
    const isKeyword = location.pathname.includes('/avainsana/')

    const postListProps = {
      context: view,
      wait: status !== STATUS.DONE || !this.viewLoaded(),
      blacklist: blacklisted,
      layout: layout.newsFeed,
      stickies: postListStickies,
      disablePagination: true,
      onLoad: () => this.doneLoading('postlist'),
    }

    if (error) {
      return <RenderedError error={error} />
    } else if (stateError) {
      throw stateError
    }

    const categoryId = ('category,post_tag'.includes(view.taxonomy) && view.id) || -1
    return (
      <Fragment>
        <Helmet>
          <title>
            {`${name || 'ladataan...'} - Tekniikan Maailma`}
          </title>
          <meta name="http:status" content="200" />
          {isKeyword ? <meta property="og:image" content={window.location.origin + '/assets/logos/tm-logo-red.png'}/> : null}
        </Helmet>
        <TopPicks />
        <TopRowAd isArticleView={false} display={true}/>
        <div styleName="main-content-col">
          <Title key="main-items-title" text={name || ''}/>
          {view.description
            ? <div styleName="cat-description">
              <HTML>{view.description}</HTML>
            </div>
            : null}
        </div>
        {isMainCategory === 'highlights disabled for now'
          ? (
            <Fragment>
              <div styleName="main-content-col-wide">
                <div styleName="sub-container">
                  <div className="row">
                    <h2 className="screen-reader-text">Pääjutut</h2>
                    <Highlights highlights={this.state.articles} stickies={stickies} layout={layout.highlights.boxes}/>
                  </div>
                </div>
              </div>
              <aside styleName="sidebar-col-narrow">
                <Sidebar latestMag carousel popular category={categoryId}
                  doneLoading={() => this.doneLoading('sidebar')}>
                  <AdsForDesktop>
                    <AdSlotRectangle1Index fallBackElements={[NoAdBlocker]}/>
                  </AdsForDesktop>
                  <AdsForMobile>
                    <AdSlotMob2 fallBackElements={[NoAdBlocker]} />
                  </AdsForMobile>
                </Sidebar>
              </aside>
              <div styleName="divider"/>
              <div styleName="full-width-col">
                <div className='row'>
                  <div styleName="full-width-col">
                    <AdsForDesktop>
                      <AdSlotDesk2 />
                    </AdsForDesktop>
                    <AdsForMobile>
                      <AdSlotMob2 />
                    </AdsForMobile>
                  </div>
                </div>
              </div>
            </Fragment>
          )
          : null
        }
        <div styleName="main-content-col">
          <div styleName="sub-container">
            <div className="row" styleName="newsfeed">
              <div styleName="full-width-col">
                {isKeyword
                  ? <Timeline term={view} compact noTitle loadMoreButton perPage={10}
                    doneLoading={() => this.doneLoading('postlist')}/>
                  : <PostList {...postListProps} history={history}/>}
              </div>
            </div>
          </div>
          <CategoryPriceGuide category={slug}/>
          {shownNativeAds
            ? <div styleName="native-ads">
              <GAMNativeAdHolder nativeAds={shownNativeAds} grow={true}/>
            </div>
            : null}
        </div>
        <aside styleName="sidebar-col">
          <IndexSidebar
            blocks={{ carousel: !isMainCategory, popular: !isMainCategory, shortcuts: true }}
            categoryId={categoryId}
            wait={status !== STATUS.DONE}
            doneLoading={() => null}
          />
        </aside>
      </Fragment>
    )
  }
}

export default withErrorBoundary(
  Taxonomy,
  ErrorPlaceholderBox('Artikkeleita ei saatu ladattua'),
)
