import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import HTML from '../general/util/HTML'
import Image from '../general/util/Image'
import './FrontpageArticle.scss'
import { Link } from '../general/util/Links'
import { SubscriberIcon } from './Icons';

export default class FrontpageArticle extends Component {
  static propTypes = {
    article: PropTypes.object,
  }

  renderVignetteAndTopic = article => {
    if (article.vignette && article.topic) {
      return (
        <Fragment>
          <Link to={{link: article.article}}><HTML>{article.vignette}</HTML>: <HTML>{article.topic}</HTML></Link>
        </Fragment>
      )
    } else if (article.vignette) {
      return <Link to={{link: article.article}}><HTML>{article.vignette}</HTML></Link>
    } else {
      return null
    }
  }

  render () {
    const { article } = this.props
    if (!article) return null
    if (!article.article) return null
    const { image, colour } = article

    return <div styleName="article">
      <div styleName="image-holder">
        <Image data={image} isZoomed/>
      </div>
      <div styleName="title-area">
        <div styleName="">
          {article.vignette
            ? (
              <div styleName="vignette">
                {this.renderVignetteAndTopic(article)}
              </div>
            )
            : null
          }
          <h1 style={{ color: colour ? colour : '#FFF' }}><Link to={{link: article.article}}><HTML>{article.title}</HTML></Link></h1>
          {article.ingress
            ? <div styleName="ingress" style={{ color: colour ? colour : '#FFF' }}><div><Link to={{link: article.article}}><HTML>{article.ingress}</HTML></Link></div></div>
            : null}
          {!!article.forSubscribers && <div styleName="subscriber-icon"><SubscriberIcon center={true}/></div>}
        </div>
      </div>
    </div>
  }

}
