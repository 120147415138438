import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ForumPostDate from './ForumPostDate'
import { ChatIcon } from '../../widgets/Icons'
import './ForumPost.scss'

class ForumPost extends Component {
  static propTypes = {
    forumPost: PropTypes.object.isRequired,
  }

  render () {
    const { forumPost } = this.props
    return (
      <div styleName="main">
        <div styleName="left">
          <ChatIcon />
        </div>
        <div styleName="right">
          <div styleName="title">{forumPost.title}</div>
          <p>{forumPost.text.substring(0, 100)}<span stylename="ellipsis">...</span></p>
          <div styleName="meta">
            <span styleName="date">
              <ForumPostDate date={ forumPost.date } />
            </span>
          </div>
        </div>
      </div>
    )
  }
}

export default ForumPost
