export const USER_ROLE_LOGGED_IN = 'USER_ROLE_LOGGED_IN' // user is logged in
export const USER_ROLE_READER = 'USER_ROLE_READER' // user is not logged in, but has read access to all articles
export const USER_ROLE_PREMIUM = 'USER_ROLE_PREMIUM' // user is logged in and has read access to all articles
export const USER_ROLE_ADMINISTRATOR_LIKE = 'USER_ROLE_ADMINISTRATOR_LIKE' // user is logged in as administrator

/**
 * Check if the user roles matches with all of the provided roles.
 */
export const userHasAccess = (userRoles, requiredRoles) => {
  if (!userRoles) {
    return false
  }
  requiredRoles = Array.isArray(requiredRoles) ? requiredRoles : [requiredRoles]
  return !requiredRoles.find(role => !userRoles.includes(role))
}

/**
 * Check if the user roles matches with any of the provided roles.
 */
export const userHasAnyAccess = (userRoles, roles) => {
  return !!roles.find(role => !userHasAccess(userRoles, role))
}

/**
 * Shorthand to check USER_ROLE_LOGGED_IN role.
 */
export const isLoggedInUser = (userRoles) => {
  return userHasAccess(userRoles, USER_ROLE_LOGGED_IN)
}

/**
 * Shorthand to check USER_ROLE_PREMIUM role.
 */
export const isPremiumUser = (userRoles) => {
  return userHasAccess(userRoles, USER_ROLE_PREMIUM)
}

export const isAdministratorLikeUser = (userRoles) => {
  return userHasAccess(userRoles, USER_ROLE_ADMINISTRATOR_LIKE)
}
