import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import GoodBad from '../../../components/general/article/GoodBad'

const Comparisons = ({ data, floated, short }) => {
  return (
    <Fragment>
      {data.map((comparison, index) => {
        return <Fragment key={index}>
          <GoodBad data={comparison} floated={floated} short={short}/>
        </Fragment>
      })}
    </Fragment>
  )
}

Comparisons.propTypes = {
  data: PropTypes.array.isRequired,
  floated: PropTypes.bool,
  short: PropTypes.bool,
}

export default Comparisons
