import React, { Component } from 'react'
import WP from '../../lib/WP'
import './Timeline.scss'
import PropTypes from 'prop-types'
import track from 'react-tracking'
import groupBy from 'lodash/groupBy'
import ArticleMetaData from '../general/article/ArticleMetadata'
import { Link } from '../general/util/Links'
import HTML from '../general/util/HTML'
import { SubscriberIcon } from '../widgets/Icons'
import ArticleThumbnail from '../general/article/ArticleThumbnail'
import NewsfeedLoader from '../general/util/NewsfeedLoader'
import format from 'date-fns/format'
import ArticleDate from '../general/article/ArticleDate'
import { ShowAll } from './ShowAll'

class Timeline extends Component {
  _mounted = false
  state = {}

  static propTypes = {
    exclude: PropTypes.array, // article id's to exclude
    term: PropTypes.object,
    posts: PropTypes.array,
    title: PropTypes.string,
    loadMore: PropTypes.func,
    url: PropTypes.string,
    loading: PropTypes.bool,
    dateOnly: PropTypes.bool,
    noTitle: PropTypes.bool,
    digimagOnly: PropTypes.bool,
    noImages: PropTypes.bool,
    compact: PropTypes.bool,
    veryCompact: PropTypes.bool,
    loadMoreButton: PropTypes.bool,
    groupBy: PropTypes.string,
    perPage: PropTypes.number,
    doneLoading: PropTypes.func
  }

  static defaultProps = {
    dateOnly: true
  }

  groupArticles (articles) {
    if (this.props.groupBy === 'date') {
      return groupBy(articles, (x) => x.createdDate.substr(0, 10))
    }
    return groupBy(articles, (x) => x.createdDate.substr(0, 4))
  }

  async loadData (props) {
    let articles
    this.setState({ loadingLocally: true })
    if (props.term && props.term.id) {
      articles = (await WP.getForContext({
        id: props.term.id,
        taxonomy: props.term.restBase,
        _meta: props.term._meta
      }, {
        per_page: props.perPage || 5,
        exclude: props.exclude,
        digimagOnly: props.digimagOnly
      })).data
    } else if (props.posts) {
      articles = props.posts
    } else {
      return
    }
    if (this._mounted) {
      articles = props.exclude
        ? articles.filter((article) => !props.exclude.includes(article.id))
        : articles
      if (this.props.veryCompact) {
        articles = (articles || []).slice(0, 10)
      }
      this.setState({ articles, loadingLocally: false }, () => this.props.doneLoading && this.props.doneLoading())
    }
  }

  async loadMoreInternally () {
    const { props } = this
    let articles
    this.setState({ loadingLocally: true })
    try {
      articles = await WP.getForContext({
        id: props.term.id,
        taxonomy: props.term.restBase,
        _meta: props.term._meta
      }, {
        per_page: props.perPage || 5,
        offset: this.state.articles.length,
        exclude: props.exclude
      })
      if (this._mounted) {
        articles = props.exclude
          ? articles.data.filter((article) => !props.exclude.includes(article.id))
          : articles.data
        this.setState({ articles: this.state.articles.concat(articles), noMorePosts: !articles.length, loadingLocally: false })
      }
    } catch (e) {
      this.setState({ noMorePosts: true, loadingLocally: false })
    }
  }

  componentDidMount () {
    this._mounted = true
    this.loadData(this.props)
  }

  componentWillUnmount () {
    this._mounted = false
  }

  componentWillReceiveProps (props) {
    if (JSON.stringify(props) !== JSON.stringify(this.props)) {
      this.loadData(props)
    }
  }

  formatGroupName (groupName) {
    if (this.props.groupBy === 'date') {
      const today = new Date().toISOString().substr(0, 10)
      const yesterday = new Date()
      yesterday.setDate(yesterday.getDate() - 1)
      const yesterdayStr = yesterday.toISOString().substr(0, 10)
      if (groupName === today) {
        return 'Tänään'
      } else if (groupName === yesterdayStr) {
        return 'Eilen'
      } else {
        return format(groupName, 'D.M.YYYY')
      }
    }
    return groupName
  }

  render () {
    const { articles, noMorePosts, loadingLocally } = this.state
    const { term, title, url, loadMore, loading, dateOnly, noTitle, compact, noImages, veryCompact, loadMoreButton } = this.props
    const isLoading = loadingLocally || loading
    if (!articles || !articles.length) {
      return isLoading ? <NewsfeedLoader/> : null
    }
    let groupedArticles
    if (!this.props.veryCompact) {
      groupedArticles = this.groupArticles(articles)
    }

    return (
      <div styleName="wrapper">
        {noTitle
          ? null
          : <div styleName="header">
            {title ? <h2>{title}</h2> : <h2>Lisää aiheesta {term.name}</h2>}
            {url || term
              ? <ShowAll url={url || this.props.term.link} inline />
              : null}
          </div>
        }
        <div>
          {veryCompact
            ? articles.map((article, idx) => <div key={idx}>
              <article styleName="item">
                <div styleName="compact-article">
                  <div>
                    <ArticleDate date={article.createdDate} timeOnly/>
                  </div>
                  <div>
                    <Link to={article}>
                      <h5><HTML>{article.title}</HTML></h5>
                    </Link>
                  </div>
                  {article.forSubscribers && <div styleName="for-subscribers">
                    <div styleName="subscriber-icon-container"><SubscriberIcon/></div>
                  </div>}
                </div>
              </article>
            </div>)
            : Object.keys(groupedArticles).sort().reverse().map((groupName, idx) => <div key={idx}>
              <div styleName="year">{this.formatGroupName(groupName)}</div>
              <div styleName={'year-list' + (compact ? ' compact' : '')}>
                {groupedArticles[groupName].map((article, idx) => <div key={idx}>
                  <article styleName="item">
                    <div>
                      <Link to={article}>
                        {article.forSubscribers && !noImages &&
                        <span styleName='thumbnail'><ArticleThumbnail article={article}/></span>}
                        <div styleName="meta-row">
                          <div>
                            <ArticleMetaData
                              categories={article.categories} noLink={true}
                              date={article.createdDate} dateOnly={dateOnly} divider={false}
                              magazineLabel={article.printmag ? article.printmag.name : null}
                            />
                          </div>
                          {article.forSubscribers && <div styleName="subscriber-icon-container"><SubscriberIcon/></div>}
                        </div>
                      </Link>
                      <Link to={article}>
                        <h5><HTML>{article.title}</HTML></h5>
                      </Link>
                    </div>
                  </article>
                </div>)}
              </div>
            </div>)
          }
          {isLoading ? <NewsfeedLoader/> : null}
          {(url || term) && !loadMoreButton ? <div styleName="footer"><ShowAll url={url || this.props.term.link} /></div> : null}
          {loadMore && !isLoading
            ? <div styleName="load-more">
              <button onClick={() => loadMore()}>Lataa lisää</button>
            </div>
            : null}
          {loadMoreButton && !noMorePosts && !isLoading
            ? <div styleName="load-more">
              <button onClick={() => this.loadMoreInternally()}>Lataa lisää</button>
            </div>
            : null}
        </div>
      </div>
    )
  }
}

export default track({ gtmContext: ['Timeline'] })(Timeline)
