import React, { Component } from 'react'
import './NativeAdHolder.scss'
import PropTypes from 'prop-types'
import GAMNativeAd from './GAMNativeAd'
import { CaretLeft, CaretRight } from './Icons'
import SimpleCarousel from '@otavamedia/om-component-library/lib/SimpleCarousel'

export default class GAMNativeAdHolder extends Component {
  static propTypes = {
    grow: PropTypes.bool,
    trigger: PropTypes.bool,
    type: PropTypes.number
  }

  constructor (props) {
    super(props)
    this.state = { hidden: [false, false, false] }
  }

  handleSlotRender = function (event, slot) {
    const newHidden = this.state.hidden
    newHidden[slot] = event.isEmpty
    this.setState({ hidden: newHidden })
  }

  render () {
    const { hidden } = this.state
    if (!hidden.some(x => !x)) { // if all slots are empty, don't display anything
      return null
    }
    const itemProps = [
      { type: this.props.type, handleSlotRender: (e) => this.handleSlotRender(e, 0) },
      { type: this.props.type, handleSlotRender: (e) => this.handleSlotRender(e, 1) },
      { type: this.props.type, handleSlotRender: (e) => this.handleSlotRender(e, 2) }
    ]

    if (window.innerWidth < 768) {
      return (
        <div styleName="native-ad-holder">
          <div styleName="title"><span>Mainos</span></div>
          <div styleName="native-ads holder-narrow-gam">
            {itemProps.map((item, key) => <GAMNativeAd key={key} type={this.props.type}/>)}
          </div>
        </div>
      )
    }
    return (
      <div styleName="native-ad-holder">
        <div styleName="title"><span>Mainos</span></div>
        <div styleName={'native-ads'}>
          <SimpleCarousel
            ItemComponent={GAMNativeAd}
            itemProps={itemProps}
            arrowLeft={<CaretLeft color="red"/>}
            arrowRight={<CaretRight color="red"/>}
          />
        </div>
      </div>
    )
  }
}
