import React from 'react'
import PropTypes from 'prop-types'
import DateField from '../widgets/DateField'
import './ForumPostDate.scss'

const ForumPostDate = ({ date }) => {
  return (
    <span styleName="date-field">
      <DateField date={ date } />
    </span>
  )
}

ForumPostDate.propTypes = {
  date: PropTypes.string,
}

export default ForumPostDate
