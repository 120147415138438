import React from 'react'
import { TextBlock, RectShape } from 'react-placeholder/lib/placeholders'
import './MagazineArticleLoader.scss'

const MagazineArticleLoader = () => {
  return <div styleName="loader">
    <div>
      <TextBlock rows={3} color='grey' />
      <RectShape style={{ width: '100%', height: 350 }}/>
      <div styleName="body-text-loader">
        <TextBlock rows={30} color='grey' />
      </div>
    </div>
  </div>
}

export default MagazineArticleLoader
