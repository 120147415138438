import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import WP from '../lib/WP'
import { ErrorPlaceholder, withErrorBoundary } from '../components/general/util/ErrorBoundaries'
import HTML from '../components/general/util/HTML'
import Image from '../components/general/util/Image'
import { IMAGE_SIZE } from '../entities/ImageModel'
import '../components/weeklyMagazine/MagazineArticle.scss'
import Timeline from '../components/widgets/Timeline'
import { authors, commentators } from '../lib/defaults'
import MagazineArticleLoader from '../components/general/util/MagazineArticleLoader'
import { MailLogo, TwitterLogo } from '../components/widgets/Icons'
import Helmet from 'react-helmet'

class Author extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    doneLoading: PropTypes.func.isRequired,
  }

  state = { posts: [] }

  async componentDidMount () {
    const nameFromUrl = this.props.match.params.name.replace('-', ' ')
    const authorId = authors[nameFromUrl]
    const commentatorId = commentators[authorId] || 999
    this.setState({ loading: true })
    const [author, posts] = await Promise.all([
      WP.getAuthor(authorId),
      WP.getAuthorPosts(authorId, commentatorId, ''),
    ])
    this.setState({
      loading: false,
      noMorePosts: !posts,
      author,
      authorId,
      commentatorId,
      posts: posts.sort((a, b) => a.createdDate < b.createdDate ? 1 : -1).slice(0, 10)
    }, () => this.props.doneLoading(true))
  }

  async loadMore () {
    const { posts, authorId, commentatorId } = this.state
    const oldestDate = posts.length ? posts[posts.length - 1].createdDate : ''
    this.setState({ loading: true })
    const morePosts = await WP.getAuthorPosts(authorId, commentatorId, oldestDate)
    this.setState({
      loading: false,
      noMorePosts: !morePosts.length,
      posts: posts.concat(morePosts.sort((a, b) => a.createdDate < b.createdDate ? 1 : -1).slice(0, 10))
    })
  }

  render () {
    const { author, posts, noMorePosts, loading } = this.state
    if (!author) return <MagazineArticleLoader/>
    const { ingress, name, title, image, description, twitter, email } = author

    return (
      <Fragment>
        <Helmet>
          <title>
            {'Toimittaja ' + name + ' - Tekniikan Maailma'}
          </title>
        </Helmet>
        <article styleName="article">
          <header styleName="author-header">
            <div styleName="featured-image author-image">
              <Image addToGallery data={image} size={IMAGE_SIZE.MEDIUM_LARGE}
                sizes={'(min-width: 768px) 860px, 100vw'}/>
            </div>
            <h1><HTML>{name}</HTML></h1>
            <h2><HTML>{title}</HTML></h2>
            <div styleName="ingress"><HTML>{ingress}</HTML></div>
            <div styleName="article-meta-row">
              <div styleName="meta-row-share-buttons">
                <div styleName="share-buttons">
                  <ul>
                    {email
                      ? <li>
                        <a href={'mailto:' + email} target="_blank" rel="noopener noreferrer">
                          <MailLogo />
                          <span className="screen-reader-text">Lähetä sähköpostia toimittajalle</span>
                        </a>
                      </li>
                      : null }
                    {twitter
                      ? <li styleName="twitter">
                        <a href={'http://www.twitter.com/' + encodeURIComponent(twitter)} target="_blank" rel="noopener noreferrer">
                          <TwitterLogo />
                          <span>@{twitter}</span>
                        </a>
                      </li>
                      : null }
                  </ul>
                </div>
              </div>
            </div>
          </header>
          <div className="article-body" styleName="article-text author-body">
            <HTML>{description}</HTML>
          </div>
          <div styleName="author-body">
            {posts
              ? <Timeline posts={posts} title="Artikkelit" loading={loading} compact
                loadMore={noMorePosts ? null : this.loadMore.bind(this)}/>
              : null}
          </div>
        </article>
      </Fragment>
    )
  }
}

export default withErrorBoundary(
  Author,
  ErrorPlaceholder()
)
