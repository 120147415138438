import React, { Component, Fragment } from 'react'
import WP from '../../lib/WP'
import ArticleListItem from '../general/article/ArticleListItem'
import './MostReadPaid.scss'
import PropTypes from 'prop-types'
import { IMAGE_SIZE } from '../../entities/ImageModel'
import track from 'react-tracking'
import shuffle from 'lodash/shuffle'

class MostReadPaid extends Component {
  _mounted = false
  state = {
    optimizeValue: 0
  }

  static propTypes = {
    exclude: PropTypes.array.isRequired, // article id's to exclude
    doneLoading: PropTypes.func,
  }

  async loadData (props) {
    const [mostReadArticles, mostOrderArticleIDs] = await Promise.all([WP.getMostReadPaid(), WP.getMostShoppingCartAction()])
    if (this._mounted) {
      this.setState({ exclude: props.exclude })
      let filteredIDs = mostOrderArticleIDs.filter((id) => !this.state.exclude.includes(id))
      let filteredMostReadArticles = mostReadArticles.filter((article) => !this.state.exclude.includes(article.id))
      filteredIDs = this.filterArticleIDs(filteredIDs, filteredMostReadArticles.length <2 ? 4-filteredMostReadArticles.length  : 2)
      filteredMostReadArticles = this.filterArticles(filteredMostReadArticles, filteredIDs.length  < 2 ? 4 - filteredIDs.length  : 2)

      const filteredMostOrderActionArticles = filteredIDs.length
        ? (await WP.getFromPostTypes(Object.keys(WP.postTypes), { include: filteredIDs })).data
        : []
      this.setState({ filteredMostOrderActionArticles, filteredMostReadArticles })
    }
    this.props.doneLoading && this.props.doneLoading()
  }

  filterArticleIDs (filteredArticles, numberOfArticles) {
    if (filteredArticles.length > numberOfArticles) {
      filteredArticles = shuffle(filteredArticles)
      filteredArticles = filteredArticles.slice(0, numberOfArticles)
    }

    filteredArticles.forEach(id => {
      this.state.exclude.push(id)
    })

    return filteredArticles
  }

  filterArticles (filteredArticles, numberOfArticles) {
    if (filteredArticles.length > numberOfArticles) {
      filteredArticles = shuffle(filteredArticles)
      filteredArticles = filteredArticles.slice(0, numberOfArticles)
    }

    filteredArticles.forEach(article => {
      this.state.exclude.push(article.id)
    })

    return filteredArticles
  }

  async componentDidMount () {
    this._mounted = true
    this.loadData(this.props)
  }

  componentWillUnmount () {
    this._mounted = false
  }

  componentWillReceiveProps (props) {
    if (JSON.stringify(props) !== JSON.stringify(this.props)) {
      this.loadData(props)
    }
  }

  shouldComponentUpdate (nextProps, nextState) {
    return nextState !== this.state
  }

  render () {
    const { filteredMostOrderActionArticles, filteredMostReadArticles } = this.state
    if (!filteredMostOrderActionArticles || !filteredMostReadArticles) {
      return null
    }

    return (
      <Fragment>
        <div styleName="wrapper">
          <div styleName="title-container">
            <div styleName="title-icon"></div>
            <h2 styleName="title-content">Suosittelemme sinulle</h2>
          </div>
          <div styleName="full-row">
            {filteredMostOrderActionArticles.map((article, idx) => <div key={idx} styleName="half-col">
              <ArticleListItem article={article} size="md" relatedArticleListLimit={0}
                sizes="(max-width: 768px) 100vw, 400px" imageSize={IMAGE_SIZE.MEDIUM_LARGE}/>
            </div>)}
            {filteredMostReadArticles.map((article, idx) => <div key={idx} styleName="half-col">
              <ArticleListItem article={article} size="md" relatedArticleListLimit={0}
                sizes="(max-width: 768px) 100vw, 400px" imageSize={IMAGE_SIZE.MEDIUM_LARGE}/>
            </div>)}
          </div>
        </div>
      </Fragment>
    )
  }
}

export default track({ gtmContext: ['MostReadPaid'] })(MostReadPaid)
