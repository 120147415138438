import React from 'react'
import { TextBlock, RectShape } from 'react-placeholder/lib/placeholders'
import './SidebarLoader.scss'

const SidebarLoader = (props) => {
  const { items = 5 } = props

  const articlePlaceholders = []
  for (let i = 0; i < items; ++i) {
    articlePlaceholders.push(
      <div styleName="loader" key={i}>
        <RectShape style={{ width: '100%', height: 200 }}/>
        <TextBlock rows={3} color='grey' />
      </div>
    )
  }
  return articlePlaceholders
}

export default SidebarLoader
