const articleTypes = {
  DEFAULT: 'default', // flow article, can also be an ad
  COMMENT: 'comment', // comment article with flow layout
  SPECIAL: 'special', // special layout, same as magazine article, can also be an ad
  FEATURE: 'feature', // super special layout, with sticky images etc
  FEATUREV2: 'featurev2', // super special layout, with different heading
  MAGAZINE_ARTICLE: 'magazine-article', // magazine layout
  MAGAZINE_COMMENT: 'magazine-comment', // comment article with magazine layout
  THEME_ARTICLE: 'om_theme_article', // magazine layout
  AD_ARTICLE: 'om_ad_article', // ad with magazine layout
}

export default articleTypes
