import React from 'react'
import PropTypes from 'prop-types'
import { Link } from '../general/util/Links'
import './ShowAll.scss'
export const ShowAll = ({ url, inline, linkText, clickLocation }) => {
  return <Link to={{ link: url }} styleName={inline ? 'with-icon inline' : 'with-icon'} clickLocation={clickLocation}>{linkText || 'Näytä kaikki'}</Link>
}

ShowAll.propTypes = {
  url: PropTypes.string,
  inline: PropTypes.bool,
  linkText: PropTypes.string,
  clickLocation: PropTypes.string,
}
