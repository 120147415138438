const validateEmail = input => {
  return input.length && /^\S+@\S+$/.test(input)
}

const validateComment = input => {
  return !!input.length
}

export {
  validateEmail,
  validateComment,
}
