import React, { Fragment } from 'react'
import { TextBlock, RectShape } from 'react-placeholder/lib/placeholders'
import './WeeklyMagazineLoader.scss'

const WeeklyMagazineLoader = () => {
  const articlePlaceholders = []
  for (let i = 0; i < 10; ++i) {
    articlePlaceholders.push(
      <div styleName="loader" key={i}>
        <RectShape style={{ width: 240, height: 166 }} />
        <TextBlock rows={3} color='grey' />
      </div>
    )
  }
  return (
    <Fragment>
      <div styleName="loader-container">
        <RectShape styleName="main-image-loader" style={{ width: '100%', height: 350 }}/>
        {articlePlaceholders}
      </div>
    </Fragment>
  )
}

export default WeeklyMagazineLoader
