import PropTypes from 'prop-types'
import { channel, buffers } from 'redux-saga'
import { put, take } from 'redux-saga/effects'
import { kea } from 'kea'
import WP from '../lib/WP'
import { logException } from '../lib/errors'

const responseChannel = channel(buffers.expanding())

export default kea({
  paths: ['newsletter'], // default
  actions: () => ({
    orderNewsletter: (email, username, list) => ({ email, username, list }),
    updateStatus: status => status,
    setNewsletterTaken: (elem) => {
      return elem
    },
  }),

  reducers: ({ actions }) => ({
    status: ['', PropTypes.string, { persist: false }, {
      [actions.updateStatus]: (state, payload) => payload
    }],
    newsletterTaken: [null, PropTypes.object, {
      [actions.setNewsletterTaken]: (state, payload) => {
        return payload
      }
    }],
  }),

  start: function * () {
    while (true) {
      const action = yield take(responseChannel)
      yield put(action)
    }
  },

  takeEvery: ({ actions }) => ({
    [actions.orderNewsletter]: function * ({ payload }) {
      try {
        yield put(actions.updateStatus('loading'))

        WP.newsletterSubscribe(payload.email, payload.username, payload.list).then(result => {
          const data = result.status === 200 ? result.data.response.response : {}
          if (data.code === 200) {
            responseChannel.put(actions.updateStatus(data.message === 'OK' ? 'success' : 'failed'))
          } else {
            console.error(result)
            responseChannel.put(actions.updateStatus('failed'))
          }
        }).catch(error => {
          logException(error)
          responseChannel.put(actions.updateStatus('failed'))
        })
      } catch (error) {
        logException(error)
        responseChannel.put(actions.updateStatus('failed'))
      }
    }
  }),

  selectors: ({ selectors }) => ({
    isSubmitted: [
      () => [selectors.status],
      status => status === 'success',
      PropTypes.bool,
    ],
    hasFailed: [
      () => [selectors.status],
      status => status === 'failed',
      PropTypes.bool,
    ],
    isLoading: [
      () => [selectors.status],
      status => status === 'loading',
      PropTypes.bool,
    ],
    isNewsletterTaken: [
      () => [selectors.newsletterTaken],
      newsletterTaken => {
        return newsletterTaken
      },
      PropTypes.object,
    ],
  })
})
