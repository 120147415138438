import React from 'react'
import PropTypes from 'prop-types'
import './CategoryList.scss'
import track from 'react-tracking'
import { Link } from '../util/Links'

const CategoryList = ({ categories, mainOnly, noLink }) => {
  if (mainOnly && categories && categories.length) {
    categories = [categories[0]]
  } else {
    categories = categories || []
  }
  return (
    <div styleName="article-categories">
      <ul>
        {categories
          ? categories.map((category, index) => (
            <li key={`category-${category.slug ? category.slug : index}`}>
              {(noLink || !category.link) ? category.name : <Link to={{ link: category.link }} rel="tag">{category.name}</Link>}
            </li>
          ))
          : null}
      </ul>
    </div>
  )
}

CategoryList.propTypes = {
  categories: PropTypes.array.isRequired,
  mainOnly: PropTypes.bool,
  noLink: PropTypes.bool
}

export default track({ gtmContext: ['CategoryList'] })(CategoryList)
