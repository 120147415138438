import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import track from 'react-tracking'
import { ErrorPlaceholder, withErrorBoundary } from '../components/general/util/ErrorBoundaries'
import PopularPosts from '../components/general/popular/PopularPosts'
import LatestMagazine from '../components/weeklyMagazine/LatestMagazine'
import MostCommented from '../components/widgets/MostCommented'
import LatestPosts from '../components/general/popular/LatestPosts'
import TopRowAd from '../components/general/util/TopRowAd'
import { AdsForDesktop, AdsForMobile, AdSlotMob2, AdSlotRectangle1, AdSlotRectangle2 } from '../components/general/ads/Ads'
import NoAdBlocker from '../components/widgets/NoAdBlocker'
import { ShowAll } from '../components/widgets/ShowAll'

import './Latest.scss'

@track({ gtmContext: ['LatestArticles'] })
class Latest extends Component {
  static propTypes = {
    match: PropTypes.object,
    setRendered: PropTypes.func,
    mostRead: PropTypes.bool,
  }

  constructor (props) {
    super()
    this.state = {}
    if (props.match.url.includes('/suosituimmat')) {
      this.state.mostRead = true
    }
  }

  async componentWillReceiveProps (props) {
    const mostRead = !!props.match.url.includes('/suosituimmat')
    this.setState({ mostRead })
  }

  setRendered (x) {
    this.props.setRendered(x)
  }

  render () {
    const { mostRead } = this.state

    return (
      <Fragment>
        <Helmet>
          <title>
            {mostRead ? 'Suosituimmat artikkelit' : 'Uusimmat artikkelit'}
          </title>
        </Helmet>
        <TopRowAd isArticleView={false} display={true}/>
        <div styleName="article-container">
          <article styleName="content">
            {mostRead
              ? <Fragment>
                <div styleName="popular">
                  <PopularPosts limit={20} doneLoading={() => 1}/>
                </div>
                <LatestPosts setRendered={this.setRendered.bind(this)}/>
              </Fragment>
              : <Fragment>
                <LatestPosts setRendered={this.setRendered.bind(this)}/>
                <div styleName="popular">
                  <PopularPosts limit={10} doneLoading={() => 1}/>
                  <ShowAll url="/suosituimmat" />
                </div>
              </Fragment>
            }
          </article>
        </div>
        <aside styleName="sidebar">
          <PopularPosts paidOnly doneLoading={() => 1}/>
          <AdsForDesktop>
            <AdSlotRectangle1 fallBackElements={[NoAdBlocker]}/>
          </AdsForDesktop>
          <AdsForMobile>
            <AdSlotMob2 fallBackElements={[NoAdBlocker]} />
          </AdsForMobile>
          <div className="latest-mag">
            <LatestMagazine/>
          </div>
          <MostCommented/>
          <AdsForDesktop>
            <AdSlotRectangle2 fallBackElements={[NoAdBlocker]}/>
          </AdsForDesktop>
          <AdsForMobile>
            <AdSlotMob2 fallBackElements={[NoAdBlocker]} />
          </AdsForMobile>
        </aside>
      </Fragment>
    )
  }
}

export default withErrorBoundary(
  Latest,
  ErrorPlaceholder()
)
