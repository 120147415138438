import styled from 'styled-components'
import ArticleCard from '@otavamedia/om-component-library/lib/ArticleCard'
import React, { Component } from 'react'
import HTMLParser from '../../lib/html-parser'
import { IMAGE_SIZE } from '../../entities/ImageModel'
import get from 'lodash/get'
import PropTypes from 'prop-types'

const ProductCard = styled(ArticleCard)`
  height: 100%;
  > div:first-child {
    height: 175px;
    overflow: hidden;
  }
  &:hover {
    h2, .topic {
      transition: color 0.1s ease;
      color: #565e70;
    }
  }
  video {
    display: block;
  }

  & .image-container {
    height: 190px;
    border-bottom: 4px solid #00091e;
  }

  & .card-image {
    height: 100%;
    object-fit: cover;
  }

  & .topic {
    font-family: "Flama medium";
    text-transform: uppercase;
    font-size: 0.75rem;
    color: #484e5d;
    margin: 0.5rem 0;
  }

  & h2 {
    font-family: "Flama Semicond Semibold";
    font-size: 20px;
    line-height: 24px;
    margin: 0 0 0.5rem 0;
    transition: color 0.1s ease;
  }
  .card-meta {
    font-family: "Flama Medium";
    text-transform: uppercase;
    font-size: 0.75rem;
    line-height: 1rem;
    color: #8c9696;
    padding: 1rem;
    padding-bottom: 0;
    justify-content: space-between;

    li {
      margin: 0;
      color: #484e5d;
    }
    .right-column {
      text-transform: none;
      color: #484e5d;
    }
  }
  .printmag {
    position: absolute;
    background: red;
    color: white;
    left: 38%;
    top: 165px;
    font-family: "Flama Medium";
    font-size: 10px;
    font-weight: 500;
    line-height: 16px;
    padding: 0 5px;
  }
  .printmag::before {
    content: '';
    background-image: url('/assets/logos/tm-logo.svg');
    background-repeat: no-repeat;
    height: 13px;
    width: 21px;
    display: inline-block;
    vertical-align: middle;
    margin-left: 0;
  }
  .grade {
    background: #009fe3;
    padding: 6px;
    border-radius: 3px;
    font-family: "Flama Cond Semibold";
    font-size: 20px;
    line-height: 20px;
    position: absolute;
    right: 1.75rem;
    top: 1rem;
    color: white;
  }
  .commentCount {
    font-family: Flama Medium,sans-serif;
    color: #979797;
    text-transform: uppercase;
    font-size: 12px;
  }
`
const BestProductCard = styled(ProductCard)`
  text-align: center;

  .right-column {
    display: none
  }
  .card-meta {
    justify-content: center;
  }
  .card-content {
    padding-top: 0;
  }
`

const ReaderCard = styled(BestProductCard)`
  .card-meta {
    justify-content: center;
    padding-top: 0;
  }
  .article-card > div:nth-child(2) {
    padding-top: 1rem;
  }
  .top-header {
    font-family: "Guardian TextSans Bold", sans-serif;
    font-size: 16px;
    padding-bottom: 4px;
    padding-top: 12px !important;
  }
`

export default class TestCard extends Component {
  static propTypes = {
    article: PropTypes.object.isRequired,
    notShown: PropTypes.object,
    isBest: PropTypes.bool,
    topHeader: PropTypes.string,
    ownGrade: PropTypes.bool,
  }

  static defaultProps = {
    notShown: {},
  }

  notALink = (props) => {
    return <div {...props}>{props.children}</div>
  }

  render () {
    const { isBest, article, notShown, ownGrade, topHeader } = this.props
    const grade = ownGrade ? 'productData.grade' : 'linkedProductCards[0].productData.grade'
    const Card = topHeader ? ReaderCard : (isBest ? BestProductCard : ProductCard)
    return <Card article={article}
      topHeader={topHeader}
      LinkComponent={this.notALink}
      htmlParser={HTMLParser.render}
      grade={get(article, grade)}
      shown={{ printmag: true }}
      notShown={{ ...notShown, ingress: true, topic: true }}
      categories={isBest ? [{ name: article.ingress }] : article.categories && article.categories.length ? [article.categories[0]] : undefined}
      displaySubscriberIcon={article.requiredSubscriptionLevel > 2} imageSize={IMAGE_SIZE.MEDIUM}
      sizes={'(min-width: 992px) 320px, (min-width: 768px) 50vw, 100vw'}/>
  }
}
