import React from 'react'
import { TextBlock, RectShape } from 'react-placeholder/lib/placeholders'
import './EditorsPicksLoader.scss'

const EditorsPicksLoader = (props) => {
  const { items = 5 } = props

  const articlePlaceholders = []
  for (let i = 0; i < items; ++i) {
    articlePlaceholders.push(
      <div styleName="loader" key={i}>
        <RectShape style={{ width: '33%', height: 75 }}/>
        <TextBlock rows={2} color='grey' />
      </div>
    )
  }
  return articlePlaceholders
}

export default EditorsPicksLoader
