import React, { Component } from 'react'
import PropTypes from 'prop-types'
import './Sidebar.scss'
import Sidebar from './Sidebar'
import { ErrorPlaceholderBox, withErrorBoundary } from '../general/util/ErrorBoundaries'
import Newsletter from '../general/newsletter/Newsletter'
import { AdsForDesktop, AdsForMobile, AdSlotMob2, AdSlotRectangle2, AdSlotSkyscraper1 } from '../general/ads/Ads'
import some from 'lodash/some'
import track from 'react-tracking'
import NoAdBlocker from '../widgets/NoAdBlocker'
import WhyOrder from '../widgets/WhyOrder'
import breakpoints from '../../lib/breakpoints'
import Breakpoint from '@otavamedia/om-component-library/lib/util/Breakpoint'

/**
 * This renders the sidebar in Taxonomy component (i.e. category and keyword pages) and also the lower sidebar
 * on the front page (Index component). This component doesn't have any functionality of its own, it mainly
 * just renders the Sidebar component, but adds a Newsletter component and some ads.
 */
@track({ gtmContext: ['IndexSidebar'] })
class IndexSidebar extends Component {
  loaded = {
    sidebar2: false,
  }

  constructor (props) {
    super(props)
    this.shouldReload = true
  }

  componentWillReceiveProps (props) {
    this.loaded = {
      sidebar2: false,
    }
  }

  doneLoading (element) {
    this.loaded[element] = true
    if (!some(Object.values(this.loaded), (x) => !x)) {
      this.props.doneLoading()
    }
  }

  render () {
    const { blocks, categoryId, error, shortcuts, facts, historyCard } = this.props
    if (error) {
      throw error
    }
    return (
      <Sidebar category={categoryId} {...blocks} articles doneLoading={() => this.doneLoading('sidebar2')}
        shortcutArticles={shortcuts} popularPaid nativeAds latest facts={facts} historyCard={historyCard}>
        <Breakpoint minBreakpoint={breakpoints.sm}>
          <WhyOrder/>
        </Breakpoint>
        <AdsForDesktop>
          <Newsletter showAlways={true}/>
          <AdSlotRectangle2 debugName="index-main-sidebar" fallBackElements={[NoAdBlocker]} />
          <AdSlotSkyscraper1 fallBackElements={[NoAdBlocker]} />
        </AdsForDesktop>
        <AdsForMobile>
          <AdSlotMob2 fallBackElements={[NoAdBlocker]} />
          <Newsletter showAlways={true}/>
        </AdsForMobile>
      </Sidebar>
    )
  }
}

IndexSidebar.propTypes = {
  status: PropTypes.number,
  wait: PropTypes.bool,
  categoryId: PropTypes.number,
  items: PropTypes.array,
  shortcuts: PropTypes.array,
  isReady: PropTypes.bool,
  facts: PropTypes.bool,
  blocks: PropTypes.object,
  error: PropTypes.object,
  historyCard: PropTypes.object,
  doneLoading: PropTypes.func,
}

IndexSidebar.defaultProps = {
  wait: false,
  blocks: {},
}

export default withErrorBoundary(
  IndexSidebar,
  ErrorPlaceholderBox('Sisältöä ei saatu ladattua')
)
