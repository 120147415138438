import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { validateComment } from '../../../validators'
import classnames from 'classnames'
import './CommentBox.scss'
import Parser from 'html-dom-parser'
import { getQuotedText, textToHtml } from '../../../lib/utils'

class CommentBox extends Component {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    quote: PropTypes.any,
  }

  constructor () {
    super()

    this.state = {
      error: false,
      message: '',
      commentText: '',
    }
  }

  handleInput = event => {
    const input = event.currentTarget.value
    this.setState({
      commentText: input,
    })
  }

  async handleSubmit () {
    const { commentText } = this.state

    // console.log('Comment submitted')
    if (!validateComment(commentText)) {
      this.setState({
        message: 'Kirjoita kommentti',
        error: true,
      })
    } else {
      this.setState({
        error: false,
      })
      const response = await this.props.onSubmit(textToHtml(this.state.commentText))

      if (response.error) {
        this.setState({
          message: `Kommentin lähettäminen epäonnistui: ${response.error}`,
          error: true,
        })
      } else if (response.status === 'approved') {
        this.setState({
          message: 'Kommentti lähetetty',
          commentText: '',
          error: false,
        })
      } else if (response.status === 'spam' || response.status === 'hold' || response.status === 'trash') {
        this.setState({
          message: 'Kommenttisi menee toimituksen tarkistettavaksi ennen julkaisemista.',
          commentText: '',
          error: false,
        })
      } else if (response.message) {
        this.setState({
          message: response.message,
          error: true,
        })
      }
    }
  }

  quote (comment) {
    const text = comment.author_name + ' kirjoitti:\n' + comment.content.rendered
    return getQuotedText(Parser(text), 1, true)
  }

  componentWillReceiveProps (newProps) {
    if (newProps.quote) {
      this.setState({ commentText: this.quote(newProps.quote) })
    }
  }

  render () {
    const { message, error, commentText } = this.state
    return (
      <div styleName="main">
        <div styleName="header">Osallistu keskusteluun</div>
        {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
        <div styleName="input" onKeyDown={(e) => e.stopPropagation()}
          onTouchStart={(e) => e.stopPropagation()}
          onTouchMove={(e) => e.stopPropagation()}
          onMouseDown={(e) => e.stopPropagation()}>
          <textarea ref={this.input} rows="6" value={commentText} placeholder="Kirjoita kommenttisi..." onChange={this.handleInput} />
        </div>
        <div styleName="actions" id="commentBox">
          <button styleName="submit" onClick={ this.handleSubmit.bind(this) }>Jätä kommentti</button>
        </div>
        {message && (
          <div styleName={classnames('message', { error })}>
            {message}
          </div>
        )}
      </div>
    )
  }
}

export default CommentBox
