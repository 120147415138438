import React, { Component } from 'react'
import PropTypes from 'prop-types'
import track from 'react-tracking'
import WP from '../../../lib/WP'
import { ErrorPlaceholder, withErrorBoundary } from '../../general/util/ErrorBoundaries'
import Timeline from '../../widgets/Timeline'
import './LatestPosts.scss'

@track({ gtmContext: ['LatestArticles'] })
class LatestPosts extends Component {
  static propTypes = {
    setRendered: PropTypes.func,
    compact: PropTypes.bool,
  }

  state = {
    latest: [],
    loading: true,
    exclude: []
  }

  async componentDidMount () {
    this.setState({ loading: true })
    const latest = await WP.getLatest(0, 1, this.state.exclude)

    this.setState({
      exclude: this.state.exclude.concat(latest.map(post => post.id)),
      loading: false,
      noMorePosts: !latest,
      latest,
      page: 1
    }, () => this.props.setRendered && this.props.setRendered(true))
  }

  async loadMore () {
    const { latest } = this.state
    this.setState({ loading: true })
    const moreLatest = await WP.getLatest(0, 1, this.state.exclude)
    this.setState({
      exclude: this.state.exclude.concat(moreLatest.map(post => post.id)),
      loading: false,
      noMorePosts: !moreLatest.length,
      latest: latest.concat(moreLatest),
    })
  }

  render () {
    const { latest, noMorePosts, loading } = this.state
    const { compact } = this.props

    return latest
      ? <div styleName={'timeline' + (compact ? ' compact' : '')}>
        <h2>Uusimmat</h2>
        <Timeline posts={latest} noTitle loading={loading} groupBy="date" compact noImages veryCompact={compact}
          url={compact ? '/uusimmat/' : null}
          dateOnly={false} loadMore={noMorePosts || compact ? null : this.loadMore.bind(this)}/>
      </div>
      : null
  }
}

export default withErrorBoundary(
  LatestPosts,
  ErrorPlaceholder()
)
