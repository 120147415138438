import React, { Component, Fragment } from 'react'
import './ReadNext.scss'
import Image from '../util/Image'
import HTML from '../util/HTML'
import WP from '../../../lib/WP'
import track from 'react-tracking'
import VideoOverlay from './../../widgets/VideoOverlay'

class VideoBox extends Component {
  _mounted

  constructor (props) {
    super(props)
    this.state = {
      hidden: false,
      videos: null
    }
  }

  close () {
    this.setState({ hidden: true })
  }

  componentWillMount () {
    this.loadData()
  }

  componentDidMount () {
    this._mounted = true
  }

  componentWillUnmount () {
    this._mounted = false
  }

  componentWillReceiveProps (newProps) {
  }

  async loadData () {
    const videos = await WP.getVideos()
    if (!this._mounted || !videos.items || !videos.items[0]) return
    this.setState({ video: videos.items[0] })
  }

  showVideo () {
    this.setState({
      activeVideo: this.state.video
    })
  }

  closeVideo () {
    this.setState({
      activeVideo: null
    })
  }

  render () {
    if (this.state.hidden || !this.state.video) return null
    const { video, activeVideo } = this.state

    return <div styleName="readNext">
      <div styleName="componentRow">
        <div styleName="componentCol">
          <div styleName="content">
            <Fragment>
              <h4 styleName="header">Katso uusin Tekniikan Maailman video</h4>
              <button styleName="close-btn" aria-hidden="true" onClick={() => this.close()}>+</button>
              <div styleName="article">
                <div>
                  <button onClick={() => { this.showVideo(); return false }}>
                    <Image data={
                      {
                        src: video.meta.poster['240p'] || video.meta.poster.image,
                        alt: '',
                        sizes: { medium: { source_url: video.meta.poster['240p'] } }
                      }
                    } sizes={'100px'}/>
                  </button>
                </div>
                <div styleName="body">
                  <button onClick={() => { this.showVideo(); return false }}>
                    <h5 styleName="title">
                      <HTML>{video.meta.title}</HTML>
                    </h5>
                  </button>
                </div>
              </div>
            </Fragment>
          </div>
        </div>
      </div>
      {!!activeVideo && <VideoOverlay activeVideo={activeVideo} closeVideo={() => this.closeVideo()} />}
    </div>
  }
}

export default track({ gtmContext: ['ReadNext'] })(VideoBox)
