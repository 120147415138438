import React, { Component } from 'react'
import WP from '../../lib/WP'
import ArticleCard from '@otavamedia/om-component-library/lib/ArticleCard'
import './TestBox.scss'
import { Link } from '../general/util/Links'
import HTMLParser from '../../lib/html-parser'
import styled from 'styled-components'
import { TmLogo } from '../widgets/Icons'
import track from 'react-tracking'

const TestArticleItem = styled(ArticleCard)`
  margin-bottom: 0;
  height: 100%;
  border: 1px solid #707070;
  h2 {
    margin: 0;
    font-size: 1.25rem;
    color: rgb(0, 9, 30);
    font-family: "Flama Semicond Semibold";
    line-height: 1.5rem;
    letter-spacing: 0;
    a {
      display: block;
    }
  }
  video {
    display: block;
  }
  .image-container {
    
  }

  .card-label {
    font-family: "Flama Medium";
    text-transform: uppercase;
    font-size: 0.75rem;
    line-height: 1rem;
    color: #484e5d;
  }

  .card-meta {
    font-family: "Flama Medium";
    text-transform: uppercase;
    font-size: 0.75rem;
    line-height: 1rem;
    color: #8c9696;
    padding: 0;
    padding-bottom: 0.75rem;
    justify-content: space-between;
  }
  .card-content {
    padding-top: 1rem !important;
  }
  .meta-divider {
    padding: 0 0.5rem;
  }

  .category-list {
    white-space: nowrap;
  }

  .category-list li:first-of-type {
    margin-left: 0;
    color: #484e5d;
  }

  .category-list li:last-of-type {
    margin-right: 0;
    color: #484e5d;
  }

  .subscriber-icon {
    font-family: "Flama Medium";
    font-size: 12px;
    color: #484e5d;
    display: flex;
    margin-left: auto;
  }

  
  img {
    height: 100% !important;
    object-fit: cover;
  }
`
const LargeTestArticleItem = styled(TestArticleItem)`
  flex-direction: row !important;
  border: none !important;
  .image-container {
    flex: 1 1 50%;
  }
  .card-content {
    flex: 1 0 50%;
  }
  `

export default @track({ gtmContext: ['TestBox'] })
class TestBox extends Component {
  constructor (props) {
    super(props)
    this.loadArticles()
    this.state = {}
  }

  async loadArticles () {
    const articles = await WP.getLatestTests()
    this.setState({ articles: articles.map(article => article.item) })
  }

  render () {
    const { articles } = this.state
    if (!articles || articles.length < 4) return null
    const mainArticle = articles[0]
    const smallArticles = articles.slice(-3)

    return <div styleName="test-box">
      <div styleName="header-bar">
        <div styleName="logo">
          <TmLogo/>
        </div>
        <div styleName="title">
          Vuoden tärkeimpiä vertailuja
        </div>
      </div>
      <div styleName="content">
        <div styleName="large-article">
          <LargeTestArticleItem
            LinkComponent={Link}
            categories={mainArticle.categories && mainArticle.categories.length ? [mainArticle.categories[0]] : []}
            article={mainArticle}
            htmlParser={HTMLParser.render}
            notShown={{ ingress: true, topic: true }}
            displaySubscriberIcon={mainArticle.forSubscribers}
            metaInsideContent={true}
          />
        </div>
        <div styleName="small-articles">
          {smallArticles.map((article, key) => <div styleName="small-article" key={key}>
            <TestArticleItem
              LinkComponent={Link}
              categories={article.categories && article.categories.length ? [article.categories[0]] : []}
              article={article}
              htmlParser={HTMLParser.render}
              notShown={{ ingress: true, topic: true, image: true }}
              displaySubscriberIcon={article.forSubscribers}
              metaInsideContent={true}
            />
          </div>)
          }
        </div>
      </div>
      <div styleName="footer-bar">
        <div styleName="red"><Link to={{ link: '/testit' }} clickLocation="text">Katso testit ja vertailut</Link></div>
        <div styleName="categories">
          <div><Link to={{ link: '/testit-autot' }} clickLocation="text">Ajoneuvot</Link></div>
          <div styleName="bar">|</div>
          <div><Link to={{ link: '/testit-elektroniikka/kodintekniikka' }} clickLocation="text">Kodintekniikka</Link></div>
          <div styleName="bar">|</div>
          <div><Link to={{ link: '/testit-elektroniikka/audio' }} clickLocation="text">Audio</Link></div>
          <div styleName="bar">|</div>
          <div><Link to={{ link: '/testit-elektroniikka/kamerat' }} clickLocation="text">Kamerat</Link></div>
          <div styleName="bar">|</div>
          <div styleName="keep-together">
            <Link to={{ link: '/testit-elektroniikka/mobiililaitteet' }} clickLocation="text">Puhelimet</Link>
            <div styleName="bar">|</div>
            <Link to={{ link: '/testit-elektroniikka/televisiot' }} clickLocation="text">Televisiot</Link>
          </div>
        </div>
      </div>
    </div>
  }
}
