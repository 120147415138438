import React from 'react'
import PropTypes from 'prop-types'
import './ArticleMeta.scss'
import { authors } from '../../../lib/defaults'
import HTML from '../util/HTML'
import { getURL } from '../../../lib/WPClient'

const ArticleAuthor = ({ author }) => {
  if (!author.name) return null

  let name = author.name
  Object.keys(authors).forEach((author) => {
    name = name.replace(new RegExp(author, 'i'), (match, offset, string) =>
      '<a href="' + getURL('/toimittaja/' + author.replace(' ', '-')) + '">' + match + '</a>')
  })
  return <span styleName="author-fields">
    <span><HTML>{name}</HTML></span>
  </span>
}

ArticleAuthor.propTypes = {
  author: PropTypes.object,
}

export default ArticleAuthor
