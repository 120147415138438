import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'kea'

import magazineStore from '../../kea/weeklyMagazine'
import auth from '../../kea/auth'
import HTML from '../general/util/HTML'
import ProductAds from '../general/article/ProductAds'
import ArticleDate from '../general/article/ArticleDate'
import ArticleAuthorList from '../general/article/ArticleAuthorList'
import ArticleMetaDivider from '../general/article/ArticleMetaDivider'
import Comparisons from '../general/article/Comparisons'
import Image from '../../components/general/util/Image'
import ShareButtons from '../general/widgets/ShareButtons'
import CategoryList from '../general/lists/CategoryList'
import KeywordList from '../general/lists/KeywordList'
import SideStories from '../general/article/SideStories'
import WeightTable from '../general/article/WeightTable'
import Accordion from '../general/article/Accordion'
import articleTypes from '../general/article/ArticleTypes'
import RelatedArticleList from '../general/article/RelatedArticleList'
import ArticleComments from '../general/comments/ArticleComments'
import Paywall from '../widgets/Paywall'
import PaywallBanner from '../widgets/PaywallBanner'
import MostReadPaid from '../widgets/MostReadPaid'
import { ErrorPlaceholder, withErrorBoundary } from '../general/util/ErrorBoundaries'
import { IMAGE_SIZE } from '../../entities/ImageModel'
import { AdsForDesktop, AdsForMobile, AdSlotDesk1, AdSlotDesk2, AdSlotMob1, AdSlotMob2 } from '../general/ads/Ads'
import { SubscriberIcon } from '../widgets/Icons'
import WP from '../../lib/WP'
import { getURL } from '../../lib/WPClient'
import bestLogo from '../../assets/logos/TM_ovaalilogot_parhaat.png'
import some from 'lodash/some'
import get from 'lodash/get'
import { Link } from '../general/util/Links'
import PrevNextBox from '../widgets/PrevNextBox'
import DigimagBox from '../widgets/DigimagBox'
import TestBox from '../widgets/TestBox'
import Timeline from '../widgets/Timeline'
import PopularPosts from '../general/popular/PopularPosts'
import NettixEmbed from '../general/nettix/NettixEmbed'
import Question from '../general/article/Question'
import ProductMemory from '../general/article/ProductMemory'
import ProductCard from '../general/article/ProductCard'
import CrossLinkTopNotification from '../widgets/CrossLinkTopNotification'
import CrossLinkBottomNotification from '../widgets/CrossLinkBottomNotification'
import Themebox from '../general/article/Themebox'
import GAMNativeAdHolder from '../widgets/GAMNativeAdHolder'
import { ShowAll } from '../widgets/ShowAll'
import AuthorWidget from '../widgets/AuthorWidget'
import ReportError from '../general/widgets/ReportError'
import AiArticleWidget from '../general/widgets/AiArticleWidget'

import './MagazineArticle.scss'


@connect({
  props: [
    magazineStore, [
      'getCurrentArticleTitle',
    ],
    auth, [
      'premiumUser',
      'shareTokenAccess'
    ]
  ]
})
  /**
   * This renders a magazine article (TVTM-artikkeli, digilehden artikkeli). It renders all content on the page
   * between the top row ad and the footer. Magazine articles don't have a sidebar.
   *
   * The Article component renders this component.
   */
class MagazineArticle extends Component {
  static propTypes = {
    article: PropTypes.object.isRequired,
    pagination: PropTypes.object,
    payWall: PropTypes.bool,
    getCurrentArticleTitle: PropTypes.string,
    noManualComparisonPlacement: PropTypes.bool,
    premiumUser: PropTypes.bool,
    previousArticle: PropTypes.object,
    nextArticle: PropTypes.object,
    shareTokenAccess: PropTypes.func,
    doneLoading: PropTypes.func,
  }

  constructor () {
    super()
    this.state = { nextArticle: {}, previousArticle: {}, articlesLoaded: false }
  }

  async loadPrevNextArticles (props, state) {
    const prev = await this.maybeLoadArticle('previousArticle', props, state)
    const next = await this.maybeLoadArticle('nextArticle', props, state)
    const newState = { ...prev, ...next }
    if (Object.keys(newState).length) {
      this.setState(newState)
    }
  }

  async maybeLoadArticle (propName, props, state) {
    const newState = {}
    if (props[propName] && props[propName].link && !state.articlesLoaded) {
      if (!props[propName].image) {
        // no image, load article
        newState[propName] = (await WP.getForURL(getURL(props[propName].link))).data
      } else {
        newState[propName] = props[propName]
      }
      newState.articlesLoaded = true
    }
    return newState
  }

  async componentDidMount () {
    this.loadPrevNextArticles(this.props, this.state, false)
    if (get(this.props, 'article.mobileHero')) {
      window.onscroll = function () {
        const elem = document.getElementsByClassName('MagazineArticle_on-hero-image-mobile')
        const elem2 = document.getElementById('hero-mobile-background')
        if (!elem[0]) {
          return
        }
        if (window.innerWidth > 576) {
          elem[0].style.backgroundColor = 'rgb(255,255,255)'
        } else {
          const headerHeight = 125
          const contentTop = elem[0].getBoundingClientRect().top
          const visibleHeight = contentTop - headerHeight
          const maxHeight = (window.innerHeight - headerHeight) * 0.4
          const percentage = 1 - Math.min(visibleHeight / maxHeight, 1)
          elem[0].style.backgroundColor = 'rgba(255,255,255,' + Math.min(0.5 + percentage * 0.5, 1) + ')'
          if (elem2) {
            elem2.style.opacity = 1 - percentage
          }
        }
      }
      window.onscroll()
    }
  }

  componentDidUpdate (prevProps, prevState) {
    if (!prevState.articlesLoaded) {
      this.loadPrevNextArticles(this.props, this.state)
    }
  }

  renderVignetteAndTopic = article => {
    if (article.vignette && article.topic) {
      return (
        <Fragment>
          <HTML>{article.vignette}</HTML>: <HTML>{article.topic}</HTML>
        </Fragment>
      )
    } else if (article.vignette) {
      return <HTML>{article.vignette}</HTML>
    } else {
      return null
    }
  }

  renderHeaderImage = (video, featuredMedia, mobileHero) => {
    return video
      ? (
        <div styleName="header-image-container" dangerouslySetInnerHTML={{
          __html: `
          <video
            loop
            muted
            autoplay
            playsinline
            src="${video.url}"
          />`
        }}>
        </div>
      )
      : <div styleName={mobileHero ? 'header-image-container not-mobile' : 'header-image-container'}>
        <Image addToGallery data={featuredMedia} noHeight minExpectedSize={300}/>
      </div>
  }

  renderMainImage = (video, featuredMedia = {}) => {
    if (video) {
      return (
        <div styleName="main-image-container" dangerouslySetInnerHTML={{
          __html: `
          <video
            loop
            muted
            autoplay
            playsinline
            src="${video.url}"
          />`
        }}>
        </div>
      )
    }

    const { copyright, caption } = featuredMedia
    return (
      <div styleName="main-image-container">
        <Image addToGallery data={featuredMedia} sizes={'(max-width: 768px) 100vw, (max-width: 1024px) 75vw, 1024px'}
          size={IMAGE_SIZE.LARGE} noHeight={true}/>

        {(caption || copyright) && (
          <div styleName="caption-box">
            <figcaption><HTML>{caption}</HTML></figcaption>
            <div styleName="image-copyright"><HTML>{copyright}</HTML></div>
          </div>
        )}
      </div>
    )
  }

  renderHeader = (article, title, video, shouldRenderHeroImage) => {
    const isBest = some(article.categories, { slug: 'testivoittaja' })
    const isResearcher = some(article.categories, (cat) => cat.slug === 'tutkijalta')

    return (
      <header styleName="header">
        {shouldRenderHeroImage ? this.renderHeaderImage(video, article.featuredMedia, article.mobileHero) : null}
        <div
          styleName={`title-area ${shouldRenderHeroImage ? (article.mobileHero ? 'on-hero-image on-hero-image-mobile' : 'on-hero-image') : ''}`}>
          {isBest
            ? <Link to={{ link: '/testivoittajat/' }}>
              <img styleName="best-icon" src={bestLogo} alt=""/>
            </Link>
            : null }
          {article.groupHeader && <span styleName="group-header">{article.groupHeader}</span>}

          <div styleName={isBest ? 'category-row below-best-icon' : 'category-row'}>
            <div styleName="article-categories">
              {(article.categories && article.categories.length) && !article.isAd
                ? (<Fragment><CategoryList categories={article.categories} mainOnly /><ArticleMetaDivider /></Fragment>)
                : null}
              <ArticleDate date={article.createdDate} dateOnly/>
            </div>
          </div>
          {article.vignette
            ? (
              <div styleName="vignette">
                {this.renderVignetteAndTopic(article)}
              </div>
            )
            : null
          }
          <h1 styleName={article.capitalizeTitle ? 'capitalized' : ''}><HTML>{title}</HTML></h1>
          {article.ingress ? <div styleName="ingress">{isResearcher && <span styleName="researcher">Tutkijalta</span>}<HTML>{article.ingress}</HTML></div> : null}
          {article.forSubscribers && <div styleName="subscriber-icon"><SubscriberIcon/></div>}
          <div styleName={`article-meta-row ${article.articleType === articleTypes.MAGAZINE_COMMENT ? 'centered' : ''}`}>
            {article.articleType !== articleTypes.MAGAZINE_COMMENT
              ? (
                <div styleName="article-author">
                  <ArticleAuthorList author={article.author} photographer={article.photographer} assistants={article.assistants} authorTitle={article.authorTitle}/>
                </div>
              )
              : null
            }
            <div styleName="meta-row-share-buttons">
              <ShareButtons shareCount={article.shareCount} article={article}/>
            </div>
          </div>
        </div>
      </header>
    )
  }

  renderCommentAuthor = article => {
    const { featuredMedia, commentator, authorTitle } = article

    return (
      <div styleName="comment-author">
        {featuredMedia && <figure>
          <div styleName="featured-image">
            <Image data={featuredMedia} isZoomed noHeight/>
          </div>
        </figure>
        }
        <div styleName="comment-author-description">
          <ArticleAuthorList author={commentator} authorTitle={authorTitle}/>
        </div>
      </div>
    )
  }

  render () {
    const {
      article,
      payWall,
      getCurrentArticleTitle,
      noManualComparisonPlacement,
      premiumUser,
      shareTokenAccess,
    } = this.props

    const {
      id,
      articleType,
      content,
      pagination,
      headerVideoMp4,
      headerVideoWebm,
      comparisons,
      sidestories,
      tags,
      weightTable,
      details,
      title,
      titleOverride,
      linkedProductCards,
      linkedHistoryCards,
      createToc,
      relatedArticles,
      productAds,
      relatedAds,
      hideAds,
      disableAdCoin,
      isAd,
      timelineTag,
      timelineDigimag,
      nettixSearchId,
      linkedProductMemories,
      categories,
      linkedQuestions,
      originalArticle,
      scrollVideos,
      author,
      commentator
    } = article

    const shouldRenderHeroImage = (article.articleType === articleTypes.SPECIAL || article.articleType === articleTypes.MAGAZINE_ARTICLE) && article.hasHeroImage
    const articleStyles = ['article', 'article-container']
    const isBest = some(article.categories, { slug: 'testivoittaja' })
    const isTest = some(article.tags, { slug: 'testipankki' })

    if (article.articleType !== articleTypes.MAGAZINE_COMMENT) articleStyles.push('special')
    if (isAd) articleStyles.push('is-ad')

    const textStyles = `article-text ${payWall ? 'paywall-fade' : ''}`

    const shownTitle = ((article.printmag || article.omAdMagazine || article.omThemeMagazine)
      ? (getCurrentArticleTitle === ''
        ? (titleOverride || title)
        : getCurrentArticleTitle)
      : (titleOverride || title))

    let ads = []
    if (payWall && linkedProductCards && linkedProductCards.length) {
      linkedProductCards.forEach((productCard) => {
        if (productCard.productAds && productCard.productAds.length) {
          ads = ads.concat(productCard.productAds)
        }
      })
    }

    return (
      <Fragment>
        <article styleName={articleStyles.join(' ')} id="magazine-article">
          {this.renderHeader(article, shownTitle, headerVideoMp4 || headerVideoWebm, shouldRenderHeroImage)}
          {!shouldRenderHeroImage && articleType !== articleTypes.MAGAZINE_COMMENT ? this.renderMainImage(headerVideoMp4 || headerVideoWebm, article.featuredMedia) : null}
          {article.originalArticle &&
            <div styleName="margins">
              <CrossLinkTopNotification text={article.originalArticle.topMessage} />
            </div>
          }
          <div styleName={`body-container ${articleType === articleTypes.MAGAZINE_COMMENT ? 'comment' : ''}`}>
            <div styleName={articleType === articleTypes.MAGAZINE_COMMENT ? '' : 'center-col'}>
              <PaywallBanner/>
            </div>
            {shareTokenAccess(article) && !premiumUser ? <div>
              <AdsForDesktop>
                <AdSlotDesk1 loadInstantly={true} setTargeting={true}/>
              </AdsForDesktop>
              <AdsForMobile>
                <AdSlotMob1 loadInstantly={true} setTargeting={true}/>
              </AdsForMobile>
            </div> : null}
            {articleType === articleTypes.MAGAZINE_COMMENT
              ? this.renderCommentAuthor(article)
              : null
            }
            {!payWall && noManualComparisonPlacement && comparisons && comparisons.length === 1
              ? (
                <div styleName="comparison-container comparison-container-right">
                  <Comparisons data={comparisons} floated={true} short={payWall}/>
                </div>
              )
              : null
            }
            <div styleName={`${textStyles}`} className={'article-body ' + (premiumUser ? 'user-level-4' : '') + (hideAds ? ' noAds' : '')}>
              {payWall
                ? <HTML options={{ pagination, filterDivs: true }}>{content}</HTML>
                : <HTML options={{ linkedProductCards, linkedHistoryCards, linkedQuestions, linkedProductMemories, scrollVideos, goodbad: comparisons, id, pagination, createToc }}>{content}</HTML>
              }
            </div>

            {payWall
              ? <div styleName={`${article.articleType !== articleTypes.MAGAZINE_COMMENT ? 'center-col' : ''}`}>
                <Paywall disableAdCoin={disableAdCoin}/>
              </div>
              : null}
            {/* payWall && linkedProductCards && linkedProductCards.length
              ? (
                <div styleName="comparison-container">
                  <Comparisons data={linkedProductCards.map(card => card.productData)} short={payWall}/>
                </div>
              )
              : null
            */}
            {!payWall && noManualComparisonPlacement && comparisons && comparisons.length > 1
              ? (
                <div styleName="comparison-container">
                  <Comparisons data={comparisons} short={payWall}/>
                </div>
              )
              : null
            }
            {weightTable ? weightTable.map((table, idx) => <WeightTable key={idx} data={table}/>) : null}
            {details && !!details.length && (
              <div styleName="details-wrapper">
                <Accordion details={details} />
              </div>
            )}
            <SideStories sidestories={sidestories} margins={article.articleType !== articleTypes.MAGAZINE_COMMENT} />
            
              <div styleName="margins">
                <AuthorWidget author={articleType === articleTypes.MAGAZINE_COMMENT ? commentator : author}/>
              </div>
            
            {(productAds || relatedAds) && <div styleName="center-col"><ProductAds ads={productAds} related={relatedAds} /></div>}
            {!!ads.length && <div styleName="center-col" style={{ marginTop: '1rem' }}><ProductAds ads={ads}/></div>}
            {!article.forSubscribers || premiumUser
              ? <Fragment>
                {linkedProductMemories.map((card, key) => <div styleName="" key={key}><ProductMemory card={card}/></div>)}
                {linkedProductCards.map((card, key) => <div styleName="" key={key}><ProductCard card={card}/></div>)}
                {linkedQuestions.map((card, key) => <div styleName="" key={key}><Question card={card}/></div>)}
              </Fragment>
              : null}
            {article.originalArticle &&
              <div styleName="margins">
                <CrossLinkBottomNotification title={originalArticle.siteName} text={originalArticle.siteDescription} linkText={originalArticle.linkText} href={originalArticle.url} />
              </div>
            }
            {isTest
              ? <div styleName="margins how-we-test">
                <p><b>Näin Tekniikan Maailma testaa.</b> Teemme testejämme, vertailujamme sekä koeajojamme puolueettomasti ja puhtaasti journalistisin periaattein.</p>
                <ShowAll linkText="Lue lisää testimenetelmistämme ja periaatteistamme täältä." url="/nain-tekniikan-maailma-testaa-autoja-renkaita-ja-elektroniikkaa/"/>
              </div>
              : null}
            {article.linkedThemeBoxesData && (
              <div styleName={articleType !== articleTypes.MAGAZINE_COMMENT ? 'margins' : ''}>
                {article.linkedThemeBoxesData.map((tbData, key) => <Themebox key={key} data={tbData}></Themebox>)}
              </div>
            )}

            <div styleName="meta-row-share-buttons-bottom">
              <ShareButtons shareCount={article.shareCount} article={article} up/>
              {isAd ? null : <ReportError article={article}/>}
            </div>
            {relatedArticles && relatedArticles.length > 0 && (
              <div styleName={`also-read ${article.articleType !== articleTypes.MAGAZINE_COMMENT ? 'margins' : ''}`}>
                <h2>Lue myös</h2>
                <Fragment>
                  <RelatedArticleList related={relatedArticles} limit={4}/>
                </Fragment>
              </div>
            )}
            <div styleName={articleType === articleTypes.MAGAZINE_COMMENT ? '' : 'center-col'}>
              <PaywallBanner showText={true}/>
            </div>
            {shareTokenAccess(article) && !premiumUser ? <div>
              <AdsForDesktop>
                <AdSlotDesk2 loadInstantly={true} setTargeting={true}/>
              </AdsForDesktop>
              <AdsForMobile>
                <AdSlotMob2 loadInstantly={true} setTargeting={true}/>
              </AdsForMobile>
            </div> : null}
            {article.forSubscribers && !premiumUser
              ? <Fragment>
                <div styleName="bottom-navigation">
                  <PrevNextBox article={article}/>
                  <DigimagBox article={article}/>
                </div>
                <div styleName={articleType !== articleTypes.MAGAZINE_COMMENT ? 'center-col' : ''}><MostReadPaid
                  exclude={[id]} doneLoading={() => this.props.doneLoading('mostRead')}/></div>
              </Fragment>
              : null}
            {tags && tags.length
              ? (
                <div styleName={`keywords ${article.articleType !== articleTypes.MAGAZINE_COMMENT ? 'margins' : ''}`}>
                  <span styleName="title">Lisää aiheesta</span>
                  <Fragment>
                    <KeywordList keywords={tags}/>
                  </Fragment>
                </div>
              )
              : null
            }
            <div styleName="margins">
              <AiArticleWidget article={article}/>
            </div>
            <div styleName="red-line margins"></div>
            {nettixSearchId
              ? (
                <div styleName="nettix-embed-container bottom-navigation">
                  <NettixEmbed searchId={nettixSearchId}/>
                </div>
              )
              : null}
          </div>
          <div styleName="almost-full">
            {timelineTag
              ? <Timeline exclude={[article.id]} term={timelineTag} digimagOnly={timelineDigimag}
              doneLoading={() => this.props.doneLoading('timeline')}/> : null}
          </div>
        </article>
        
          {isBest || isTest ? <div styleName="margins"><TestBox/></div> : null}
        
        <div styleName={articleStyles.join(' ')}>
          {!hideAds
            ? <div>
              <AdsForDesktop>
                <AdSlotDesk1 loadInstantly={true} />
              </AdsForDesktop>
              <AdsForMobile>
                <AdSlotMob1 loadInstantly={true} />
              </AdsForMobile>
              <GAMNativeAdHolder grow={true} />
            </div>
            : null}
          
            {!payWall && (!shareTokenAccess(article) || premiumUser)
              ? <div styleName="bottom-navigation">
                <PrevNextBox article={article}/>
                <DigimagBox article={article}/>
              </div>
              : null
            }
          
          
            {!payWall
              ? <div styleName="margins top-margin"><PopularPosts doneLoading={() => {}}/></div>
              : <div styleName="margins top-margin"><PopularPosts doneLoading={() => {}}/></div>}
          
          
            <div styleName="margins top-margin"><PopularPosts paidOnly doneLoading={() => {}}/></div>
          
        </div>
        
          {!isAd && <div styleName="comment-container margins">
            <ArticleComments articleId={id}/>
          </div>}
        
      </Fragment>
    )
  }
}

export default withErrorBoundary(
  MagazineArticle,
  ErrorPlaceholder()
)
