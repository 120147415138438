import DiskStorage from './DiskStorage'
import omit from 'lodash/omit'

import { isDevelopment } from '@otavamedia/om-component-library/lib/util/env'

/**
 * Least-recently-used (LRU) cache driver for localForage stores, based on DiskStorage
 *
 * @param {localForageInstance} store
 * @param {object} options
 * @param {number} options.orderMax The amount of values after which to start deleting LRU values
 */
class CacheDriver extends DiskStorage {
  constructor (store, options = {}) {
    const { orderMax = 1000 } = options
    super(store)

    this.reservedKeys = ['order']
    this.orderMax = orderMax

    this.store.keys().then(keys => {
      if (keys.length > this.orderMax) {
        this.clean('10%')
      }
    }).catch(e => console.log('Unable to check store size', e))

    this.clean = this.clean.bind(this)

    if (isDevelopment()) {
      window.CacheDriver = this
    }
  }

  /**
   * Get cache order
   *
   * @private
   */
  async getOrder () {
    try {
      const order = await this.store.getItem('order')

      if (order) {
        return order
      }

      return []
    } catch (e) {
      console.log("Can't get order, private browsing?", e)
      return []
    }
  }

  /**
   * Reorder cache by setting a new order value
   * @param {array} order
   *
   * @private
   */
  async setOrder (order = []) {
    // Limit the size to this.orderMax
    if (order.length > this.orderMax) {
      order.splice(0, this.orderMax)
    }

    try {
      return this.store.setItem('order', order)
    } catch (e) {
      console.log("Can't set order, private browsing?", e)
      return false
    }
  }

  /**
   * Get value from cache using DiskStorage.get. Reorder cache so that most recent values rise to the top.
   * See DiskStorage.get for params.
   */
  async get (...params) {
    const [stringKey] = params
    const key = super.getKey(stringKey)
    const order = await this.getOrder()
    const keyIndex = order.indexOf(key)

    if (keyIndex > -1) {
      order.splice(keyIndex, 1)
    }

    order.unshift(key)
    this.setOrder(order)

    return super.get(...params)
  }

  /**
   * Clean cache to free disk space.
   * @param {string} amount Approximate amount of items to remove, in percentage or number.
   */
  async clean (amount = '10%') {
    try {
      console.log('Cleaning cache')
      const order = await this.getOrder()
      const allKeys = (await this.store.keys()).reduce((acc, x) => {
        acc[x] = null
        return acc
      }, {})
      const keys = Object.keys(omit(allKeys, [
        ...this.reservedKeys,
        order, // don't remove recently fetched
      ]))

      let removed = []
      if (Number.isInteger(amount)) {
        removed = keys.splice(amount * -1, amount)
      } else if (amount.indexOf('%') > -1) {
        const percentage = parseFloat(amount.replace('%', '')) / 100
        const toRemove = parseInt(keys.length * percentage, 10)

        removed = keys.splice(toRemove * -1, toRemove)
      }

      removed.map(async key => {
        const item = await this.store.getItem(key)

        this.store.removeItem(key)
        return item
      })

      return removed
    } catch (e) {
      console.log('Unable to clean cache', e)
      return false
    }
  }
}

export default CacheDriver
