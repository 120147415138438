import req from './req'
import WPClient, { getURL } from './WPClient' // eslint-disable-line camelcase
// import DiskStorage, { requestCache } from './DiskStorage'
// import { getEnv, isDevelopment } from '@otavamedia/om-component-library/lib/util/env'

const WP = new WPClient({
  url: getURL(),
  HTTPClient: req,
  // cacheClient: isDevelopment() ? false : new DiskStorage(requestCache),
  // cacheClient: new DiskStorage(requestCache), // Provided in Settings
})

WP.extend('pages', async function pages (options = {}) {
  const response = await this.get('/wp-json/wp/v2/pages', {
    params: options,
  })

  return response
})

// Expose WP to global ns so console usage is possible
window.WP = WP
export default WP
