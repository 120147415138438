import get from 'lodash/get'

export const IMAGE_SIZE = {
  FULL: 'full',
  LARGE: 'large',
  MEDIUM_LARGE: 'medium_large',
  MEDIUM: 'medium',
  SMALL: 'small',
  THUMBNAIL: 'thumbnail',
  ORIGINAL: 'original',
}

export const IMAGE_SIZE_MAP = [
  IMAGE_SIZE.THUMBNAIL,
  IMAGE_SIZE.SMALL,
  IMAGE_SIZE.MEDIUM,
  IMAGE_SIZE.MEDIUM_LARGE,
  IMAGE_SIZE.LARGE,
  IMAGE_SIZE.ORIGINAL,
  IMAGE_SIZE.FULL,
]
export default class ImageModel {
  id
  title
  caption
  credit
  copyright
  mediaType
  mimeType
  url
  alt
  sizes
  width
  height
  srcSet
  src
  focus

  constructor (image) {
    if (!image || (!image.url && !(image.id || (image.kuva && image.kuva.id)))) return undefined

    try {
      const kuva = image.kuva || image

      if (kuva.type === 'video') {
        this.id = kuva.id
        this.title = kuva.title
        this.caption = kuva.caption || kuva.kuvateksti
        this.credit = ''
        this.copyright = ''
        this.mediaType = kuva.type
        this.mimeType = kuva.mime_type
        this.alt = kuva.alt || ''
        this.sizes = {}
        this.width = kuva.width
        this.height = kuva.height
        this.src = kuva.url
        return
      }

      this.id = kuva.id
      this.title = kuva.title || get(kuva, 'title.rendered', '')
      this.caption = get(kuva, 'caption.rendered', kuva.caption || kuva.kuvateksti || '')
      this.credit = get(kuva, 'media_details.image_meta.credit', '')
      this.copyright = get(kuva, 'media_details.image_meta.copyright', kuva.copyright)
      this.mediaType = kuva.type || kuva.media_type
      this.mimeType = kuva.mime_type
      if (kuva['data-smartcrop-focus']) {
        this.focus = JSON.parse(kuva['data-smartcrop-focus'])
      }
      this.alt = kuva.alt || kuva.alt_text || ''
      if (kuva.sizes) {
        this.sizes = {
          thumbnail: kuva.sizes.thumbnail && {
            file: '',
            width: kuva.sizes['thumbnail-width'],
            height: kuva.sizes['thumbnail-height'],
            mime_type: '',
            source_url: kuva.sizes.thumbnail,
          },
          medium: kuva.sizes.medium && {
            file: '',
            width: kuva.sizes['medium-width'],
            height: kuva.sizes['medium-height'],
            mime_type: '',
            source_url: kuva.sizes.medium,
          },
          medium_large: kuva.sizes.medium_large && {
            file: '',
            width: kuva.sizes['medium_large-width'],
            height: kuva.sizes['medium_large-height'],
            mime_type: '',
            source_url: kuva.sizes.medium_large,
          },
          large: kuva.sizes.large && {
            file: '',
            width: kuva.sizes['large-width'],
            height: kuva.sizes['large-height'],
            mime_type: '',
            source_url: kuva.sizes.large,
          },
          'list-image': kuva.sizes['list-image'] && {
            file: '',
            width: kuva.sizes['list-image-width'],
            height: kuva.sizes['list-image-height'],
            mime_type: '',
            source_url: kuva.sizes['list-image'],
          },
          slider: kuva.sizes.slider && {
            file: '',
            width: kuva.sizes['slider-width'],
            height: kuva.sizes['slider-height'],
            mime_type: '',
            source_url: kuva.sizes.slider,
          },
          full: kuva.sizes.full && {
            file: '',
            width: kuva.sizes['full-width'],
            height: kuva.sizes['full-height'],
            mime_type: '',
            source_url: kuva.sizes.full,
          },
          original: kuva.height && kuva.width && {
            file: '',
            width: kuva.width,
            height: kuva.height,
            mime_type: '',
            source_url: kuva.url,
          },
        }
      } else {
        this.sizes = get(image, 'media_details.sizes', {})
      }
      this.width = kuva.width || get(kuva, 'media_details.width', 0)
      this.height = kuva.height || get(kuva, 'media_details.height', 0)
      this.src = kuva.url || kuva.source_url || get(kuva, 'guid.rendered', '')
      this.srcSet = this.createSrcset()
    } catch (e) {
      console.log('Error creating image: ' + e)
    }
  }

  /**
   * This creates an array of ImageModels for images found in a WP post.
   * Depending on the post type, images can appear in featuredmedia, ACF field 'kuvat'
   * or 'om:images'.
   * @param WPModel
   * @returns {ImageModel[]}
   */
  static createFromWPPost (WPModel) {
    let imageData = get(WPModel, '_embedded.wp:featuredmedia') || []

    if (!imageData.length) {
      imageData = get(WPModel, 'acf.kuvat') || []
    }
    if (!imageData.length) {
      imageData = get(WPModel, 'om:images') || []
    }
    return imageData.map((image) => image && new ImageModel(image)).filter((model) => model.src)
  }

  /**
   * Takes a sizes object and returns a srcet string. Makes sure all images have the same
   * orientation (landscape/portrait) as the original image.
   */
  createSrcset () {
    const isLandscape = this.height < this.width
    return Object.keys(this.sizes)
      .map((key) => {
        if (!this.sizes[key] || (isLandscape !== (this.sizes[key].height < this.sizes[key].width))) return null
        const url = this.sizes[key].source_url
        return `${url} ${this.sizes[key].width}w`
      })
      .filter(size => !!size)
  }
}
