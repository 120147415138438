import PropTypes from 'prop-types'
import { channel, buffers } from 'redux-saga'
import { kea } from 'kea'
import { put, take } from 'redux-saga/effects'
import { LOCATION_CHANGE } from 'react-router-redux'
import AD_CONTEXT from '../components/general/ads/AdContext'
import { logException } from '../lib/errors'
import AdManager, { debugAdsMode } from '../components/general/ads/AdManager'

const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms))

const responseChannel = channel(buffers.expanding())
let slotDefined = 0

export default kea({
  paths: ['ads'],
  actions: () => ({
    setupAds: () => true,
    setAdBlockTaken: (elem) => elem,
    setDebugAds: (bool) => bool,
    setAdRefreshKey: (key) => key, // updating this key will cause all the mounted AdSlot components to refresh their ads
    setAdContext: (context) => context,
    setAdContextByLocation: (location) => location,
    markSlotDefined: (slot) => slot,
  }),

  reducers: ({ actions }) => ({
    adContext: [AD_CONTEXT.UNKNOWN, PropTypes.string, {
      [actions.setAdContext]: (state, payload) => payload
    }],
    adRefreshKey: ['', PropTypes.string, {
      [actions.setAdRefreshKey]: (state, payload) => payload
    }],
    adsEnabled: [false, PropTypes.bool, {
      [actions.setupAds]: (state, payload) => true
    }],
    debugAds: [false, PropTypes.bool, {
      [actions.setDebugAds]: (state, payload) => payload
    }],
    adBlockTaken: [null, PropTypes.object, {
      [actions.setAdBlockTaken]: (state, payload) => payload
    }],
  }),

  start: function * () {
    while (true) {
      const action = yield take(responseChannel)
      yield put(action)
    }
  },

  takeLatest: ({ actions }) => ({
    [actions.setupAds]: function * ({ type, payload }) {
      try {
        const debugAds = debugAdsMode()
        yield put(actions.setDebugAds(debugAds))
        window.googletag = window.googletag || {}
        window.googletag.cmd = window.googletag.cmd || []
        AdManager.init()
        AdManager.loadGPT()
      } catch (e) {
        logException(e)
      }
    },
    [actions.markSlotDefined]: function * ({ type, payload }) {
      if (++slotDefined === 2) {
        window.loadAds()
      }
    },
    [LOCATION_CHANGE]: function * ({ payload }) {
      try {
        if (payload.search.includes('?page=')) {
          return
        }
        slotDefined = 0
        AdManager.updateTargeting()

        yield put(actions.setAdContextByLocation(payload))
        // Leave some time for the current ConnectedAdSlot components to unmount so we don't trigger unnecessary refresh.
        yield delay(100)
        yield put(actions.setAdRefreshKey(payload.key || null))
      } catch (e) {
        logException(e)
      }
    },
    [actions.setAdContextByLocation]: function * ({ type, payload }) {
      try {
        const parts = payload.pathname.split('/')
        if (payload.pathname === '/') {
          yield put(actions.setAdContext(AD_CONTEXT.FRONTPAGE))
        } else if (parts[1] === 'lehti') {
          yield put(actions.setAdContext(AD_CONTEXT.DIGITAL_MAG))
        } else {
          yield put(actions.setAdContext(AD_CONTEXT.CONTENT))
        }
      } catch (e) {
        logException(e)
      }
    },
  }),

  selectors: ({ selectors }) => ({
    isAdBlockTaken: [
      () => [selectors.adBlockTaken],
      adBlockTaken => adBlockTaken,
      PropTypes.object,
    ],
  })
})
