import React, { Component } from 'react'

import WP from '../../../lib/WP'
import { ShowAll } from '../../widgets/ShowAll'
import { FacebookGrayLogo, TwitterGrayLogo } from '../../widgets/Icons'
import { withRouter } from 'react-router-dom'
import { Link } from '../util/Links'
import HTML from '../util/HTML'
import Image from '../util/Image'
import shuffle from 'lodash/shuffle'
import { fixArticleUrl } from '../../../lib/url'
import track from 'react-tracking'

import './FactCard.scss'

/**
 * Displays a fact card (tiesitkö-kortti)
 */
class FactCard extends Component {
  static propTypes = {}

  state = {
    factCards: [],
    index: null
  }

  constructor (props) {
    super(props)
    this.loadCards()
  }

  async loadCards () {
    const cards = await WP.getFactCards()
    this.setState({ cards: shuffle(cards), index: 0 })
  }

  nextFact () {
    document.activeElement.blur()
    if (this.state.index < this.state.cards.length - 1) {
      this.setState({ index: this.state.index + 1 })
    } else {
      this.setState({ index: 0 })
    }
  }

  render () {
    const { cards, index } = this.state
    if (!cards || !cards.length) return null
    const card = cards[index]

    if (!card) {
      return null
    }

    const articleLink = fixArticleUrl(card.acf.article)
    return <div styleName="fact">
      <div styleName="image">
        <Link to={{ link: articleLink }} clickLocation="image">
          <Image data={{ src: card.acf.image, alt: '' }}/>
        </Link>
      </div>
      <div styleName="right">
        <div styleName="text">
          <h3><Link to={{ link: articleLink }} clickLocation="title">Tiesitkö?</Link></h3>
          <Link to={{ link: articleLink }} clickLocation="text"><HTML>{card.title.rendered}</HTML></Link>
          <div>
            <ShowAll url={articleLink} inline linkText={'Lue juttu TM:stä niin tiedät lisää'} clickLocation="link"/>
          </div>
        </div>
        <div styleName="buttons">
          <div styleName="share">
            <a href={'http://www.facebook.com/sharer/sharer.php?u=' + articleLink} target="_blank"
              rel="noopener noreferrer"><FacebookGrayLogo/></a>
            <a href={'http://www.twitter.com/share?text=' + encodeURIComponent(card.title.rendered) +
              '&url=' + articleLink} target="_blank" rel="noopener noreferrer"><TwitterGrayLogo/></a>
          </div>
          <div>
            <button onClick={() => this.nextFact()}>Seuraava fakta</button>
          </div>
        </div>
      </div>
    </div>
  }
}

export default track({ gtmContext: ['FactCard'] })(withRouter(FactCard))
