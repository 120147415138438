import React from 'react'
import { TextBlock, RectShape } from 'react-placeholder/lib/placeholders'

import BestProductLoader from './BestProductLoader'

import './BestProductPageLoader.scss'

const BestProductPageLoader = () => {
  return (
    <div styleName="loader">
      <div styleName="title-loader">
        <TextBlock rows={3} color='grey' />
      </div>
      <div styleName="text-loader">
        <TextBlock rows={5} color='grey' />
      </div>
      <div styleName="filter-loader">
        <RectShape style={{ width: '33%', height: 75 }}/>
        <RectShape style={{ width: '33%', height: 75 }}/>
      </div>
      <BestProductLoader />
    </div>
  )
}

export default BestProductPageLoader
