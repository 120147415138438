import WP from './WP'

export const stripApiHostname = (url, replace = '') => {
  return url ? url.replace(WP.url, replace) : false
}

export const fixArticleUrl = (url, status) => {
  if (!url) return null
  const newUrl = stripApiHostname(url)
  if (status && (status !== 'publish')) {
    return (newUrl.indexOf('?') >= 0) ? newUrl + '&preview=true' : newUrl + '?preview=true'
  }
  return newUrl
}
