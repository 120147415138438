import React, { Component } from 'react'
import WP from '../../lib/WP'
import './MostCommented.scss'
import track from 'react-tracking'
import { Link } from '../general/util/Links'
import HTML from '../general/util/HTML'
import ArticleThumbnail from '../general/article/ArticleThumbnail'
import { SubscriberIcon } from './Icons'

class MostCommented extends Component {
  _mounted = true
  state = {}

  async loadData (props) {
    const articles = await WP.getMostCommented()
    if (this._mounted) {
      this.setState({ articles })
    }
  }

  componentDidMount () {
    this._mounted = true
    this.loadData(this.props)
  }

  componentWillUnmount () {
    this._mounted = false
  }

  render () {
    const { articles } = this.state
    if (!articles || !articles.length) {
      return null
    }
    return (
      <div styleName="wrapper">
        <div styleName="header">Kommentoiduimmat artikkelit</div>
        <div>
          {articles.map((article, idx) => <div key={idx}>
            <article styleName="item">
              <div>
                <Link to={article}>
                  <span styleName='thumbnail'><ArticleThumbnail article={article}/></span>
                </Link>
              </div>
              <div>
                <div styleName="meta-row">
                  {/* article.forSubscribers && <div styleName="subscriber-icon-container"><SubscriberIcon/></div> */}
                </div>
                <Link to={article}>
                  <h5>
                    <HTML>{article.title}</HTML>
                    {!!article.forSubscribers && (
                      <div styleName="subscriber-icon-container"><SubscriberIcon/></div>
                    )}</h5>
                  <span
                    styleName="comment-count">{article.commentCount} kommentti{article.commentCount > 1 ? 'a' : ''}</span>
                </Link>
              </div>
            </article>
          </div>)}
        </div>
      </div>
    )
  }
}

export default track({ gtmContext: ['MostCommented'] })(MostCommented)
