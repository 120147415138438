import React, { Component } from 'react'
import PropTypes from 'prop-types'
import hintaOpasLogo from '../../assets/logos/hintaopas-logo.png'

import './PriceGuide.scss'

/**
 * Displays a "Hintaopas" box.
 */
export default class PriceGuide extends Component {
  static propTypes = {
    priceGuide: PropTypes.object
  }

  getPrice (priceGuide) {
    return priceGuide.price && priceGuide.price.toFixed && priceGuide.price.toFixed(2).replace('.', ',')
  }

  render () {
    const { priceGuide } = this.props
    if (!priceGuide) {
      return null
    }

    return <a styleName="price-guide" href={'https://hintaopas.fi/product.php?ref=61830&p=' + priceGuide.id} rel="noopener noreferrer" target="_blank">
      <div styleName="ad">Mainos</div>
      <div styleName="border">
        <div styleName="icon"><img src={hintaOpasLogo} alt="Hintaopas logo"/></div>
        <div styleName="title"><div>{priceGuide.title}</div></div>
        <div styleName="price">{this.getPrice(priceGuide)} €</div>
        <div styleName="link"><span>Katso</span></div>
      </div>
    </a>
  }
}
