import styled from 'styled-components'
import MagazineCardCarousel from '@otavamedia/om-component-library/lib/MagazineCardCarousel'

const StyledDigiMagazineCardCarousel = styled(MagazineCardCarousel)`
  padding: 0 0.75rem;

  .Icons_caret-icon {
    width: 1.5rem;
    height: 1.5rem;
  }
  
  .items-carousel {
    .nav-button-prev {
      flex: 0 0 40px;
      background-color: rgba(255, 255, 255, 0.8);
      margin-right: 0;
    }
    .nav-button-next {
      flex: 0 0 40px;
      background-color: rgba(255, 255, 255, 0.8);
      margin-left: 0;
    }
    
    @media (min-width: 1200px) {
      margin-left: rem(38px);
    }
  }
  
  .magazine-card {
    width: auto !important;
    height: auto !important;
    margin-right: 0;
    
    .name {
      font-family: "Flama Medium", sans-serif;
      font-size: 14px;
      display: block;
      color: #484e5d;
      text-transform: uppercase;
      text-align: center;
      margin: 0;
    }
    .updated {
      font-family: "Flama Medium", sans-serif;
      font-size: 14px;
      display: block;
      color: #484e5d;
    }
        
    .title {
      min-height: 40px;
      text-align: center;
      margin: 10px 0;
    }
    
    .image {
      min-width: 100px;
      min-height: 125px;
      @media (min-width: 1000px) {
          min-width: 190px;
          min-height: 244px;
        }
      img {
        width: 100px;
        height: 125px;
        @media (min-width: 1000px) {
          width: 190px;
          height: 244px;
        } 
      }
      display: flex;
      justify-content: center;
    }
  }
  
  &.highlight-selected {
    .slick-slide > div > div {
      opacity: 0.5;
      
      &.selected {
        opacity: 1;
      }
    }
  }

  .slick-slider {
    margin-left: -2.75rem;
  }
  
  .slick-slide {
    cursor: pointer;
    > div > div {
      display: block !important;
      div.image {
        min-height: 125px;
        @media (min-width: 1000px) {
          min-height: 244px;
        } 
      }
    }
  }
  .magazine-card {
    padding-top: 2rem;
  }
`

export default StyledDigiMagazineCardCarousel
