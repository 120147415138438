import React, { Component } from 'react'
import PropTypes from 'prop-types'
import './TotalPoll.scss'
import HTML from '../../general/util/HTML'
import WP from '../../../lib/WP'

/* eslint-disable jsx-a11y/label-has-for */

class TotalPoll extends Component {
  static propTypes = {
    poll: PropTypes.number
  }

  state = {}

  constructor (props) {
    super(props)
    this.loadPoll(props.poll)
  }

  async loadPoll (pollId) {
    const data = await WP.getTotalPoll(pollId)
    const questions = Object.values(data.questions)
    const options = Object.values(questions[0].choices)
    this.setState({ question: questions[0], options, poll: data })
  }

  async submitVote () {
    const data = await WP.submitPoll(this.props.poll, this.state.question.uid, this.state.option)
    document.cookie = `voted${this.props.poll}=1`
    if (data.error && data.error.data && data.error.data.status === 422) {
      this.setState({ error: 'Voit äänestää vain yhden kerran!' })
    } else if (data.error) {
      this.setState({ error: 'Äänestäminen juuri nyt ei onnistu.' })
    } else {
      const questions = Object.values(data.questions)
      const options = Object.values(questions[0].choices)
      this.setState({ question: questions[0], options, poll: data, submitted: true, showResults: true })
    }
  }

  render () {
    const { question, options, poll, showResults, submitted, error } = this.state
    if (!question) {
      return null
    }

    const voted = document.cookie.includes(`voted${this.props.poll}=1`)
    const sortedOptions = [...options].sort((a, b) => b.votes - a.votes)

    if (voted || showResults) {
      return <div styleName="total-poll">
        <div styleName="title-bar">TM:n viikon kysymys</div>
        <div styleName="vote-form results">
          <h3><HTML>{poll.title}</HTML></h3>
          {submitted ? <p>Kiitos äänestäsi!</p> : null}
          <p>Tilanne:</p>
          {sortedOptions.map((option, key) => <label key={key}>{option.votes} ääntä: <HTML>{option.html}</HTML></label>)}
          {error ? <span styleName="error">{error}</span> : null}
        </div>
      </div>
    }

    return <div styleName="total-poll">
      <div styleName="title-bar">TM:n viikon kysymys</div>
      <div styleName="vote-form">
        <h3><HTML>{poll.title}</HTML></h3>
        <p><HTML>{question.content}</HTML></p>
        {options.map((option, key) => <label key={key}>
          <input name="poll" type="radio" required={true} onChange={() => this.setState({ option: option.uid })}/>
          <i styleName="icon-radio"></i>
          <div><HTML>{option.html}</HTML></div>
        </label>)}
        <div styleName="button-row">
          <button type="button" styleName="show-results"
            onClick={() => this.setState({ showResults: true })}>Näytä tulokset
          </button>
          {!voted
            ? <button type="button" disabled={!this.state.option}
              onClick={() => this.submitVote()}>Vastaa</button>
            : null}
        </div>
      </div>
    </div>
  }
}

export default TotalPoll
