import React, { Component } from 'react'
import PropTypes from 'prop-types'
import RelatedArticle from './RelatedArticle'
import './RelatedArticle.scss'
import track from 'react-tracking'

@track({ gtmContext: ['RelatedArticleList'] })
class RelatedArticleList extends Component {
  static propTypes = {
    related: PropTypes.array.isRequired,
    limit: PropTypes.number.isRequired,
  }

  static defaultProps = {
    limit: Number.POSITIVE_INFINITY,
  }

  render () {
    const { related, limit } = this.props
    return (
      <ul styleName="list">
        {related.slice(0, limit).map((related, key) => (
          <li key={ key }>
            <RelatedArticle article={ related } />
          </li>
        ))}
      </ul>
    )
  }
}

export default RelatedArticleList
