import PropTypes from 'prop-types'
import { kea } from 'kea'
import { channel, buffers } from 'redux-saga'
import { put, take } from 'redux-saga/effects'
import isEqual from 'lodash/isEqual'

import { LOCATION_CHANGE } from 'react-router-redux'

const responseChannel = channel(buffers.expanding())

export default kea({
  paths: ['gallery'],
  actions: () => ({
    openGallery: (images, startIndex) => ({ images, startIndex }),
    addImage: (img, fromModel = false) => ({ img, fromModel }),
    removeImage: (img) => ({ img }),
    reset: true,
    closeGallery: () => true,
    setIndex: (index) => index,
  }),

  reducers: ({ actions }) => ({
    images: [[], PropTypes.array, { persist: false }, {
      // [actions.openGallery]: (state, payload) => payload.images,
      [actions.addImage]: (state, { img, fromModel }) => {
        const obj = {}
        if (img.sizes) {
          const keys = Object.keys(img.sizes)
          const largestImage = keys.reduce((current, key) => {
            return (img.sizes[key] && img.sizes[key].width > current.width) ? img.sizes[key] : current
          }, { width: 0 })
          obj.src = largestImage ? largestImage.source_url : img.src
        } else {
          obj.src = img.src
        }

        if (img.caption) {
          obj.caption = img.caption
        }

        // Avoid duplicates
        if (state.findIndex(i => isEqual(obj, i)) !== -1) {
          return state
        }

        return [...state, obj]
      },
      [actions.removeImage]: (state, { img }) => {
        const obj = {
          src: img.src,
        }

        if (img.caption) {
          obj.caption = img.caption
        }

        const positionInState = state.findIndex(i => isEqual(obj, i))

        if (positionInState > -1) {
          const newState = [...state]

          newState.splice(positionInState, 1)

          return newState
        }

        return state
      },
      [actions.reset]: (state) => [],
    }],
    galleryOpen: [false, PropTypes.bool, { persist: false }, {
      [actions.openGallery]: (state, payload) => true,
      [actions.closeGallery]: (state, payload) => false,
      [actions.reset]: (state) => false,
    }],
    index: [0, PropTypes.number, { persist: false }, {
      [actions.openGallery]: (state, payload) => payload.startIndex,
      [actions.setIndex]: (state, payload) => payload,
      [actions.reset]: (state) => 0,
    }],
  }),

  start: function * () {
    while (true) {
      const action = yield take(responseChannel)
      yield put(action)
    }
  },

  takeLatest: ({ actions }) => ({
    [LOCATION_CHANGE]: function * () {
      const open = yield this.get('galleryOpen')
      if (open) yield put(this.actions.closeGallery())
    },
  }),

  selectors: ({ selectors }) => ({
    getIndexFromImage: [
      () => [selectors.images],
      (images) => (img) => {
        const { src, srcset } = img

        if (src) {
          const index = images.findIndex(i => src.indexOf(i.src) === 0)

          if (index !== -1) {
            return index
          }
        }

        if (srcset) {
          const index = images.findIndex(i => srcset.indexOf(i.src) !== -1)

          if (index !== -1) {
            return index
          }
        }

        return -1
      },
      PropTypes.func,
    ],
  }),
})
