import React, { Component } from 'react'
import './NativeAd.scss'
import PropTypes from 'prop-types'
import { NativeAdSlot1, NativeAdSlot2 } from '../general/ads/Ads'

/**
 * This displays a single native ad (natiivimainos) from Google Ad Manager.
 */
export default class GAMNativeAd extends Component {
  static propTypes = {
    fullSize: PropTypes.bool,
    handleSlotRender: PropTypes.func,
    type: PropTypes.number
  }

  constructor (props) {
    super(props)
    this.state = { hidden: false }
  }

  handleSlotRender = function (event) {
    if (event.isEmpty) {
      this.setState({ hidden: true })
    } else {
      this.setState({ hidden: false })
    }
    this.props.handleSlotRender && this.props.handleSlotRender(event)
  }

  render () {
    const { type, fullSize } = this.props
    const { hidden } = this.state

    if (type === 2) {
      return (
        <div styleName={'native-ad' + (fullSize ? ' full-size' : '') + (hidden ? ' hidden' : '')} ref={(ad) => { this.element = ad }}>
          <NativeAdSlot2 handleSlotRender={(e) => this.handleSlotRender(e)}/>
        </div>
      )
    }
    return (
      <div styleName={'native-ad' + (fullSize ? ' full-size' : '') + (hidden ? ' hidden' : '')} ref={(ad) => { this.element = ad }}>
        <NativeAdSlot1 handleSlotRender={(e) => this.handleSlotRender(e)}/>
      </div>
    )
  }
}
