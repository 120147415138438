import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import ArticleAuthor from './ArticleAuthor'
import './ArticleMeta.scss'

const ArticleAuthorListV2 = ({ author, photographer, assistants, authorTitle }) => {
  let newPhotographer
  if ((!photographer || !photographer.title) && (newPhotographer = assistants.find(assistant => assistant.title.toLowerCase().includes('kuvat')))) {
    photographer = newPhotographer
    assistants = assistants.filter(assistant => !assistant.title.toLowerCase().includes('kuvat'))
  }
  return (
    <Fragment>
      <div styleName="feature">
        <div id="article-author">
          <span styleName="heading">{authorTitle || 'Teksti'}</span> <ArticleAuthor author={author}/>
        </div>
        {photographer && photographer.name
          ? (
            <div id="article-photograph-author">
              <span><span styleName="heading">{photographer.title || 'Kuvat'}</span> <ArticleAuthor author={photographer}/></span>
            </div>
          )
          : null
        }
      </div>

      {assistants && assistants.length
        ? <div styleName="assistants">
          {assistants.map((assistant, index) => (
            <div key={`assistant-${index + 1}`}>
              <span><span styleName="heading">{assistant.title}</span> <ArticleAuthor author={assistant}/></span>
            </div>
          ))}
        </div>
        : null
      }
    </Fragment>
  )
}

ArticleAuthorListV2.propTypes = {
  author: PropTypes.object,
  authorTitle: PropTypes.string,
  photographer: PropTypes.object,
  assistants: PropTypes.array,
  isFeature: PropTypes.bool,
}

export default ArticleAuthorListV2
