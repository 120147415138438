import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'kea'
import Helmet from 'react-helmet'
import WP from '../../../lib/WP'

import { history } from '../../../store'
import auth from '../../../kea/auth'

import './404-page.scss'

@connect({
  actions: [
    auth, [
      'updateModal',
    ],
  ],
})
class Page401 extends Component {
  static propTypes = {
    setRendered: PropTypes.func,
  }

  componentDidMount () {
    this.props.setRendered(true)
  }

  componentWillUnmount () {
    this.props.setRendered(false)
  }

  render () {
    return (
      <div styleName="container">
        <Helmet>
          <title>401 | Sinulla ei ole oikeutta katsella tätä sivua.</title>
          <meta name="http:status" content="401" />
        </Helmet>

        <h1>Hups, sisältöä ei voida näyttää (401)</h1>
        <p styleName="large-font">Sinulla ei ole oikeutta katsella tätä sivua.</p>
        <div styleName="button-row">
          <button onClick={() => history.goBack()} styleName="nav-button">Siirry takaisin</button>
          <a id="login-button" styleName="nav-button" href={WP.getLoginUrl()}>
            Kirjaudu sisään</a>
        </div>
      </div>
    )
  }
}

export default Page401
