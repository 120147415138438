import React from 'react'
import { TextBlock, RectShape } from 'react-placeholder/lib/placeholders'
import './MagazineCarouselLoader.scss'

const MagazineCarouselLoader = () => {
  const blocks = []
  for (let i = 0; i < 8; i++) {
    blocks.push(<div key={i}><RectShape /><TextBlock rows={1} color='grey' /></div>)
  }

  return (
    <div styleName="loader">
      {blocks}
    </div>
  )
}

export default MagazineCarouselLoader
