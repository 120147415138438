const handleImageSizes = urls => {
  const sizeKeys = urls ? Object.keys(urls) : []
  const sizes = {}

  sizeKeys.forEach(key => {
    sizes[key] = {
      source_url: urls[key]
    }
  })

  return sizes
}

export default class ArchiveMagazineModel {
  coverImage
  publishDate
  name
  year
  pages
  summary
  digimagUrl
  behindPaywall
  highlights
  archiveMag
  slug
  archiveUrl

  constructor (WPData) {
    try {
      this.id = WPData.id
      this.name = WPData.name.split(' ')[0]
      this.slug = WPData.slug
      this.publishDate = WPData.publish_date_gmt
      this.year = new Date(WPData.publish_date_gmt).getFullYear()
      this.link = `/arkisto/${this.year}/${this.slug}`
      this.coverImage = WPData.cover_image.urls && WPData.cover_image.urls
        ? {
          sizes: handleImageSizes(WPData.cover_image.urls),
          src: WPData.cover_image.urls.small,
          alt: 'Kansikuva',
        }
        : null
      this.pages = WPData.pages
        ? WPData.pages.map((page, index) => ({
          id: page.page_id,
          image: {
            src: page.thumbnails && page.thumbnails.small,
            alt: 'sivu',
            sizes: { ...page.thumbnails, thumbnail: null },
          },
          title: page.page_title,
          excerpt: page.page_excerpt ? page.page_excerpt : undefined,
          link: page.page_permalink,
          showInToc: page.show_in_table_of_contents && Number(page.page_title) !== index + 1,
          number: WPData.page_numbering_offset + index + 1,
        }))
        : []
      this.summary = WPData.html || undefined
      this.digimagUrl = WPData.digimagUrl
      this.behindPaywall = WPData.permissions && !WPData.permissions.read
      this.highlights = WPData.highlights
      this.archiveMag = true
      this.archiveUrl = WPData.archiveUrl
    } catch (e) {
      console.error('Failed to parse archived magazine model')

      throw e
    }
  }
}
