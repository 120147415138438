import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import TestCard from '../components/widgets/TestCard'
import { connect } from 'kea'
import auth from '../kea/auth'
import application from '../kea/application'
import HTML from '../components/general/util/HTML'
import { Link } from '../components/general/util/Links'
import BestProductPageLoader from '../components/general/util/BestProductPageLoader'
import BestProductLoader from '../components/general/util/BestProductLoader'
import { CaretDown } from '../components/widgets/Icons'
import { ErrorPlaceholder, withErrorBoundary } from '../components/general/util/ErrorBoundaries'
import WP from '../lib/WP'
import { renderError } from '../lib/errors'
import { STATUS } from '../lib/request-state'
import Image from '../components/general/util/Image'
import { stripHTML } from '../lib/utils'

import './BestProductCollection.scss'
import { IMAGE_SIZE } from '../entities/ImageModel'
import track from 'react-tracking'

const getPageFromUrl = (link) => {
  const regex = /^(.*\/)([0-9]+)(\/)$/
  return link && parseInt(link.replace(regex, '$2'))
}

@connect({
  actions: [
    application, [
      'setViewData',
    ]
  ],
  props: [
    application, [
      'view',
    ],
    auth, [
      'premiumUser',
    ],
  ]
})
  /**
   * This displays a page with a collection of product cards, with the ability to filter by category and
   * sort. This is used on /kayttajaarvostelut/[category]/, /lukijoiden-vinkit/ and /lukijoiden-muistot/ pages.
   * Very similar to TestBankCategory component, used on /testit-[category]/ pages.
   */
class CardCollection extends Component {
  static propTypes = {
    view: PropTypes.object,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    setRendered: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    premiumUser: PropTypes.bool,
    page: PropTypes.string,
    postType: PropTypes.string,
    mainCategory: PropTypes.string,
    customSort: PropTypes.object
  }

  constructor (props) {
    super()
    this.state = {
      data: [],
      status: STATUS.NOT_REQUESTED,

      page: getPageFromUrl(window.location.href) || 1,

      categories: [], // Available filters
      selectedCategory: null,
      selectedCategoryId: 0,
      categorySlug: null,
      order: 'DESC',
      orderby: 'date',
      waitForCategory: false
    }

    const categorySlug = props.match ? props.match.params.category : null
    this.state.categorySlug = categorySlug
    if (categorySlug) {
      this.state.waitForCategory = true
      this.loadCategoryByName(categorySlug)
    }
  }

  componentDidMount () {
    if (!this.state.waitForCategory) {
      this.loadData()
    }
  }

  componentWillUnmount () {
    this.props.setRendered(false)
  }

  componentWillReceiveProps (nextProps) {
    if (nextProps.location.key !== this.props.location.key) {
      this.loadData()
    }
  }

  async loadCategoryByName (categorySlug) {
    const category = await WP.getTerms('category', {
      slug: categorySlug,
      per_page: 1,
      hide_empty: false,
      cache: {
        expireAfter: Date.now() + 3600000 * 24 // 1 day
      }
    })

    this.setState({
      selectedCategory: category.data[0],
      selectedCategoryId: category.data[0].id
    })
    this.loadData()
  }

  async loadData () {
    const { page } = this.props

    try {
      const postReq = await WP.getForURL(WP.url + '/' + page)
      const post = postReq.data

      this.actions.setViewData(post)
      this.setState({ categories: post.categoriesAvailable }, () => this.getProducts())
    } catch (e) {
      this.setState({
        status: STATUS.ERROR,
        data: e,
      })
    }

    if (this.props.view.id) {
      WP.triggerHitCounter(this.props.view.id)
    }
  }

  async getProducts (givenPage = -1) {
    const { selectedCategory, page, order, orderby } = this.state
    const { postType, mainCategory } = this.props
    this.props.setRendered(false)

    try {
      const params = {
        paged: givenPage >= 0 ? givenPage : page,
        order,
        posts_per_page: 9
      }
      if (orderby) {
        params.orderby = orderby
      }

      if (postType) {
        params.post_type = postType
      }

      if (mainCategory) {
        params.category_name = mainCategory
      }

      if (selectedCategory) {
        const termId = selectedCategory.term_id ? selectedCategory.term_id : selectedCategory.id
        params.tax_query = [
          {
            taxonomy: selectedCategory.taxonomy,
            field: 'term_id',
            terms: [termId],
          },
        ]
      }

      this.setState({ status: STATUS.REQUESTED })

      const response = await this.getArticles(postType, params)
      const { data: articles, headers } = response
      this.setState({
        data: (this.state.page === 1) ? articles : [...this.state.data, ...articles],
        status: STATUS.DONE,
        maxPages: Math.min(parseInt(headers['x-wp-totalpages'], 10), 1000),
      }, () => this.props.setRendered(true))
    } catch (e) {
      this.setState({
        status: STATUS.ERROR,
        data: e,
      })
    }
  }

  getArticles (postType, params) {
    switch (postType) {
    case 'om_product':
      return WP.getProductCards(params)
    case 'om_product_memory':
      return WP.getMemoryCards(params)
    case 'om_question':
      return WP.getQuestionCards(params)
    }
  }

  handleFormChange = (e) => {
    const { target } = e
    const { page } = this.props

    if (target.name === 'category-select') {
      // eslint-disable-next-line eqeqeq
      if (parseInt(target.value) === 0) {
        this.setState({
          selectedCategory: null,
          selectedCategoryId: 0,
          categorySlug: null,
          page: 1,
        })
        this.props.history.replace('/' + page + '/')
      } else {
        const selectedCategory = this.state.categories.filter((x) => x.term_id === parseInt(target.value))[0]
        const categorySlug = selectedCategory.slug

        this.setState({
          selectedCategory,
          selectedCategoryId: selectedCategory.term_id,
          categorySlug,
          page: 1,
        })
        this.props.history.replace('/' + page + '/' + categorySlug + '/')
      }
    } else {
      if (target.value !== 'ASC' && target.value !== 'DESC') {
        this.setState({
          order: 'DESC',
          orderby: target.value,
          page: 1,
        }, this.getProducts)
      } else {
        this.setState({
          order: target.value,
          orderby: 'date',
          page: 1,
        }, this.getProducts)
      }
    }
  }

  addPage = () => {
    this.getProducts(this.state.page + 1)
    this.setState({
      page: this.state.page + 1
    })
  }

  renderProducts (products) {
    const { status, maxPages, page } = this.state

    const items = products.map((p, i) => (
      <li styleName="result-item" key={`${i}-${p.id}`}>
        <Link to={p}>
          <TestCard article={p} notShown={{ createdDate: false, categories: true }}
            topHeader={p.parser === 'memorycard' && p.comments && p.comments.length ? p.comments[p.comments.length - 1].author_name : null}/>
        </Link>
      </li>
    ))

    const loading = status === STATUS.REQUESTED

    return <Fragment>
      <ul styleName="result-list" data-loading={loading}>
        {items}
      </ul>
      {products.length && page < maxPages
        ? (
          <div styleName="load-more">
            <button styleName="load-more" onClick={() => this.addPage()}>
              Lataa lisää
            </button>
          </div>
        )
        : null
      }
    </Fragment>
  }

  render () {
    const { status, data, categories, selectedCategoryId } = this.state
    const { view, premiumUser, customSort } = this.props

    if (status !== STATUS.ERROR && (!view || !view.id)) {
      return <BestProductPageLoader />
    }

    const {
      content,
      title,
      excerpt,
      createdDate,
      modifiedDate,
      link,
      featuredMedia
    } = view

    const fullLink = window.location.origin + link
    const metaTags = {
      'og:locale': 'fi_FI',
      'og:type': 'article',
      'og:title': stripHTML(title),
      'og:description': stripHTML(excerpt),
      'og:url': fullLink,

      // og:type is article, more tags for it:
      'article:published_time': createdDate,
      'article:modified_time': modifiedDate,

      'http:status': 200,
    }
    /* eslint-disable jsx-a11y/no-onchange */
    /* eslint-disable jsx-a11y/label-has-for */
    return (
      <Fragment>
        <Helmet>
          <title>
            {stripHTML(title) + ' - Tekniikan Maailma'}
          </title>
          <link rel="canonical" href={fullLink} />
          <meta name="description" content={metaTags['og:description']}/>
          {Object.entries(metaTags).map(([key, value], i) => Array.isArray(value)
            ? (
              value.map(x => (
                <meta name={key} key={`${key}-${i}`} content={x} />
              ))
            )
            : (
              <meta name={key} key={`${key}-${i}`} content={value} />
            ))}
        </Helmet>
        <div styleName="main-image">
          {featuredMedia
            ? <Image data={featuredMedia} size={IMAGE_SIZE.LARGE} sizes={'(max-width: 1440px) 100vw, 1440px'}/>
            : null
          }
        </div>
        <div styleName='magazine-content'>
          <article styleName="article-container">
            <header styleName="below-best-icon">
              <h1><HTML>{title}</HTML></h1>
            </header>
            <div styleName="article-body" className={premiumUser ? 'user-level-4' : ''}>
              <HTML>{content}</HTML>
            </div>
          </article>
          <form styleName="filterform">
            <div styleName="result-filters">
              <div>
                <label htmlFor="sort">Kategoria</label>
                <div styleName="select-div">
                  <select id="category-select" name="category-select" onChange={this.handleFormChange} value={selectedCategoryId}>
                    <option value="0">Valitse</option>
                    {categories && categories.length
                      ? categories.map(x => (
                        <option value={x.term_id} key={x.term_id}>{x.name}</option>
                      ))
                      : <option value="">Kategorioita ladataan...</option>}
                  </select>
                  <div styleName="select-caret"><CaretDown color="red"/></div>
                </div>
              </div>

              <div>
                <label htmlFor="sort">Järjestä</label>
                <div styleName="select-div">
                  <select id="sort-select" name="order" onChange={this.handleFormChange} defaultValue="desc">
                    <option value="DESC">Uusimmat</option>
                    <option value="ASC">Vanhimmat</option>
                    {customSort
                      ? Object.keys(customSort).map((key) => <option key={key} value={key}>{customSort[key]}</option>)
                      : null
                    }
                  </select>
                  <div styleName="select-caret"><CaretDown color="red"/></div>
                </div>
              </div>
            </div>
          </form>

          <section styleName="results">
            {data.length
              ? this.renderProducts(data)
              : (status === STATUS.ERROR
                ? renderError(data)
                : (status === STATUS.DONE ? null : <BestProductLoader/>)
              )
            }
          </section>
        </div>
      </Fragment>
    )
    /* eslint-enable jsx-a11y/no-onchange */
    /* eslint-enable jsx-a11y/label-has-for */
  }
}

export default track({ gtmContext: ['CardCollection'] })(withErrorBoundary(
  CardCollection,
  ErrorPlaceholder()
))
