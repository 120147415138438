import { stripApiHostname } from './url'
import CategoryModel from '../entities/CategoryModel'
import TagModel from '../entities/TagModel'

/*
 * Transformers mutate the object, if you need a new one, create one.
 */

export const addMetadata = (type, obj) => {
  obj._meta = {
    type,
  }

  return obj
}

export const transformPostType = type => {
  return addMetadata('post_type', type)
}

export const transformTaxonomy = taxonomy => {
  return addMetadata('taxonomy', taxonomy)
}

export const transformTerm = term => {
  term.link = stripApiHostname(term.link)

  switch (term.taxonomy) {
  case 'category': {
    term = new CategoryModel(term)
    break
  }

  case 'post_tag': {
    term = new TagModel(term)
    break
  }

  default: {
    console.error('No model found for term', term)
    break
  }
  }
  return addMetadata('term', term)
}
