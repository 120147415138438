import React, { Component } from 'react'
import PropTypes from 'prop-types'
import LibreForm, { feedbackInputMutations } from '../components/libreform/LibreForm'

import './Feedback.scss'

export default class Feedback extends Component {
  static propTypes = {
    setRendered: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    form: PropTypes.string.isRequired,
  }

  shouldComponentUpdate () {
    if (this.submitted) { // prevent rendering the form again after submit
      return false
    }

    return true
  }

  render () {
    const { setRendered, form } = this.props

    return <div styleName="feedback">
      <span>Haluatko antaa palautetta jutussa olevasta virheestä? Lähetä se sähköpostilla <a href={'mailto:asiavirheet@otava.fi'}>tästä linkistä</a>.<br/>

        Muissa lehteen liittyvissä asioissa voit antaa palautetta alla olevalla lomakkeella.</span>
      <LibreForm
        form={form}
        onSubmitSuccess={response => {
          // console.log('submitted feedback')
          this.submitted = true
        }}
        onSubmitDenied={errOrResponse => {
          console.log(errOrResponse)
          alert('Jotain meni vikaan lomaketta lähettäessä.')
        }}
        afterLoad={(form, container) => {
          feedbackInputMutations(form, container)
          setRendered(true)
        }} />
    </div>
  }
}
