export const current = '20190318'

// eslint-disable-next-line
const sampleVersion = {
  version: '20270101',
  invalidateDiskStorage: false, // Will clear ALL disk saved data
  invalidateStores: ['requestCache', 'userData'], // Will clear stores with corresponding names
}

// Don't go backwards in versions.
// If something hits the fan, rollback using git revert,
// and add a new version. The version compare logic will fail if you neglect this.
export const versions = [
  {
    version: '20190318',
    invalidateDiskStorage: true,
  },
  {
    version: '20190313',
    invalidateDiskStorage: true,
  },
  {
    version: 'tvtm-offline',
    invalidateDiskStorage: true,
  },
  {
    version: '20190225',
    invalidateDiskStorage: true,
  },
  {
    version: '20190220',
    invalidateDiskStorage: true,
  },
  {
    version: '20181009',
    invalidateDiskStorage: true,
  },
  {
    version: '20180926',
    invalidateDiskStorage: true,
  },
  {
    version: '20180925',
    invalidateStores: ['userData', 'requestCache'],
  },
  {
    version: '20180911',
    invalidateStores: ['userData'],
  },
  {
    version: '20180831',
    invalidateStores: ['userData', 'requestCache'],
  },
  {
    version: '20180821',
    invalidateStores: ['userData', 'requestCache'],
  },
  {
    version: '20180622',
    invalidateStores: ['userData', 'requestCache'],
  },
  {
    version: '20180605',
    invalidateStores: ['userData', 'requestCache'],
  },
  {
    version: '20180601',
    invalidateStores: ['userData', 'requestCache'],
  },

  {
    version: '20180529',
    invalidateStores: ['userData', 'requestCache'],
  },

  {
    version: '20180528',
    invalidateStores: [],
  },

  {
    version: '20180527',
    invalidateStores: [],
  },
].reverse()
export const getVersion = key => versions.find(({ version }) => version === key)
export const currentVersion = getVersion(current)

export const getVersionIndex = version => versions.findIndex(
  ({ version: v }) => v === version
)
export const getVersionsBetween = (a, b) => versions.slice(
  getVersionIndex(a) + 1,
  getVersionIndex(b)
)
export default currentVersion
