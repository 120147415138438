import React, { Component } from 'react'
import PropTypes from 'prop-types'
import WP from '../../lib/WP'
import { ErrorPlaceholder, withErrorBoundary } from '../general/util/ErrorBoundaries'
import HTML from '../general/util/HTML'
import Image from '../general/util/Image'
import { IMAGE_SIZE } from '../../entities/ImageModel'
import './AuthorWidget.scss'
import { commentators, authors } from '../../lib/defaults'
import { Link } from '../general/util/Links'

class AuthorWidget extends Component {
  static propTypes = {
    author: PropTypes.object,
  }

  state = {}

  async componentDidMount () {
    const { author } = this.props
    const matchName = author.name.toLowerCase().replace('-', ' ')
    const authorKeys = Object.keys(authors).filter(name => matchName.includes(name))
    const authorIds = authorKeys.map(authorKey => authors[authorKey])

    if (authorIds.length) {
      this.setState({ loading: true })
      const authorData = await Promise.all(authorIds.map(authorId => WP.getAuthor(authorId)))
      this.setState({
        loading: false,
        authors: authorData,
      })
    }
  }


  render () {
    let { authors } = this.state
    if (!authors || !authors.length || !authors[0].name) return null

    authors = authors.sort((a, b) => {
      // Extract the last word from each string
      let lastWordA = a.name ? a.name.split(" ").pop() : ''
      let lastWordB = b.name ? b.name.split(" ").pop() : ''
      // Compare the last words
      if (lastWordA < lastWordB) return -1
      if (lastWordA > lastWordB) return 1
      return 0
    })

    return authors.map((author, key) => {
      const { name, image, ingress } = author
      if (!name) {
        return null
      }
      const authorLink = '/toimittaja/' + name.toLowerCase().replace(' ', '-')

      return <div styleName="author" key={key}>
        <div styleName="featured-image">
          <Link to={{link: authorLink}}>
            <Image addToGallery data={image} size={IMAGE_SIZE.MEDIUM_LARGE}
                   sizes={'(min-width: 768px) 860px, 100vw'}/>
          </Link>
        </div>
        <div styleName="author-header">
          <Link to={{link: authorLink}}>
            <h3><HTML>{ name }</HTML></h3>
            <HTML>{ ingress }</HTML>
          </Link>
        </div>
      </div>
    })

  }
}

export default withErrorBoundary(
  AuthorWidget,
  ErrorPlaceholder()
)
