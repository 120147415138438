import React, { Component, Fragment } from 'react'
import { connect } from 'kea'
import PropTypes from 'prop-types'
import ArticleComment from './ArticleComment'
import articleComments from '../../../kea/articleComments'
import { ErrorPlaceholderBox, withErrorBoundary } from '../util/ErrorBoundaries'
import './ArticleComments.scss'
import CommentBox from './CommentBox'
import WP from '../../../lib/WP'
import auth from '../../../kea/auth'

@connect({
  props: [
    auth, [
      'loggedIn',
    ],
  ],
  actions: [
    articleComments, [
      'submitComment',
    ],
    auth, [
      'updateModal',
    ],
  ]
})

class ArticleComments extends Component {
  static propTypes = {
    articleId: PropTypes.number.isRequired,
    loggedIn: PropTypes.bool,
  }

  _mounted = false
  constructor (props) {
    super(props)
    this.state = {
      comments: [],
      commentCount: 0,
      status: 'loading',
      showAll: false,
      admins: []
    }
  }

  asyncSetState (...args) {
    // avoid setting state after unmount in case async operations attempts to do so
    if (this._mounted) this.setState(...args)
  }

  async loadComments (scrollToLast = false) {
    const [comments, admins] = await Promise.all([
      WP.getComments(this.props.articleId),
      WP.getAuthors()
    ])
    this.asyncSetState({
      poll: null,
      comments,
      commentCount: comments.length,
      articleId: this.props.articleId,
      status: comments ? 'loaded' : 'no comments',
      quote: null,
      admins: admins || []
    }, () => {
      scrollToLast && document.querySelector('#lastComment') && document.querySelector('#lastComment').scrollIntoView(false)
    })
  }

  async handleCommentSubmit (comment) {
    const { articleId } = this.props
    // console.log('Posting comment...')
    const response = await WP.postComment(comment, articleId)
    // console.log('Received response ', JSON.stringify(response))
    if (response.status === 'approved') {
      this.showAll()
      this.asyncSetState({ quote: null })
      this.loadComments(true)
    }
    return response
  }

  quote (comment, id) {
    this.asyncSetState({ quote: comment })
  }

  componentDidMount () {
    this._mounted = true
    this.loadComments()
  }

  componentWillReceiveProps (nextProps) {
    if (nextProps.articleId !== this.state.articleId) {
      this.loadComments()
    }
  }

  componentWillUnmount () {
    this._mounted = false
  }

  showAll = () => {
    this.asyncSetState({
      showAll: true,
    })
  }

  render () {
    const {
      comments,
      showAll,
      commentCount,
      quote,
      status,
      admins
    } = this.state
    const { loggedIn } = this.props
    const renderedComments = showAll ? comments : comments.slice(0, 5)

    return <Fragment>
      <div styleName="main">
        <div styleName="header">
          <h3 styleName="title">Keskustelu</h3>
          {
            commentCount
              ? <span styleName="comment-count">{commentCount} {commentCount === 1 ? 'kommentti' : 'kommenttia'}</span>
              : null
          }
        </div>
        <div styleName="content">
          <div styleName="comments">
            <div>
              { /* poll && poll.PollID && (
                <Poll
                  poll={ poll }
                  vote={ this.postVote }
                />
              ) */}
              {comments && renderedComments.map((comment, idx) => {
                return (
                  <ArticleComment
                    key={ comment.id }
                    comment={ comment }
                    handleReport={ this.handleReport }
                    quote={this.quote.bind(this)}
                    isLast={idx === comments.length - 1}
                    admins={admins}
                  />
                )
              })}
              {comments && !commentCount && status === 'loaded'
                ? <div>Ei vielä kommentteja.</div>
                : null}
              {status === 'error'
                ? <div>Kommentteja ei saatu ladattua.</div>
                : null}
            </div>
            {
              renderedComments.length < commentCount
                ? (
                  <div styleName="show-more">
                    <button onClick={this.showAll}>Näytä kaikki</button>
                  </div>
                )
                : null
            }
          </div>
        </div>
      </div>
      {
        loggedIn
          ? <CommentBox onSubmit={this.handleCommentSubmit.bind(this)} quote={quote}/>
          : <a id="login-button" styleName="login-button" href={WP.getLoginUrl()}>
            Kirjaudu sisään kommentoidaksesi</a>
      }
    </Fragment>
  }

  handleReport = (comment) => {
    // console.log('Report comment:', comment)
  }
}

export default withErrorBoundary(
  ArticleComments,
  ErrorPlaceholderBox('Kommentteja ei saatu ladattua')
)
