import React, { Fragment } from 'react'
import {
  FacebookLogo,
  InstagramLogo,
  YoutubeLogo,
  WhatsappLogo,
  TwitterLogo, TikTokLogo,
} from '../widgets/Icons'

const SocialLinks = () => (
  <Fragment>
    <a href="https://www.whatsapp.com/channel/0029VaUaaz9ISTkGwIVnqd2G" target="blank">
      <WhatsappLogo />
      <span className="screen-reader-text">TM WhatsAppissa</span>
    </a>
    <a href="https://www.facebook.com/tmtestaa/" target="blank">
      <FacebookLogo />
      <span className="screen-reader-text">TM Facebookissa</span>
    </a>
    <a href="https://twitter.com/TM_testaa" target="blank">
      <TwitterLogo />
      <span className="screen-reader-text">TM Twitterissä</span>
    </a>
    <a href="https://www.instagram.com/tekniikanmaailma/" target="blank">
      <InstagramLogo />
      <span className="screen-reader-text">TM Instagramissa</span>
    </a>
    <a href="https://www.youtube.com/channel/UClkRP15ZkqTRYfabEIcC-HQ" target="blank">
      <YoutubeLogo />
      <span className="screen-reader-text">TM YouTubessa</span>
    </a>
    <a href="https://www.tiktok.com/@tekniikanmaailma" target="blank">
      <TikTokLogo/>
      <span className="screen-reader-text">TM TikTokissa</span>
    </a>
  </Fragment>
)

export default SocialLinks
