import React, { Component } from 'react'
import PropTypes from 'prop-types'
import hintaOpasLogo from '../../assets/logos/hintaopas-logo.png'
import WP from '../../lib/WP'

import './CategoryPriceGuide.scss'

/**
 * Displays a "Hintaopas" box with most popular items from a category.
 */
export default class CategoryPriceGuide extends Component {
  static propTypes = {
    category: PropTypes.string,
    categoryList: PropTypes.array
  }

  categoryMap = {
    tietotekniikka: { id: 328, title: 'Tietokoneet & Tarvikkeet' },
    televisiot: { id: 107, title: 'Televisiot' },
    mobiililaitteet: { id: 298, title: 'Puhelimet & GPS' },
    audio: { id: 1314, title: 'Hifi- & Kotiteatteritarvikkeet' },
    kodintekniikka: { id: 504, title: 'Kodinkoneet' },
    renkaat: { id: 2185, title: 'Renkaat' },
    kamerat: { id: 480, title: 'Valokuvaus & Video' },
    polkupyorat: { id: 888, title: 'Polkupyörät' }
  }

  constructor (props) {
    super(props)
    const categorySlug = this.getBestCategorySlug(props)
    this.state = { items: this.loadItems(categorySlug, true), categorySlug }
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    const categorySlug = this.getBestCategorySlug(this.props)
    if (categorySlug !== this.state.categorySlug) {
      this.setState({ categorySlug })
      this.loadItems(categorySlug)
    }
  }

  getBestCategorySlug (props) {
    if (props.category) {
      return props.category
    }
    if (!props.categoryList || !props.categoryList.length) {
      return null
    }
    const mainCat = props.categoryList.find(element => {
      return Object.keys(this.categoryMap).includes(element.slug)
    })
    return mainCat ? mainCat.slug : null
  }

  async loadItems (categorySlug, returnItems = false) {
    if (categorySlug && this.categoryMap[categorySlug]) {
      const items = await WP.getHintaopasCategory(this.categoryMap[categorySlug].id)
      this.setState({ items: items.slice(0, 4) })
    } else {
      if (returnItems) {
        return []
      } else {
        this.setState({ items: [] })
      }
    }
  }

  getPrice (item) {
    return item.price && item.price.regular && item.price.regular.toFixed && item.price.regular.toFixed(2).replace('.', ',')
  }

  render () {
    const { items } = this.state
    if (!items || !items.length || !this.state.categorySlug || !this.categoryMap[this.state.categorySlug]) {
      return null
    }

    return <div styleName="price-guide">
      MAINOS
      <div styleName="items">
        {items.map((item, key) => <a href={item.price.web_uri.replace(/ref=61398/, 'ref=61830')} key={key} rel="noopener noreferrer"
          styleName="item" target="_blank">
          <div styleName="image"><img src={item.media.first['280']} alt={item.name}/></div>
          <div styleName="title">
            <div>{item.name}</div>
          </div>
          <div styleName="price">{this.getPrice(item)} €</div>
        </a>)}
      </div>
      <div styleName="footer">
        <div>Hintaopas: {this.categoryMap[this.state.categorySlug].title}</div>
        <div>Yhteistyössä <img src={hintaOpasLogo} alt="Hintaopas logo"/></div>
      </div>
    </div>
  }
}
