import React from 'react'
import PropTypes from 'prop-types'
import DateField from '../widgets/DateField'
import './ArticleMeta.scss'

const ArticleDate = ({ date, dateOnly, timeOnly }) => {
  return (
    <span styleName="date-field">
      <DateField date={ date } dateOnly={dateOnly} timeOnly={timeOnly}/>
    </span>
  )
}

ArticleDate.propTypes = {
  date: PropTypes.string,
  dateOnly: PropTypes.bool,
  timeOnly: PropTypes.bool,
}

export default ArticleDate
