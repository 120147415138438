import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import ReactPlaceholder from 'react-placeholder'
import WP from '../../../lib/WP'
import { STATUS } from '../../../lib/request-state'
import { errors as err } from '../../../lib/errors'
import './EditorsPicks.scss'
import { ErrorPlaceholderBox, withErrorBoundary } from '../util/ErrorBoundaries'
import Title from '../util/Title'
import EditorsPicksLoader from '../util/EditorsPicksLoader'
import { Link } from '../util/Links'
import HTML from '../util/HTML'
import ArticleMetaData from '../article/ArticleMetadata'
import ArticleThumbnail from '../article/ArticleThumbnail'
import { SubscriberIcon } from '../../widgets/Icons'
import track from 'react-tracking'

class EditorsPicks extends Component {
  _mounted = false

  constructor (props) {
    super(props)
    this.state = {
      editorsPicks: null,
      status: STATUS.NOT_REQUESTED,
    }
  }

  async loadData (category) {
    try {
      const editorsPicks = await WP.getEditorsPicks(category)
      this.setState({ editorsPicks, status: editorsPicks ? STATUS.DONE : STATUS.ERROR })
    } catch (e) {
      this.setState({ status: STATUS.ERROR })
    }
    this.props.doneLoading && this.props.doneLoading()
  }

  componentDidMount () {
    this.loadData(this.props.category)
  }

  componentWillUnmount () {
    this._mounted = false
  }

  render () {
    const { editorsPicks, status } = this.state

    if (status === STATUS.ERROR) throw err.editorsPicksLoadError({ previousError: this.state.error })

    return (
      <div styleName="main">
        <Title text="Toimituksen valinnat" />
        <div styleName="editors-picks-list">
          <ReactPlaceholder ready={status === STATUS.DONE} customPlaceholder={<EditorsPicksLoader items={3} />}>
            <ul>
              {editorsPicks && editorsPicks.map(({ item: article }, idx) => (
                <li key={idx}>
                  <article styleName="editors-picks-item">
                    <div styleName="meta-row">
                      <ArticleMetaData
                        categories={article.categories}
                        date={article.createdDate}
                        magazineLabel={article.printmag ? article.printmag.name : null}
                      />
                    </div>
                    <Fragment>
                      <Link to={article} clickLocation="text">
                        <span styleName='thumbnail'><ArticleThumbnail article={article} /></span>
                        <h5><HTML>{article.title}</HTML></h5>
                        {article.forSubscribers && <div styleName="subscriber-icon-container"><SubscriberIcon/></div>}
                      </Link>
                    </Fragment>
                  </article>
                </li>
              ))}
            </ul>
          </ReactPlaceholder>
        </div>
      </div>
    )
  }
}

EditorsPicks.propTypes = {
  category: PropTypes.number,
  doneLoading: PropTypes.func,
}

export default track({ gtmContext: ['EditorsPicks'] })(withErrorBoundary(
  EditorsPicks,
  ErrorPlaceholderBox('Toimituksen valintoja ei saatu ladattua'),
))
