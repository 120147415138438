import isMatch from 'lodash/isMatch'
import articleTypes from '../components/general/article/ArticleTypes'
import trimStart from 'lodash/trimStart'
import { optimizeExperiments } from '../lib/defaults'

export const stripHTML = (text) => {
  const body = (new DOMParser().parseFromString(text, 'text/html')).body
  return body ? body.textContent : ''
}

export const getRandomInt = max => {
  return Math.floor(Math.random() * Math.floor(max))
}

export const validatePowerAd = data => {
  if (!data) {
    return false
  } else if (!data.Title || !data.Title.length) {
    return false
  } else if (!data.Url || !data.Url.length) {
    return false
  } else if (!data.Price || isNaN(Number(data.Price))) {
    return false
  } else {
    return true
  }
}

export const validateDNAAd = data => {
  if (!data) {
    return false
  } else if (!data.product_name || !data.product_name.length) {
    return false
  } else if (!data.product_deeplink || !data.product_deeplink.length) {
    return false
  } else if (!data.product_price || !data.product_price.length) {
    return false
  } else {
    return true
  }
}

export const getCookie = name => {
  const decodedCookie = decodeURIComponent(document.cookie)
  const cookies = decodedCookie.split(';')
  const cookie = cookies.find(cookie => {
    const parts = cookie.split('=')
    return parts[0].trim() === name
  })

  if (cookie) {
    const parts = cookie.split('=')
    return {
      name: parts[0].trim(),
      value: parts[1],
    }
  } else {
    return undefined
  }
}

export const shuffle = array => {
  /* modern version of the Fisher-Yates shuffle */
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1))
    const x = array[i]
    array[i] = array[j]
    array[j] = x
  }
  return array
}

/*
 * Helper function to be used with `yield take` when an action
 * may payload is an object, and you want to wait until an action with a payload that matches
 * the object provided.
 * yield take(actionResultMatchesObject(actions.actionName, { data: { status: STATUS.DONE } }))
 */
export const actionResultMatchesObject = (action, object = {}) => {
  const name = action.toString()

  // console.log(name, object)

  return ({ type, payload }) => {
    // console.log(type, payload)
    return name === type && isMatch(object, payload)
  }
}

/*
 * Helper function to be used with `yield take` when an action
 * may result in many different values.
 * yield take(actionResultIs(actions.actionName, 'block-until-value-is-this'))
 */
export const actionResultIs = (action, value) => {
  const name = action.toString()

  return ({ type, payload }) => {
    // Handle multiple possible values
    if (name === type && Array.isArray(value)) {
      return value.some(x => x === payload)
    }

    return name === type && payload === value
  }
}

/**
 * Get value from Google Optimize (A/B testing)
 *
 * @param experimentName
 * @returns {Promise<int>}
 */
export const getOptimizeValue = (experimentName) => {
  const timestamp = new Date().getTime()
  return new Promise((resolve) => {
    const intervalId = setInterval(() => {
      if (window.google_optimize !== undefined) {
        const experimentId = optimizeExperiments[experimentName][window.location.href.includes('asteaws.dev') ? 'development' : 'production']
        const variant = window.google_optimize.get(experimentId)
        clearInterval(intervalId)
        resolve(parseInt(variant) || 0)
      } else if (new Date().getTime() > timestamp + 2000) {
        resolve(0)
      }
    }, 100)
  })
}

export const getPageType = (view) => {
  const { articleType, _meta, taxonomy, type } = view
  if (articleType) {
    return articleType === articleTypes.SPECIAL ? 'erikoistaitto' : 'artikkeli'
  }
  if (_meta) {
    if (taxonomy === 'category') {
      return 'kategoria'
    } else if (taxonomy === 'post_tag') {
      return 'avainsana'
    }
  } else if (type === 'index') {
    return 'etusivu'
  } else {
    return 'sivu'
  }
}

export function getLeikiContextual (SmartProfiles) {
  /*
  if (!window.dataLayer) {
    console.error('LeikiSmartProfiles: dataLayer is not defined.')
    return
  }

  const dataLayerKey = 'LeikiSmartProfiles'
   */
  const keywordFilters = {
    aste_helsinki: {
      relevance: 2,
      reversedSlotsLeft: 5,
    },
    iabtier1: {
      relevance: 20,
      reversedSlotsLeft: 5,
    }
  }
  /*
  let dataLayer = window.dataLayer

  // Find correct object by key from dataLayer.
  const SmartProfiles = (dataLayer.find(dataLayerItem => dataLayerItem[dataLayerKey]) || {})[dataLayerKey] || {}
  */
  return Object.keys(SmartProfiles) // First get all object keys of SmartProfiles. Keys are SmartProfile classifications.
    .filter(x => keywordFilters[x]) // Use only keywordFilters classifications
    .flatMap(x => SmartProfiles[x] // Go through all classification objects
      .filter(y => // Filter out all keywords that don't meet minimum relevance level
        y.relevance >= keywordFilters[x].relevance && keywordFilters[x].reversedSlotsLeft && keywordFilters[x].reversedSlotsLeft--
      )
      .map(y => y.ID) // Get just the ID string
    )
    .join() // Convert array to string. Keywords are separated by , by default
}

export function generateUniqueId () {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = Math.random() * 16 | 0
    const v = c === 'x' ? r : (r & 0x3 | 0x8)
    return v.toString(16)
  })
}

let elementSelector
let animationsEnabled = false
const scroll = window.requestAnimationFrame ||
  function (callback) {
    window.setTimeout(callback, 1000 / 60)
  }

function isElementBottomInViewport (el) {
  const scrollConstant = window.innerHeight / 2
  const rect = el.getBoundingClientRect()
  const height = window.innerHeight || document.documentElement.clientHeight
  return rect.bottom <= height + scrollConstant && rect.bottom >= -scrollConstant
}

function isElementTopInViewport (el) {
  const scrollConstant = window.innerHeight / 2
  const rect = el.getBoundingClientRect()
  const height = window.innerHeight || document.documentElement.clientHeight
  return rect.top <= height + scrollConstant && rect.top >= -scrollConstant
}

function loop () {
  if (!animationsEnabled) return

  const elementsToShow = document.querySelectorAll(elementSelector)
  // const height = window.innerHeight || document.documentElement.clientHeight

  elementsToShow.forEach(function (element) {
    if (isElementTopInViewport(element)) {
      if (element.classList.contains('is-bottom-visible') && !element.classList.contains('is-top-visible')) {
        element.classList.remove('is-scrolling-in')
        element.classList.remove('is-scrolling-out')
        // top came into view while scrolling up, picture is now completely visible
      } else if (!element.classList.contains('is-bottom-visible') && !element.classList.contains('is-top-visible')) {
        // top came into view while scrolling down
        element.classList.add('is-scrolling-in')
      }
      if (!element.classList.contains('is-top-visible')) element.classList.add('is-top-visible')
    } else {
      if (element.classList.contains('is-top-visible') && !element.classList.contains('is-bottom-visible')) {
        // completely scrolled out, upwards
        element.classList.remove('is-scrolling-out')
        element.classList.remove('is-scrolling-in')
      } else if (element.classList.contains('is-top-visible') && element.classList.contains('is-bottom-visible')) {
        // started scrolling out, downwards
        element.classList.add('is-scrolling-out')
      }
      if (element.classList.contains('is-top-visible')) element.classList.remove('is-top-visible')
    }

    if (isElementBottomInViewport(element)) {
      if (element.classList.contains('is-top-visible') && !element.classList.contains('is-bottom-visible')) {
        // bottom came into view while scrolling down, picture is now completely visible
        element.classList.remove('is-scrolling-in')
        element.classList.remove('is-scrolling-out')
      } else if (!element.classList.contains('is-top-visible') && !element.classList.contains('is-bottom-visible')) {
        // bottom came into view while scrolling up
        element.classList.add('is-scrolling-in')
      }
      if (!element.classList.contains('is-bottom-visible')) element.classList.add('is-bottom-visible')
    } else {
      if (element.classList.contains('is-bottom-visible') && !element.classList.contains('is-top-visible')) {
        // completely scrolled out, downwards
        element.classList.remove('is-scrolling-out')
        element.classList.remove('is-scrolling-in')
      } else if (element.classList.contains('is-bottom-visible') && element.classList.contains('is-top-visible')) {
        // started scrolling out, upwards
        element.classList.add('is-scrolling-out')
      }
      if (element.classList.contains('is-bottom-visible')) element.classList.remove('is-bottom-visible')
    }
    /*
    const nextSibling = element.nextElementSibling || element.parentElement.parentElement.querySelector('.FeatureArticle_ingress')
    if (nextSibling) {
      const rect = nextSibling.getBoundingClientRect()
      if (rect.top <= height && rect.bottom > 0) {
        element.classList.add('caption-showing')
      } else {
        element.classList.remove('caption-showing')
      }
    }
     */
  })

  scroll(loop)
}

export function enableScrollAnimations (selector) {
  elementSelector = selector
  animationsEnabled = true
  loop()
}

export function disableScrollAnimations () {
  animationsEnabled = false
}

/*
 * Convert comment to HTML before sending to server.
 * Changes ">" to <blockquote>, line break to <br/>, and quoted username to bold
 */
export function textToHtml (text) {
  let quotesOnLastLine = 0
  const html = text.match(/^.*$/gm).reduce((result, line) => {
    const quotesOnThisLine = (line.match(/^> /g) && line.match(/^(> )+/g).length && line.match(/^(> )+/g)[0].match(/>/g).length) || 0
    let output = ''
    let count = 0
    let newQuote = false
    while (quotesOnThisLine > quotesOnLastLine + count) {
      output += '<blockquote>'
      newQuote = true
      count++
    }
    count = 0
    while (quotesOnThisLine + count < quotesOnLastLine) {
      output += '</blockquote>'
      count++
    }
    quotesOnLastLine = quotesOnThisLine
    let newLine = line.replace(/^(> )+/g, ' ')
    if (newQuote) {
      newLine = newLine.replace(/^\s+(\S+) kirjoitti:$/g, ' <b>$1</b> kirjoitti:')
    }
    return result + output + newLine + '\n'
  }, '')
  // console.log('Converted comment text to html')
  return html
}

/*
 * Converts quoted HTML to text, so that it can be easily edited. Changes <blockquote> to ">",
 * limits line length to 80 chars, and removes all tags
 */
export function getQuotedText (elem, quoteLevel, addQuotes = false) {
  let result
  if (Array.isArray(elem)) {
    result = elem.map((el) => getQuotedText(el, quoteLevel)).join('')
  } else {
    switch (elem.type) {
    case 'tag':
      switch (elem.name) {
      case 'br':
        result = ''
        break
      case 'blockquote':
        result = getQuotedText(elem.children, ++quoteLevel)
        addQuotes = true
        break
      case 'p':
        result = getQuotedText(elem.children, quoteLevel) + '\n\n'
        break
      default:
        result = getQuotedText(elem.children, quoteLevel)
      }
      break
    case 'cdata':
      result = getQuotedText(elem.children, quoteLevel)
      break
    case 'text':
      result = elem.data
      break
    default:
      result = ''
    }
  }

  if (addQuotes) {
    if (result.trim().length) {
      const rege = new RegExp('(\\n\\n)|(.{1,' + (80 - 5 * quoteLevel) + '}[^\\s]*)', 'g')
      return trimStart(result).match(rege).reduce((text, line) => text + '> ' + trimStart(line) + '\n', '')
    } else {
      return (quoteLevel ? '> '.repeat(quoteLevel) : '') + '\n'
    }
  } else {
    return result
  }
}
