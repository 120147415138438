import React from 'react'
import PropTypes from 'prop-types'
import './InfoHighlight.scss'
import { ArrowDown } from './Icons'

export const InfoHighlight = () => {
  return (
    <div styleName="info-highlight">
      <ArrowDown/>
    </div>
  )
}

export const InfoText = ({ highlight }) => {
  const texts = [
    '',
    'Siirry selaamaan kaikkia digilehtiä',
    'Avaa digilehden sisällysluettelo',
    'Avaa tämä digilehden lukuohje',
    'Siirry järjestyksessä edelliseen ja seuraavaan digilehden artikkeliin',
  ]

  return (
    <div styleName="info-text">
      {texts[highlight]}
    </div>
  )
}

InfoText.propTypes = {
  highlight: PropTypes.number,
}
