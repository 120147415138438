import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'kea'
import LibreForm, { configure } from 'react-libre-form'

import formLogic from '../../kea/form'
import auth from '../../kea/auth'
import WP from '../../lib/WP'

import './LibreForm.scss'

export default @connect({
  props: [
    formLogic, [
      'referrer',
    ],
    auth, [
      'loggedIn',
      'user',
    ],
  ]
})
class LibreFormWrapper extends Component {
  static propTypes = {
    form: PropTypes.string,
    referrer: PropTypes.string,
    formKey: PropTypes.string,
    loggedIn: PropTypes.bool,
    user: PropTypes.object,
  }

  componentWillMount () {
    const { loggedIn } = this.props
    let config = {
      WordPress: WP.url,
      ajaxCredendtials: 'include',
      ajaxURL: WP.url + '/wp-json/wplf/v1/submit',
      i18n: {
        scriptTimeout: 'Lomakkeen riippuvuuden lataus epäonnistui',
        failedToLoadForm: 'Lomaketta ei saatu ladattua',
        formNotFound: 'Lomaketta ei löytynyt',
        WPURLNull: 'WordPressin URL-osoitetta ei ole asetettu',
        unknown: 'Tuntematon virhe',
        loading: 'Ladataan...',
      },
    }

    if (loggedIn) {
      config = {
        ...config,
        headers: {
          Authorization: ''
        },
      }
    }

    configure(config)
  }

  render () {
    return (
      <div styleName="form-wrapper">
        <LibreForm {...this.props} />
      </div>
    )
  }
}

// Takes both form and container as params to work as-is in afterLoad
const feedbackInputMutations = (form, container) => {
  const formEl = container.querySelector('form')

  if (formEl) {
    const uaInput = formEl.querySelector('[name="useragent"]')
    const dimensionsInput = formEl.querySelector('[name="dimensions"]')

    uaInput && (uaInput.value = navigator.userAgent)
    dimensionsInput && (dimensionsInput.value = `${window.innerWidth}x${window.innerHeight}`)
  }
}

export {
  feedbackInputMutations
}
