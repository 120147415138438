import PropTypes from 'prop-types'
import { kea } from 'kea'
import { channel, buffers } from 'redux-saga'
import { put, take } from 'redux-saga/effects'
import { STATUS } from '../lib/request-state'
import WP from '../lib/WP'

export default kea({
  paths: ['adminbar'], // default
  actions: () => ({
    nukeObjectCache: true,
    setFlushStatus: (status) => status,
  }),

  reducers: ({ actions }) => ({
    objectCache: [{ flush: STATUS.NOT_REQUESTED }, PropTypes.object, {}, {
      [actions.nukeObjectCache]: (state) => ({ ...state, flush: STATUS.REQUESTED }),
      [actions.setFlushStatus]: (state, status) => ({ ...state, flush: status }),
    }],
  }),

  start: function * () {
    const responseChannel = channel(buffers.expanding())
    while (true) {
      const action = yield take(responseChannel)
      yield put(action)
    }
  },

  takeEvery: ({ actions, workers }) => ({
    [actions.nukeObjectCache]: function * ({ type, payload }) {
      try {
        const { flush } = yield this.get('objectCache')

        if (flush === STATUS.REQUESTED) {
          yield WP.nukeObjectCache()
          yield put(actions.setFlushStatus(STATUS.DONE))
          alert('Välimuisti tyhjennetty.')
        } else {
          console.log('Request to nuke cache already in progress')
        }
      } catch (e) {
        console.log('Failed to nuke object cache', e)
        yield put(actions.setFlushStatus(STATUS.ERROR))
        alert('Välimuistin tyhjennys epäonnistui.')
      }
    },
  }),

  selectors: ({ selectors }) => ({
    flushRequested: [
      () => [selectors.objectCache],
      ({ flush }) => flush === STATUS.REQUESTED,
      PropTypes.bool,
    ],
  })
})
