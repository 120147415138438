import get from 'lodash/get'
import { stripApiHostname } from '../lib/url'
import WP from '../lib/WP'

export default class CategoryModel {
  id
  name
  slug
  link
  primary
  taxonomy
  restBase
  description
  nativeAds
  _meta

  constructor (WPModel, primary) {
    this.id = WPModel.id || WPModel.term_id
    this.name = WPModel.name
    this.slug = WPModel.slug
    this.link = WPModel.link ? stripApiHostname(WPModel.link) : '/avainsana/' + this.slug
    this.primary = primary === this.id
    this.taxonomy = WPModel.taxonomy
    this.description = WPModel.description
    this.nativeAds = get(WPModel, 'acf.nativeads') || []
    this.nativeAds.forEach((ad) => {
      ad.image = ad.image && ad.image.url ? ad.image.url : ad.image
    })
    this.restBase = WP.getRESTBase(this.taxonomy)
    this._meta = {
      type: 'term',
    }
  }

  static createFromWPPost (WPModel) {
    const { 'om:yoast_primary_category': primary } = WPModel
    const categories = get(WPModel, 'categories', [])
      .map((term) => new CategoryModel(term, primary)).reverse()

    // Make sure that primary category is first
    return categories.sort(
      (a, b) => a.id === primary ? -1 : b.id === primary ? 1 : 0
    )
  }
}
