import React from 'react'
import PropTypes from 'prop-types'
import tmLogo from '../../../assets/logos/tm-logo-crop.svg'
import HTML from './HTML'
import './Title.scss'

const Title = ({ text, displayLogo }) => {
  return (
    <div styleName="title-container">
      <div styleName="title-icon">
        {displayLogo
          ? <img styleName="logo" src={tmLogo} alt="TM" />
          : null
        }
      </div>
      <h2 styleName="title-content">
        <HTML>{text}</HTML>
      </h2>
    </div>
  )
}

Title.propTypes = {
  text: PropTypes.string.isRequired,
  displayLogo: PropTypes.bool,
}

export default Title
