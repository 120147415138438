import React, { Component } from 'react'
import get from 'lodash/get'
import NettixBox from '@otavamedia/om-component-library/lib/NettixBox'
import PropTypes from 'prop-types'
import WP from '../../../lib/WP'
import { STATUS } from '../../../lib/request-state'
import { ErrorPlaceholder, withErrorBoundary } from '../util/ErrorBoundaries'

const searchTagsMockData = [
  { label: 'Kia stonic' },
  { label: '1995 - 2005' },
]

class NettixEmbed extends Component {
  static propTypes = {
    className: PropTypes.string,
    searchTags: PropTypes.array,
    searchId: PropTypes.any,
    doneLoading: PropTypes.func,
  }

  static defaultProps = {
    searchTags: searchTagsMockData,
  }

  constructor () {
    super()

    this.state = {
      items: [],
      totalItemCount: -1,
      status: STATUS.NOT_REQUESTED,
    }
  }

  componentDidMount () {
    this.loadData()
  }

  async loadData () {
    const { searchId } = this.props

    this.setState({ status: STATUS.REQUESTED })

    let data
    try {
      data = await WP.getNettixEmbed(searchId)
    } catch (e) {
      this.setState({ status: STATUS.ERROR, items: [] })
      return
    }

    if (!data) {
      this.setState({ status: STATUS.ERROR, items: [] })
      return
    }

    this.setState({
      items: get(data, 'items', []),
      totalItemCount: get(data, 'count.total', 0),
      status: STATUS.DONE,
    })
  }

  render () {
    const { className, searchTags, searchId } = this.props
    const { items, totalItemCount, status } = this.state
    if (status !== STATUS.DONE && status !== STATUS.ERROR) {
      return null
    }
    setTimeout(() => this.props.doneLoading && this.props.doneLoading(), 100)
    return (
      <NettixBox
        className={className}
        items={items}
        searchId={searchId}
        searchTags={searchTags}
        totalItemCount={totalItemCount}
      />
    )
  }
}

export default withErrorBoundary(
  NettixEmbed,
  ErrorPlaceholder()
)
