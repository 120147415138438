import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Link } from '../general/util/Links'
import Image from '../general/util/Image'
import HTML from '../general/util/HTML'
import missingImage from '../../assets/pictures/missing-image.png'
import { ErrorPlaceholderBox, withErrorBoundary } from '../general/util/ErrorBoundaries'
import { IMAGE_SIZE } from '../../entities/ImageModel'
import './ArticleItem.scss'
import DateField from '../general/widgets/DateField'
import diff from 'date-fns/difference_in_days'

class ArticleItem extends Component {
  static propTypes = {
    article: PropTypes.object,
    size: PropTypes.string.isRequired,
    noImage: PropTypes.bool,
    backgroundColour: PropTypes.string,
    capitalizeTitle: PropTypes.bool,
    showDate: PropTypes.bool,
    latest: PropTypes.string,
    equation: PropTypes.oneOfType([
      PropTypes.array,
      PropTypes.bool,
    ]),
    magazine: PropTypes.string,
    related: PropTypes.array,
    imageLocation: PropTypes.string,
  }

  renderVignetteAndTopic = (vignette, topic, date, isLatest, isNew, isUpdated) => {
    if (vignette && topic) {
      return (
        <header>
          <div styleName="meta-row-topic"><HTML>{vignette}</HTML>&nbsp;|&nbsp;</div>
          <div styleName="meta-row-topic light"><HTML>{topic}</HTML></div>
          {date ? <div styleName="meta-row-topic light">&nbsp;|&#32;<DateField date={date} dateOnly/></div> : null}
          {isNew ? <div styleName="latest">Uusi</div> : null}
          {isUpdated ? <div styleName="latest">Päivitetty</div> : null}
        </header>
      )
    } else if (vignette && !topic) {
      return <header styleName="meta-row-topic">
        <HTML>{vignette}</HTML>
        {date ? <div styleName="meta-row-topic light">&nbsp;|&#32;<DateField date={date} dateOnly/></div> : null}
        {isNew ? <div styleName="latest">Uusi</div> : null}
        {isUpdated ? <div styleName="latest">Päivitetty</div> : null}
      </header>
    } else {
      return <header>
        {date ? <div styleName="meta-row-topic light"><DateField date={date} dateOnly/></div> : null}
        {isNew ? <div styleName="latest">Uusi</div> : null}
        {isUpdated ? <div styleName="latest">Päivitetty</div> : null}
      </header>
    }
  }

  renderRelatedVignetteAndTopic = (vignette, topic) => {
    if (vignette && topic) {
      return <header><HTML>{vignette}: {topic}</HTML> &mdash; </header>
    } else if (vignette && !topic) {
      return <header><HTML>{vignette}</HTML>: </header>
    } else {
      return null
    }
  }

  renderImage = () => {
    const { article, size } = this.props
    return (article.image && article.image.src)
      ? <Image data={article.image} size={size === 'sm' ? IMAGE_SIZE.MEDIUM : IMAGE_SIZE.LARGE }
        sizes={size === 'sm' ? '100px' : '(max-width: 768px) 50vw, 100vw'} />
      : <img src={missingImage} alt="Kuvaa ei löytynyt" />
  }

  renderNormalTitle = () => {
    const { article, capitalizeTitle } = this.props
    return <h3 styleName={`${capitalizeTitle ? 'capitalized' : ''}`}><HTML>{article.title}</HTML></h3>
  }

  renderEquationTitle = (date, isLatest, isNew, isUpdated) => {
    const { equation } = this.props
    return (
      <h3 styleName="capitalized">
        <div styleName="topic-header">
          Puheenaihe
          {date ? <div styleName="light">&nbsp;|&#32;<DateField date={date} dateOnly/></div> : null}
          {isNew ? <div styleName="latest">Uusi</div> : null}
          {isUpdated ? <div styleName="latest">Päivitetty</div> : null}
        </div>
        <div styleName="equation">
          {equation.map((part, index) => {
            return <span key={`equation-part-${index}`} styleName={`${part.isOperator ? 'operator' : 'operand'}`}>{part.part} </span>
          })}
        </div>
      </h3>
    )
  }

  renderTileContents = () => {
    const { article, size, equation, showDate, latest } = this.props
    const isLatest = showDate && latest && article.date && latest === article.date
    const isNew = showDate && latest && article.date && diff(new Date(), article.date) < 8
    const isUpdated = !isNew && showDate && latest && article.modifiedDate && diff(new Date(), article.modifiedDate) < 4
    return (
      <Fragment>
        {!equation && (
          <div styleName="meta-row">
            {this.renderVignetteAndTopic(article.vignette, article.topic, showDate ? article.date : null, isLatest, isNew, isUpdated)}
          </div>
        )}
        {equation
          ? this.renderEquationTitle(showDate ? article.date : null, isLatest, isNew, isUpdated)
          : this.renderNormalTitle()
        }
        <div styleName="ingress">
          {size !== 'sm' && (article.description || article.excerpt)
            ? (
              <Fragment>
                <HTML>{article.description || article.excerpt}</HTML>
              </Fragment>
            )
            : null}
        </div>
      </Fragment>
    )
  }

  renderWithoutImage = () => {
    const { article, size, backgroundColour } = this.props
    return (
      <Fragment>
        <div styleName={`article-content-without-image ${backgroundColour ? `background-${backgroundColour}` : ''}`}>
          {this.renderTileContents(article, size)}
        </div>
      </Fragment>
    )
  }

  renderWithImage = () => {
    const { article, size, backgroundColour, imageLocation } = this.props
    return size === 'md' && imageLocation === 'right'
      ? (
        <Fragment>
          <div styleName={`article-content ${backgroundColour ? `background-${backgroundColour}` : ''}`}>
            {this.renderTileContents(article, size)}
          </div>
          <div styleName="item-image">
            {this.renderImage()}
          </div>
        </Fragment>
      )
      : (
        <Fragment>
          <div styleName="item-image">
            {this.renderImage()}
          </div>
          <div styleName={`article-content ${backgroundColour ? `background-${backgroundColour}` : ''}`}>
            {this.renderTileContents(article, size)}
          </div>
        </Fragment>
      )
  }

  render () {
    const { article, size, noImage, equation, related } = this.props
    const isAd = !!article && article.type === 'om_ad_article'

    return article
      ? (
        <div styleName={`${size}`} key={article.id}>
          {article.link
            ? (
              <Link to={article}>
                <article styleName={`article-list-item ${isAd ? 'is-ad' : ''}`}>
                  {noImage || equation
                    ? this.renderWithoutImage()
                    : this.renderWithImage()
                  }
                </article>
              </Link>
            )
            : (
              <article styleName={`article-list-item ${isAd ? 'is-ad' : ''} disabled`}>
                {noImage || equation
                  ? this.renderWithoutImage()
                  : this.renderWithImage()
                }
              </article>
            )}
          {related && related.length
            ? (
              <div styleName="related-container">
                <span>Lue myös:</span>
                <ul>
                  {related.map((item, index) => {
                    return (
                      <li key={`related-article-${index}`}>
                        <Link to={{ link: item.post.link }}>
                          {this.renderRelatedVignetteAndTopic(item.post.vignette, item.post.topic)}{item.post.title}
                        </Link>
                      </li>
                    )
                  })}
                </ul>
              </div>
            )
            : null}
        </div>
      )
      : null
  }
}

export default withErrorBoundary(
  ArticleItem,
  ErrorPlaceholderBox('Artikkelia ei saatu ladattua'),
)
