import React, { Fragment, Component } from 'react'
import PropTypes from 'prop-types'
import HTMLParser from '../../../lib/html-parser'

import { connect } from 'kea'
import galleryLogic from '../../../kea/gallery'

export default @connect({
  actions: [
    galleryLogic, [
      'addImage',
    ],
  ],
})
class HTML extends Component {
  static propTypes = {
    children: PropTypes.node,
    options: PropTypes.object,
  }

  render () {
    const { children, options = {} } = this.props
    const { addImage } = this.actions

    options.gallery = {
      addImage,
    }

    return <Fragment>{HTMLParser.render(children, options)}</Fragment>
  }
}

export const hasGoodBadShortcodes = html => html && html.indexOf('data-goodbad') > -1
