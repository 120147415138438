import { Component } from 'react'
import './SidebarLoader.scss'
import { connect } from 'kea'
import auth from '../../../kea/auth'
import querystring from 'querystring'
import PropTypes from 'prop-types'

@connect({
  actions: [
    auth, [
      'remoteLogin',
    ],
  ]
})
class RemoteLogin extends Component {
  static propTypes = {
    actions: PropTypes.object,
  }

  constructor (props) {
    super(props)
    const qs = querystring.parse(window.location.search.substring(1))
    props.actions.remoteLogin(qs.username, qs.token)
  }

  render () {
    return null
  }
}

export default RemoteLogin
