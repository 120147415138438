import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { connect } from 'kea'
import DfpAdSlot from './DfpAdSlot'
import { ErrorPlaceholderBox, withErrorBoundary } from '../util/ErrorBoundaries'
import './DfpAdSlot.scss'
import AD_CONTEXT from './AdContext'
import appLogic from '../../../kea/application'
import adsLogic from '../../../kea/ads'
import AdDebugInfoBox from './AdDebugInfoBox'
import { isHeadless } from '@otavamedia/om-component-library/lib/util/env'

// ad counter for unique id to refresh (remount) DfpAdSlot component
let counter = 0

export const AD_DISPLAY_TYPE = {
  ALWAYS: 'always',
  EXPAND: 'expand', // expand the slot only when ad has been loaded
  COLLAPSE: 'collapse', // collapse the slot if no ad was received
}

const dfpNetworkId = '135812570'
const containerName = 'tekniikanmaailma'

@connect({
  props: [
    appLogic, [
      'location',
    ],
    adsLogic, [
      'adRefreshKey',
      'adContext',
      'adsEnabled',
      'debugAds',
    ],
  ],
  actions: [
    adsLogic, [
      'markSlotDefined',
    ]
  ]
})
class ConnectedAdSlot extends Component {
  static propTypes = {
    slotId: PropTypes.string.isRequired,
    adUnit: PropTypes.string.isRequired,
    sizes: PropTypes.array.isRequired,
    displayType: PropTypes.string,
    adContext: PropTypes.string,
    adContextOverride: PropTypes.string,
    location: PropTypes.object,
    actions: PropTypes.object,
    adRefreshKey: PropTypes.string,
    debugName: PropTypes.string,
    loadInstantly: PropTypes.bool,
    adsEnabled: PropTypes.bool,
    debugAds: PropTypes.bool,
    noId: PropTypes.bool,
    fallBackElements: PropTypes.array,
    markSlotDefined: PropTypes.func,
    handleSlotRender: PropTypes.func
  }

  static defaultProps = {
    displayType: AD_DISPLAY_TYPE.ALWAYS,
    loadInstantly: false,
  }

  _mounted = false
  asyncSetState (...args) {
    // avoid setting state after unmount in case async operations attempts to do so
    if (this._mounted) this.setState(...args)
  }

  initPath
  initTimestamp
  lifetimeWarningInterval = 1000 // ms

  constructor (props) {
    super()

    this.initPath = window.location.pathname
    this.initTimestamp = Date.now()

    counter++

    this.state = {
      loading: true,
      empty: false,
      counter,
      refreshKey: props.location.key,
      adBlocker: true
    }
  }

  componentDidMount () {
    this._mounted = true
    if (isHeadless() || (window.googletag && window.googletag.pubadsReady && window.cxSegmentIds)) {
      this.setState({ adBlocker: false })
    } else {
      const interval = setInterval(() => window.googletag && window.googletag.pubadsReady && window.cxSegmentIds &&
        this.setState({ adBlocker: false }) && clearInterval(interval), 1000)
    }
  }

  componentWillUnmount () {
    this._mounted = false

    // try to check if ad is being rapidly unmounted within the same location
    if (this.initPath === window.location.pathname && Date.now() - this.initTimestamp < this.lifetimeWarningInterval) {
      const uniqueId = this.getUniqueId()
      console.warn(`Ad slot '${uniqueId}' was unmounted just after it's mount. This may be caused by unnecessary renders and will negatively impact ad stats.`)
    }
  }

  componentWillReceiveProps (nextProps) {
    if (nextProps.adRefreshKey !== this.props.adRefreshKey && nextProps.adRefreshKey !== this.state.refreshKey) {
      this.setState({
        refreshKey: nextProps.adRefreshKey,
      })
      this.incrementAdCounter()
    }
  }

  incrementAdCounter () {
    counter++
    this.setState({
      counter,
      loading: true,
      empty: false,
    })
  }

  getAdUnitPath () {
    const { adUnit, adContext, adContextOverride } = this.props
    return dfpNetworkId + '/' + containerName + '/' + (adContextOverride || adContext) + '/' + adUnit
  }

  getUniqueId () {
    const { debugName, slotId } = this.props
    const prefix = debugName ? debugName + '_ad_' : 'ad_'
    return prefix + slotId + '_' + this.state.counter
  }

  render () {
    const { empty, loading, adBlocker } = this.state
    const {
      sizes,
      slotId,
      adContext,
      loadInstantly,
      adsEnabled,
      debugAds,
      adUnit,
      actions,
      noId,
      setTargeting
    } = this.props
    const FallBackElement = this.props.fallBackElements ? this.props.fallBackElements[0] : null
    const FallBackElement2 = this.props.fallBackElements ? this.props.fallBackElements[1] : null

    if (!adsEnabled) return null

    // skip rendering if we are unaware of the context (app/ads state initializing)
    if (adContext === AD_CONTEXT.UNKNOWN) return null

    const displayType = debugAds ? AD_DISPLAY_TYPE.ALWAYS : this.props.displayType

    const classes = classnames('dfp-ad-slot', slotId, 'display-type--' + displayType, {
      loading,
      unfilled: empty,
    })

    const adUnitPath = this.getAdUnitPath()
    const uniqueId = this.getUniqueId()

    // add data-ad-unit and data-ad-sizes property for easier debugging via dom
    return (
      <div styleName={loading ? 'loading-parent' : ''}>
        <div styleName={classes} data-ad-unique-id={uniqueId} data-ad-sizes={JSON.stringify(sizes)}>
          {debugAds && (
            <AdDebugInfoBox {...this.props} adUnit={adUnit} uniqueId={uniqueId}/>
          )}
          <div>
            <DfpAdSlot className={['adBox', slotId].join(' ')} key={uniqueId} sizes={sizes} adUnitPath={adUnitPath}
              adUnit={adUnit} loadInstantly={loadInstantly} onSlotRender={this.handleSlotRender}
              markSlotDefined={actions.markSlotDefined} noId={noId} setTargeting={setTargeting}/>
            {debugAds && loading && this.dfpSlotTemplate('Mainosta ladataan...')}
          </div>
        </div>
        {(!!empty || adBlocker) && !!FallBackElement && <FallBackElement fallbackElement={FallBackElement2}/>}
      </div>
    )
  }

  dfpSlotTemplate (text, classes, hideOnFill) {
    const { slotId } = this.props
    return (
      <div className={classnames('adunitContainer', classes)}>
        <div className="adBox" id={slotId}/>
        <span className={hideOnFill}>{text}</span>
      </div>
    )
  }

  handleSlotRender = (renderInfo) => {
    const { event } = renderInfo
    this.asyncSetState({
      loading: false,
      empty: event.isEmpty,
    })
    this.props.handleSlotRender && this.props.handleSlotRender(event)
  }
}

export default withErrorBoundary(
  ConnectedAdSlot,
  ErrorPlaceholderBox('-'),
)

/**
 * Util  function to get info about current page ads.
 * (Should be called only after page refresh.)
 * @returns {{info: Array, byContext: Object}}
 */
window.getAdsInfo = () => {
  const ads = window.dfpAdSlotObjects
  const info = Object.keys(ads).map(key => {
    const ad = ads[key]
    const { component } = ad
    const { adUnit, sizes, appContext } = component.props
    return {
      adUnit,
      sizes,
      appContext,
    }
  })

  const byContext = info.reduce((result, ad) => {
    const contextName = ad.appContext.join('>')
    const list = result[contextName] || []
    list.push(ad.adUnit)
    result[contextName] = list
    return result
  }, {})

  return {
    info,
    byContext,
  }
}

/**
 * Print all rendered page ads grouped by ads application context.
 * (Should be called only after page refresh.)
 */
window.printAdsByContext = () => {
  const info = window.getAdsInfo()
  let result = ''
  Object.keys(info.byContext).forEach(key => {
    const contextName = appContextMap[key] || key
    result += '\n\n' + contextName
    info.byContext[key].forEach(item => {
      result += '\n' + item.replace(dfpNetworkId + '/' + containerName, '')
    })
  })

  return result
}

// Map contexts to simplified context names
const appContextMap = {
  HeaderAds: 'Header',
  'FrontPage>Sidebar': 'Upper Sidebar',
  'Article>Sidebar': 'Upper Sidebar',
  'FrontPage>IndexSidebar>Sidebar': 'Sidebar',
  'Taxonomy>IndexSidebar>Sidebar': 'Sidebar',
  'FrontPage>Newsfeed': 'Newsfeed',
  'Taxonomy>Newsfeed': 'Newsfeed',
  'FrontPage>NewsfeedHeaderAds': 'Newsfeed header',
  'Article>LatestNews': 'Latest news block',
}
