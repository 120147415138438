import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import PopularPosts from '../general/popular/PopularPosts'
import LatestMagazine from '../weeklyMagazine/LatestMagazine'
import ForumBox from '../general/forum/ForumBox'
import './Sidebar.scss'
import Shortcuts from '../widgets/Shortcuts'
import track from 'react-tracking'
import SidebarArticles from './SidebarArticles'
import some from 'lodash/some'
import MagShortCut from '../widgets/MagShortCut'
import Breakpoint from '@otavamedia/om-component-library/lib/util/Breakpoint'
import GAMNativeAdHolder from '../widgets/GAMNativeAdHolder'
import LatestPosts from '../general/popular/LatestPosts'

/**
 * This component displays a sidebar which is used on for example the front page and in flow articles.
 * With parameters, you can choose to display certain standard components (PopularPosts, LatestMagazine,
 * Shortcuts, SidebarArticles etc.). You can also add custom content to the sidebar with child components.
 */
@track({ gtmContext: ['Sidebar'] })
class Sidebar extends Component {
  loaded

  constructor (props) {
    super()
    this.loaded = {
      popular: !props.popular,
      popularPaid: !props.popularPaid,
      shortcuts: !props.shortcuts,
      articles: !props.articles,
    }
  }

  componentWillReceiveProps (props) {
    this.loaded = {
      popular: !props.popular,
      popularPaid: !props.popularPaid,
      shortcuts: !props.shortcuts,
      articles: !props.articles,
    }
  }

  doneLoading (element) {
    this.loaded[element] = true
    if (!some(Object.values(this.loaded), (x) => !x)) {
      this.props.doneLoading()
    }
  }

  render () {
    const {
      latestMag,
      popular,
      forumPosts,
      category,
      shortcuts,
      articles,
      filteredArticles,
      latestMagazine,
      shortcutArticles,
      lateShortcuts,
      popularPaid,
      nativeAds,
      hideAds,
      facts,
      historyCard,
      latest,
    } = this.props
    return (
      <Fragment>
        {latestMag && (
          <div className="latest-mag">
            {latestMagazine
              ? <LatestMagazine doneLoading={() => this.doneLoading('latestMag')}/>
              : <MagShortCut doneLoading={() => this.doneLoading('latestMag')}/>}
          </div>
        )}
        {shortcuts
          ? <Shortcuts category={category} doneLoading={() => this.doneLoading('shortcuts')}
            articles={shortcutArticles}/>
          : null
        }
        {popular
          ? <div styleName='popular'>
            <PopularPosts doneLoading={() => this.doneLoading('popular')}/>
          </div>
          : null
        }
        {latest
          ? <Breakpoint maxBreakpoint={767}>
            <LatestPosts compact/>
          </Breakpoint>
          : null
        }
        {this.props.children}
        {popularPaid && <PopularPosts paidOnly doneLoading={() => this.doneLoading('popularPaid')}/>}
        {nativeAds && !hideAds && <Breakpoint maxBreakpoint={767}>
          <GAMNativeAdHolder grow={true} type={2}/>
        </Breakpoint>}
        {lateShortcuts
          ? <Shortcuts category={category} doneLoading={() => this.doneLoading('shortcuts')}
            articles={shortcutArticles}/>
          : null
        }
        {articles
          ? <SidebarArticles doneLoading={() => this.doneLoading('articles')} hideAds={hideAds} facts={facts}
            historyCard={historyCard}/>
          : null }
        {filteredArticles
          ? <SidebarArticles filtered doneLoading={() => this.doneLoading('articles')} hideAds={hideAds} facts={facts}
            historyCard={historyCard}/>
          : null }
        {forumPosts
          ? <ForumBox/>
          : null
        }
      </Fragment>
    )
  }
}

Sidebar.propTypes = {
  children: PropTypes.any,
  latestMag: PropTypes.bool,
  shortcuts: PropTypes.bool,
  popularPaid: PropTypes.bool,
  shortcutArticles: PropTypes.array,
  lateShortcuts: PropTypes.bool,
  popular: PropTypes.bool,
  forumPosts: PropTypes.bool,
  category: PropTypes.number,
  doneLoading: PropTypes.func,
  articles: PropTypes.bool,
  articleContent: PropTypes.string,
  latestMagazine: PropTypes.bool,
  latest: PropTypes.bool,
  filteredArticles: PropTypes.bool,
  nativeAds: PropTypes.bool,
  hideAds: PropTypes.bool,
  facts: PropTypes.bool,
  historyCard: PropTypes.any,
}

export default Sidebar
