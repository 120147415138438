import localforage from 'localforage'
import XXH from 'xxhashjs'
import { current, getVersion, getVersionsBetween, currentVersion } from '../version'

// localForage store containing user data, such as auth tokens & WP.getUser()
export let userData
export let settingsData

// localForage store for caching requests from WP_Client
export let requestCache

try {
  requestCache = localforage.createInstance({
    name: 'requestCache',
  })

  userData = localforage.createInstance({
    name: 'userData',
  })

  settingsData = localforage.createInstance({
    name: 'settingsData',
  })
} catch (e) {
  console.log('Failed initalizing localforage', e)
}

/**
 * Boilerplate class around localForage.
 *
 * @param {localForageInstance} store
 */
export default class DiskStorage {
  constructor (store) {
    if (!store) {
      throw new TypeError('store is mandatory')
    }

    this.store = store
    this.driver = store.driver()

    this.get = this.get.bind(this)
    this.put = this.put.bind(this)
    this.nuked = []
  }

  /**
   * Get a hashed key from a JSON.stringifyable value to limit key length
   *
   * @param {*} key
   * @private
   */
  getKey (key) {
    return XXH.h32(JSON.stringify(key), 0xABCD).toString(16)
  }

  async maybeNuke (version) {
    const { _config } = this.store
    const { name: storeName } = _config
    let nuke = false

    if (version !== current) {
      const oldVersion = getVersion(version)

      if (!oldVersion && !nuke) {
        // Request was saved from an ancient version; so old that it doesn't exist under versions
        nuke = true
      }

      if (currentVersion.invalidateStores) {
        const currentStoreListed = currentVersion.invalidateStores.indexOf(storeName) > -1

        if (currentStoreListed) {
          nuke = true
        }
      }

      const newerVersions = [
        ...getVersionsBetween(version, current),
        currentVersion,
      ]

      // User may have saved something while in version 1, a breaking change could've happened
      // at version 2, and the next time the user visits the app, the app might be in version 4.
      const shouldInvalidate = newerVersions.some(v => {
        if (v.invalidateStores && v.invalidateStores.indexOf(storeName) > -1) {
          return true
        }

        return false
      })

      if (shouldInvalidate && !nuke) {
        nuke = true
      }
    }

    if (nuke) {
      console.log(`Nuking store ${storeName} contents`)
      await this.store.clear()
      this.nuked = [...this.nuked, storeName]

      return true
    } else {
      return false
    }
  }

  /**
   * Get stored value from store
   *
   * @param {*} key JSON.stringifyable value
   * @param {object} config Configuration object
   * @param {boolean} config.acceptExpired Get stored value even if it's expired
   *
   */
  async get (key, config = {}) {
    try {
      const generatedKey = this.getKey(key)
      const item = await this.store.getItem(generatedKey)

      if (!item) {
        return false
      }

      const data = JSON.parse(item)
      const meta = data._meta

      if (!meta) {
        return false
      }

      const { expireAfter, version } = meta

      const nuked = await this.maybeNuke(version)
      if (nuked) {
        return false
      }

      const { acceptExpired } = config
      const isExpired = Date.now() > expireAfter

      // If expired, return false, except if explicitly allowed
      return !isExpired ? data : acceptExpired ? { ...data, expired: true } : false
    } catch (e) {
      return e
    }
  }

  /**
   * Store a value on disk
   *
   * @param {*} key JSON.stringifyable value
   * @param {object} meta Metadata object
   * @param {object} meta.expireAfter Timestamp after which data is considered expired
   * @param {*} data Value to be stored
   *
   */
  async put (key, meta, data) {
    try {
      const generatedKey = this.getKey(key)
      const result = await this.store.setItem(generatedKey, JSON.stringify({
        ...data,
        _meta: {
          ...meta,
          version: current,
        },
      }))

      return result
    } catch (e) {
      return false
    }
  }
}
