import React from 'react'
import { TextBlock, RectShape } from 'react-placeholder/lib/placeholders'
import './ArticleLoader.scss'

const ArticleLoader = () => {
  return <div styleName="loader">
    <div>
      <TextBlock rows={3} color='grey' />
      <RectShape style={{ width: '100%', height: 350 }}/>
      <TextBlock rows={30} color='grey' />
    </div>
    <div>
      <RectShape style={{ width: '100%', height: 300 }}/>
      <TextBlock rows={3} color='grey' />
      <TextBlock rows={3} color='grey' />
      <TextBlock rows={3} color='grey' />
      <TextBlock rows={3} color='grey' />
      <TextBlock rows={3} color='grey' />
      <TextBlock rows={3} color='grey' />
      <TextBlock rows={3} color='grey' />
    </div>
  </div>
}

export default ArticleLoader
