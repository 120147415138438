import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { NavLink } from '../util/Links'
import { history } from '../../../store'
import './404-page.scss'

export default class Page403 extends Component {
  static propTypes = {
    setRendered: PropTypes.func,
  }

  componentDidMount () {
    this.props.setRendered(true)
  }

  componentWillUnmount () {
    this.props.setRendered(false)
  }

  render () {
    return (
      <div styleName="container">
        <Helmet>
          <title>403 | Pääsy kielletty</title>
          <meta name="http:status" content="403" />
        </Helmet>

        <p styleName="large-font">Sinulla ei näytä olevan käyttöoikeuksia tähän sivuun.</p>
        <div styleName="button-row">
          <button onClick={() => history.goBack()} styleName="nav-button">Siirry takaisin</button>
          <NavLink to={{ link: '/' }} styleName="nav-button">Palaa etusivulle</NavLink>
        </div>
      </div>
    )
  }
}
