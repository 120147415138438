import React, { Component } from 'react'
import PropTypes from 'prop-types'
import './ProductComment.scss'
import DateField from '../widgets/DateField'
import HTML from '../util/HTML'
import WP from '../../../lib/WP'
import { TmLogo } from '../../widgets/Icons'
import get from 'lodash/get'
import { connect } from 'kea'
import auth from '../../../kea/auth'
import thumbGray from '../../../assets/ui/thumb-gray.svg'
import thumb from '../../../assets/logos/thumb.svg'
import application from '../../../kea/application'

const ConnectedComponent = @connect({
  props: [
    auth, [
      'userRoles',
      'userData',
    ],
    application, [
      'view',
    ],
  ],
  actions: [
    auth, [
      'setCommenting',
    ]
  ]
}) class ProductComment extends Component {
  static propTypes = {
    comment: PropTypes.object.isRequired,
    setComment: PropTypes.func,
    setCommenting: PropTypes.func,
    quote: PropTypes.func,
    isLast: PropTypes.bool,
    isReply: PropTypes.bool,
    userRoles: PropTypes.array,
    userData: PropTypes.object,
    view: PropTypes.object,
    admins: PropTypes.array.isRequired,
    showStars: PropTypes.bool,
    showTitle: PropTypes.bool,
    showImage: PropTypes.bool,
    hideActions: PropTypes.bool
  }

  state = {
    showModal: false,
    reportReason: '',
    email: ''
  }

  constructor (props) {
    super()
    this.state.thumbs = get(props, 'comment.acf.peukut', [])
  }

  componentWillReceiveProps (props) {
    const thumbs = get(props, 'comment.acf.peukut', [])
    this.setState({ thumbs })
  }

  isAdminComment = () => {
    const { comment, admins } = this.props
    return admins.includes(comment.author) && comment.date > '2014-12'
  }

  async toggleLike () {
    if (this.props.userData) {
      const like = !!this.props.userData && !!this.state.thumbs && this.state.thumbs.includes(this.props.userData.id)
      let thumbs = this.state.thumbs || []
      if (!like) {
        thumbs.push(this.props.userData.id)
      } else {
        thumbs = thumbs.filter(x => (x !== this.props.userData.id))
      }
      this.setState({ thumbs })
      await WP.likeComment(this.props.comment, !like)
    }
  }

  quote () {
    const { comment, quote, userData } = this.props
    if (userData) {
      if (document.querySelector('#commentBox')) {
        document.querySelector('#commentBox').scrollIntoView(false)
      } else if (document.querySelector('#CustomForm_subtitle')) {
        document.querySelector('#CustomForm_subtitle').scrollIntoView(false)
      }
      quote && quote(comment)
    }
  }

  render () {
    const { comment, isLast, isReply, showStars, showImage, showTitle, userData, hideActions } = this.props
    const { thumbs } = this.state
    const image = get(comment, 'acf.kuva.url', null)
    const title = get(comment, 'acf.otsikko', '')
    const like = !!userData && !!thumbs && thumbs.includes(userData.id)
    const thumbCount = thumbs.length

    return (
      <div styleName={'main' + (isReply ? ' reply' : '')} id={isLast ? 'lastComment' : ''}>
        {showImage && image
          ? <div styleName="image">
            <img src={image} alt=""/>
          </div>
          : null}
        <div styleName="header">
          <div styleName="meta">
            <img src="https://keskustelu.tekniikanmaailma.fi/applications/dashboard/design/images/defaulticon.png"
              styleName="profile-photo" alt=""/>
            <span
              styleName={'author' + (this.isAdminComment() ? ' admin' : '')}>{comment.author_name}</span>
            <span styleName="date">
              <DateField date={comment.date}/>
            </span>
            {showStars ? <span styleName="stars">{'★'.repeat(get(comment, 'acf.tahdet') || 0)}</span> : null}
            {this.isAdminComment()
              ? <span styleName="is-admin-author"><div styleName="red-logo"><TmLogo/></div>Toimituksen kommentti</span>
              : null
            }
          </div>
        </div>
        {showTitle && title ? <div styleName="title">{comment.acf.otsikko}</div> : null}
        <div styleName="text"><HTML>{comment.content.rendered}</HTML></div>
        <div styleName="footer">
          <div styleName="like-button">
            <button onClick={() => this.toggleLike()}><img src={like ? thumb : thumbGray} alt=""/>{thumbCount}</button>
          </div>
          {hideActions
            ? null
            : <div styleName="actions">
              <button styleName="quote" onClick={ () => this.quote() }>Vastaa</button>
            </div>}
        </div>
        {comment.reply
          ? <ConnectedComponent
            key={comment.reply.id}
            comment={comment.reply}
            isLast={true}
            admins={this.props.admins}
            setComment={comment => this.props.setComment(comment)}
            isReply={true}
            hideActions={true}
          />
          : null}
      </div>
    )
  }
}

export default ConnectedComponent
