import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import Column from './Column'
import { ErrorPlaceholder, withErrorBoundary } from '../general/util/ErrorBoundaries'
import './WeeklyMagazine.scss'

class Row extends Component {
  renderColumns = row => {
    const { magazine, showDate, latest } = this.props
    switch (row.length) {
    case 1:
      return (
        <div styleName="full-width">
          <Column content={row[0]} magazine={magazine} showDate={showDate} latest={latest}/>
        </div>
      )
    case 2:
      return (
        <Fragment>
          <div styleName="half-width">
            <Column content={row[0]} magazine={magazine} showDate={showDate} latest={latest}/>
          </div>
          <div styleName="half-width">
            <Column content={row[1]} magazine={magazine} showDate={showDate} latest={latest}/>
          </div>
        </Fragment>
      )
    case 3:
      return (
        <Fragment>
          <div styleName="third-width">
            <Column content={row[0]} magazine={magazine} showDate={showDate} latest={latest}/>
          </div>
          <div styleName="third-width">
            <Column content={row[1]} magazine={magazine} showDate={showDate} latest={latest}/>
          </div>
          <div styleName="third-width">
            <Column content={row[2]} magazine={magazine} showDate={showDate} latest={latest}/>
          </div>
        </Fragment>
      )
    default:
      // Shouldn't go here
    }
  }

  render () {
    const { row } = this.props
    const classes = ['magazine-content-row']

    if (row.length === 1) {
      if (
        (['primary', 'quote'].includes(row[0].type)) ||
        (row[0].singularArticle && row[0].singularArticle.blockStyle.size === 'large')
      ) {
        classes.push('borders')
      }
    }

    return (
      <div styleName={classes.join(' ')}>
        {this.renderColumns(row)}
      </div>
    )
  }
}

Row.propTypes = {
  row: PropTypes.array.isRequired,
  magazine: PropTypes.string,
  showDate: PropTypes.bool,
  latest: PropTypes.string,
}

export default withErrorBoundary(
  Row,
  ErrorPlaceholder(null)
)
