import React, { Component, Fragment } from 'react'
import { Link } from '../general/util/Links'
import Image from '../general/util/Image'
import WP from '../../lib/WP'
import { logException } from '../../lib/errors'
import HTML from '../general/util/HTML'
import { ArrowRightRedIcon } from './Icons'
import PropTypes from 'prop-types'

import './MagShortCut.scss'
import shuffle from 'lodash/shuffle'
import flatMap from 'lodash/flatMap'
import sample from 'lodash/sample'

export default class MagShortCut extends Component {
  static propTypes = {
    isTheme: PropTypes.bool,
    className: PropTypes.string,
    doneLoading: PropTypes.func,
  }

  _mounted = false
  state = {
    magazine: null,
  }

  async componentDidMount () {
    this._mounted = true
    try {
      if (this.props.isTheme) {
        const magazines = (await WP.getThemeMagazines()).sort((a, b) => a.latest_post_date_gmt < b.latest_post_date_gmt ? 1 : -1)
        const magazine = await WP.getMagazine(sample(magazines).slug, 'thememagazine')
        const articles = magazine.toc
          .reduce((retList, section) => {
            return retList.concat(section.articles)
          }, [])
          .sort((a, b) => a.date < b.date ? 1 : -1)
          .slice(0, 1)

        this.asyncSetState({ magazine, latest: articles[0] })
      } else {
        const magazine = await WP.getLatestMagazine()
        const articles = shuffle(
          flatMap(magazine.toc, 'articles')
            .sort((a, b) => a.views < b.views ? 1 : -1)
            .slice(0, 10))
          .slice(0, 3)
        this.asyncSetState({ magazine, articles })
      }
      this.props.doneLoading && this.props.doneLoading()
    } catch (e) {
      this.props.doneLoading && this.props.doneLoading()
      logException(e)
    }
  }

  asyncSetState (...args) {
    // avoid setting state after unmount in case async operations attempts to do so
    if (this._mounted) this.setState(...args)
  }

  componentWillUnmount () {
    this._mounted = false
  }

  renderVignetteAndTopic = item => {
    if (item.isEquation) {
      return <span styleName="vignette">Puheenaihe </span>
    }

    if (item.vignette && item.topic) {
      return (
        <Fragment>
          <span styleName="vignette"><HTML>{item.vignette}</HTML> </span>
          <span><HTML>{item.topic}</HTML> &mdash; </span>
        </Fragment>
      )
    } else if (item.vignette && !item.topic) {
      return <span styleName="vignette"><HTML>{item.vignette}</HTML> </span>
    } else {
      return null
    }
  }

  render () {
    const { magazine, latest, articles } = this.state
    const { isTheme, className } = this.props
    if (!magazine || (isTheme && !latest)) {
      return null
    }

    const { coverImage } = magazine

    return (
      <div styleName={(className || '') + ' mag-shortcut'}>
        <div styleName="image-box">
          <Link to={magazine} clickLocation="image">
            <Image data={coverImage} alt={`Lehden ${magazine.name} kansi`} noHeight/>
          </Link>
        </div>
        <div styleName="toc-box">
          <h3>{isTheme ? 'Tekniikan Maailman teemalehti' : ''}</h3>
          <h2>
            <Link to={magazine} clickLocation="title">
              {isTheme ? magazine.name : 'TM ' + magazine.name}
            </Link>
          </h2>
          <ul>
            {isTheme
              ? <Fragment>
                <li>
                  <ArrowRightRedIcon/> <Link to={latest}>Uusin artikkeli: <br/><HTML>{latest.originalTitle}</HTML></Link>
                </li>
                <li>
                  <ArrowRightRedIcon/> <Link to={{ link: '/teemalehdet/' }}> Kaikki teemalehdet </Link>
                </li>
              </Fragment>
              : <Fragment>
                {articles.map((article, idx) => <li key={idx}>
                  <ArrowRightRedIcon/> <Link to={ article } clickLocation="text">
                    <HTML>{article.originalTitle}</HTML>
                  </Link>
                </li>)}
              </Fragment>
            }
          </ul>
        </div>
      </div>
    )
  }
}
