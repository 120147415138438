import get from 'lodash/get'
import ImageModel from './ImageModel'
import PrintmagModel from './PrintmagModel'
import { fixArticleUrl } from '../lib/url'
import flatMap from 'lodash/flatMap'

export default class DigimagModel {
  coverImage // ImageModel
  primaryImage
  primaryVideo
  primaryVideoMobile
  publishDate
  published
  sections
  term
  name
  slug
  toc
  nextMagPreview
  link
  printMagUrl
  legacyMaglink
  _meta
  updatedDate

  constructor (WPData) {
    try {
      this.coverImage = new ImageModel(WPData.coverImage)
      this.primaryImage = new ImageModel(get(WPData, 'sections[0].rows[0][0].primary.post.image'))
      const video = get(WPData, 'sections[0].rows[0][0].primary.post.header_video_mp4')
      const video2 = get(WPData, 'sections[0].rows[0][0].primary.post.header_video_webm')
      this.primaryVideo = video ? new ImageModel(video) : (video2 ? new ImageModel(video2) : null)

      const videom = get(WPData, 'sections[0].rows[0][0].primary.post.header_video_mobile_mp4')
      const video2m = get(WPData, 'sections[0].rows[0][0].primary.post.header_video_mobile_webm')
      this.primaryVideoMobile = videom ? new ImageModel(videom) : (video2m ? new ImageModel(video2m) : null)

      this.layoutActivated = WPData.layoutActivated
      this.publishDate = WPData.publishDate
      this.published = WPData.published
      this.updatedDate = WPData.latest_post_date_gmt
      this.name = get(WPData, 'term.name') || ''
      this.slug = get(WPData, 'term.slug') || ''
      this.legacyMaglink = WPData.legacyMaglink || ''
      try {
        const url = new URL(get(WPData, 'term.link'))
        this.link = url.pathname
      } catch (e) {
        this.link = null
      }
      this.sections = DigimagModel.parseSections(WPData.sections, this.link)

      this.term = new PrintmagModel(WPData.term)
      this.toc = DigimagModel.getWeeklyMagazineToc(WPData.sections, this.link)
      if (!this.updatedDate) {
        const latestArticles = flatMap(this.toc, 'articles').sort((a, b) => a.date < b.date ? 1 : -1)
        this.updatedDate = latestArticles && latestArticles[0] ? latestArticles[0].date : null
      }
      this.nextMagPreview = (get(WPData, 'nextIssue.highlights') || []).map(article => {
        return {
          singularArticle: {
            post: {
              ...article,
              image: article.image ? new ImageModel(article.image) : null,
            },
            blockStyle: {
              size: 'large',
            },
            effects: {
              capitalizeTitle: false,
            },
          },
          type: 'singularArticle'
        }
      })
      this.printMagUrl = WPData.legacyMaglink
      this._meta = {
        type: 'digimag',
        ...WPData._meta,
      }
    } catch (e) {
      console.error('Failed to parse digimag model')
      throw e
    }
  }

  /**
   * Get frontend link (url points to current magazine) and api link (points to actual article)
   *
   * @param link
   * @param magazineLink
   * @param postStatus
   */
  static getLinks (link, magazineLink, postStatus) {
    const originalLink = fixArticleUrl(link, postStatus) || ''
    if (originalLink.includes('preview=true')) {
      return { originalLink, shownLink: originalLink }
    }
    const shownLink = originalLink.replace(/^(\/[^/]+\/[^/]+)?\//, magazineLink)
    return { originalLink, shownLink }
  }

  static parseRelated (item, magazineLink) {
    if (Array.isArray(item.related)) {
      item.related = item.related.map(item => {
        const { post } = item
        const { originalLink, shownLink } = this.getLinks(post.link, magazineLink, post.postStatus)
        post.link = shownLink
        post.originalLink = originalLink
        return item
      })
    }
  }

  static parseSections (sections, magazineLink) {
    (sections || []).forEach((section) => {
      (section.rows || []).forEach((row) => {
        row.forEach((column) => {
          if (column[column.type]) {
            if (column.type === 'postGroup') {
              column[column.type].posts.forEach(item => {
                const link = get(item, 'post.link') || false
                if (link) {
                  const { originalLink, shownLink } = this.getLinks(link, magazineLink, item.post.postStatus)
                  item.post.link = shownLink
                  item.post.originalLink = originalLink
                }
                item.post.image = item.post.image ? new ImageModel(item.post.image) : null
                this.parseRelated(item, magazineLink)
              })
            } else if (column.type === 'primary') {
              const item = column[column.type]
              const { post } = item

              const { originalLink, shownLink } = this.getLinks(post.link, magazineLink, post.postStatus)
              post.link = shownLink
              post.originalLink = originalLink

              post.image = post.image ? new ImageModel(post.image) : null

              if (post.postStatus !== 'publish') {
                post.link = post.link.indexOf('?') ? post.link + '&preview=true' : post.link + '?preview=true'
              }
              this.parseRelated(item, magazineLink)
            } else {
              const item = column[column.type]
              const link = get(item, 'post.link') || false
              if (link) {
                const { originalLink, shownLink } = this.getLinks(link, magazineLink, item.post.postStatus)
                column[column.type].post.link = shownLink
                column[column.type].post.originalLink = originalLink
              }
              item.post.image = item.post.image ? new ImageModel(item.post.image) : null
              this.parseRelated(item, magazineLink)
            }
          }
        })
      })
    })
    return sections
  }

  static parseArticle (article, magazineLink) {
 //    const link = fixArticleUrl(article.post.link, article.post.postStatus)

    return {
      title: article.post.title,
      originalTitle: article.post.originalTitle,
      description: article.post.description || article.post.excerpt,
      link: article.post.link,
      originalLink: article.post.originalLink,
      mainCategory: article.post.categories[0],
      id: article.post.id,
      image: article.post.image,
      isEquation: article.effects ? !!article.effects.title : false,
      vignette: article.post.vignette || undefined,
      topic: article.post.topic || undefined,
      inTocSidebar: article.tocColumn,
      forSubscribers: get(article, 'post.asauth.is_paid') || false,
      date: article.post.date,
      views: article.post.views,
      modifiedDate: article.post.modifiedDate,
    }
  }

  static parseArticleAndRelated (article, magazineLink) {
    return article.related
      ? [
        DigimagModel.parseArticle(article, magazineLink),
        ...article.related.map(relatedArticle => DigimagModel.parseArticle(relatedArticle, magazineLink))
      ]
      : [
        DigimagModel.parseArticle(article, magazineLink)
      ]
  }

  static getWeeklyMagazineToc (sections, magazineLink) {
    return (sections || []).reduce((tocArticles, section) => {
      return [
        ...tocArticles,
        {
          title: section.title,
          articles: (section.rows || []).reduce((rows, row) => {
            return [
              ...rows,
              ...(row || []).reduce((columns, column) => {
                if (column.type === 'postGroup') {
                  return [
                    ...columns,
                    ...(column[column.type].posts || []).reduce((articles, article) => {
                      return typeof article === 'string' ? articles : [...articles, ...DigimagModel.parseArticleAndRelated(article, magazineLink)]
                    }, [])
                  ]
                } else {
                  return [
                    ...columns,
                    ...DigimagModel.parseArticleAndRelated(column[column.type], magazineLink)
                  ]
                }
              }, [])
            ]
          }, [])
        }
      ]
    }, [])
  }
}
