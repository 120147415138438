import React, { Component, Fragment } from 'react'
import { connect } from 'kea'
import PropTypes from 'prop-types'
import newsletter from '../../../kea/newsletter'
import { validateEmail } from '../../../validators'
import { ErrorPlaceholderBox, withErrorBoundary } from '../util/ErrorBoundaries'
import './Newsletter.scss'

@connect({
  props: [
    newsletter, [
      'isSubmitted',
      'hasFailed',
      'isLoading',
      'isNewsletterTaken'
    ],
  ],
  actions: [
    newsletter, [
      'orderNewsletter',
      'setNewsletterTaken'
    ]
  ]
})
class Newsletter extends Component {
  canShowNewsletter = false
  constructor (props) {
    super(props)

    this.state = {
      email: '',
      valid: false,
      username: '',
      list: { 85085: false, 138627: false }
    }
    if (!props.isNewsletterTaken && !props.showAlways) {
      this.canShowNewsletter = true
      props.actions.setNewsletterTaken(this)
    }
  }

  handleInput = event => {
    const input = event.currentTarget.value
    this.setState({
      email: input,
      valid: validateEmail(input) && !!(Object.keys(this.state.list).filter((key) => !!this.state.list[key]).length)
    })
  }

  handleUsernameInput = event => {
    const input = event.currentTarget.value
    this.setState({
      username: input,
    })
  }

  handleEnter = event => {
    if (event.keyCode === 13 || event.key === 'Enter') {
      this.handleSubmit()
    }
  }

  handleSubmit = () => {
    this.actions.orderNewsletter(this.state.email, this.state.username, Object.keys(this.state.list).filter((key) => !!this.state.list[key]))
  }

  componentWillUnmount () {
    if (this.canShowNewsletter) {
      this.canShowNewsletter = false
      this.actions.setNewsletterTaken(null)
    }
  }

  handleCheckboxChange (list) {
    const oldList = this.state.list
    oldList[list] = !oldList[list]
    this.setState({
      list: oldList,
      valid: validateEmail(this.state.email) && !!(Object.keys(oldList).filter((key) => !!oldList[key]).length)
    })
  }

  render () {
    const { list } = this.state
    if (!this.canShowNewsletter && !this.props.showAlways) {
      return null
    }
    return (
      <div styleName={this.props.wide ? 'container wide' : 'container'}>
        <h4 styleName="title">Tilaa Tekniikan Maailman uutiskirjeet</h4>
        <div styleName="content">
          {this.props.isSubmitted
            ? (
              <Fragment>
                <p>Kiitos! Sähköpostiosoitteesi on lisätty postituslistallemme. Saat jatkossa tuoreimmat uutiset ja tarjoukset suoraan sähköpostiisi.</p>
                <p>Kirjeen tilauksen voit perua milloin vain joka päivä uutiskirjeen lopussa olevasta linkistä.</p>
                <p>Hyviä lukuhetkiä!</p>
              </Fragment>
            )
            : (
              <Fragment>

                <p><b>Tilaa TM-uutiskirje,</b> niin pysyt ajan tasalla autoalan, teknologian ja tieteen uutisista! Saat TM-uutiskirjeen joka arkipäivä.</p>
                <p><span style={{
                  color: '#e4001e',
                  fontFamily: 'Guardian TextSans Bold',
                  fontWeight: 'bold'
                }}>UUTUUS:</span><b> TM Tiede & teknologia -uutiskirje</b> kokoaa sinulle parhaat juttumme tieteestä ja teknologiasta kerran viikossa.</p>
                {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
                <div styleName="fields" onKeyDown={(e) => e.stopPropagation()}
                  onTouchStart={(e) => e.stopPropagation()}
                  onTouchMove={(e) => e.stopPropagation()}
                  onMouseDown={(e) => e.stopPropagation()}>
                  <div styleName="boxes">
                    <label><input type="checkbox" checked={list['85085']}
                      onChange={() => this.handleCheckboxChange('85085')}/> TM-uutiskirje</label>
                    <label><input type="checkbox" checked={list['138627']}
                      onChange={() => this.handleCheckboxChange('138627')}/> TM Tiede &amp; teknologia -uutiskirje</label>
                  </div>
                  <div styleName="boxes2">
                    <input type="email" className="required" onKeyDown={this.handleEnter} value={this.state.email} placeholder="Sähköpostiosoite" onChange={this.handleInput} />
                    <input className="required" onKeyDown={this.handleEnter} name="username" placeholder="Username" onChange={this.handleUsernameInput} />
                    {this.props.isLoading
                      ? <button disabled onClick={this.handleSubmit}>Tilausta käsitellään...</button>
                      : <button disabled={!this.state.valid} onClick={this.handleSubmit}>Tilaa</button>
                    }
                  </div>
                </div>

                {this.props.hasFailed ? <div styleName="error">Tilaus epäonnistui! Yritä uudelleen myöhemmin</div> : null}
              </Fragment>
            )
          }
        </div>
      </div>
    )
  }
}

Newsletter.propTypes = {
  isSubmitted: PropTypes.bool,
  isNewsletterTaken: PropTypes.object,
  actions: PropTypes.object,
  hasFailed: PropTypes.bool,
  isLoading: PropTypes.bool,
  wide: PropTypes.bool,
  showAlways: PropTypes.bool,
}

export default withErrorBoundary(
  Newsletter,
  ErrorPlaceholderBox()
)
