import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Lightbox from 'react-image-lightbox'
import { connect } from 'kea'
import galleryLogic from '../../kea/gallery'
import 'react-image-lightbox/style.css'
import HTML from '../general/util/HTML'

import s from './Gallery.scss' // eslint-disable-line

@connect({
  actions: [
    galleryLogic, [
      'closeGallery',
      'setIndex',
    ],
  ],
  props: [
    galleryLogic, [
      'index',
      'images',
    ],
  ],
})
class Gallery extends Component {
  mounted = true
  state = {
    fullscreen: false,
  }

  static propTypes = {
    index: PropTypes.number,
    images: PropTypes.array,
    onCloseRequest: PropTypes.func,
  }

  constructor () {
    super()
    const e = document.body
    this.state.requestFullScreen = e.requestFullScreen || e.webkitRequestFullScreen || e.mozRequestFullScreen || e.msRequestFullScreen
  }

  enableFullscreen = () => {
    this.state.requestFullScreen.call(document.body)
    this.setState({ fullscreen: true })
  }

  disableFullscreen = () => {
    const e = document
    const method = e.exitFullscreen || e.webkitExitFullscreen || e.mozCancelFullScreen || e.msExitFullscreen
    const element = e.fullscreenElement || e.webkitFullscreenElement || e.mozFullscreenElement || e.msFullscreenElement

    if (element && method) {
      method.call(e)
    }
    if (this.mounted) {
      this.setState({ fullscreen: false })
    }
  }

  handleClose = () => {
    if (this.props.onCloseRequest) {
      this.props.onCloseRequest()
    } else {
      this.actions.closeGallery()
    }

    if (this.state.fullscreen) {
      this.disableFullscreen()
    }
  }

  componentDidMount () {
    this.mounted = true
  }

  componentWillUnmount () {
    this.mounted = false
  }

  render () {
    const { index, images } = this.props
    const { fullscreen, requestFullScreen } = this.state

    const prevIndex = (index + images.length - 1) % images.length
    const nextIndex = (index + 1) % images.length

    return (
      <Lightbox
        mainSrc={images[index].src}
        nextSrc={images[prevIndex].src}
        prevSrc={images[nextIndex].src}
        imageCaption={<HTML>{images[index].caption}</HTML>}
        toolbarButtons={fullscreen || !requestFullScreen
          ? null
          : [
            <button
              key="fullscreen"
              styleName="s.fullscreen"
              onClick={this.enableFullscreen}
            >
            Sovita koko näytölle
            </button>,
          ]}
        onCloseRequest={this.handleClose}
        onMovePrevRequest={() =>
          this.actions.setIndex(prevIndex)
        }
        onMoveNextRequest={() =>
          this.actions.setIndex(nextIndex)
        }
      />
    )
  }
}

export default Gallery
