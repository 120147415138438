import React from 'react'
import { TextBlock, RectShape } from 'react-placeholder/lib/placeholders'
import './BestProductLoader.scss'

const BestProductLoader = (props) => {
  const articlePlaceholders = []
  for (let i = 0; i < 12; ++i) {
    articlePlaceholders.push(
      <div key={i} styleName="item-wrapper">
        <div styleName="loader-item">
          <RectShape style={{ width: '100%', height: 220 }}/>
          <TextBlock rows={3} color='grey' />
        </div>
      </div>
    )
  }
  return (
    <div styleName="loader">
      {articlePlaceholders}
    </div>
  )
}

export default BestProductLoader
