import PropTypes from 'prop-types'
import { kea } from 'kea'
import { channel, buffers } from 'redux-saga'
import { put, take } from 'redux-saga/effects'

import WP from '../lib/WP'
import { STATUS } from '../lib/request-state'
import { addMetadata } from '../lib/transformers'
import { LOCATION_CHANGE } from 'react-router-redux'
import { stripApiHostname } from '../lib/url'
import { preventScroll, allowScroll } from '@otavamedia/om-component-library/lib/util/overlay'

const responseChannel = channel(buffers.expanding())

export default kea({
  paths: ['header'],
  actions: () => ({
    loadMenu: (name, params = {}) => ({ name, params }),
    updateStatus: status => status,
    addMenu: menus => menus,
    setSecondLevelNavStatus: isOpen => isOpen,
    setBurgerMenuOpen: isOpen => {
      if (isOpen) {
        preventScroll()
        document.body.classList.add('burger-menu-open')
      } else {
        allowScroll()
        document.body.classList.remove('burger-menu-open')
      }
      return isOpen
    },
    setWeeklyMagazineContentsMenuOpen: isOpen => {
      if (isOpen) {
        preventScroll()
        document.body.classList.add('weekly-magazine-contents-menu-open')
      } else {
        allowScroll()
        document.body.classList.remove('weekly-magazine-contents-menu-open')
      }
      return isOpen
    },
    setSearchOpen: isOpen => {
      if (isOpen) {
        preventScroll()
        document.body.classList.add('search-open')
      } else {
        allowScroll()
        document.body.classList.remove('search-open')
      }
      return isOpen
    },
  }),

  reducers: ({ actions }) => ({
    status: [STATUS.NOT_REQUESTED, PropTypes.number, { persist: false }, {
      [actions.updateStatus]: (state, payload) => payload
    }],
    menus: [[], PropTypes.array, { persist: false }, {
      [actions.addMenu]: (state, payload) => {
        const generateItem = item => {
          const {
            title: name,
            object: type,
            object_id: id,
            url,
            classes: className,
            parent,
            children,
          } = item

          const data = {
            name,
            url: stripApiHostname(url),
            className,
            children: children ? children.map(generateItem) : false,
          }

          switch (item.type) {
          case 'taxonomy': {
            Object.assign(data, addMetadata('term', { // It says taxonomy on the object but it's actually a term
              taxonomy: type,
              id,
              parent,
              rest_base: WP.getRESTBase(type),
            }))
            break
          }

          case 'custom': {
            break
          }

          case 'post_type': {
            break
          }

          default: {
            console.log('Unhandled menu item type', item.type)
          }
          }

          return data
        }

        return payload.map(generateItem)
      }
    }],

    secondLevelNavOpen: [false, PropTypes.bool, { persist: false }, {
      [actions.setSecondLevelNavStatus]: (state, payload) => payload,
    }],

    burgerMenuOpen: [false, PropTypes.bool, { persist: false }, {
      [actions.setBurgerMenuOpen]: (state, payload) => payload,
    }],

    searchOpen: [false, PropTypes.bool, { persist: false }, {
      [actions.setSearchOpen]: (state, payload) => payload,
    }],

    weeklyMagazineContentsMenuOpen: [false, PropTypes.bool, { persist: false }, {
      [actions.setWeeklyMagazineContentsMenuOpen]: (state, payload) => payload,
    }]
  }),

  start: function * () {
    while (true) {
      const action = yield take(responseChannel)
      yield put(action)
    }
  },

  takeLatest: ({ actions }) => ({
    // If this is used in application.js status is changed too early
    [actions.loadMenu]: function * ({ payload }) {
      try {
        const { name, params } = payload
        const currentStatus = yield this.get('status')
        const currentExpired = currentStatus === STATUS.EXPIRED

        if (!currentExpired) {
          // If it's expired, update it stealthily to avoid component unmount
          yield put(actions.updateStatus(STATUS.REQUESTED))
        }

        const { data, expired } = yield WP.getMenu(name, params)
        const { items } = data

        yield put(actions.addMenu(items))

        if (expired) {
          responseChannel.put(actions.updateStatus(STATUS.EXPIRED))
        } else {
          responseChannel.put(actions.updateStatus(STATUS.DONE))
        }
      } catch (e) {
        responseChannel.put(actions.updateStatus(STATUS.ERROR))
        console.log('Failed to load menu', e)
      }
    },
    [actions.setBurgerMenuOpen]: function * ({ payload }) {
      if (payload) {
        const searchOpen = yield this.get('searchOpen')
        if (searchOpen) yield put(actions.setSearchOpen(false))
      }
    },
    [actions.setSearchOpen]: function * ({ payload }) {
      if (payload) {
        const burgerMenuOpen = yield this.get('burgerMenuOpen')
        if (burgerMenuOpen) yield put(actions.setBurgerMenuOpen(false))
      }
    },
    [LOCATION_CHANGE]: function * () {
      const searchOpen = yield this.get('searchOpen')
      if (searchOpen) yield put(this.actions.setSearchOpen(false))

      const burgerMenuOpen = yield this.get('burgerMenuOpen')
      if (burgerMenuOpen) yield put(this.actions.setBurgerMenuOpen(false))
    }
  }),

  selectors: ({ selectors }) => ({
    isSecondLevelNavOpen: [
      () => [selectors.secondLevelNavOpen],
      isOpen => isOpen,
      PropTypes.bool
    ],
    ready: [
      () => [selectors.status],
      (status) => status === STATUS.DONE || status === STATUS.EXPIRED,
      PropTypes.bool,
    ],
    isLoading: [
      () => [selectors.status],
      status => status === STATUS.REQUESTED,
      PropTypes.bool,
    ]
  })
})
