import React from 'react'
import { TextBlock, RectShape } from 'react-placeholder/lib/placeholders'
import './ArchiveHighlightsLoader.scss'

const ArchiveHighlightsLoader = () => {
  const articlePlaceholders = []
  for (let i = 0; i < 3; ++i) {
    articlePlaceholders.push(
      <div styleName="loader" key={i}>
        <div styleName="header"/>
        <div styleName="content">
          <TextBlock rows={3} color='grey' />
          <RectShape style={{ width: '50%', height: 200 }}/>
        </div>
      </div>
    )
  }
  return (
    <div styleName="loader-container">
      {articlePlaceholders}
    </div>
  )
}

export default ArchiveHighlightsLoader
