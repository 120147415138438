import React from 'react'
import { TextBlock, RectShape } from 'react-placeholder/lib/placeholders'
import './NewsfeedLoader.scss'

const NewsfeedLoader = () => {
  const articlePlaceholders = []
  for (let i = 0; i < 10; ++i) {
    articlePlaceholders.push(
      <div styleName="loader" key={i}>
        <RectShape style={{ width: 240, height: 166 }}/>
        <TextBlock rows={3} color='grey' />
      </div>
    )
  }
  return articlePlaceholders
}

export default NewsfeedLoader
