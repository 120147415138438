import React, { Component } from 'react'
import PropTypes from 'prop-types'
import HTML from '../util/HTML'
import { ErrorPlaceholder, withErrorBoundary } from '../util/ErrorBoundaries'
import './Accordion.scss'

class Accordion extends Component {
  constructor () {
    super()
    this.state = {
      openStatus: {},
    }
  }

  handleItemClick = id => () => {
    const { openStatus } = this.state
    openStatus[id] = !openStatus[id]
    this.setState({
      openStatus,
    })
  }

  render () {
    const { details } = this.props
    const { openStatus } = this.state

    return !!details && (
      <ul styleName="accordion">
        {details.map((section, idx) => (
          <li key={idx} styleName={openStatus[idx] ? 'open' : ''}>
            <div
              styleName="head"
              onClick={this.handleItemClick(idx)}
              onKeyPress={this.handleItemClick(idx)}
              role="button"
              tabIndex="0"
            >
              <h2><HTML>{section.summary}</HTML></h2>
              <i><span>+</span></i>
            </div>
            <div styleName="content">
              {
                section.columns && section.columns.length
                  ? section.columns.map((column, idx) => <div key={idx}><HTML>{column.content}</HTML></div>)
                  : null
              }
            </div>
          </li>
        ))}
      </ul>
    )
  }
}

Accordion.propTypes = {
  details: PropTypes.array,
}

export default withErrorBoundary(
  Accordion,
  ErrorPlaceholder(null)
)
