import PropTypes from 'prop-types'
import { kea } from 'kea'
import { put } from 'redux-saga/effects'
import WP from '../lib/WP'
import application from './application'
import { STATUS } from '../lib/request-state'
import flatten from 'lodash/flatten'

export default kea({
  paths: ['magazines'],
  actions: () => ({
    getMagazines: () => {},
    loadMagazine: (pathname) => ({ pathname }),
    storeMagazines: magazines => magazines,
    updateStatus: status => status,
    storeContents: magazine => magazine,
  }),

  reducers: ({ actions }) => ({
    status: [STATUS.NOT_REQUESTED, PropTypes.number, {}, {
      [actions.updateStatus]: (state, payload) => payload,
    }],
    magazines: [{}, PropTypes.object, {}, {
      [actions.storeMagazines]: (state, payload) => payload,
    }],
    contents: [{}, PropTypes.object, {}, {
      [actions.storeContents]: (state, payload) => ({ ...payload }),
    }]
  }),

  takeLatest: ({ actions, workers }) => ({
    [actions.loadMagazine]: function * (action) {
      try {
        if (!action.payload.pathname) {
          yield put(actions.storeContents({ noMagazine: true }))
          return
        }
        yield put(this.actions.updateStatus(STATUS.REQUESTED))

        const { pathname } = action.payload
        const endpoint = pathname.match(/\/lehti\/[^/]+/)
          ? 'digimag'
          : (
            pathname.match(/\/teemalehti\/[^/]+/)
              ? 'thememagazine'
              : (
                pathname.match(/\/mainoslehti\/[^/]+/) ? 'admagazine' : null
              )
          )
        if (!endpoint) {
          yield put(actions.storeContents({ noMagazine: true }))
          return
        }
        const number = pathname.match(/\/[^/]*lehti\/([^/]+)/)[1]

        const navigation = yield WP.getMagazineNavigation(number, endpoint)
        yield put(this.actions.storeMagazines({
          previous: navigation.previousFive,
          next: navigation.nextFive,
        }))
        const magazine = yield WP.getMagazine(number, endpoint)

        if (pathname.match(/\/[^/]*lehti\/([^/]+)\/?$/)) {
        // if (action.payload.path === pathCondition) {
          // the URL consists of three parts if splitting by / produces more than 4 items to an array ["", "lehti", "28a-2018", "article-title", "" ]
          // in that case we don't set the view data to contain magazine configurations
          // yield put(application.actions.setViewData(magazine))
        }

        yield put(actions.updateStatus(STATUS.DONE))
        yield put(actions.storeContents(magazine))
      } catch (e) {
        // Uh. Yeah. Refactor Resolver to a service that also handles Digilehti.
        // Resolver handles singular articles but it probably should also handle
        // Digilehti magazines ("printmag" WP taxonomy terms).
        yield put(application.actions.setViewData({ error: e }))
        yield put(actions.updateStatus(STATUS.ERROR))
      }
    }
  }),

  selectors: ({ selectors }) => ({
    allMagazines: [
      () => [selectors.magazines],
      magazines => magazines,
      PropTypes.array,
    ],
    previousMagazines: [
      () => [selectors.magazines],
      magazines => magazines.previous,
      PropTypes.array,
    ],
    nextMagazines: [
      () => [selectors.magazines],
      magazines => magazines.next,
      PropTypes.array,
    ],
    latestMagazine: [
      () => [selectors.magazines],
      magazines => magazines.length ? magazines[0] : undefined,
      PropTypes.object,
    ],
    isLoading: [
      () => [selectors.status],
      status => status === STATUS.REQUESTED,
      PropTypes.bool,
    ],
    contents: [
      () => [selectors.contents],
      contents => contents,
      PropTypes.object,
    ],
    status: [
      () => [selectors.status],
      status => status,
      PropTypes.number,
    ],
    allArticles: [
      () => [selectors.contents],
      (contents) => {
        if (!contents.toc) {
          return 0
        }

        return flatten(contents.toc.reduce((acc, section) => {
          acc.push(section.articles)

          return acc
        }, []))
      },
      PropTypes.array,
    ],
    articleCount: [
      () => [selectors.allArticles],
      (allArticles) => {
        return allArticles.length
      },
      PropTypes.number,
    ],
    currentArticleNumber: [
      () => [
        selectors.contents,
        selectors.allArticles,
        application.selectors.view,
      ],
      (contents, allArticles, view) => {
        if (!contents.toc || !view.link) {
          return 0
        }

        return allArticles.findIndex(article => {
          return article.link === view.link || article.originalLink === view.link
        })
      },
      PropTypes.number,
    ],
    previousArticle: [
      () => [
        selectors.allArticles,
        selectors.currentArticleNumber,
      ],
      (allArticles, currentArticleNumber) => {
        if (currentArticleNumber < 1) { // No previous article
          return {}
        }

        const article = allArticles[currentArticleNumber - 1]
        return article || {}
      },
      PropTypes.object,
    ],
    nextArticle: [
      () => [
        selectors.allArticles,
        selectors.articleCount,
        selectors.currentArticleNumber,
        application.selectors.view,
      ],
      (allArticles, count, currentArticleNumber, view) => {
        return (currentArticleNumber < count && currentArticleNumber > -1) ? allArticles[currentArticleNumber + 1] : {}
      },
      PropTypes.object,
    ],
    firstArticle: [
      () => [selectors.contents, selectors.allArticles],
      (contents, allArticles) => {
        if (!allArticles || !allArticles.length) {
          return {}
        }
        return allArticles[0]
      },
      PropTypes.object,
    ],
    lastArticle: [
      () => [selectors.contents, selectors.allArticles],
      (contents, allArticles) => {
        if (!allArticles || !allArticles.length) {
          return {}
        }
        return allArticles[allArticles.length - 1]
      },
      PropTypes.object,
    ],
    getCurrentArticleTitle: [
      () => [selectors.contents, application.selectors.view],
      (contents, view) => {
        if (!contents.toc) {
          return contents.noMagazine ? '' : undefined
        }
        const linkPart = view.link.split('/').filter(x => x).pop()
        const currentArticle = contents.toc.reduce((result, section, sectionIndex) => {
          const article = section.articles.find(article =>
            article instanceof Array
              ? article.find(article2 => article2.link.indexOf(linkPart) !== -1)
              : article.link && article.link.indexOf(linkPart) !== -1)
          return article || result
        }, undefined)

        return currentArticle ? currentArticle.title : ''
      },
      PropTypes.string,
    ]
  })
})
