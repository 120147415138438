export const optimizeExperiments = {
  paywallHeight: {
    development: 'KvhxYjb_RzqUITCQxpNGlw',
    production: 'cALooEiyToeCUKl214S1-Q'
  },
  paywallStyle: {
    development: '-u-PZJ06QrqiTAGzH1Lm-A',
    production: 'ZhwJW_fRSm-G0Dx0g9pM7w'
  },
  mostReadPaid: {
    development: 'mgyzx7vSTXGpZV2F6yQ1pw',
    production: 'tkBGuRZgTd2lDb517KHB1g'
  }
}

// This links author names to author IDs, so we can look up author and their posts when the url
// is like "/toimittaja/mirva-heiskanen"
export const authors = {
  'aimo niemi': 62,
  'aleksi herttua': 153353,
  'ari hirvonen': 119400,
  'harri domonyi': 14,
  'harri slip': 237,
  'heikki parviainen': 41, // 136
  'ilkka herttua': 81,
  'ilpo salonen': 102575,
  'ismo virta': 138700,
  'jan enqvist': 186318,
  'jari kohonen': 153352,
  'jari kujala': 48479,
  'jari lammassaari': 171227,
  'jari pitkäjärvi': 74,
  'joni nikkola': 186176,
  'joonas gustavsson': 116031,
  'jukka antila': 5,
  'jukka nortio': 134467,
  'jussa nieminen': 7, // 4532
  'kalevi rantanen': 33,
  'kirsti herttua': 49,
  'kristiina kuisma': 132728, // 82191
  'kyösti isosaari': 23,
  'lauri ahtiainen': 164944,
  'marko mäkinen': 64,
  'markus kasesmaa': 177, // 109
  'martti merilinna': 58,
  'matti virtanen': 153466,
  'mauri kaarre': 119754,
  'mika hämäläinen': 153354,
  'mirva heiskanen': 174344,
  // 'niko rinta': 26616,
  'olli laitinen': 133868,
  'osku kannisto': 174286,
  'pekka aromaa': 67,
  'petja partanen': 186317,
  'raimo ylönen': 42,
  'rasmus hakala': 153463,
  'reijo ruokanen': 119766,
  'risto niska': 28,
  'robert koistinen': 50,
  'saga wiklund': 132152,
  'sami rainisto': 121164,
  'samuli herttua': 174,
  'tero kiira': 20,
  'tomi heinvuo': 11,
  'toni jalovaara': 180,
  'veikka niemi': 124414,
  'velimatti honkanen': 6,
  'vesa ylä-jääski': 46,
  'vesa ylä jääski': 46,
  'heikki manninen': 143865,
  'mirva kakko': 133873,
  'olavi lempinen': 10,
  'jari mäkinen': 4,
  'axa sorjanen': 143481,
}
// This links commentator (kolumnisti) IDs to author IDs
export const commentators = {
  121164: 8,
  102575: 13,
  119754: 14,
  62: 17,
  81: 18,
  177: 19,
  138700: 21,
  20: 22,
  1903: 23,
  144987: 24,
  132728: 25,
  116031: 26,
  7: 12,
  119766: 10,
  6: 11,
  119400: 20,
  174286: 32,
  174344: 33,
  171227: 31,
  186176: 35,
  186318: 37,
  186317: 36,
  124414: 38
  // 26616: 34
  /*
    'Elina Hiltunen': 0,
    'Esko Valtaoja': 1,
    'Timo Tervo': 2,
    'Jaakko Suominen': 3,
    'Taavi Soininvaara': 4,
    'Kalevi Ekman': 5,
    'Tuuli Kaskinen': 6,
    'Yrjö Neuvo': 7,
    'Olli Koivusalo': 9,
    'Juho Huttula': 15,
    'Jarmo Vainio': 16,
    'Ari Hirvonen': 20, // 119400
  */
}

export const indexLayout = {
  highlights: {
    stickyCount: 1,
    boxes: [
      {
        boxSize: 'large',
        items: [
          {
            size: 'lg',
            contentType: 'sticky',
            showRelated: true
          }
        ]
      },
      {
        boxSize: 'small',
        items: [
          {
            size: 'md',
            contentType: 'highlight'
          },
          {
            size: 'md',
            contentType: 'highlight'
          }
        ]
      },
      {
        boxSize: 'secondary',
        items: [
          {
            size: 'auto',
            contentType: 'highlight',
            noImage: true
          },
          {
            size: 'auto',
            contentType: 'highlight',
            noImage: true
          },
          {
            size: 'auto',
            contentType: 'highlight',
            noImage: true
          }

        ]
      },
    ],
  },
  newsFeed: {
    stickyCount: 4,
    postCount: 20,
    loadMore: true,
    boxes: [
      {
        boxSize: 'medium-col',
        items: [
          {
            size: 'md',
            contentType: 'newsItem',
            showRelated: true,
          },
        ]
      },
      {
        boxSize: 'medium-col',
        items: [
          {
            size: 'md',
            contentType: 'newsItem',
            showRelated: true,
          },
        ]
      },
      {
        boxSize: 'x-large-col',
        items: [
          {
            size: 'lg',
            contentType: 'sticky',
            showRelated: true
          },
        ]
      },
      {
        boxSize: 'x-large-col',
        items: [
          {
            size: 'xs',
            contentType: 'newsItem',
            showRelated: true,
          },
          {
            size: 'xs',
            contentType: 'ad',
          },
        ]
      },
      {
        boxSize: 'x-large-col',
        items: [
          {
            size: 'lg',
            contentType: 'magazine',
            page: 1,
            isTheme: true
          },
        ]
      },
      {
        boxSize: 'medium-col',
        items: [
          {
            size: 'md',
            contentType: 'newsItem',
            showRelated: true,
          },
        ]
      },
      {
        boxSize: 'medium-col',
        items: [
          {
            size: 'md',
            contentType: 'newsItem',
            showRelated: true,
          },
        ]
      },
      {
        boxSize: 'x-large-col',
        items: [
          {
            size: 'lg',
            contentType: 'ad',
          },
        ]
      },
      {
        boxSize: 'medium-col',
        items: [
          {
            size: 'md',
            contentType: 'newsItem',
            showRelated: true,
          },
        ]
      },
      {
        boxSize: 'medium-col',
        items: [
          {
            size: 'md',
            contentType: 'newsItem',
            showRelated: true,
          },
        ]
      },
      {
        boxSize: 'x-large-col',
        items: [
          {
            size: 'xs',
            contentType: 'newsItem',
            showRelated: true,
          },
        ]
      },
      {
        boxSize: 'medium-col',
        items: [
          {
            size: 'md',
            contentType: 'newsItem',
            showRelated: true,
          },
        ]
      },
      {
        boxSize: 'medium-col',
        items: [
          {
            size: 'md',
            contentType: 'newsItem',
          },
        ]
      },
      {
        boxSize: 'x-large-col',
        items: [
          {
            size: 'lg',
            contentType: 'sticky',
            showRelated: true,
          },
        ]
      },
      {
        boxSize: 'x-large-col',
        items: [
          {
            size: 'xs',
            contentType: 'newsItem',
            showRelated: true,
          },
        ]
      },
      {
        boxSize: 'x-large-col',
        items: [
          {
            size: 'lg',
            contentType: 'ad',
          },
        ]
      },
      {
        boxSize: 'medium-col',
        items: [
          {
            size: 'md',
            contentType: 'newsItem',
            showRelated: true,
          },
        ]
      },
      {
        boxSize: 'medium-col',
        items: [
          {
            size: 'md',
            contentType: 'newsItem',
            showRelated: true,
          },
        ]
      },
      {
        boxSize: 'x-large-col',
        items: [
          {
            size: 'lg',
            contentType: 'sticky',
            showRelated: true
          },
        ]
      },
      {
        boxSize: 'x-large-col',
        items: [
          {
            size: 'xs',
            contentType: 'newsItem',
            showRelated: true,
          },
          {
            size: 'xs',
            contentType: 'ad',
          },
        ]
      },
      {
        boxSize: 'x-large-col',
        items: [
          {
            size: 'lg',
            contentType: 'magazine',
            page: 1,
            isTheme: true
          },
        ]
      },
      {
        boxSize: 'medium-col',
        items: [
          {
            size: 'md',
            contentType: 'newsItem',
            showRelated: true,
          },
        ]
      },
      {
        boxSize: 'medium-col',
        items: [
          {
            size: 'md',
            contentType: 'newsItem',
            showRelated: true,
          },
        ]
      },
      {
        boxSize: 'x-large-col',
        items: [
          {
            size: 'lg',
            contentType: 'ad',
          },
        ]
      },
      {
        boxSize: 'medium-col',
        items: [
          {
            size: 'md',
            contentType: 'newsItem',
            showRelated: true,
          },
        ]
      },
      {
        boxSize: 'medium-col',
        items: [
          {
            size: 'md',
            contentType: 'newsItem',
            showRelated: true,
          },
        ]
      },
      {
        boxSize: 'x-large-col',
        items: [
          {
            size: 'xs',
            contentType: 'newsItem',
            showRelated: true,
          },
        ]
      },
      {
        boxSize: 'medium-col',
        items: [
          {
            size: 'md',
            contentType: 'newsItem',
            showRelated: true,
          },
        ]
      },
      {
        boxSize: 'medium-col',
        items: [
          {
            size: 'md',
            contentType: 'ad',
          },
        ]
      },
      {
        boxSize: 'x-large-col',
        items: [
          {
            size: 'lg',
            contentType: 'sticky',
            showRelated: true,
          },
        ]
      },
      {
        boxSize: 'x-large-col',
        items: [
          {
            size: 'xs',
            contentType: 'newsItem',
            showRelated: true,
          },
        ]
      },
      {
        boxSize: 'medium-col',
        items: [
          {
            size: 'md',
            contentType: 'ad',
          },
        ]
      },
      {
        boxSize: 'medium-col',
        items: [
          {
            size: 'md',
            contentType: 'ad',
          }
        ]
      },
    ]
  }
}

export const archiveLayout = {
  highlights: {
    stickyCount: 1,
    boxes: [
      {
        boxSize: 'large',
        items: [
          {
            size: 'lg',
            contentType: 'sticky',
            showRelated: true
          }
        ]
      },
      {
        boxSize: 'small',
        items: [
          {
            size: 'md',
            contentType: 'highlight',
            showRelated: false
          },
          {
            size: 'md',
            contentType: 'highlight',
            showRelated: false
          }
        ]
      },
      {
        boxSize: 'secondary',
        items: [
          {
            size: 'auto',
            contentType: 'highlight',
            noImage: true,
            showRelated: false
          },
          {
            size: 'auto',
            contentType: 'highlight',
            noImage: true,
            showRelated: false
          },
          {
            size: 'auto',
            contentType: 'highlight',
            noImage: true,
            showRelated: false
          }

        ]
      },
    ],
  },
  newsFeed: {
    stickyCount: 2,
    postCount: 10,
    loadMore: true,
    boxes: [
      {
        boxSize: 'x-large-col',
        items: [
          {
            size: 'lg',
            contentType: 'newsItem',
            showRelated: true
          }
        ]
      },
      {
        boxSize: 'medium-col',
        items: [
          {
            size: 'md',
            contentType: 'newsItem',
            showRelated: true,
          },
        ]
      },
      {
        boxSize: 'medium-col',
        items: [
          {
            size: 'md',
            contentType: 'newsItem',
            showRelated: true,
          },
        ]
      },
      {
        boxSize: 'x-large-col',
        items: [
          {
            size: 'lg',
            contentType: 'sticky',
            showRelated: true
          },
        ]
      },
      {
        boxSize: 'x-large-col',
        items: [
          {
            size: 'xs',
            contentType: 'newsItem',
            showRelated: true,
          },
          {
            size: 'xs',
            contentType: 'ad',
          },
        ]
      },
      {
        boxSize: 'medium-col',
        items: [
          {
            size: 'md',
            contentType: 'newsItem',
            showRelated: true,
          },
        ]
      },
      {
        boxSize: 'medium-col',
        items: [
          {
            size: 'md',
            contentType: 'newsItem',
            showRelated: true,
          },
        ]
      },
      {
        boxSize: 'x-large-col',
        items: [
          {
            size: 'lg',
            contentType: 'ad',
          },
        ]
      },
      {
        boxSize: 'medium-col',
        items: [
          {
            size: 'md',
            contentType: 'newsItem',
            showRelated: true,
          },
        ]
      },
      {
        boxSize: 'medium-col',
        items: [
          {
            size: 'md',
            contentType: 'newsItem',
            showRelated: true,
          },
        ]
      },
      {
        boxSize: 'x-large-col',
        items: [
          {
            size: 'xs',
            contentType: 'newsItem',
            showRelated: true,
          },
        ]
      },
      {
        boxSize: 'medium-col',
        items: [
          {
            size: 'md',
            contentType: 'newsItem',
            showRelated: true,
          },
        ]
      },
      {
        boxSize: 'medium-col',
        items: [
          {
            size: 'md',
            contentType: 'nativeAd',
          },
        ]
      },
      {
        boxSize: 'x-large-col',
        items: [
          {
            size: 'lg',
            contentType: 'sticky',
            showRelated: true,
          },
        ]
      },
      {
        boxSize: 'medium-col',
        items: [
          {
            size: 'md',
            contentType: 'ad',
          },
        ]
      },
      {
        boxSize: 'medium-col',
        items: [
          {
            size: 'md',
            contentType: 'ad',
          },
        ]
      },
    ]
  }
}

export const articleLayout = {
  stickyCount: 0,
  digimagCount: 1,
  postCount: 4,
  loadMore: true,
  boxes: [
    {
      boxSize: 'x-large-col',
      items: [
        {
          size: 'xs',
          contentType: 'newsItem',
          showRelated: false
        },
        {
          size: 'lg',
          contentType: 'digimagItem',
          showRelated: false
        },
        {
          size: 'xs',
          contentType: 'ad',
        },
        {
          size: 'xs',
          contentType: 'magazine',
          page: 1,
          className: 'desktop',
          isTheme: true
        },
        {
          size: 'xs',
          contentType: 'newsItem',
          showRelated: false
        },
        {
          size: 'xs',
          contentType: 'newsItem',
          showRelated: false
        },
        {
          size: 'xs',
          contentType: 'magazine',
          page: 2,
          className: 'mobile',
          isTheme: true
        },
      ]
    },
  ]
}

export const SPECIAL_NUMBERS = [
  {
    html: 'Suomi 100',
    name: '18E/2016 näköislehti',
    meta: {
      content_format: 'print_replica'
    },
    cover_image: {
      urls: {
        thumbnail: 'https://wp.tekniikanmaailma.fi/wp-content/uploads/asteikko_issues/issue1381/page701243/fe34b5b7efbd6b81a518ce508e55382b-150x150.jpg',
        small: 'https://wp.tekniikanmaailma.fi/wp-content/uploads/asteikko_issues/issue1381/page701243/fe34b5b7efbd6b81a518ce508e55382b-233x300.jpg',
        medium: 'https://wp.tekniikanmaailma.fi/wp-content/uploads/asteikko_issues/issue1381/page701243/fe34b5b7efbd6b81a518ce508e55382b-543x700.jpg',
        large: 'https://wp.tekniikanmaailma.fi/wp-content/uploads/asteikko_issues/issue1381/page701243/fe34b5b7efbd6b81a518ce508e55382b-930x1200.jpg',
        zoom: 'https://wp.tekniikanmaailma.fi/wp-content/uploads/asteikko_issues/issue1381/page701243/fe34b5b7efbd6b81a518ce508e55382b.jpg',
        full: 'https://wp.tekniikanmaailma.fi/wp-content/uploads/asteikko_issues/issue1381/page701243/fe34b5b7efbd6b81a518ce508e55382b.jpg'
      },
      timestamp: 1475636400
    },
    slug: '18e-2016',
  },
  {
    html: 'Älykäs koti',
    name: '18/2015 näköislehti',
    meta: {
      content_format: 'print_replica'
    },
    cover_image: {
      urls: {
        thumbnail: 'https://wp.tekniikanmaailma.fi/wp-content/uploads/asteikko_issues/issue1290/page468004/b425d63d29f9dc4c19359e96b61fdfb0-150x150.jpg',
        small: 'https://wp.tekniikanmaailma.fi/wp-content/uploads/asteikko_issues/issue1290/page468004/b425d63d29f9dc4c19359e96b61fdfb0-233x300.jpg',
        medium: 'https://wp.tekniikanmaailma.fi/wp-content/uploads/asteikko_issues/issue1290/page468004/b425d63d29f9dc4c19359e96b61fdfb0-543x700.jpg',
        large: 'https://wp.tekniikanmaailma.fi/wp-content/uploads/asteikko_issues/issue1290/page468004/b425d63d29f9dc4c19359e96b61fdfb0-930x1200.jpg',
        zoom: 'https://wp.tekniikanmaailma.fi/wp-content/uploads/asteikko_issues/issue1290/page468004/b425d63d29f9dc4c19359e96b61fdfb0.jpg',
        full: 'https://wp.tekniikanmaailma.fi/wp-content/uploads/asteikko_issues/issue1290/page468004/b425d63d29f9dc4c19359e96b61fdfb0.jpg'
      },
      timestamp: 1443582000
    },
    slug: '18-2015',
  },
  {
    html: 'TM 60 vuotta',
    name: '10/2013 näköislehti',
    meta: {
      content_format: 'print_replica'
    },
    cover_image: {
      urls: {
        thumbnail: 'https://wp.tekniikanmaailma.fi/wp-content/uploads/asteikko_issues/issue116/page304242/2cf10a94954c6f834a1593ee55e1bc4e-150x150.jpg',
        small: 'https://wp.tekniikanmaailma.fi/wp-content/uploads/asteikko_issues/issue116/page304242/2cf10a94954c6f834a1593ee55e1bc4e-232x300.jpg',
        medium: 'https://wp.tekniikanmaailma.fi/wp-content/uploads/asteikko_issues/issue116/page304242/2cf10a94954c6f834a1593ee55e1bc4e.jpg',
        large: 'https://wp.tekniikanmaailma.fi/wp-content/uploads/asteikko_issues/issue116/page304242/2cf10a94954c6f834a1593ee55e1bc4e.jpg',
        zoom: 'https://wp.tekniikanmaailma.fi/wp-content/uploads/asteikko_issues/issue116/page304242/2cf10a94954c6f834a1593ee55e1bc4e.jpg',
        full: 'https://wp.tekniikanmaailma.fi/wp-content/uploads/asteikko_issues/issue116/page304242/2cf10a94954c6f834a1593ee55e1bc4e.jpg'
      },
      timestamp: 1370811600
    },
    slug: '10-2013',
  },
  {
    html: 'Kodin energia',
    name: '18/2012 näköislehti',
    meta: {
      content_format: 'print_replica'
    },
    cover_image: {
      urls: {
        thumbnail: 'https://wp.tekniikanmaailma.fi/wp-content/uploads/asteikko_issues/issue128/page307400/a3ec4e13b6e8d5de8ee623a28779fb13-150x150.jpg',
        small: 'https://wp.tekniikanmaailma.fi/wp-content/uploads/asteikko_issues/issue128/page307400/a3ec4e13b6e8d5de8ee623a28779fb13-232x300.jpg',
        medium: 'https://wp.tekniikanmaailma.fi/wp-content/uploads/asteikko_issues/issue128/page307400/a3ec4e13b6e8d5de8ee623a28779fb13.jpg',
        large: 'https://wp.tekniikanmaailma.fi/wp-content/uploads/asteikko_issues/issue128/page307400/a3ec4e13b6e8d5de8ee623a28779fb13.jpg',
        zoom: 'https://wp.tekniikanmaailma.fi/wp-content/uploads/asteikko_issues/issue128/page307400/a3ec4e13b6e8d5de8ee623a28779fb13.jpg',
        full: 'https://wp.tekniikanmaailma.fi/wp-content/uploads/asteikko_issues/issue128/page307400/a3ec4e13b6e8d5de8ee623a28779fb13.jpg'
      },
      timestamp: 1350507600
    },
    slug: '18-2012',
  },
  {
    html: 'Kodin energia',
    name: '18E/2011 näköislehti',
    meta: {
      content_format: 'print_replica'
    },
    cover_image: {
      urls: {
        thumbnail: 'https://wp.tekniikanmaailma.fi/wp-content/uploads/asteikko_issues/issue1281/page463543/588c0e4d54a8e3bff4e664efe40ba2e5-150x150.jpg',
        small: 'https://wp.tekniikanmaailma.fi/wp-content/uploads/asteikko_issues/issue1281/page463543/588c0e4d54a8e3bff4e664efe40ba2e5-233x300.jpg',
        medium: 'https://wp.tekniikanmaailma.fi/wp-content/uploads/asteikko_issues/issue1281/page463543/588c0e4d54a8e3bff4e664efe40ba2e5.jpg',
        large: 'https://wp.tekniikanmaailma.fi/wp-content/uploads/asteikko_issues/issue1281/page463543/588c0e4d54a8e3bff4e664efe40ba2e5.jpg',
        zoom: 'https://wp.tekniikanmaailma.fi/wp-content/uploads/asteikko_issues/issue1281/page463543/588c0e4d54a8e3bff4e664efe40ba2e5.jpg',
        full: 'https://wp.tekniikanmaailma.fi/wp-content/uploads/asteikko_issues/issue1281/page463543/588c0e4d54a8e3bff4e664efe40ba2e5.jpg'
      },
      timestamp: 1317416400
    },
    slug: '18e-2011',
  },
  {
    html: 'TM-digiopas',
    name: '08E/2007 näköislehti',
    meta: {
      content_format: 'print_replica'
    },
    cover_image: {
      urls: {
        thumbnail: 'https://wp.tekniikanmaailma.fi/wp-content/uploads/asteikko_issues/issue1284/page463903/2ba87a73cf868baa8eeeaaffc0204405-150x150.jpg',
        small: 'https://wp.tekniikanmaailma.fi/wp-content/uploads/asteikko_issues/issue1284/page463903/2ba87a73cf868baa8eeeaaffc0204405-233x300.jpg',
        medium: 'https://wp.tekniikanmaailma.fi/wp-content/uploads/asteikko_issues/issue1284/page463903/2ba87a73cf868baa8eeeaaffc0204405.jpg',
        large: 'https://wp.tekniikanmaailma.fi/wp-content/uploads/asteikko_issues/issue1284/page463903/2ba87a73cf868baa8eeeaaffc0204405.jpg',
        zoom: 'https://wp.tekniikanmaailma.fi/wp-content/uploads/asteikko_issues/issue1284/page463903/2ba87a73cf868baa8eeeaaffc0204405.jpg',
        full: 'https://wp.tekniikanmaailma.fi/wp-content/uploads/asteikko_issues/issue1284/page463903/2ba87a73cf868baa8eeeaaffc0204405.jpg'
      },
      timestamp: 1177966800
    },
    slug: '08e-2007',
  },
  {
    html: 'TM 50 vuotta',
    name: '07/2003 näköislehti',
    meta: {
      content_format: 'print_replica'
    },
    cover_image: {
      urls: {
        thumbnail: 'https://wp.tekniikanmaailma.fi/wp-content/uploads/asteikko_issues/issue301/page362478/62d6ed36b559f06ef579acd45f7c4f74-150x150.jpg',
        small: 'https://wp.tekniikanmaailma.fi/wp-content/uploads/asteikko_issues/issue301/page362478/62d6ed36b559f06ef579acd45f7c4f74-232x300.jpg',
        medium: 'https://wp.tekniikanmaailma.fi/wp-content/uploads/asteikko_issues/issue301/page362478/62d6ed36b559f06ef579acd45f7c4f74.jpg',
        large: 'https://wp.tekniikanmaailma.fi/wp-content/uploads/asteikko_issues/issue301/page362478/62d6ed36b559f06ef579acd45f7c4f74.jpg',
        zoom: 'https://wp.tekniikanmaailma.fi/wp-content/uploads/asteikko_issues/issue301/page362478/62d6ed36b559f06ef579acd45f7c4f74.jpg',
        full: 'https://wp.tekniikanmaailma.fi/wp-content/uploads/asteikko_issues/issue301/page362478/62d6ed36b559f06ef579acd45f7c4f74.jpg'
      },
      timestamp: 1049662800
    },
    slug: '07-2003',
  },
  {
    html: 'Autovuosi 2003',
    name: '18E/2002 näköislehti',
    meta: {
      content_format: 'print_replica'
    },
    cover_image: {
      urls: {
        thumbnail: 'https://wp.tekniikanmaailma.fi/wp-content/uploads/asteikko_issues/issue1384/page708252/1d037a096872334c7b99e4775f9ff09a-150x150.jpg',
        small: 'https://wp.tekniikanmaailma.fi/wp-content/uploads/asteikko_issues/issue1384/page708252/1d037a096872334c7b99e4775f9ff09a-228x300.jpg',
        medium: 'https://wp.tekniikanmaailma.fi/wp-content/uploads/asteikko_issues/issue1384/page708252/1d037a096872334c7b99e4775f9ff09a.jpg',
        large: 'https://wp.tekniikanmaailma.fi/wp-content/uploads/asteikko_issues/issue1384/page708252/1d037a096872334c7b99e4775f9ff09a.jpg',
        zoom: 'https://wp.tekniikanmaailma.fi/wp-content/uploads/asteikko_issues/issue1384/page708252/1d037a096872334c7b99e4775f9ff09a.jpg',
        full: 'https://wp.tekniikanmaailma.fi/wp-content/uploads/asteikko_issues/issue1384/page708252/1d037a096872334c7b99e4775f9ff09a.jpg'
      },
      timestamp: 1029704400
    },
    slug: '18e-2002',
  },
  {
    html: 'Autovuosi 2002',
    name: '18E/2001 näköislehti',
    meta: {
      content_format: 'print_replica'
    },
    cover_image: {
      urls: {
        thumbnail: 'https://wp.tekniikanmaailma.fi/wp-content/uploads/asteikko_issues/issue1385/page711749/c856ee63c57093222c0797c53343f843-150x150.jpg',
        small: 'https://wp.tekniikanmaailma.fi/wp-content/uploads/asteikko_issues/issue1385/page711749/c856ee63c57093222c0797c53343f843-228x300.jpg',
        medium: 'https://wp.tekniikanmaailma.fi/wp-content/uploads/asteikko_issues/issue1385/page711749/c856ee63c57093222c0797c53343f843.jpg',
        large: 'https://wp.tekniikanmaailma.fi/wp-content/uploads/asteikko_issues/issue1385/page711749/c856ee63c57093222c0797c53343f843.jpg',
        zoom: 'https://wp.tekniikanmaailma.fi/wp-content/uploads/asteikko_issues/issue1385/page711749/c856ee63c57093222c0797c53343f843.jpg',
        full: 'https://wp.tekniikanmaailma.fi/wp-content/uploads/asteikko_issues/issue1385/page711749/c856ee63c57093222c0797c53343f843.jpg'
      },
      timestamp: 998168400
    },
    slug: '18e-2001',
  },
  {
    html: 'Suuri testinumero',
    name: '15/1999 näköislehti',
    meta: {
      content_format: 'print_replica'
    },
    cover_image: {
      urls: {
        thumbnail: 'https://wp.tekniikanmaailma.fi/wp-content/uploads/asteikko_issues/issue334/page373205/9503c3d058816395986e630578f2e10e-150x150.jpg',
        small: 'https://wp.tekniikanmaailma.fi/wp-content/uploads/asteikko_issues/issue334/page373205/9503c3d058816395986e630578f2e10e-233x300.jpg',
        medium: 'https://wp.tekniikanmaailma.fi/wp-content/uploads/asteikko_issues/issue334/page373205/9503c3d058816395986e630578f2e10e.jpg',
        large: 'https://wp.tekniikanmaailma.fi/wp-content/uploads/asteikko_issues/issue334/page373205/9503c3d058816395986e630578f2e10e.jpg',
        zoom: 'https://wp.tekniikanmaailma.fi/wp-content/uploads/asteikko_issues/issue334/page373205/9503c3d058816395986e630578f2e10e.jpg',
        full: 'https://wp.tekniikanmaailma.fi/wp-content/uploads/asteikko_issues/issue334/page373205/9503c3d058816395986e630578f2e10e.jpg'
      },
      timestamp: 932072400
    },
    slug: '15-1999',
  },
  {
    html: 'Vallankumoukset auton historiassa',
    name: '07E/1996 näköislehti',
    meta: {
      content_format: 'print_replica'
    },
    cover_image: {
      urls: {
        thumbnail: 'https://wp.tekniikanmaailma.fi/wp-content/uploads/asteikko_issues/issue1308/page489476/2708d7cc85558e8369ea05445a8f8158-150x150.jpg',
        small: 'https://wp.tekniikanmaailma.fi/wp-content/uploads/asteikko_issues/issue1308/page489476/2708d7cc85558e8369ea05445a8f8158-227x300.jpg',
        medium: 'https://wp.tekniikanmaailma.fi/wp-content/uploads/asteikko_issues/issue1308/page489476/2708d7cc85558e8369ea05445a8f8158.jpg',
        large: 'https://wp.tekniikanmaailma.fi/wp-content/uploads/asteikko_issues/issue1308/page489476/2708d7cc85558e8369ea05445a8f8158.jpg',
        zoom: 'https://wp.tekniikanmaailma.fi/wp-content/uploads/asteikko_issues/issue1308/page489476/2708d7cc85558e8369ea05445a8f8158.jpg',
        full: 'https://wp.tekniikanmaailma.fi/wp-content/uploads/asteikko_issues/issue1308/page489476/2708d7cc85558e8369ea05445a8f8158.jpg'
      },
      timestamp: 828910800
    },
    slug: '07e-1996',
  },
  {
    html: 'Testivaliot',
    name: '07B/1995 näköislehti',
    meta: {
      content_format: 'print_replica'
    },
    cover_image: {
      urls: {
        thumbnail: 'https://wp.tekniikanmaailma.fi/wp-content/uploads/asteikko_issues/issue1310/page494325/a77f98d7232920e7e29f21bfb0298fb4-150x150.jpg',
        small: 'https://wp.tekniikanmaailma.fi/wp-content/uploads/asteikko_issues/issue1310/page494325/a77f98d7232920e7e29f21bfb0298fb4-222x300.jpg',
        medium: 'https://wp.tekniikanmaailma.fi/wp-content/uploads/asteikko_issues/issue1310/page494325/a77f98d7232920e7e29f21bfb0298fb4.jpg',
        large: 'https://wp.tekniikanmaailma.fi/wp-content/uploads/asteikko_issues/issue1310/page494325/a77f98d7232920e7e29f21bfb0298fb4.jpg',
        zoom: 'https://wp.tekniikanmaailma.fi/wp-content/uploads/asteikko_issues/issue1310/page494325/a77f98d7232920e7e29f21bfb0298fb4.jpg',
        full: 'https://wp.tekniikanmaailma.fi/wp-content/uploads/asteikko_issues/issue1310/page494325/a77f98d7232920e7e29f21bfb0298fb4.jpg'
      },
      timestamp: 797288400
    },
    slug: '07b-1995',
  },
  {
    html: 'TM Miesten maailma Sportti',
    name: '15C/1988 näköislehti',
    meta: {
      content_format: 'print_replica'
    },
    cover_image: {
      urls: {
        thumbnail: 'https://wp.tekniikanmaailma.fi/wp-content/uploads/asteikko_issues/issue1316/page516451/2455b001c7690283eac2f2442526be1a-150x150.jpg',
        small: 'https://wp.tekniikanmaailma.fi/wp-content/uploads/asteikko_issues/issue1316/page516451/2455b001c7690283eac2f2442526be1a-208x300.jpg',
        medium: 'https://wp.tekniikanmaailma.fi/wp-content/uploads/asteikko_issues/issue1316/page516451/2455b001c7690283eac2f2442526be1a.jpg',
        large: 'https://wp.tekniikanmaailma.fi/wp-content/uploads/asteikko_issues/issue1316/page516451/2455b001c7690283eac2f2442526be1a.jpg',
        zoom: 'https://wp.tekniikanmaailma.fi/wp-content/uploads/asteikko_issues/issue1316/page516451/2455b001c7690283eac2f2442526be1a.jpg',
        full: 'https://wp.tekniikanmaailma.fi/wp-content/uploads/asteikko_issues/issue1316/page516451/2455b001c7690283eac2f2442526be1a.jpg'
      },
      timestamp: 585003600
    },
    slug: '15c-1988',
  },
  {
    html: 'TM Rakennusmaailma',
    name: '15B/1988 näköislehti',
    meta: {
      content_format: 'print_replica'
    },
    cover_image: {
      urls: {
        thumbnail: 'https://wp.tekniikanmaailma.fi/wp-content/uploads/asteikko_issues/issue1315/page514951/8b22b43033f867e79f7b00779e1162ad-150x150.jpg',
        small: 'https://wp.tekniikanmaailma.fi/wp-content/uploads/asteikko_issues/issue1315/page514951/8b22b43033f867e79f7b00779e1162ad-217x300.jpg',
        medium: 'https://wp.tekniikanmaailma.fi/wp-content/uploads/asteikko_issues/issue1315/page514951/8b22b43033f867e79f7b00779e1162ad.jpg',
        large: 'https://wp.tekniikanmaailma.fi/wp-content/uploads/asteikko_issues/issue1315/page514951/8b22b43033f867e79f7b00779e1162ad.jpg',
        zoom: 'https://wp.tekniikanmaailma.fi/wp-content/uploads/asteikko_issues/issue1315/page514951/8b22b43033f867e79f7b00779e1162ad.jpg',
        full: 'https://wp.tekniikanmaailma.fi/wp-content/uploads/asteikko_issues/issue1315/page514951/8b22b43033f867e79f7b00779e1162ad.jpg'
      },
      timestamp: 585003600
    },
    slug: '15b-1988',
  },
  {
    html: 'TM Rakennusmaailma',
    name: '07B/1988 näköislehti',
    meta: {
      content_format: 'print_replica'
    },
    cover_image: {
      urls: {
        thumbnail: 'https://wp.tekniikanmaailma.fi/wp-content/uploads/asteikko_issues/issue1319/page525213/71a4198b416bdc1335296eb1a9ad4ed3-150x150.jpg',
        small: 'https://wp.tekniikanmaailma.fi/wp-content/uploads/asteikko_issues/issue1319/page525213/71a4198b416bdc1335296eb1a9ad4ed3-210x300.jpg',
        medium: 'https://wp.tekniikanmaailma.fi/wp-content/uploads/asteikko_issues/issue1319/page525213/71a4198b416bdc1335296eb1a9ad4ed3.jpg',
        large: 'https://wp.tekniikanmaailma.fi/wp-content/uploads/asteikko_issues/issue1319/page525213/71a4198b416bdc1335296eb1a9ad4ed3.jpg',
        zoom: 'https://wp.tekniikanmaailma.fi/wp-content/uploads/asteikko_issues/issue1319/page525213/71a4198b416bdc1335296eb1a9ad4ed3.jpg',
        full: 'https://wp.tekniikanmaailma.fi/wp-content/uploads/asteikko_issues/issue1319/page525213/71a4198b416bdc1335296eb1a9ad4ed3.jpg'
      },
      timestamp: 576450000
    },
    slug: '07b-1988',
  },
  {
    html: 'TM Miesten maailma',
    name: '06C/1988 näköislehti',
    meta: {
      content_format: 'print_replica'
    },
    cover_image: {
      urls: {
        thumbnail: 'https://wp.tekniikanmaailma.fi/wp-content/uploads/asteikko_issues/issue1320/page527204/028959669d88e806090209505d743031-150x150.jpg',
        small: 'https://wp.tekniikanmaailma.fi/wp-content/uploads/asteikko_issues/issue1320/page527204/028959669d88e806090209505d743031-212x300.jpg',
        medium: 'https://wp.tekniikanmaailma.fi/wp-content/uploads/asteikko_issues/issue1320/page527204/028959669d88e806090209505d743031.jpg',
        large: 'https://wp.tekniikanmaailma.fi/wp-content/uploads/asteikko_issues/issue1320/page527204/028959669d88e806090209505d743031.jpg',
        zoom: 'https://wp.tekniikanmaailma.fi/wp-content/uploads/asteikko_issues/issue1320/page527204/028959669d88e806090209505d743031.jpg',
        full: 'https://wp.tekniikanmaailma.fi/wp-content/uploads/asteikko_issues/issue1320/page527204/028959669d88e806090209505d743031.jpg'
      },
      timestamp: 573688800
    },
    slug: '06c-1988',
  },
  {
    html: 'TM Rakennusmaailma',
    name: '05B/1988 näköislehti',
    meta: {
      content_format: 'print_replica'
    },
    cover_image: {
      urls: {
        thumbnail: 'https://wp.tekniikanmaailma.fi/wp-content/uploads/asteikko_issues/issue1321/page527972/c846c1de0c0f9211c37da887626be56a-150x150.jpg',
        small: 'https://wp.tekniikanmaailma.fi/wp-content/uploads/asteikko_issues/issue1321/page527972/c846c1de0c0f9211c37da887626be56a-214x300.jpg',
        medium: 'https://wp.tekniikanmaailma.fi/wp-content/uploads/asteikko_issues/issue1321/page527972/c846c1de0c0f9211c37da887626be56a.jpg',
        large: 'https://wp.tekniikanmaailma.fi/wp-content/uploads/asteikko_issues/issue1321/page527972/c846c1de0c0f9211c37da887626be56a.jpg',
        zoom: 'https://wp.tekniikanmaailma.fi/wp-content/uploads/asteikko_issues/issue1321/page527972/c846c1de0c0f9211c37da887626be56a.jpg',
        full: 'https://wp.tekniikanmaailma.fi/wp-content/uploads/asteikko_issues/issue1321/page527972/c846c1de0c0f9211c37da887626be56a.jpg'
      },
      timestamp: 573602400
    },
    slug: '05b-1988',
  },
  {
    html: 'Valokuvauslehti',
    name: '04B/1957 näköislehti',
    meta: {
      content_format: 'print_replica'
    },
    cover_image: {
      urls: {
        thumbnail: 'https://wp.tekniikanmaailma.fi/wp-content/uploads/asteikko_issues/issue1337/page585197/14770d348bb42ccbc03f3ff0f462eed3-150x150.jpg',
        small: 'https://wp.tekniikanmaailma.fi/wp-content/uploads/asteikko_issues/issue1337/page585197/14770d348bb42ccbc03f3ff0f462eed3-212x300.jpg',
        medium: 'https://wp.tekniikanmaailma.fi/wp-content/uploads/asteikko_issues/issue1337/page585197/14770d348bb42ccbc03f3ff0f462eed3.jpg',
        large: 'https://wp.tekniikanmaailma.fi/wp-content/uploads/asteikko_issues/issue1337/page585197/14770d348bb42ccbc03f3ff0f462eed3.jpg',
        zoom: 'https://wp.tekniikanmaailma.fi/wp-content/uploads/asteikko_issues/issue1337/page585197/14770d348bb42ccbc03f3ff0f462eed3.jpg',
        full: 'https://wp.tekniikanmaailma.fi/wp-content/uploads/asteikko_issues/issue1337/page585197/14770d348bb42ccbc03f3ff0f462eed3.jpg'
      },
      timestamp: -407210400
    },
    slug: '04b-1957',
  },
  {
    html: 'Radiolehti',
    name: '09B/1957 näköislehti',
    meta: {
      content_format: 'print_replica'
    },
    cover_image: {
      urls: {
        thumbnail: 'https://wp.tekniikanmaailma.fi/wp-content/uploads/asteikko_issues/issue1335/page582879/8906935c5eb7dba8d395f27a68c60cd7-150x150.jpg',
        small: 'https://wp.tekniikanmaailma.fi/wp-content/uploads/asteikko_issues/issue1335/page582879/8906935c5eb7dba8d395f27a68c60cd7-215x300.jpg',
        medium: 'https://wp.tekniikanmaailma.fi/wp-content/uploads/asteikko_issues/issue1335/page582879/8906935c5eb7dba8d395f27a68c60cd7.jpg',
        large: 'https://wp.tekniikanmaailma.fi/wp-content/uploads/asteikko_issues/issue1335/page582879/8906935c5eb7dba8d395f27a68c60cd7.jpg',
        zoom: 'https://wp.tekniikanmaailma.fi/wp-content/uploads/asteikko_issues/issue1335/page582879/8906935c5eb7dba8d395f27a68c60cd7.jpg',
        full: 'https://wp.tekniikanmaailma.fi/wp-content/uploads/asteikko_issues/issue1335/page582879/8906935c5eb7dba8d395f27a68c60cd7.jpg'
      },
      timestamp: -401680800
    },
    slug: '09b-1957',
  },
  {
    html: 'Rakentamisen ja asumisen erikoisnumero',
    name: '06/1976 näköislehti',
    meta: {
      content_format: 'print_replica'
    },
    cover_image: {
      urls: {
        thumbnail: 'https://wp.tekniikanmaailma.fi/wp-content/uploads/asteikko_issues/issue876/page600216/aee9f137f379d37dd439e06d37060108-150x150.jpg',
        small: 'https://wp.tekniikanmaailma.fi/wp-content/uploads/asteikko_issues/issue876/page600216/aee9f137f379d37dd439e06d37060108-215x300.jpg',
        medium: 'https://wp.tekniikanmaailma.fi/wp-content/uploads/asteikko_issues/issue876/page600216/aee9f137f379d37dd439e06d37060108.jpg',
        large: 'https://wp.tekniikanmaailma.fi/wp-content/uploads/asteikko_issues/issue876/page600216/aee9f137f379d37dd439e06d37060108.jpg',
        zoom: 'https://wp.tekniikanmaailma.fi/wp-content/uploads/asteikko_issues/issue876/page600216/aee9f137f379d37dd439e06d37060108.jpg',
        full: 'https://wp.tekniikanmaailma.fi/wp-content/uploads/asteikko_issues/issue876/page600216/aee9f137f379d37dd439e06d37060108.jpg'
      },
      timestamp: 194997600
    },
    slug: '06-1976',
  },
  {
    html: 'Rakentamisen ja asumisen erikoisnumero',
    name: '10/1976 näköislehti',
    meta: {
      content_format: 'print_replica'
    },
    cover_image: {
      urls: {
        thumbnail: 'https://wp.tekniikanmaailma.fi/wp-content/uploads/asteikko_issues/issue872/page596205/0d6ff4d2f6594b5e2ebbdeb5ed5a9787-150x150.jpg',
        small: 'https://wp.tekniikanmaailma.fi/wp-content/uploads/asteikko_issues/issue872/page596205/0d6ff4d2f6594b5e2ebbdeb5ed5a9787-215x300.jpg',
        medium: 'https://wp.tekniikanmaailma.fi/wp-content/uploads/asteikko_issues/issue872/page596205/0d6ff4d2f6594b5e2ebbdeb5ed5a9787.jpg',
        large: 'https://wp.tekniikanmaailma.fi/wp-content/uploads/asteikko_issues/issue872/page596205/0d6ff4d2f6594b5e2ebbdeb5ed5a9787.jpg',
        zoom: 'https://wp.tekniikanmaailma.fi/wp-content/uploads/asteikko_issues/issue872/page596205/0d6ff4d2f6594b5e2ebbdeb5ed5a9787.jpg',
        full: 'https://wp.tekniikanmaailma.fi/wp-content/uploads/asteikko_issues/issue872/page596205/0d6ff4d2f6594b5e2ebbdeb5ed5a9787.jpg'
      },
      timestamp: 200613600
    },
    slug: '10-1976',
  },
  {
    html: '63 Pientalon jättikatsaus',
    name: '18/1976 näköislehti',
    meta: {
      content_format: 'print_replica'
    },
    cover_image: {
      urls: {
        thumbnail: 'https://wp.tekniikanmaailma.fi/wp-content/uploads/asteikko_issues/issue864/page587950/703dba6bdecd0d6c66ae0dd2449bce7d-150x150.jpg',
        small: 'https://wp.tekniikanmaailma.fi/wp-content/uploads/asteikko_issues/issue864/page587950/703dba6bdecd0d6c66ae0dd2449bce7d-212x300.jpg',
        medium: 'https://wp.tekniikanmaailma.fi/wp-content/uploads/asteikko_issues/issue864/page587950/703dba6bdecd0d6c66ae0dd2449bce7d.jpg',
        large: 'https://wp.tekniikanmaailma.fi/wp-content/uploads/asteikko_issues/issue864/page587950/703dba6bdecd0d6c66ae0dd2449bce7d.jpg',
        zoom: 'https://wp.tekniikanmaailma.fi/wp-content/uploads/asteikko_issues/issue864/page587950/703dba6bdecd0d6c66ae0dd2449bce7d.jpg',
        full: 'https://wp.tekniikanmaailma.fi/wp-content/uploads/asteikko_issues/issue864/page587950/703dba6bdecd0d6c66ae0dd2449bce7d.jpg'
      },
      timestamp: 209253600
    },
    slug: '18-1976',
  },
  {
    html: 'Rakentamisen ja asumisen erikoisnumero',
    name: '22/1976 näköislehti',
    meta: {
      content_format: 'print_replica'
    },
    cover_image: {
      urls: {
        thumbnail: 'https://wp.tekniikanmaailma.fi/wp-content/uploads/asteikko_issues/issue860/page582717/1ffc22173aaddba1e2ac2fdd8b0fc1ee-150x150.jpg',
        small: 'https://wp.tekniikanmaailma.fi/wp-content/uploads/asteikko_issues/issue860/page582717/1ffc22173aaddba1e2ac2fdd8b0fc1ee-215x300.jpg',
        medium: 'https://wp.tekniikanmaailma.fi/wp-content/uploads/asteikko_issues/issue860/page582717/1ffc22173aaddba1e2ac2fdd8b0fc1ee.jpg',
        large: 'https://wp.tekniikanmaailma.fi/wp-content/uploads/asteikko_issues/issue860/page582717/1ffc22173aaddba1e2ac2fdd8b0fc1ee.jpg',
        zoom: 'https://wp.tekniikanmaailma.fi/wp-content/uploads/asteikko_issues/issue860/page582717/1ffc22173aaddba1e2ac2fdd8b0fc1ee.jpg',
        full: 'https://wp.tekniikanmaailma.fi/wp-content/uploads/asteikko_issues/issue860/page582717/1ffc22173aaddba1e2ac2fdd8b0fc1ee.jpg'
      },
      timestamp: 214869600
    },
    slug: '22-1976',
  },
  {
    html: 'TM 25 vuotta',
    name: '03/1978 näköislehti',
    meta: {
      content_format: 'print_replica'
    },
    cover_image: {
      urls: {
        thumbnail: 'https://wp.tekniikanmaailma.fi/wp-content/uploads/asteikko_issues/issue835/page547301/191beb639876e4b10a97c998c5a06d28-150x150.jpg',
        small: 'https://wp.tekniikanmaailma.fi/wp-content/uploads/asteikko_issues/issue835/page547301/191beb639876e4b10a97c998c5a06d28-215x300.jpg',
        medium: 'https://wp.tekniikanmaailma.fi/wp-content/uploads/asteikko_issues/issue835/page547301/191beb639876e4b10a97c998c5a06d28.jpg',
        large: 'https://wp.tekniikanmaailma.fi/wp-content/uploads/asteikko_issues/issue835/page547301/191beb639876e4b10a97c998c5a06d28.jpg',
        zoom: 'https://wp.tekniikanmaailma.fi/wp-content/uploads/asteikko_issues/issue835/page547301/191beb639876e4b10a97c998c5a06d28.jpg',
        full: 'https://wp.tekniikanmaailma.fi/wp-content/uploads/asteikko_issues/issue835/page547301/191beb639876e4b10a97c998c5a06d28.jpg'
      },
      timestamp: 255391200
    },
    slug: '03-1978',
  },
  {
    html: 'Auto 80',
    name: '05/1980 näköislehti',
    meta: {
      content_format: 'print_replica'
    },
    cover_image: {
      urls: {
        thumbnail: 'https://wp.tekniikanmaailma.fi/wp-content/uploads/asteikko_issues/issue793/page505640/03a7be8c1fdce020e45753682a0a87b8-150x150.jpg',
        small: 'https://wp.tekniikanmaailma.fi/wp-content/uploads/asteikko_issues/issue793/page505640/03a7be8c1fdce020e45753682a0a87b8-214x300.jpg',
        medium: 'https://wp.tekniikanmaailma.fi/wp-content/uploads/asteikko_issues/issue793/page505640/03a7be8c1fdce020e45753682a0a87b8.jpg',
        large: 'https://wp.tekniikanmaailma.fi/wp-content/uploads/asteikko_issues/issue793/page505640/03a7be8c1fdce020e45753682a0a87b8.jpg',
        zoom: 'https://wp.tekniikanmaailma.fi/wp-content/uploads/asteikko_issues/issue793/page505640/03a7be8c1fdce020e45753682a0a87b8.jpg',
        full: 'https://wp.tekniikanmaailma.fi/wp-content/uploads/asteikko_issues/issue793/page505640/03a7be8c1fdce020e45753682a0a87b8.jpg'
      },
      timestamp: 321141600
    },
    slug: '05-1980',
  },
]
