import React, { Component } from 'react'
import PropTypes from 'prop-types'
import './ArticleComment.scss'
import DateField from '../widgets/DateField'
import HTML from '../util/HTML'
import WP from '../../../lib/WP'
import Modal from 'react-modal'
import { TmLogo } from '../../widgets/Icons'

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    color: 'black',
    padding: 0,
    borderRadius: '4px',
    maxWidth: '600px',
    width: '90%',
  }
}

class ArticleComment extends Component {
  static propTypes = {
    comment: PropTypes.object.isRequired,
    quote: PropTypes.func,
    isLast: PropTypes.bool,
    hideActions: PropTypes.bool,
    admins: PropTypes.array.isRequired
  }

  state = {
    showModal: false,
    reportReason: '',
    email: ''
  }

  handleReport = () => {
    WP.reportComment(this.props.comment, this.state.reportReason, this.state.email).then(() =>
      this.setState({ showModal: false, reportReason: '', email: '' })
    )
  }

  closeModal = () => {
    this.setState({ showModal: false })
  }

  showModal = () => {
    this.setState({ showModal: true })
  }

  handleChange = (event) => {
    this.setState({ reportReason: event.target.value })
  }

  handleEmailChange = (event) => {
    this.setState({ email: event.target.value })
  }

  isAdminComment = () => {
    const { comment, admins } = this.props
    return admins.includes(comment.author) && comment.date > '2014-12'
  }

  render () {
    const { comment, isLast, hideActions } = this.props
    const { showModal, responseText, error, reportReason, email } = this.state
    return (
      <div styleName="main" id={isLast ? 'lastComment' : ''}>
        <div styleName="header">
          <div styleName="meta">
            <span
              styleName={'author' + (this.isAdminComment() ? ' admin' : '')}>{comment.author_name}</span>
            <span styleName="date">
              <DateField date={comment.date} />
            </span>
            {this.isAdminComment()
              ? <span styleName="is-admin-author"><div styleName="red-logo"><TmLogo /></div>Toimituksen kommentti</span>
              : null
            }
          </div>
        </div>
        <div styleName="text"><HTML>{comment.content.rendered}</HTML></div>
        {hideActions
          ? null
          : <div styleName="actions">
            <button styleName="report" onClick={ this.showModal }>Raportoi</button>
            <button styleName="quote" onClick={ this.quote }>Vastaa</button>
          </div>
        }
        { showModal
          ? <Modal
            isOpen={showModal}
            onRequestClose={this.closeModal}
            style={customStyles}
            contentLabel="Ilmoita asiaton viesti">
            {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
            <div id="login-modal" onKeyDown={(e) => e.stopPropagation()}
              onTouchStart={(e) => e.stopPropagation()}
              onTouchMove={(e) => e.stopPropagation()}
              onMouseDown={(e) => e.stopPropagation()}>
              <header styleName="modal-header">
                <h2>Miksi tämä viesti tulisi poistaa?</h2>
                <button styleName="close-button" onClick={this.closeModal}>
                  <span aria-label="Sulje modaali">×</span>
                </button>
              </header>
              {responseText
                ? <p styleName={`response-text ${error ? 'error' : ''}`}>{responseText}</p>
                : null
              }
              <section styleName="modal-body">
                <label styleName="email" aria-hidden="true" htmlFor="email">
                  Tämä kenttä on validointitarkoituksiin ja tulee jättää koskemattomaksi.
                  <input value={email} name="email" type="email" onChange={this.handleEmailChange}/>
                </label>
                <textarea value={reportReason} rows="6" cols="50" onChange={this.handleChange}></textarea>
                <div styleName="button-row">
                  <button styleName="cancel-button" type="reset" onClick={this.closeModal}>Peruuta</button>
                  <button styleName="submit-button" type="button" onClick={this.handleReport}>Lähetä</button>
                </div>
              </section>
            </div>
          </Modal>
          : null }
      </div>
    )
  }

  quote = () => {
    const { comment, quote } = this.props
    document.querySelector('#commentBox').scrollIntoView(false)
    quote && quote(comment)
  }
}

export default ArticleComment
