import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import CategoryList from '../lists/CategoryList'
import ArticleMetaDivider from './ArticleMetaDivider'
import ArticleDate from './ArticleDate'
import { SubscriberIcon } from '../../widgets/Icons'
import { MagazineLabel } from '../../widgets/MagazineLabel'
import './ArticleMeta.scss'

const ArticleMetaData = ({
  categories,
  mainCategoryOnly = true,
  date,
  dateOnly = false,
  displaySubscriberIcon = false,
  divider = true,
  magazineLabel,
  noLink = false
}) => {
  return (
    <Fragment>
      <CategoryList categories={categories} mainOnly={mainCategoryOnly} noLink={noLink}/>
      <div styleName="meta">
        {divider ? <ArticleMetaDivider/> : null}
        <ArticleDate date={date} dateOnly={dateOnly} />
        {displaySubscriberIcon && <div styleName="subscriber-icon-container"><SubscriberIcon/></div>}
      </div>
      {magazineLabel && <div styleName="magazine-label-container"><MagazineLabel text={magazineLabel}/></div>}
    </Fragment>
  )
}

ArticleMetaData.propTypes = {
  categories: PropTypes.array,
  mainCategoryOnly: PropTypes.bool,
  displaySubscriberIcon: PropTypes.bool,
  date: PropTypes.string,
  dateOnly: PropTypes.bool,
  divider: PropTypes.bool,
  noLink: PropTypes.bool,
  magazineLabel: PropTypes.string,
}

export default ArticleMetaData
