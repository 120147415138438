import React, { Component } from 'react'
import PropTypes from 'prop-types'

import isEqual from 'lodash/isEqual'
import { connect } from 'kea'
import querystring from 'querystring'
import debouncedRender from 'react-debounce-render'
import application from '../kea/application'

import WP from '../lib/WP'
import ArticleLoader from '../components/general/util/ArticleLoader'
import MagazineArticleLoader from '../components/general/util/MagazineArticleLoader'
import Article from './Article'

import { RenderedError } from '../lib/errors'
import renderDebugger from '../lib/debug-render'
import { STATUS } from '../lib/request-state'
import auth from '../kea/auth'

@connect({
  actions: [
    application, [
      'setViewData',
      'updateResolverStatus',
    ],
  ],
  props: [
    auth, [
      'premiumUser',
      'loggedIn'
    ],
    application, [
      'view',
    ],
  ]
})
@renderDebugger
class Resolver extends Component {
  state = {
    routeComponent: null,
    componentProps: {},
    isPreview: false,
    status: STATUS.NOT_REQUESTED,
  }

  static propTypes = {
    setRendered: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    view: PropTypes.object,
    history: PropTypes.object,
    loggedIn: PropTypes.bool,
    premiumUser: PropTypes.bool,
    actions: PropTypes.object,
  }

  viewUpdating = false

  async componentDidMount () {
    this.doRouting(this.props)
    // this.chooseComponent()
  }

  componentWillReceiveProps (nextProps) {
    const viewNotEqual = !isEqual(this.props.view, nextProps.view)
    const locationNotEqual = !isEqual(this.props.location.pathname, nextProps.location.pathname)

    if (viewNotEqual) {
      // console.log('Resolver: view has changed, will not route again')
      this.viewUpdating = true
    } else {
      this.viewUpdating = false
    }

    if (locationNotEqual) {
      this.doRouting(nextProps)
    }
  }

  async doRouting (props) {
    const { location, setRendered } = props
    const { setViewData, updateResolverStatus } = this.actions

    try {
      const qs = location.search && querystring.parse(location.search.substring(1))
      const url = WP.url + location.pathname + (
        (qs.p && qs.preview) ? location.search : ''
      )

      this.setState({
        isPreview: Boolean(qs.preview),
        status: STATUS.REQUESTED
      })

      updateResolverStatus(STATUS.REQUESTED)

      const { data } = await WP.getForURL(url, { shared: qs.shared }, true)

      if (data) {
        if (data.error) {
          updateResolverStatus(STATUS.ERROR)
          throw data.error
        }

        const routeComponent = Article
        const componentProps = {
          doneLoading: () => this.props.setRendered(true)
        }

        this.setState({
          status: STATUS.DONE,
          routeComponent,
          componentProps,
        }, () => {
          setViewData(data)
        })
      }
    } catch (e) {
      this.setState({
        status: STATUS.ERROR,
        componentProps: { error: e },
      }, () => setRendered(true))
    }

    updateResolverStatus(STATUS.DONE)
  }

  render () {
    const { routeComponent: RenderedComponent, componentProps, status } = this.state

    if (status === STATUS.ERROR) {
      return <RenderedError {...componentProps} />
    } else if (status !== STATUS.DONE || !RenderedComponent || this.viewUpdating || (!this.props.view || !this.props.view.id)) {
      if (this.props.match.path === '/lehti/:number/:article') {
        return <MagazineArticleLoader />
      } else if (this.props.match.path === '/mainoslehti/:number/:article') {
        return <MagazineArticleLoader />
      } else {
        return <ArticleLoader />
      }
    }

    return (
      <RenderedComponent {...componentProps} history={this.props.history} />
    )
  }
}

// export default Resolver
export default debouncedRender(Resolver)
