import React from 'react'
import './ArticleMeta.scss'

const ArticleMetaDivider = () => {
  return (
    <span styleName='divider'>|</span>
  )
}

export default ArticleMetaDivider
