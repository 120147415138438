import PropTypes from 'prop-types'
import { put } from 'redux-saga/effects'
import { kea } from 'kea'

export default kea({
  paths: ['articleComments'], // default
  actions: () => ({
    submitComment: (articleId, comment) => ({ articleId, comment }),
    updateStatus: status => status,
  }),

  reducers: ({ actions }) => ({
    submitted: [false, PropTypes.bool, { persist: false }, {
      [actions.updateStatus]: (state, payload) => payload
    }],
  }),

  takeEvery: ({ actions, workers }) => ({
    [actions.submitComment]: function * ({ type, payload }) {
      // console.log('submitComment - type:', type, 'payload:', payload)
      yield put(actions.updateStatus(true))
    },
  }),
})
