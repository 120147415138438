import {
  withScope,
  init,
  captureException,
} from '@sentry/browser'
import { isProduction } from '@otavamedia/om-component-library/lib/util/env'

let sentryEnabled = false

export function consoleLoggingEnabled () {
  return process.env.SENTRY_CONSOLE_LOGGING === 'true'
}

const setupSentry = () => {
  if (!sentryEnabled) {
    return
  }
  if (process.env.SENTRY_ENABLED !== 'true') {
    console.warn('Sentry disabled')
    return
  }

  const dsn = process.env.SENTRY_DSN
  const release = process.env.SENTRY_RELEASE_ID

  if (!dsn) {
    console.warn('Sentry disabled: No DSN provided')
    return
  }

  const conf = {
    dsn,
    release,
  }

  if (isProduction()) {
    conf.whitelistUrls = [
      /https?:\/\/([a-z0-9]+[.])*tekniikanmaailma\.fi/,
      /https?:\/\/([a-z0-9]+[.])*googletagmanager\.com/,
      /https?:\/\/([a-z0-9]+[.])*googletagservices\.com/,
      /https?:\/\/([a-z0-9]+[.])*google-analytics\.com/,
      /https?:\/\/([a-z0-9]+[.])*google\.com/,
      /https?:\/\/([a-z0-9]+[.])*pubmatic\.com/,
    ]
  }

  // console.log('Setup sentry: ', conf)

  init(conf)

  sentryEnabled = false // disable sentry temporarily, as error in adCoin causes crashes
}

const handleException = sentryEnabled
  ? (error) => {
    if (consoleLoggingEnabled()) {
      console.error(error)
    }

    captureException(error)
  }
  : console.error

const handleErrorBoundaryError = sentryEnabled
  ? (error, errorInfo) => {
    if (consoleLoggingEnabled()) {
      console.error(error, errorInfo)
    }

    withScope(scope => {
      Object.keys(errorInfo).forEach(key => {
        scope.setExtra(key, errorInfo[key])
      })

      captureException(error)
    })
  }
  : console.error

export {
  setupSentry,
  handleException,
  handleErrorBoundaryError,
}
