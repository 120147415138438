import get from 'lodash/get'
import ImageModel from './ImageModel'

export default class AuthorModel {
  id
  name
  title
  ingress
  description
  twitter
  email

  constructor (WPModel) {
    this.id = WPModel.id
    this.name = WPModel.name
    this.image = get(WPModel, 'acf.image') || null
    this.image = this.image ? new ImageModel(this.image) : null
    this.title = get(WPModel, 'acf.title') || ''
    this.ingress = get(WPModel, 'acf.ingress') || ''
    this.twitter = get(WPModel, 'acf.twitter') || ''
    this.email = get(WPModel, 'acf.email') || ''
    this.description = (WPModel.description || '').replace(/(\r?\n)/g, '<br/>')
  }

  static createFromWPPost (WPModel) {
    const author = get(WPModel, 'acf.author')
    if (author && author.length) {
      return [new AuthorModel({ id: null, name: author })]
    } else {
      return get(WPModel, '_embedded.author', [{ id: null, name: 'Tekniikan Maailma' }]).map((author) => new AuthorModel(author))
    }
  }
}
