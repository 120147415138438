import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import TestCard from '../components/widgets/TestCard'
import { connect } from 'kea'
import auth from '../kea/auth'
import application from '../kea/application'
import HTML from '../components/general/util/HTML'
import { Link } from '../components/general/util/Links'
import BestProductPageLoader from '../components/general/util/BestProductPageLoader'
import BestProductLoader from '../components/general/util/BestProductLoader'
import { CaretDown } from '../components/widgets/Icons'
import { ErrorPlaceholder, withErrorBoundary } from '../components/general/util/ErrorBoundaries'
import WP from '../lib/WP'
import { renderError } from '../lib/errors'
import { STATUS } from '../lib/request-state'
import ArticleModel from '../entities/ArticleModel'
import Image from '../components/general/util/Image'
import { stripHTML } from '../lib/utils'
import bestLogo from '../assets/logos/TM_ovaalilogot_parhaat.png'

import './BestProductCollection.scss'
import { IMAGE_SIZE } from '../entities/ImageModel'
import track from 'react-tracking'

const getPageFromUrl = (link) => {
  const regex = /^(.*\/)([0-9]+)(\/)$/
  return link && parseInt(link.replace(regex, '$2'))
}

@connect({
  actions: [
    application, [
      'setViewData',
    ]
  ],
  props: [
    application, [
      'view',
    ],
    auth, [
      'premiumUser',
    ],
  ]
})
class BestProductCollection extends Component {
  static propTypes = {
    view: PropTypes.object,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    setRendered: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    premiumUser: PropTypes.bool,
  }

  constructor (props) {
    super()
    this.state = {
      data: [],
      status: STATUS.NOT_REQUESTED,

      page: getPageFromUrl(window.location.href) || 1,
      maxPages: 1,

      categories: [], // Available filters
      mainTerm: null, // Used to get products
      secondaryTerm: 0, // Used to filter products
      order: 'desc',
      waitForCategory: false
    }

    this.state.categoryName = props.match ? props.match.params.category : null
    if (this.state.categoryName) {
      this.state.waitForCategory = true
      this.loadCategoryByName(this.state.categoryName)
    }
  }

  componentDidMount () {
    if (!this.state.waitForCategory) {
      this.loadData()
    }
  }

  componentWillUnmount () {
    this.props.setRendered(false)
  }

  componentWillReceiveProps (nextProps) {
    if (nextProps.location.key !== this.props.location.key) {
      this.loadData()
    }
  }

  async loadCategoryByName (categoryName) {
    const category = await WP.getTerms('category', {
      slug: categoryName,
      per_page: 1,
      hide_empty: false,
      cache: {
        expireAfter: Date.now() + 3600000 * 24 // 1 day
      }
    })
    this.setState({ secondaryTerm: category.data[0].id }, this.loadData)
  }

  async loadData () {
    try {
      const [postReq, mainTermReq] = await Promise.all([
        WP.getForURL(WP.url + '/testivoittajat'),
        WP.getTerms('category', {
          slug: 'testivoittaja',
          per_page: 1,
          hide_empty: false,
          cache: {
            expireAfter: Date.now() + 3600000 * 24 // 1 day
          }
        }),
      ])
      const mainTerm = mainTermReq.data[0]
      const post = postReq.data

      this.setState({ mainTerm })
      this.actions.setViewData(post)
      this.setState({ categories: post.categoriesAvailable })
      await this.getProducts()
    } catch (e) {
      this.setState({
        status: STATUS.ERROR,
        data: e,
      })
    }

    if (this.props.view.id) {
      WP.triggerHitCounter(this.props.view.id)
    }
  }

  async getProducts (givenPage = -1) {
    const { mainTerm, secondaryTerm, page, order } = this.state
    this.props.setRendered(false)

    try {
      const params = {
        paged: givenPage >= 0 ? givenPage : page,
        order,
        posts_per_page: 12,
        tax_query: [
          {
            taxonomy: mainTerm.taxonomy,
            field: 'term_id',
            terms: [mainTerm.id],
          },
        ]
      }

      const cache = {
        expireAfter: Date.now() + 3600000,
      }

      if (secondaryTerm) {
        params.tax_query = [
          ...params.tax_query,
          {
            taxonomy: 'category',
            field: 'term_id',
            terms: [secondaryTerm],
          }
        ]
      }

      this.setState({ status: STATUS.REQUESTED })

      const r = await WP.get('/wp-json/wp_query/args/', { params, cache })
      const { data, headers } = r

      this.setState({
        data: [...this.state.data, ...data.map(x => new ArticleModel(x))],
        status: STATUS.DONE,
        maxPages: Math.min(parseInt(headers['x-wp-totalpages'], 10), 1000),
      }, () => this.props.setRendered(true))
    } catch (e) {
      this.setState({
        status: STATUS.ERROR,
        data: e,
      })
    }
  }

  handleFormChange = (e) => {
    const { target } = e
    // console.log(target.name, target.value)
    if (target.name === 'secondaryTerm') {
      // eslint-disable-next-line eqeqeq
      const catSlug = this.state.categories.filter((x) => x.term_id == target.value)[0].slug
      this.setState({
        [target.name]: target.value,
        data: [],
        page: 1,
      })
      this.props.history.replace('/testivoittajat/' + catSlug + '/')
    } else {
      this.setState({
        [target.name]: target.value,
        data: [],
        page: 1,
      }, this.getProducts)
    }
  }

  addPage = () => {
    this.getProducts(this.state.page + 1)
    this.setState({
      page: this.state.page + 1
    })
  }

  renderProducts (products) {
    const { page, maxPages, status } = this.state

    const items = products.map((p, i) => (
      <li styleName="result-item" key={`${i}-${p.id}`}>
        <Link to={p}>
          <TestCard article={p} notShown={{ createdDate: true }} isBest={true}/>
        </Link>
      </li>
    ))

    const loading = status === STATUS.REQUESTED

    return (
      <Fragment>
        <ul styleName="result-list" data-loading={loading}>
          {items}
        </ul>
        {
          loading
            ? <BestProductLoader />
            : (
              page < maxPages
                ? (
                  <div styleName="load-more">
                    <button styleName="load-more" onClick={() => this.addPage()}>
                      Lataa lisää
                    </button>
                  </div>
                )
                : null
            )
        }
      </Fragment>
    )
  }

  render () {
    const { status, data, categories, secondaryTerm } = this.state
    const { view, premiumUser } = this.props

    if (status !== STATUS.ERROR && (!view || !view.id)) {
      return <BestProductPageLoader />
    }

    const {
      content,
      title,
      excerpt,
      createdDate,
      modifiedDate,
      link,
      featuredMedia
    } = view

    const fullLink = window.location.origin + link
    const metaTags = {
      'og:locale': 'fi_FI',
      'og:type': 'article',
      'og:title': stripHTML(title),
      'og:description': stripHTML(excerpt),
      'og:url': fullLink,

      // og:type is article, more tags for it:
      'article:published_time': createdDate,
      'article:modified_time': modifiedDate,

      'http:status': 200,
    }
    /* eslint-disable jsx-a11y/no-onchange */
    /* eslint-disable jsx-a11y/label-has-for */
    return (
      <Fragment>
        <Helmet>
          <title>
            {stripHTML(title) + ' - Tekniikan Maailma'}
          </title>
          <link rel="canonical" href={fullLink} />
          <meta name="description" content={metaTags['og:description']}/>
          {Object.entries(metaTags).map(([key, value], i) => Array.isArray(value)
            ? (
              value.map(x => (
                <meta name={key} key={`${key}-${i}`} content={x} />
              ))
            )
            : (
              <meta name={key} key={`${key}-${i}`} content={value} />
            ))}
        </Helmet>
        <div styleName="main-image">
          {featuredMedia
            ? <Image data={featuredMedia} size={IMAGE_SIZE.LARGE} sizes={'(max-width: 1440px) 100vw, 1440px'}/>
            : null
          }
        </div>
        <div styleName='magazine-content'>
          <article styleName="article-container">
            <Link to={{ link: '/testivoittajat/' }}>
              <img styleName="best-icon" src={bestLogo} alt=""/>
            </Link>
            <header styleName="below-best-icon">
              <h1><HTML>{title}</HTML></h1>
            </header>
            <div styleName="article-body" className={premiumUser ? 'user-level-4' : ''}>
              <HTML>{content}</HTML>
            </div>
          </article>
          <form styleName="filterform">
            <div styleName="result-filters">
              <div>
                <label htmlFor="sort">Kategoria</label>
                <div styleName="select-div">
                  <select id="category-select" name="secondaryTerm" onChange={this.handleFormChange} value={secondaryTerm}>
                    <option value="0">Valitse</option>
                    {categories && categories.length
                      ? categories.map(x => (
                        <option value={x.term_id} key={x.term_id}>{x.name}</option>
                      ))
                      : <option value="">Kategorioita ladataan...</option>}
                  </select>
                  <div styleName="select-caret"><CaretDown color="red"/></div>
                </div>
              </div>

              <div>
                <label htmlFor="sort">Järjestä</label>
                <div styleName="select-div">
                  <select id="sort-select" name="order" onChange={this.handleFormChange} defaultValue="desc">
                    <option value="desc">Uusimmat</option>
                    <option value="asc">Vanhimmat</option>
                  </select>
                  <div styleName="select-caret"><CaretDown color="red"/></div>
                </div>
              </div>
            </div>
          </form>

          <section styleName="results">
            {data.length
              ? (
                this.renderProducts(data)
              )
              : status === STATUS.ERROR
                ? (
                  renderError(data)
                )
                : (
                  <BestProductLoader/>
                )}
          </section>
        </div>
      </Fragment>
    )
    /* eslint-enable jsx-a11y/no-onchange */
    /* eslint-enable jsx-a11y/label-has-for */
  }
}

export default track({ gtmContext: ['BestProductCollection'] })(withErrorBoundary(
  BestProductCollection,
  ErrorPlaceholder()
))
