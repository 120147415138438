import React, { Fragment, Component } from 'react'
import PropTypes from 'prop-types'
import PaginatingList from '../pagination/PaginatingList'
import WP from '../../../lib/WP'
import { errors as err } from '../../../lib/errors'
import times from 'lodash/times'
import querystring from 'querystring'

import './PostList.scss'
import ArticleListItem from '../article/ArticleListItem'

const getMax = (headers, key) => headers && headers[key]
  ? parseInt(headers[key], 10)
  : 0

class PostList extends Component {
  _mounted = false
  asyncSetState (...args) {
    // avoid setting state after unmount in case async operations attempts to do so
    if (this._mounted) this.setState(...args)
  }

  constructor (props) {
    super(props)
    let page = 1
    if (location.search) {
      const qs = querystring.parse(location.search.substring(1))
      if (qs.page) {
        page = parseInt(qs.page) || 1
      }
    }
    this.state = {
      layout: props.layout ? props.layout : defaultLayout,
      page,
      maxPages: 1,
      excluded: []
    }
  }

  componentDidMount () {
    this._mounted = true
  }

  componentWillUnmount () {
    this._mounted = false
  }

  componentWillReceiveProps (nextProps) {
    const qs = querystring.parse(location.search.substring(1))
    if (nextProps.context.id !== this.props.context.id) {
      this.asyncSetState({ excluded: [], page: (qs && parseInt(qs.page)) || 1, layout: nextProps.layout ? nextProps.layout : defaultLayout })
    } else if (qs.page) {
      this.setState({ page: parseInt(qs.page) || 1 })
    }
  }

  async loadItems (stickies = [], layout, offset, digimagIdx, page, context, exclude = []) {
    if (!context) {
      throw err.noContextError()
    }

    // this Array.from(new Set... is used to remove duplicates
    exclude = Array.from(new Set(exclude.concat(stickies.map(item => item.id)).concat(this.state.excluded)))
    const result = await WP.getForContext(context, {
      exclude,
      per_page: layout.postCount,
      offset // all posts on previous pages are excluded, so we don't need an offset
    })
    const posts = result ? result.data : []
    exclude = Array.from(new Set(exclude.concat(posts.map(item => item.id))))

    const result2 = layout.digimagCount
      ? await WP.getFromPostTypes('om_digimag_post', {
        exclude,
        per_page: layout.digimagCount,
        offset: digimagIdx
      }, 'digimag-posts')
      : null

    const digimagPosts = result2 ? result2.data : []

    const headers = result ? result.headers : {}
    const maxPages = Math.min(getMax(headers, 'x-wp-totalpages'), 1000)
    const maxPosts = Math.min(getMax(headers, 'x-wp-total'), 10000)

    const articleList = []
    layout.boxes.forEach((box) =>
      box.items.forEach((item) => {
        if (item.contentType === 'sticky') {
          articleList.push(stickies.shift())
        } else if (item.contentType === 'newsItem') {
          articleList.push(posts.shift())
        } else if (item.contentType === 'digimagItem') {
          articleList.push(digimagPosts.shift())
        }
      })
    )

    this.asyncSetState({ maxPages, excluded: exclude })

    return {
      items: articleList,
      maxPages,
      maxPosts,
    }
  }

  addPage (e) {
    this.setState({ page: this.state.page + 1 })
    this.props.history.replace('?page=' + (this.state.page + 1))
    e.preventDefault()
  }

  render () {
    const { layout, maxPages, page } = this.state
    const { props } = this
    const nextPage = props.history.location.pathname + '?page=' + (page + 1)
    const pages = times(this.state.page, (i) => i + 1)

    return <Fragment>
      {pages.map((page, idx) => {
        const stickies = props.stickies && props.stickies.length
          ? props.stickies.slice(idx * layout.stickyCount, (idx + 1) * layout.stickyCount)
          : []
        const digimagIdx = idx * layout.digimagCount
        return idx < maxPages &&
        <PaginatingList {...props} loadItems={this.loadItems.bind(this, stickies, layout, props.offset || 0, digimagIdx)}
          key={idx} page={page} ListItem={ArticleListItem} className="post-list" layout={layout} notShown={props.notShown}
          sizes="(max-width: 768px) 100vw, 950px"/>
      })}
      {layout.loadMore && maxPages > pages.length
        ? <div styleName="load-more">
          <a onClick={(e) => this.addPage(e)} href={nextPage}>Lataa lisää</a>
        </div>
        : null}
    </Fragment>
  }
}

PostList.propTypes = {
  context: PropTypes.object.isRequired,
  layout: PropTypes.object,
  stickies: PropTypes.array,
  offset: PropTypes.number,
  history: PropTypes.object,
  notShown: PropTypes.object
}

export default PostList

const defaultLayout = {
  stickyCount: 0,
  postCount: 8,
  boxes: [
    {
      boxSize: 'x-large-col',
      items: [
        {
          size: 'lg',
          contentType: 'newsItem'
        },
      ]
    },
    {
      boxSize: 'x-large-col',
      items: [
        {
          size: 'xs',
          contentType: 'newsItem',
        },
        {
          size: 'xs',
          contentType: 'ad',
        },
      ]
    },
    {
      boxSize: 'medium-col',
      items: [
        {
          size: 'md',
          contentType: 'newsItem',
          noImage: true
        },
      ]
    },
    {
      boxSize: 'medium-col',
      items: [
        {
          size: 'md',
          contentType: 'newsItem',
        },
      ]
    },
    {
      boxSize: 'x-large-col',
      items: [
        {
          size: 'lg',
          contentType: 'ad',
        },
      ]
    },
    {
      boxSize: 'medium-col',
      items: [
        {
          size: 'md',
          contentType: 'newsItem',
        },
      ]
    },
    {
      boxSize: 'medium-col',
      items: [
        {
          size: 'md',
          contentType: 'newsItem',
        },
      ]
    },
    {
      boxSize: 'x-large-col',
      items: [
        {
          size: 'xs',
          contentType: 'newsItem',
        },
      ]
    },
    {
      boxSize: 'medium-col',
      items: [
        {
          size: 'md',
          contentType: 'nativeAd',
        },
      ]
    },
    {
      boxSize: 'medium-col',
      items: [
        {
          size: 'md',
          contentType: 'newsItem',
        },
      ]
    },
    {
      boxSize: 'x-large-col',
      items: [

        {
          size: 'lg',
          contentType: 'newsItem',
        },
      ]
    },
    {
      boxSize: 'medium-col',
      items: [
        {
          size: 'md',
          contentType: 'ad',
        },
      ]
    },
    {
      boxSize: 'medium-col',
      items: [
        {
          size: 'md',
          contentType: 'ad',
        }
      ]
    }
  ]
}
