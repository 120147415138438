import ImageModel from './ImageModel'

export default class IssueNavigationModel {
  previousFive
  nextFive

  constructor (WPData) {
    this.previousFive = WPData.previousFive.map((item) => ({
      ...item,
      coverImage: new ImageModel(item.coverImage)
    }))
    this.nextFive = WPData.nextFive.map((item) => ({
      ...item,
      coverImage: new ImageModel(item.coverImage)
    }))
  }
}
